import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete, Box, Grid } from '@mui/material';
import { get, post } from '../../libraries/network';
import Link from '@mui/material/Link';
import { ProgressDialog } from './progressDialog';
import AlertDialog from './alertDialog';


export function BookEventByCash( props ) {

  const {handleClose, setProgress, setError, events, access,reload} = props;
  const [event, setEvent] = React.useState(null);
  const [dates, setDates] = React.useState([]);
  const [option, setOption] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {

    setDates([]);
    setOption('');
    setPrice(0);
  }, [event])

  React.useEffect(() => {
    if(!!option)
    {
      setPrice((parseFloat(option?.price) * dates.length).toFixed(2));
    }
  }, [option, dates])



  React.useEffect(() => {

    if(!event || !option || !dates)
    {
      setReady(false);
    }
    else
    {
      setReady(true);
    }
  }, [event, option, dates])

  const handleChange = (event) => {
    setEvent(event);

  };


  const handleDateChange = ( e ) => {
    setDates(e.target.value);
  }

  const handleDateSelectAll = ( e ) => {
    setDates(event?.dates || []);
  }

  const handleOptionChange = ( e ) => {
    setOption(e.target.value);
  }

  const handlePriceChange = (e ) => {
    setPrice(parseFloat(e.target.value))
  }

  const formatDate = ( d ) => {
      const w = new Date( d );
      const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      const z = w.toLocaleDateString();
      return `${z} ${x}`;

  }
  const onSave = async(e) => {
      e.preventDefault();

      let chain = 'client_id=' + encodeURIComponent(access);

      chain    += '&eid='       + encodeURIComponent(event.eid);
      chain    += '&dates='     + encodeURI( JSON.stringify(dates) );
      chain    += '&pid='       + encodeURIComponent(option.id);
      chain    += '&price='     + encodeURIComponent(price);

      try{
        setProgress(1);
        await post(`/customersrestapi/register-event-cash`, chain);
        await reload();
        setProgress(100);
        handleClose()
        setTimeout(() => {
          setProgress(0);
        }, 2000)
      }
      catch(e)
      {
        setTimeout(() => {
          setProgress(0);
          setError("Sorry, we have been unable to complete this booking. Please refresh this page an try again.");
          handleClose();
        }, 2000)
      }


  }

  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Book an Event</DialogTitle>
        <DialogContent>
            <DialogContentText>
                To book this customer into an event, please complete the following form:
            </DialogContentText>
            <Box sx={{pt:2, pb:2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant='outlined' fullWidth>
                                  <Autocomplete
                                    id="event-select-autocomplete"
                                    options={events}
                                    getOptionLabel={(event) => event.name}
                                    value={event}
                                    onChange={(event, newValue) => handleChange(newValue)}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select an event" variant="outlined" />
                                    )}
                                  />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="event-select-select-standard-label">Select date(s)</InputLabel>
                                    <Select
                                        labelId="event-select-select-standard-label"
                                        id="event-select-select-standard"
                                        value={dates || []}
                                        multiple
                                        disabled={!event}
                                        onChange={handleDateChange}
                                        label="Select an event"
                                    >
                                    {
                                      event?.dates?.map( e => (
                                        <MenuItem key={`date-list-item-${e.id}`} value={e}>{formatDate(e.datetime)}</MenuItem>
                                      ))
                                    }
                                    </Select>
                                    <Link  disabled={!event} onClick={handleDateSelectAll} sx={{mt: 1, textAlign:"right", fontSize: "small"}}>Select all dates</Link>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="option-select-select-standard-label">Pricing option</InputLabel>
                                    <Select
                                        labelId="option-select-select-standard-label"
                                        id="option-select-select-standard"
                                        value={option}
                                        disabled={!event || !dates?.length}
                                        onChange={handleOptionChange}
                                        label="Pricing option"
                                    >
                                    {
                                      event?.options?.map( e => (
                                        <MenuItem key={`option-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                                      ))
                                    }
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="price"
                                    label="Price paid"
                                    type="number"
                                    fullWidth
                                    disabled={!option}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </Grid>
                        </Grid>
                
 
            </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined'  disabled={!ready} color="primary">Book Event</Button>
        </DialogActions>
        </form>
      </Dialog>     
    </div>
  );
}


export function BookEventByPass( props ) {

  const {handleClose, setProgress, setError, events, access, passes, reload} = props;
  const [event, setEvent] = React.useState(null);
  const [dates, setDates] = React.useState([]);
  const [option, setOption] = React.useState('');
  const [credits, setCredits] = React.useState(0);
  const [pass, setPass] = React.useState(false);
  const [passesf, setPassesf] = React.useState(passes?.filter(f => f.credits > 0));
  const [oldestdate, setOldestDate] = React.useState(false);
  const [ready, setReady] = React.useState(false);


  React.useEffect(() => {

    setDates([]);
    setOption('');
    setCredits(0);
    setOldestDate(false)
    setPass(false);
  }, [event])

  React.useEffect(() => {

    if(!event || !pass || !option || !dates || !Number.isInteger(credits))
    {
      setReady(false);
    }
    else
    {
      setReady(true);
    }
  }, [event, pass, option, dates, credits])


  React.useEffect(() => {
    if(!!option)
    {
      setCredits((parseInt(option?.credits) * dates.length));
    }
    setPassesf(passes?.filter(f => {
      if(!!oldestdate)
      {
          if(!!f.expires)
          {
            const expires = new Date(f.expires);

            if(expires < oldestdate)
            {
              return false;
            }            
          }
          
      }
      if(f.state === 'VOID')
      {
        return false;
      }
      
      return (f.credits > 0 && credits <= (f.credits) );      

    }));

  }, [option, oldestdate])

  React.useEffect(() => {

    setPassesf(passes?.filter(f => {
      if(!!oldestdate)
      {
          if(!!f.expires)
          {
            const expires = new Date(f.expires);

            if(expires < oldestdate)
            {
              return false;
            }            
          }
      }
      if(f.state === 'VOID')
      {
        return false;
      }
      
      return (f.credits > 0 && credits <= (f.credits) );      

    }));

    let passv = passesf.find( p => p.id === pass.id);
    if(!passv)
      setPass(false)

  }, [credits])

  React.useEffect(() => {
    const d = dates.reduce((previous, current) => {
      const nd = new Date(current);
      if(!!previous)
      {
        return nd;
      }
      else
      {
          if(previous > nd)
          {
            return previous;
          }
          else
          {
            return nd;
          }
      }

    }, 0);

    setOldestDate( d );

  }, [dates])

  const handleChange = (event) => {
    setEvent(event);

  };


  const handleDateChange = ( e ) => {
    setDates(e.target.value);
  }

  const handleDateSelectAll = ( e ) => {
    setDates(event?.dates || []);
  }

  const handleOptionChange = ( e ) => {
    setOption(e.target.value);
  }

  const handleCreditChange = (e ) => {
    setCredits(parseInt(e.target.value))
  }

  const handlePassChange = (e ) => {
    setPass( e.target.value);
  }

  const formatDate = ( d ) => {
    if(d)
    {
      const w = new Date( d );
      const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      const z = w.toLocaleDateString();
      return `${z} ${x}`;        
    }
    return '- -';

  }


  const onSave = async(e) => {
      e.preventDefault();

      let chain = 'client_id=' + encodeURIComponent(access);
      chain    += '&eid='       + encodeURIComponent(event.eid);
      chain    += '&dates='     + encodeURI( JSON.stringify(dates) );
      chain    += '&pid='       + encodeURIComponent(option.id);
      chain    += '&credits='   + encodeURIComponent(credits);
      chain    += '&pass='     + encodeURIComponent(pass.id);

      try{
        setProgress(1);
        await post(`/customersrestapi/register-event-pass`, chain);
        setProgress(100);
        await reload();
        handleClose()
        setTimeout(() => {
          setProgress(0);
        }, 2000)
      }
      catch(e)
      {
          setError("Sorry, we have been unable to complete this booking. Please refresh this page an try again.");
          setTimeout(() => {
            setProgress(0);
            handleClose();
          }, 2000)
      }


  }

  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Book an Event</DialogTitle>
        <DialogContent>
            <DialogContentText>
                To book this customer into an event, please complete the following form:
            </DialogContentText>
            <Box sx={{pt:2, pb:2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                          <Autocomplete
                            id="event-select-autocomplete"
                            options={events}
                            getOptionLabel={(event) => event.name}
                            value={event}
                            onChange={(event, newValue) => handleChange(newValue)}
                            renderInput={(params) => (
                              <TextField {...params} label="Select an event" variant="outlined" />
                            )}
                          />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="event-select-select-standard-label">Select date(s)</InputLabel>
                            <Select
                                labelId="event-select-select-standard-label"
                                id="event-select-select-standard"
                                value={dates || []}
                                multiple
                                disabled={!event}
                                onChange={handleDateChange}
                                label="Select date(s)"
                            >
                            {
                              event?.dates?.map( e => (
                                <MenuItem key={`date-list-item-${e.id}`} value={e}>{formatDate(e.datetime)}</MenuItem>
                              ))
                            }
                            </Select>
                            <Link  disabled={!event} onClick={handleDateSelectAll} sx={{mt: 1, textAlign:"right", fontSize: "small"}}>Select all dates</Link>
                        </FormControl> 
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="option-select-select-standard-label">Pricing option</InputLabel>
                            <Select
                                labelId="option-select-select-standard-label"
                                id="option-select-select-standard"
                                value={option}
                                disabled={!event || !dates?.length}
                                onChange={handleOptionChange}
                                label="Pricing option"
                            >
                            {
                              event?.options?.map( e => (
                                <MenuItem key={`option-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                              ))
                            }
                            </Select>
                        </FormControl> 
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="credits"
                            label="credits used"
                            type="number"
                            fullWidth
                            disabled={!option}
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: 1 } }}
                            value={credits}
                            onChange={handleCreditChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="pass-select-select-standard-label">Select Pass</InputLabel>
                            <Select
                                labelId="pass-select-select-standard-label"
                                id="pass-select-select-standard"
                                value={pass || false}
                                disabled={!event}
                                onChange={handlePassChange}
                                label="Select a pass"
                            >
                            {
                              passesf?.map( e => (
                                <MenuItem key={`pass-list-item-${e.id}`} value={e}>{`${e.name}. ID: ${parseInt(e.id).toString(16)}. Credits: ${e.credits}. Expires: ${formatDate(e.expires)}`}</MenuItem>
                              ))
                            }
                            </Select>
                        </FormControl> 
                    </Grid>                
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined' disabled={!ready} color="primary">Book Event</Button>
        </DialogActions>
        </form>
      </Dialog>     
    </div>
  );
}

export function BookEvent( props ) {
  const {events, access, passes, initiate, reload} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState( false );
	const inputElement = React.useRef()

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onBookByCash = () => {
    setState(1);
    handleClose();
  }
  const onBookByPass = () => {
    setState(2);
    handleClose();
  }

  React.useEffect(() => {
    if(initiate === 0)
      inputElement?.current?.click();
  }, [initiate])

  return (
    <div>
      <Button
        id="book-button"
        aria-controls={open ? 'book-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        ref={inputElement}
      >
        Book an Event
      </Button>
      <Menu
        id="book-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'book-button',
        }}
      >
        <MenuItem onClick={onBookByCash}>Payment by cash</MenuItem>
        <MenuItem onClick={onBookByPass}>Charge a pass</MenuItem>
      </Menu>
      {
        state === 1 &&
        <BookEventByCash events={events} access={access} handleClose={() => {setState(0)}} setError={setError} setProgress={setProgress} reload={reload}/>
      }
      {
        state === 2 &&
        <BookEventByPass events={events} access={access} handleClose={() => {setState(0)}} setError={setError} setProgress={setProgress} passes={passes} reload={reload}/>
      }



      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      }       
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }

    </div>
  );
}