import * as React from 'react';
import Box from '@mui/material/Box';
import VideoLibraries from '../elements/VideoLibraries';



export default function VideoManager() {
  return (
    <Box sx={{ overflow: 'hidden' }} className="Video rvNS">
      <VideoLibraries loading={true} />
    </Box>
  );
}
