import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Grid } from '@mui/material';
import { post } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';


export default function TransferDialog( props ) {

  const {handleClose, setError, events, from, reload} = props;
  const [event, setEvent] = React.useState('');
  const [date, setDate] = React.useState('');
  const [ready, setReady] = React.useState(false);
  const [option, setOption] = React.useState('');
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {

    setDate('');
    setOption('');

  }, [event])



  React.useEffect(() => {

    if(!event || !date  || !option)
    {
      setReady(false);
    }
    else
    {
      setReady(true);
    }
  }, [event, date, option])

  const handleChange = (event) => {
    setEvent(event.target.value);

  };


  const handleDateChange = ( e ) => {
    setDate(e.target.value);
  }

  const formatDate = ( d ) => {
      const w = new Date( d );
      const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      const z = w.toLocaleDateString();
      return `${z} ${x}`;

  }

  const handleOptionChange = ( e ) => {
    setOption(e.target.value);
  }


  const onSave = async(e) => {
      e.preventDefault();

      var chain = '&from_eid='    + from.eid;
      chain    += '&from_did='    + from.did;
      chain    += '&from_stxid='  + from.stxid;
      chain    += '&user='        + window?.configuration?.access;
      chain    += '&to_eid='      + event.eid;
      chain    += '&to_did='      + date.id;
      chain    += '&to_po='       + option.id;


      try{
        setProgress(1);
        await post(`/api-actions-customers/ajax-transfer-from-event-to-event`, chain);
        setProgress(50);
        await reload();
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          handleClose()
        }, 500)
      }
      catch(e)
      {
        setTimeout(() => {
          setProgress(0);
          setError("Sorry, we have been unable to complete this booking. Please refresh this page an try again.");
          handleClose();
        }, 2000)
      }


  }

  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Transfer Booking</DialogTitle>
        <DialogContent>
          <DialogContentText>
              To transfer this booking to another event or date, please complete the following form:
          </DialogContentText>
          <Box sx={{pt:2, pb:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="event-select-select-standard-label">Select a new event</InputLabel>
                        <Select
                            labelId="event-select-select-standard-label"
                            id="event-select-select-standard"
                            value={event}
                            onChange={handleChange}
                            label="Select a new event"
                        >

                        {
                          events?.map( e => (
                            <MenuItem key={`event-list-item-${e.eid}`} value={e}>{e.name}</MenuItem>
                          ))
                        }
      
                        </Select>
                    </FormControl> 
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="date-select-select-standard-label">Select date</InputLabel>
                        <Select
                            labelId="date-select-select-standard-label"
                            id="event-select-select-standard"
                            value={date || ''}
                            disabled={!event}
                            onChange={handleDateChange}
                            label="Select date"

                        >
                        {
                          event?.dates?.map( e => (
                            <MenuItem key={`date-list-item-${e.id}`} value={e}>{formatDate(e.datetime)}</MenuItem>
                          ))
                        }
                        </Select>
                    </FormControl> 
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="option-select-select-standard-label">Pricing option</InputLabel>
                        <Select
                            labelId="option-select-select-standard-label"
                            id="option-select-select-standard"
                            value={option}
                            onChange={handleOptionChange}
                            label="Pricing option"
                            disabled={!event || !date}
                        >
                        {
                          event?.options?.map( e => (
                            <MenuItem key={`option-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                          ))
                        }
                        </Select>
                    </FormControl> 
                </Grid>                            
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined' disabled={!ready} color="primary">Transfer Booking</Button>
        </DialogActions>
        </form>
      </Dialog> 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Transfer is being processed." text={''} progress={progress} />
      }
    
    </div>
  );
}
