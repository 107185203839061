import {  Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { checkEpochShift, getCache, setCache } from '../../libraries/cache';
import { get } from '../../libraries/network';
import SettingsIcon from '@mui/icons-material/Settings';
import { BottomBar } from '../elements/BottomBar';
import { EventsPanel } from '../elements/EventsPanel';




function SettingsWidget( props ) {
    const {refresh} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onRefresh = () => {
        handleClose();
        refresh();
    }
  


    return (
      <div>
        <IconButton
          id="positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="primary"
          size="large"
        >
            <SettingsIcon sx={{fontSize:'40px'}}/>
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={onRefresh}>Refresh</MenuItem>
        </Menu>
      </div>
    );
  }

const template_customer_basic = {
    "id": -1, 
    "firstname": '', 
    "lastname": '', 
    "email": '', 
    "mobile": '', 
}

const template_12_sales = {
    "transactions": {
      "labels": [
      ],
      "datasets": [
        {
          "label": "# bookings",
          "data": [
          ],
          "backgroundColor": [
            "rgba(89, 150, 210, 1)"
          ]
        },
        {
          "label": "# passes",
          "data": [
          ],
          "backgroundColor": [
            "rgba(136, 206, 162, 1)"

          ]
        },
        {
          "label": "# videos",
          "data": [

          ],
          "backgroundColor": [
            '#2196f3'
          ]
        }
      ]
    }
}

export function Events( props )
{
    
    const [events, setEvents] = React.useState(getCache(`events-list`) || []);
    const [customerbookings, setCustomerBookings] = React.useState(getCache(`customer-bookings-${window?.configuration?.access}`) || []);
    const [customerpasses, setCustomerPasses] = React.useState(getCache(`customer-passes-${window?.configuration?.access}`) || []);
    const [customermedia, setCustomerMedia] = React.useState(getCache(`customer-media-${window?.configuration?.access}`) || []);
    const [customerquestionnaires, setCustomerQuestionnaires] = React.useState(getCache(`customer-questionnaires-${window?.configuration?.access}`));
    const [customersubscriptions, setCustomerSubscriptions] = React.useState(getCache(`customer-subscriptions-${window?.configuration?.access}`) || []);
    const [companypasses, setCompanyPasses] = React.useState( getCache('company-passes') || [] );
    const [companyquestionnaires, setCompanyQuestionnaires] = React.useState( getCache('company-questionnaires') || [] );
    const [companymedia, setCompanyMedia] = React.useState( getCache('company-media') || [] );
    const [customernotes, setCustomerNotes] = React.useState( getCache(`customer-notes-${window?.configuration?.access}`) || [] );
    const tz =  (Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe-London').replace(/\//g, '-');
    const [action, setAction] = React.useState('');
    const [busy, setBusy] = React.useState( false );
   
    const createStack = ( url, callback, key, busy, template, cache, filter ) => {
        return {url, callback, key, busy, template, cache, filter}
    }




    

    const callstack = [
        createStack(`/customersrestapi/events-list/${encodeURIComponent(tz)}`, setEvents, `events`, false, [], true, 'events'),
    ]

    const isNullOrEmptyArray = (arr) => {
        return arr === null || (Array.isArray(arr) && arr.length === 0);
    }

    const fetchdata = async( server_requested ) => {
        if(busy)
        {
            return;
        }
        setBusy(true);
        var cs = callstack.filter( c => { 
            return ((typeof server_requested === 'string' && c.filter === server_requested) || typeof server_requested !== 'string')
        });

        for(let i = 0; i < cs.length; i++)
        {
            try{
                if(cs[i]?.busy)
                    cs[i].busy( true );
                console.log("server requested", server_requested, cs[i].key);
                if(!server_requested)
                {


                    const has_data = getCache(cs[i].key);
                    if(!isNullOrEmptyArray(has_data))
                    {
                        console.log("Skipping key", cs[i].key, has_data);
                        if(cs[i]?.busy)
                            cs[i].busy( false );
                        continue;
                    }
                }
                // we need to throttle the requests to the server so we do not hammer it...
                const data = await new Promise(( resolve, reject) => {
                    setTimeout(async ()=> {
                        try{
                            let d = await get( cs[i].url );
                            console.log("Data data is ", d);
                            if(!d)
                            {
                                d =  cs[i].template;
                            }
                            resolve(d);
                        }
                        catch(e)
                        {
                            if(window?.static_override)
                            {
                                let d = window?.static_events[cs[i].key];
                                console.log("static events", d);
                                if(!d)
                                {
                                    d = cs[i].template;
                                }
                                resolve(d);
                            }
                            else
                            {
                                reject(e);
                            }
                            
                        }                                        
                    }, 500);
 
                });
                cs[i].callback( data );
                if(cs[i]?.busy)
                    cs[i].busy( false );
                if(cs[i].cache)
                    setCache(cs[i].key, data);

            }
            catch(err)
            {
               
                console.error("Failed to get", err);
                if(cs[i]?.busy)
                    cs[i].busy( false );

            }
        }
        setBusy(false);
    }



    React.useEffect(() => {
        fetchdata( true );
        const intervalid = setInterval(async() => {
           
            await fetchdata()
            
        }, 120000);
        return () => clearInterval(intervalid);
    }, []);




    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar widgets={[<SettingsWidget key="setting-1" refresh={()=>{fetchdata(true)}} />]}/>                
            </Box>
     
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>  
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Events and appointments <small> courses, classes, appointments and retreats</small>
                                </Typography>
                                <Divider />
                                <EventsPanel
                                    bookings={customerbookings} 
                                    events={events} 
                                    passes={customerpasses} 
                                    companypasses={companypasses} 
                                    media={customermedia} 
                                    companymedia={companymedia}
                                    subscriptions={customersubscriptions}
                                    questionnaires={customerquestionnaires}
                                    allquestionnaires={companyquestionnaires}
                                    reloadPasses={async()=>{await fetchdata('passes')}} 
                                    reloadBookings={async()=>{await fetchdata('events'); await fetchdata('passes')}}  
                                    reloadVideos={async()=>{await fetchdata('media');  await fetchdata('passes')}}                               
                                    reloadSubscriptions={async()=>{await fetchdata('subscriptions')}}
                                    reloadQuestionnaires={async()=>{await fetchdata('questionnaires')}}
                                    notes={customernotes}
                                    reloadNotes={async()=>{await fetchdata('notes')}} 
                                    action={action}
                                    />         
                            </CardContent>
                        </Card>  

                    </Grid> 
                </Grid>
            </Box> 
          
            <BottomBar />
        </div>
    )
}