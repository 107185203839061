import * as React from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { BlockPicker } from "react-color";


export default function CodeDialog( props ) {

  const {title, description, handleClose, base, uri} = props;
  const [color, setColor] = React.useState({hex:'#2ccce4', rgb: {r: 44, g: 204, b: 228}});
  const [widget, setWidget] = React.useState();

  const [type, setType] = React.useState('link');

  const handleChange = color => setColor(color);


const  contrastColor = ( color ) =>
{
  let luma = ((0.299 * color.rgb.r) + (0.587 * color.rgb.g) + (0.114 * color.rgb.b)) / 255;

  // Return black for bright colors, white for dark colors
  return luma > 0.5 ? "#000" : '#fff';                

}

  const handleChangeType = type => {
    setType(type);

    if(type === 'link')
    {
      setWidget(`${base}${uri}`)
    }
    else
    {
      let   p = [];
      const s = {
                  'text-decoration': 'none',
                  'background-color': color.hex,
                  'color': contrastColor(color),
                  'padding': '15px',
                  'line-height': '20px',
                  'border-radius': '5px'

                };
      for(var prop in s)
        p.push(prop + ':' + s[prop]);



      const a = `<a href="${base}${uri}" style="${p.join(';')}" rel="nofollow noopener">Buy now</a>`;
      setWidget(a);

    }
  }

  React.useEffect(() => {
    handleChangeType( type );
  })

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          <Box sx={{pt:2, pb:2}}>
            <Grid container>
              <Grid item xs={6}>
                  <FormControl>
                      <FormLabel id="widget-radio-buttons-group-label">Widget type</FormLabel>
                      <RadioGroup
                        aria-labelledby="widget-radio-buttons-group-label"
                        value={type}
                        onChange={(e) => {handleChangeType(e.target.value)}}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel value="link" control={<Radio />} label="Web URL link" />
                        <FormControlLabel value="button" control={<Radio />} label="HTML Button Widget" />
                      </RadioGroup>                      
                  </FormControl>  
                    {
                      type === 'button' &&
                      <Box>
                        <BlockPicker color={color} disabled={true} onChangeComplete={handleChange} />
                      </Box>
                    }  
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-multiline-static"
                    label="Widget"
                    multiline
                    rows={13}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={widget}
                    onChange={() => {}}
                    helperText="Copy and paste this to your website. If you have selected a HTML widget, you must add this to your website as HTML."
                  />
              </Grid>
            </Grid>


            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}