import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, FormHelperText } from '@mui/material';
import { post } from '../../libraries/network';

const theme = createTheme();

export default function ResetCode() {
  const [busy, setBusy] = React.useState(false);
  const [codes, setCodes] = React.useState(new Array(6).fill(''));
  const [keyCode, setKeyCode] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const navigate = ( r ) => {
    window.location.href = r;

  }

  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const handleCodeChange = (index, event) => {
    const { value } = event.target;

    // Only allow numbers and backspace
    if (!isNaN(value) || keyCode === 8) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);
  
      // Handle backspace
      if (keyCode === 8 && index > 0) {
        // Move focus to the previous input field
        setKeyCode(false);
        inputRefs[index - 1].current.focus();
      } else if (value !== '' && index < 5) {
        // Move focus to the next input field if a digit is entered and it's not the last one
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {

    setKeyCode(e.keyCode);

  }

  


  const handleCodePaste = (event) => {
    const pastedData = event.clipboardData.getData('Text').replace(/\D/g, '').split('');
    const newCodes = [...codes];
    pastedData.forEach((digit, index) => {
      if (index < 6) {
        newCodes[index] = digit;
        if (index < 5) {
          document.getElementById(`code${index + 1}`).focus();
        }
      }
    });
    setCodes(newCodes);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const code = codes.join('');
    console.log('MFA Code:', code);

    if(!code)
    {
      return;
    }



    // Perform your submission logic here
    setBusy(true);
    try{
      let r = await post('/clientrestapi/resetcode', `code=${encodeURIComponent(code)}`);
      if(r.status === 'OK')
      {
        setBusy(false);
        navigate(`/client/resetpassword?email=${encodeURIComponent(email)}`);

      }
      else
      {
        setError('Authentication failed. Please check your email and check the code');
        setBusy(false);
      }
    }
    catch(e)
    {
      setBusy(false);
      if(window?.static_override)
      {
        // OK if in client mode - we can redirect
        setError('Authentication failed. Please check the entered code');

        setTimeout(() => {
          navigate(`/client/resetpassword?email=${encodeURIComponent(email)}`);

        }, 5000);

      }
      else
      {
        setError('Invalid code entered');
      }

    }

  };

  const inputRefs = Array.from({ length: 6 }, () => React.createRef());


  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className="loginBox"
          sx={{
            marginTop: 1,
            paddingTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%' // Ensure the Box component spans the full width
          }}
        >
          <Avatar sx={{ m: 1 }} src="/logo512.png" style={{ width: '100px', height: '100px' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="body2" variant="div" sx={{mt: 2, mb: 2, textAlign: "center"}}>
              Please enter the 6-digit code that we have emailed you. If you have not received it, please be patient. Check your spam folder and ensure that you have entered the correct email address for your account.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <Box display="flex" justifyContent="center">
              {codes.map((code, index) => (
                <TextField
                  key={index}
                  id={`code${index}`}
                  variant="outlined"
                  size="small"
                  type="text"
                  inputProps={{ maxLength: 1 }}
                  autoFocus={index === 0}
                  value={code}
                  onChange={(e) => handleCodeChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handleCodePaste}
                  inputRef={inputRefs[index]} // Set the ref for each input field
                  sx={{ mr: 1, width: 40 }} // Adjust width as needed
                />
              ))}
            </Box>
            <Box>
                <FormHelperText error sx={{textAlign: 'center'}}>{error || ' '}</FormHelperText>
            </Box>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Continue
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={busy}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}
