import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'


export default function Oops(){

    return (
        <Box className="rvNS error" sx={{mt:10}}>
            <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12}>
                    <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h1" gutterBottom>
                        OOPS!
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        Sorry either this page does not exist or you do not have permmission to access it...
                    </Typography>
                </Grid>
            </Grid>     
        </Box>
    )
}