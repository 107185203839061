import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, setRef } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import getSymbolFromCurrency from 'currency-symbol-map'
import CustomerSubscriptionMandateAction from './CustomerSubscriptionMandateAction';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { del, put } from '../../libraries/network';
import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import PauseSubscriptionDialog from './PauseSubscriptionDialog';
import EditSubscriptionGraceDialog from './EditSubscriptionGraceDialog';
import UnpauseSubscriptionDialog from './UnpauseSubscriptionDialog';
import EditSubscriptionDialog from './EditSubscriptionDialog';
import { formatDateToLocalDetectTz } from '../../libraries/format';


const SUBSCRIPTION_MANDATE_STATUS = {
  ERROR: -1,
  MANUAL: 0,
  ACTIVE: 1,
  PAUSED: 2,
  DELETED: 3,
  SUSPENDED: 4,
  PARENT_SUSPENDED: 5
};


function createData( id, bought, name, next, invoices, paid, currency, state, statuscode, plan_id, grace) {


    const w = new Date(next);
    const xs = w.toLocaleDateString([], {year: '2-digit', month:'2-digit', day: 'numeric'});

    const p = new Date(bought);
    const c = p.toLocaleDateString();
    const r =  { id, purchased: c, name, next,  expires_short: `${xs}`, invoices, paid, currency, state, statuscode, plan_id, grace};
    console.log("returning pass", r);
    return r;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomersTableSubscriptions( props ) {
  const {subscriptions, plans, reload} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [grace, setGrace] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [pause, setPause] = React.useState(false);
  const [unpause, setUnpause] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const access = window?.configuration?.access;



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onMandateCancel = ( context ) => {
    setCancel( context )
  }

  const onCompleteCancel = async() => {
    setCancel( false );
    try{
      setCancel(false);
      setProgress(1);
      await del(`/customersrestapi/subscriptions/${encodeURIComponent(access)}/${cancel?.id}`);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to cancel the mandate.");
      }, 500);
    }

  }

  
  const onMandatePause = ( context ) => {
    setPause( context );
  }

  const onCompletePause = async() => {
    setPause( false );
    try{
      setPause(false);
      setProgress(1);
      await put(`/customersrestapi/subscriptions/${encodeURIComponent(access)}/pause/${pause?.id}`);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {
      setTimeout(()=> {
        setProgress(0);
        setError("Failed to update.");
      }, 500);
    }

  }


  const onMandateUnPause = ( context ) => {
      setUnpause( context );
  }

  const onMandateEdit = ( context ) => {
    setEdit( context );
  }

  const onCompleteUnPause = async( v, tz ) => {
    try{
      setUnpause(false);
      setProgress(1);
      const resumeDate = new Date(v); // Convert v to a Date object
      const options = { timeZone: tz };
      const resumeDateUTC = resumeDate.toLocaleString('en-US', options); // Convert to local time based on timezone
      const z = `resumeat=${encodeURIComponent(new Date(resumeDateUTC).toISOString())}`;
      await put(`/customersrestapi/subscriptions/${encodeURIComponent(access)}/resume/${unpause?.id}`, z);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to resume the mandate.");
      }, 500);
    }

  }


    const onCompleteEdit = async (plan, charge, date, tz) => {
      setEdit(false);
      try {
        setProgress(1);
        const dateUTC = date.toLocaleString('en-US', { timeZone: tz }); // Convert date to local time based on timezone
        const p = `plan=${encodeURIComponent(plan)}&charge=${encodeURIComponent(charge)}&date=${encodeURIComponent(new Date(dateUTC).toISOString())}`;
        await put(`/customersrestapi/subscriptions/${encodeURIComponent(access)}/update/${edit?.id}`, p);
        setProgress(50);
        await reload();
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
        }, 500);
      } catch (e) {
        setTimeout(() => {
          setProgress(0);
          setError("Failed to update this subscription.");
        }, 500);

      }
  }


  const onMandateGraceChange = ( context ) => {
    setGrace( context );
  }


  const onCompleteGrace = async( v ) => {
    setGrace( false );
    try{
      setGrace(false);
      setProgress(1);
      const p = `grace=${encodeURIComponent(v)}`;
      await put(`/customersrestapi/subscriptions/${encodeURIComponent(access)}/grace/${grace?.id}`, p);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to change the grace period.");
      }, 500);
    }    
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() =>{
    const frow = subscriptions?.filter( c => {

      if(!!!filtertext)
      {
        return true;
      }
      if( c?.name?.indexOf(filtertext) > -1 || c?.purchased?.indexOf(filtertext) > -1  || c?.next?.indexOf(filtertext) > -1)
      {
        return true;
      }


      return false;
    });
    setRows(frow?.map( row => createData(row.id, row.purchased, row.name, row.next, row.invoices?.length || 0, row.paid, row.currency, row.state, row.status_code, row?.plan_id, row?.grace)));
  }, [filtertext, subscriptions])


  const convertCurrency = ( c ) => {
    const currency = getSymbolFromCurrency(c);
    console.log(currency);
    if(!currency)
    {
       return c;
    }
    return currency;
 }

 



  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
          
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}> ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell'}}}>Name</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Created</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Invoices</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Next payment</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Price</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>State</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                
                     
                        <TableRow
                          key={`customers-${row.id}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "1px solid  rgba(224, 224, 224, 1);" } }}
                          >
                        
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${parseInt(row.id).toString(16)}`} 
                              </TableCell>        
                          
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {row.name}
                                <Box  sx={{ display: { xs: 'block', sm: 'none' }}}>
                                Exp: {row.expires_short}
                                </Box>
                              </TableCell>
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {row.purchased} 
                              </TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{row.invoices}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {`${formatDateToLocalDetectTz(row.next)}`}
                              </TableCell>
                              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${convertCurrency(row.currency)}${parseFloat(row.paid).toFixed(2)}`}
                              </TableCell>   
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{row.state}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                <CustomerSubscriptionMandateAction 
                                  isActive={row.statuscode === SUBSCRIPTION_MANDATE_STATUS.ACTIVE || row.statuscode === SUBSCRIPTION_MANDATE_STATUS.MANUAL || row.statuscode === SUBSCRIPTION_MANDATE_STATUS.PAUSED}
                                  cancelActive={row.statuscode === SUBSCRIPTION_MANDATE_STATUS.ACTIVE || row.statuscode === SUBSCRIPTION_MANDATE_STATUS.MANUAL || row.statuscode === SUBSCRIPTION_MANDATE_STATUS.PAUSED}
                                  onCancel={onMandateCancel}
                                  graceActive={row.statuscode === SUBSCRIPTION_MANDATE_STATUS.ACTIVE}
                                  onGrace={onMandateGraceChange}
                                  pauseActive={row.statuscode === SUBSCRIPTION_MANDATE_STATUS.PAUSED}
                                  onPause={onMandatePause}
                                  onUnpause={onMandateUnPause}
                                  onMandateEdit={onMandateEdit}
                                  context={row}
                                /> 
                              </TableCell>
                            
                          </TableRow>
                
               
                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={8} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>This customer has no subscriptions.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={4} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no bookings yet for this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={8}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid> 
      {
        cancel && <CancelSubscriptionDialog open={true} onClose={()=>{setCancel(false)}} onCancel={()=>{onCompleteCancel()}}/>
      }  
      {
        pause && <PauseSubscriptionDialog  open={true} onClose={()=>{setPause(false);}} onPause={()=>{onCompletePause()}}/>
      }

      {
        grace && <EditSubscriptionGraceDialog  initialGrace={grace?.grace || 0} open={true} onClose={()=>{setGrace(false);}} onSave={( e )=>{onCompleteGrace(e)}}/>
      }

      {
        unpause && <UnpauseSubscriptionDialog next={unpause?.next} open={true} onClose={()=>{setUnpause(false);}} onComplete={(e)=>{onCompleteUnPause( e )}}/>
      }
      {
        edit && <EditSubscriptionDialog subscriptions={subscriptions} plans={plans} open={true} onClose={()=>{setEdit(false);}} subscription={edit} onComplete={(plan, charge, date, tz)=>{onCompleteEdit( plan, charge, date, tz )}}/>
      }
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }
    </React.Fragment>


  );
}