import React from 'react';

import { TextField } from '@mui/material';


const QuestionaireMultiTextBox = ({id, text, index, required, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div className='customer_questionnaire_field'>
                <TextField
                    autoFocus={id===1? true: false } 
                    margin="dense"
                    id={id}
                    name={id}
                    required={required}
                    label={`${index} ${text}`}
                    value={answer.answer} 
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    fullWidth
                    multiline
                    autoComplete={id + Date.now().toString()} 
                    variant="standard"
                    onChange={(e) => {
                        console.log("Updated text", e.target.value);
                        onAnswer(id, e.target.value);
                    }}
                /> 
               
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireMultiTextBox;