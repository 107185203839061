import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Link,
} from '@mui/material';
import { put } from '../../libraries/network';
import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import { MuiTelInput } from 'mui-tel-input';  
import parsePhoneNumberFromString from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js'

const CustomerEditForm = ( props ) => {
  const { id, firstname, lastname, email, mobile, reload } = props;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);      
  const [tel, setTel] = useState('');      
  const [showPassword, setShowPassword] = useState(true);

  const [formData, setFormData] = useState({
    id: id,
    firstName: firstname,
    lastName: lastname,
    email: email,
    mobileNumber: mobile,
    password: ''
  });

  React.useEffect(() => {
    setFormData({
      id: id,
      firstName: firstname,
      lastName: lastname,
      email: email,
      mobileNumber: mobile,
    }); 
  }, [firstname, lastname, id, email, mobile]);

  React.useEffect(() => {
    let t = formData?.mobileNumber;
    if (!t) {
      t = '';
    } else {
      t = t.toString();
    }

    if (!t.startsWith("+")) {
      if (!!t?.length) {
        const ptel = parsePhoneNumber(t, getCountry());
        if (!!t) {
          t = ptel.formatInternational();
          console.log(getCountry(), t);
        }
      }
    }
    setTel(t);
  }, [formData.mobileNumber]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMobileChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
    setTel(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData(formData)) {
      let x = {};
      for (const key in formData) {
        x[key] = encodeURIComponent(formData[key]);
      }

      try {
        setProgress(1);
        await put(`/customersrestapi/account/${window?.configuration?.access}/update`, `data=${JSON.stringify(x)}`);
        setProgress(50);
        await reload();
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          handleClose();
        }, 500);
      } catch (e) {
        setTimeout(() => {
          setProgress(0);
          setError("Failed to edit this account");
          console.error(e);
        }, 1000);
      }
    }
  };

  const isValid = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.mobileNumber.toString().trim() !== '' &&
      isMobileValid() && isStrongPassword()
    );
  };

  const validateFormData = (data) => {
    return (
      data.firstName.trim() !== '' &&
      data.lastName.trim() !== '' &&
      data.email.trim() !== '' &&
      data.mobileNumber.toString().trim() !== '' &&
      isMobileValid() && isStrongPassword()
    );
  };

  const isStrongPassword = () => {
    const password = formData.password;
    if (!!!password?.length) {
      return true;
    }

    if (password.length < 8) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/\d/.test(password)) {
      return false;
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      return false;
    }

    return true;
  };

  const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
  };

  const getCountry = () => {
    const country = getNavigatorLanguage().split('-');
    if (country.length > 1) {
      return country[1];
    }
    return 'GB';
  };

  const isMobileValid = () => {
    if (tel.toString().trim() !== '') {
      const parsedPhoneNumber = parsePhoneNumberFromString(tel.toString().trim());

      if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen} disabled={!!!id}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Customer Information</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  label="First Name"
                  variant="standard"
                  required
                  value={formData.firstName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  variant="standard"
                  required
                  value={formData.lastName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email Address"
                  variant="standard"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MuiTelInput
                  value={formData.mobileNumber || ''}
                  required
                  autoComplete="off"
                  variant="standard"
                  label="Mobile Number"
                  helperText={"Enter mobile number"}
                  error={!isMobileValid()}
                  onChange={handleMobileChange}
                  defaultCountry={getCountry()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  label="Password"
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password || ''}
                  onChange={handleChange}
                  error={!isStrongPassword()}
                  helperText="Leave blank if you do not want to change this password. If you change this password, the password must be greater than 7 characters and contain at least one upper case, one lower case, one numerical digit and one of the following special characters: !@#$%^&*()_+{}[]:;<>,.?~\\-"
                  fullWidth
                  autoComplete='off'
                  inputProps={{
                    autoComplete: 'new-password'
                  }}
                />
                {
                  !showPassword &&
                  <Link href="#" onClick={() => setShowPassword(true)} style={{ fontSize: '0.8em' }}>Show password</Link>
                }
                {
                  !!showPassword &&
                  <Link href="#" onClick={() => setShowPassword(false)} style={{ fontSize: '0.8em' }}>Hide password</Link>
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={!isValid()}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={() => { setError(false) }} />
      }
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }
    </div>
  );
};

export default CustomerEditForm;
