import React from 'react';
import { FormControl, FormGroup, FormControlLabel,  Checkbox, FormLabel, makeStyles } from '@mui/material';
import styles from './Questionaires.module.scss';


const QuestionaireMultiCheckbox = ( props ) => {

    const {id, text, index, required, options, answer, onAnswer } = props;

    const is_array = Array.isArray(answer);
    console.log("parameters", required);
    const jsonUnescape = (str) =>{
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }

    return ( 
        <React.Fragment>
            <div className='customer_questionnaire_field'>

                <FormControl component="fieldset" key={id}>
                    <FormLabel component="legend" className={styles.legend}>{text}</FormLabel>
                    <FormGroup>
                    {
                        is_array &&
                        options.map( (o, i) => (
                            <FormControlLabel  key={`multi-checkbox-${id}-${o.id}`}
                                control={<Checkbox id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={!!answer.find((ans) => (ans.answer === o.id))} onChange={(e) => {onAnswer(id, e.target.value)}} />}
                                label={jsonUnescape(o.text)}
                            />                        
                        ))
                    }
                    {
                        !!!is_array &&
                        options.map( (o, i) => (
                            <FormControlLabel
                                control={<Checkbox id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={false} onChange={(e) => {onAnswer(id, e.target.value)}} />}
                                label={jsonUnescape(o.text)}
                            />                        
                        ))
                    }
                  
                    </FormGroup>
                </FormControl>
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireMultiCheckbox;