import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../elements/Copyright';
import { Backdrop, CircularProgress } from '@mui/material';
import { post } from '../../libraries/network';


const theme = createTheme();

export default function ResetDone() {

  const [busy, setBusy] = React.useState(false);
  const [emailerror, setEmailError] = React.useState(false);
  const [message, setMessage] = React.useState(' ');
  const [email, setEmail] = React.useState('');

  const navigate = ( r ) => {
    window.location.href = r;

  }

  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);
    if(emailerror)
    {
      setBusy(false);
      setMessage("Please check your email address and try again");
      setEmailError(true);
      return;
    }
    else
    {
      setMessage(" ");
      setEmailError(false);

    }
    try{
      let r = await post('/clientrestapi/reset', `email=${encodeURIComponent(email)}`);
      if(r.status === 'OK')
      {
        setBusy(false);
        navigate(`/client/resetcode?email=${encodeURIComponent(email)}`);
      }
      else
      {
        setMessage(r?.reason || 'Please check your email address and try again');
      }
    }
    catch(e)
    {
      setBusy(false);
      if(window?.static_override)
      {
        // OK if in client mode - we can redirect
        setMessage("Account error")
        setTimeout(() => {
          navigate(`/client/resetcode?email=${encodeURIComponent(email)}`);
        }, 5000);

      }

    }

  };

  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className="loginBox"
          sx={{
            marginTop: 1,
            paddingTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%' // Ensure the Box component spans the full width
          }}
        >
          <Avatar sx={{ m: 1 }} src="/logo512.png" style={{ width: '100px', height: '100px' }}>
          </Avatar>
          <Typography component="h1" variant="h5">
          Password Reset
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 , width: '100%'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!message}
              helperText={message}
            />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Next
              </Button>


              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ borderBottom: '1px solid #ccc', width: '100%' }} />
                <Box sx={{ px: 2, color: '#777', fontSize: '14px' }}>or</Box>
                <Box sx={{ borderBottom: '1px solid #ccc', width: '100%' }} />
              </Box>

              <Button
                component="a"
                href={`/client/login?email=${encodeURIComponent(email)}`}
                fullWidth
                variant="outlined"
                color="inherit"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign in
              </Button>       
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}