import { Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { blue } from '@mui/material/colors';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AppsIcon from '@mui/icons-material/Apps';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import TollIcon from '@mui/icons-material/Toll';
import GroupAddIcon from '@mui/icons-material/GroupAdd';


export function Reports()
{
 


    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar  title={"Reports"}/>                
            </Box>
                <Box sx={{p: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Booking reports <span className='small'>booking insights</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>
                                    <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                                        <ListItem component={Link} href="/reports/booking-overview">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <ImageIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Booking Overview" secondary="List of customers that booked between specific dates" />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/bookings-stopped">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <DoNotDisturbAltIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Bookings stopped" secondary="Helps identify clients that have stopped booking after a defined cut-off period." />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/bookings-stopped-range">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <DoNotDisturbAltIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Bookings stopped (range)" secondary="Helps identify clients that booked between a defined date range but not booked since." />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/bookings-detail">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <ManageHistoryIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Booking Detail" secondary="Identifies all customers that have booked up to specific date. Details their total spend, classes and or passes booked." />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/bookings-itemised">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <SettingsSuggestIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Itemised report" secondary="Provides detailed information of bookings on a customer by customer basis" />
                                        </ListItem>

                                        </List>                                        
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Pass Reports <span className='small'>Pass insights</span>
                                    </Typography>
                                    <Divider/>
                    
                                    <Box sx={{pt: 3, pb: 3}}>
                                    <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                                        <ListItem component={Link} href="/reports/pass-purchase">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <AppsIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Pass Purchase Overview" secondary="Customers that have purchased a pass between specified dates" />
                                        </ListItem>
              

                                        </List>                                        
                                         
                                    </Box>
                                          
                                </CardContent>
                            </Card>  

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Attendance Reports <span className='small'>Insights on attendance and waiting lists</span>
                                    </Typography>
                                    <Divider/>
                    
                                    <Box sx={{pt: 3, pb: 3}}>
                                    <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                                        <ListItem component={Link} href="/reports/attendance-attended">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <PeopleIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Event Attendance Overview" secondary="Details of who has paid and subsequently marked attended." />
                                        </ListItem>
              
                                        <ListItem component={Link} href="/reports/attendance-no-show">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <PersonOffIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Missed class overview" secondary="Details of who has paid and subsequently not marked as attended." />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/attendance-count">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <GroupAddIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="A count of classes attended." secondary="Details of who has paid and marked as attended via the respective registers." />
                                        </ListItem>

                                        <ListItem component={Link} href="/reports/joined-waiting-list">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <HourglassTopIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Subscribed to waiting list" secondary="Details of people that signed up to one or more waiting lists." />
                                        </ListItem>

                                        </List>                                        
                                         
                                    </Box>
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Financial reports <span className='small'>Income insights</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>
                                    <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                                        <ListItem component={Link} href="/reports/financial-overview">
                                            <ListItemAvatar> 
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <AccountBalanceIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Financial Overview" secondary="income received between specific dates" />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/financial-detail">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <MonetizationOnIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Financial Detail Per Event" secondary="Lists events, associated bookings and provides an overview of earnings. Any bookings via pass will be converted to the cash value based on the amount that the customer payed." />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/financial-percentile">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <LeaderboardIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Percentile distribution of sales by class" secondary="Analysis of income and how sales are distributed between all events that fall within the specified date range" />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/financial-charge-aggregates">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <TollIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Monthly charge aggregation report" secondary="Analysis of charges made by reservie for transactions" />
                                        </ListItem>
                                        <ListItem component={Link} href="/reports/report-financial-sales-by-category">
                                            <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: blue[500] }}>
                                                <InsightsIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Percent distribution of sales by category" secondary="Analysis of income and how sales are distributed between all events that fall within the specified date range" />
                                        </ListItem>

                                        </List>                                        
                                         
                                    </Box>
                                          
                                </CardContent>
                            </Card>  

                        </Grid>
    

   
                    </Grid>
                </Box>

        </div>
    )
}