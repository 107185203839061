import * as React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function QRCodeDialog( props ) {

  const { handleClose, handleComplete} = props;
  const [value, setValue] = React.useState('event-add');

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Action Selector
        </DialogTitle>
        <DialogContent>
          <FormControl>
              <FormLabel id="qrcode-action-radio-buttons-group-label">Select an action</FormLabel>
              <RadioGroup
                aria-labelledby="qrcode-action-radio-buttons-group-label"
                defaultValue="event-add"
                name="radio-buttons-group"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <FormControlLabel value="event-add" control={<Radio />} label="Book Event" />
                <FormControlLabel value="pass-add" control={<Radio />} label="Add Pass" />
                <FormControlLabel value="video-add" control={<Radio />} label="Add Video" />
                <FormControlLabel value="questionnaire-add" control={<Radio />} label="Add Questionnaire" />
                <FormControlLabel value="note-add" control={<Radio />} label="Add note" />
              </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={(e) => handleComplete(value)}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}