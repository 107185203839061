import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, TextField, MenuItem } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import {getDateFormatByLocation, getLocaleDateTime, timezonesSameLabel } from '../../libraries/format';

const UnpauseSubscriptionDialog = ({ open, onClose, onComplete, next }) => {
  const [selectedDate, setSelectedDate] = useState(getLocaleDateTime(new Date(next)));

  const [selectedTimeZone, setSelectedTimeZone] = useState(getDefaultTimeZone());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };

  function getDefaultTimeZone() {
    const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return defaultTimeZone || 'UTC';
  }

  const isFutureDate = (date) => {
    return date.getTime() > new Date().getTime();
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <p>
          When resuming a subscription from a paused state, you must select a date and time for the subscription to restart.
          This date/time must be now or in the future.
        </p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={2}>
            <DateTimePicker
              label="Select Restart Date"
              renderInput={(props) => <TextField {...props} variant='standard' fullWidth />}
              value={selectedDate}
              onChange={handleDateChange}
              minDateTime={new Date()}
              inputFormat={getDateFormatByLocation()}
              ampm={false}
            />
            <TextField
              select
              label="Select Timezone"
              value={selectedTimeZone}
              onChange={handleTimeZoneChange}
              fullWidth
              variant='standard'
            >
              {timezonesSameLabel.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="standard"
          onClick={() => onComplete(selectedDate, selectedTimeZone)}
          disabled={!isFutureDate(selectedDate)} // Disable the button if selectedDate is not in the future
        >
          Resume Subscription
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnpauseSubscriptionDialog;
