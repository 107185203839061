import * as React from 'react';
import { IconButton } from "@mui/material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export class ViewButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
          this.setState({ data: this.props.data });
        }
    }

    _handleClick = () => {
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className="iconView" disabled={this.props?.disabled || false} aria-label="Edit" onClick={this._handleClick}>
                <ReceiptLongIcon className="iconWidget"/>
            </IconButton>            
        )
    }
}
