import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, TextField, MenuItem, Box, Stack, useMediaQuery } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDateFormatByLocation, getLocaleDateTime, timezonesSameLabel } from '../../libraries/format';

const EditSubscriptionDialog = ({ open, onClose, onComplete, plans, subscription }) => {
  const [selectedPlan, setSelectedPlan] = useState(subscription?.plan_id);
  const [charge, setCharge] = useState(subscription?.paid || 0);
  const [selectedDate, setSelectedDate] = useState(getLocaleDateTime(new Date(subscription?.next)));
  const [selectedTimeZone, setSelectedTimeZone] = useState(getDefaultTimeZone());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };

  function getDefaultTimeZone() {
    const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return defaultTimeZone || 'UTC';
  }


  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    let p = plans?.find( p => parseInt(p.id) === parseInt(event.target.value));
    setCharge(p.gross);
  };

  const handleChargeChange = (event) => {
    setCharge(event.target.value);
  };


  const handleSubmit = () => {
    // Logic to handle form submission
    // You can access selectedPlan, charge, and selectedDate here
    onComplete(selectedPlan, charge, selectedDate, selectedTimeZone); // Close the dialog after submission
  };

  const isFutureDate = (date) => {
    return date.getTime() > new Date().getTime();
  };



  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const maxWidth = isSmallScreen ? 'xs' : 'sm'; // Adjust the maxWidth based on screen size

  return (
    <Dialog open={open} fullWidth onClose={onClose} maxWidth={maxWidth}>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', '& > *': { my: 2 } }}>
            <Stack spacing={4}>

            <TextField
            select
            label="Select Subscription Plan"
            value={selectedPlan}
            onChange={handlePlanChange}
            fullWidth
            variant='standard'
            disabled={!plans?.length > 0}
            >
            {
            plans?.map((p) => (
              <MenuItem value={`${p?.id}`}>{`${p?.name} (Plan: ${p?.id})`}</MenuItem>
            ))
            }
            </TextField>
            <TextField
            label="Charge Price"
            value={charge}
            onChange={handleChargeChange}
            fullWidth
            variant='standard'
            disabled={!plans?.length > 0}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                label="Next charge date"
                renderInput={(props) => <TextField {...props} variant='standard' fullWidth />}
                value={selectedDate}
                onChange={handleDateChange}
                minDateTime={new Date()}
                disabled={!plans?.length > 0}
                ampm={false}
                inputFormat={getDateFormatByLocation()}
                />

            </LocalizationProvider>
            <TextField
                select
                label="Select Timezone"
                value={selectedTimeZone}
                onChange={handleTimeZoneChange}
                fullWidth
                variant='standard'
                disabled={!plans?.length > 0}
                >
                {timezonesSameLabel.map((timezone) => (
                    <MenuItem key={timezone.value} value={timezone.value}>
                    {timezone.label}
                    </MenuItem>
                ))}
            </TextField>
            </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={handleSubmit}  
        disabled={(!plans?.length > 0) || !isFutureDate(selectedDate)}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubscriptionDialog;
