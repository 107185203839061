import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ManageButton } from './ManageButton';
import SearchIcon from '@mui/icons-material/Search';
import { AddCustomer } from './AddCustomer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function createData(id, firstname, lastname, email, mobile) {
  console.log("data", { id, firstname, lastname, email, mobile });
  return { id, firstname, lastname, email, mobile };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomersTableActive(props) {
  const { customers, reload } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rows, setRows] = React.useState(customers?.map(r => createData(r.id, r.firstname, r.lastname, r.email, r.mobile)));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('rvRowsPerPage') || -1);
  const [filtertext, setFilterText] = React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onExport = (e) => {
    var r = [
      ["ID", "First Name", "Last name", 'Email', 'Mobile'],
    ].concat(rows.map(r => [`${r?.id}, ${r?.firstname},${r?.lastname},${r?.email}, ${r?.mobile}`]));

    let csvContent = "data:text/csv;charset=utf-8," + r.map(e => Object.values(e).join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "active-customers-export" + (new Date()).toDateString() + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('rvRowsPerPage', parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const r = customers.filter(c => {
      if (!!!filtertext) {
        return true;
      }
      const f = filtertext.toLowerCase();
      if (c?.firstname?.toLowerCase().indexOf(f) > -1) {
        return true;
      }
      if (c?.lastname?.toLowerCase().indexOf(f) > -1) {
        return true;
      }
      const name = `${c.firstname} ${c.lastname}`;
      if (name?.toLowerCase().indexOf(f) > -1) {
        return true;
      }

      if (!!c?.mobile && c?.mobile?.toString().indexOf(f) > -1) {
        return true;
      }
      if (!!c?.email && c?.email?.indexOf(f) > -1) {
        return true;
      }

      return false;
    });
    setRows(r);
  }, [filtertext]);

  const goto = (to) => {
    //navigate( to );
    window.location.href = to;
  };

  return (
    <TableContainer component={Paper} variant='outlined'>
      <Table aria-label="Active Customers">
        <TableHead>
          <TableRow>
            <TableCell colSpan={isMobile ? 2 : 4} align='left'>
              <Grid container justifyContent={isMobile ? "center" : "flex-end"} alignItems="center">
                <Grid item xs={10} sm={4} md={3} sx={{ pt: 1, pr: 2 }}>
                  <AddCustomer reload={reload} fullWidth={true} />
                </Grid>
                <Grid item xs={2} sm={2} md={1}>
                  <IconButton aria-label="download" size="large" onClick={onExport}>
                    <FileDownloadIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
                 
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '100%', pr: 2 },
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={(e) => { e.preventDefault() }}
                    >
                      <TextField
                        id="search"
                        variant="standard"
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                    </Box>
                  </Grid>
                
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }, maxWidth: '200px', wordWrap: 'break-word' }}>Email</TableCell>
            <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Phone</TableCell>
            {!isMobile && <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'none' } }}>Contact</TableCell>}
            <TableCell align="left">Manage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows && (rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows).map((row) => (
              <TableRow
                key={`customers-${row.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {!isMobile && 
                <TableCell>
                  {`${row.firstname} ${row.lastname}`}
                </TableCell>
                }
                {isMobile && 
                <TableCell>
                  <stack>
                  <Typography variant="body2">{`${row.firstname} ${row.lastname}`}</Typography>
                  <Box><a href={`mailto:${row.email}`} className='link'>{row.email}</a></Box>
                  <Box><a href={`tel:${row.mobile}`} className='link'>{row.mobile}</a></Box>

                  </stack>
                </TableCell>
                }
                <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }} className='link'><a href={`mailto:${row.email}`}>{row.email}</a></TableCell>
                <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }} className='link'><a href={`tel:${row.mobile}`}>{row.mobile}</a></TableCell>

                <TableCell align="left"><ManageButton callback={(e) => { goto(`/customers/manage/${row.id}`) }} data={row} /></TableCell>
              </TableRow>
            ))}
          {(rowsPerPage === 0 || !!!rows || rows?.length === 0) && (
            <TableRow>
              <TableCell colSpan={isMobile ? 2 : 4} sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                <Box sx={{ p: 10 }}>
                  <Typography variant="h6" component="p" style={{ textAlign: 'center' }}>You currently have no customers.</Typography>
                </Box>
              </TableCell>
              <TableCell colSpan={3} sx={{ display: { xs: 'table-cell', sm: 'none' } }}>
                <Box sx={{ p: 10 }}>
                  <Typography variant="h6" component="p" style={{ textAlign: 'center' }}>You currently have no customers.</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={isMobile ? 2 : 4}
              count={!!rows ? rows.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={!!rows ? rows.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Rows"
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
