import { Button, Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import CloseIcon from '@mui/icons-material/Close';
import Chart, { elements } from 'chart.js/auto'; // keep here - it does the auto-cleanup
import { Bar, Pie } from 'react-chartjs-2';
import { checkEpochShift, getCache, setCache } from '../../libraries/cache';
import EventsTable from '../elements/eventsTable';
import { get, post } from '../../libraries/network';
import WaitingTable from '../elements/waitingTable';
import SalesTable from '../elements/salesTable';
import SettingsIcon from '@mui/icons-material/Settings';
import PromptDialog from '../elements/promptDialog';
import TwoFactorDialog from '../elements/TwoFactorAuthentictionDialog';


const template_7_day_avail = {
    "labels": [],
    "datasets": [
      {
        "label": "Space",
        "data": [
         
        ],
        "backgroundColor": "rgba(0, 0, 0, 0.05)",
        "stack": 0
      },
      {
        "label": "Allocated",
        "data": [

        ],
        "backgroundColor": "rgba(118, 98, 180, 1)",
        "stack": 0
      },
      {
        "label": "Waiting list",
        "data": [

        ],
        "backgroundColor": "rgba(54, 162, 235, 0.1)",
        "stack": 0
      }
    ]
};

const template_7_day_sales = {
    "labels": [],
    "datasets":  [
        {
          "label": "# bookings",
          "data": [],
          "backgroundColor": [
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)",
            "rgba(89, 150, 210, 1)"
          ]
        },
        {
          "label": "# pass credits",
          "data": [],
          "backgroundColor": [
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)",
            "rgba(269, 161, 102, 1)"
          ]
        },
        {
          "label": "Income",
          "data": [],
          "backgroundColor": [
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)",
            "rgba(113, 208, 158, 1)"
          ],
          "color": "rgba(113, 208, 158, 1)"
        },
        {
          "label": "# videos",
          "data": [],
          "backgroundColor": [
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)",
            "rgba(212, 183, 100, 1)"
          ],
          "color": "rgba(212, 183, 100, 1)"
        }
      ]
};

const template_12_month_avail = {

};


const template_12_sales = {
    "transactions": {
      "labels": [
      ],
      "datasets": [
        {
          "label": "# bookings",
          "data": [
    
          ],
          "backgroundColor": [

          ]
        },
        {
          "label": "# pass credits",
          "data": [
          ],
          "backgroundColor": [

          ]
        },
        {
          "label": "# videos",
          "data": [

          ],
          "backgroundColor": [

          ]
        }
      ]
    },
    "income": {
      "labels": [

      ],
      "datasets": [
        {
          "label": "Income",
          "data": [
         
          ],
          "backgroundColor": [

          ]
        }
      ]
    }
}


function SettingsWidget( props ) {
    const {refresh} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onRefresh = () => {
        handleClose();
        refresh();
    }
  
    return (
      <div>
        <IconButton
          id="positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="primary"
          size="large"
        >
            <SettingsIcon sx={{fontSize:'40px'}}/>
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={onRefresh}>Refresh</MenuItem>
        </Menu>
      </div>
    );
  }


export default function Dashboard()
{

    
    const [avail7days, setAvail7Days] = React.useState(getCache('avail-7-days') || template_7_day_avail);
    const [avail7daysBusy, setAvail7DaysBusy] = React.useState(true);
    const [avail12months, setAvail12Months] = React.useState(getCache('avail-12-month') || template_12_month_avail);
    const [avail12monthsBusy, setAvail12MonthsBusy] = React.useState(true);
    const [eventsSummary, setEventsSummary] = React.useState(getCache('events') || []);
    const [eventsSummaryBusy, setEventsSummaryBusy] = React.useState( true );
    const [sales7days, setSales7Days] = React.useState(getCache('sales-7-days') || template_7_day_sales);
    const [sales7daysBusy, setSales7DaysBusy] = React.useState(true);
    const [salesdetails, setSalesDetails] = React.useState(getCache('sales-detail'));
    const [salesdetailsBusy, setSalesDetailsBusy] = React.useState(true);
    const [waitingfuture, setWaitingFuture] = React.useState(getCache('waiting-future')||[]);
    const [waitingfutureBusy, setWaitingFutureBusy] = React.useState(true);
    const [sales12month, setSales12Month] = React.useState(getCache('sales-12-month'));
    const [sales12MonthBusy, setSales12MonthBusy] = React.useState(true);
    const [showMFA, setShowMFA] = React.useState(false);
    const [busy, setBusy] = React.useState(false);
    const [message, setMessage] = React.useState(getCache('message'));

    const snack = false;

    console.log(avail7days);

    const onAcknowledgeMessage = async () => {
        try{
            const data = await post( `/messages/ajax-accept-message`, `term=${message.id}`);
        }
        catch(e)
        {

        }
        setMessage(false);
    }

    const createStack = ( url, callback, key, busy, template, cache ) => {
        return {url, callback, key, busy, template, cache}
    }

    const callstack = [
        createStack('/backenddata/ajax-fetch-events-overview', setEventsSummary, 'events', setEventsSummaryBusy, [], true),
        createStack('/backenddata/ajax-fetch-7-day-avail', setAvail7Days, 'avail-7-days', setAvail7DaysBusy, template_7_day_avail, true),
        createStack('/backenddata/ajax-fetch-waiting', setWaitingFuture, 'waiting-future', setWaitingFutureBusy, [],true),
        createStack('/backenddata/ajax-fetch-12-month-avail', setAvail12Months, 'avail-12-month', setAvail12MonthsBusy, template_12_month_avail, true),
        createStack('/backenddata/ajax-fetch-7-day-sales', setSales7Days, 'sales-7-days', setSales7DaysBusy, template_7_day_sales, true),
        createStack('/backenddata/ajax-fetch-sales-detail', setSalesDetails, 'sales-detail', setSalesDetailsBusy, [], true),
        createStack('/backenddata/ajax-fetch-12-month-sales', setSales12Month, 'sales-12-month', setSales12MonthBusy, template_12_sales, true),
        createStack('/backenddata/ajax-fetch-system-message', setMessage, 'message', ()=>{}, false, false),
        createStack('/backenddata/ajax-fetch-2fa-settings', setShowMFA, '2fa', ()=>{}, false, false)
        
    ]

    const fetchdata = async( server_requested ) => {
        if(busy)
        {
            return;
        }
        setBusy(true);
        for(let i = 0; i < callstack.length; i++)
        {
            try{
                callstack[i].busy( true );
                console.log("server requested", server_requested, callstack[i].key);
                if(!server_requested)
                {
                    const has_data = !!getCache(callstack[i].key);
                    if(has_data)
                    {
                        console.log("Skipping key", callstack[i].key);
                        callstack[i].busy( false );
                        continue;
                    }
                }
                // we need to throttle the requests to the server so we do not hammer it...
                const data = await new Promise(( resolve, reject) => {
                    setTimeout(async ()=> {
                        try{
                            let d = await get( callstack[i].url );
                            console.log("Data is ", d);
                            if(!d)
                            {
                                d =  callstack[i].template;
                            }
                            resolve(d);
                        }
                        catch(e)
                        {
                            if(window?.static_override)
                            {
                                let d = window?.static_dashboard[callstack[i].key];
                                if(!d)
                                {
                                    d =  callstack[i].template;
                                }
                                resolve(d);
                            }
                            else
                            {
                                reject(e);
                            }
                            
                        }                                        
                    }, 500);
 
                });
                callstack[i].callback( data );
                callstack[i].busy( false );
                if(callstack[i].cache)
                    setCache(callstack[i].key, data);

            }
            catch(err)
            {
               
                console.error("Failed to get", err);
                callstack[i].busy( false );

            }
        }
        setBusy(false);
    }

    const fetchDataIfRequired = async() => {
        try{
            let d = await get( '/backenddata/is_refresh_required');
            fetchdata( d?.refresh );
        } 
        catch(e)
        {
            if(window?.static_override)
            {
                fetchdata( false );
            }

        }       
    }

    React.useEffect(() => {

        if(checkEpochShift('DASHBOARD')) // epoch has shifted. Update
        {
            fetchdata( true );
        }
        else
        {
            fetchDataIfRequired();
        }
        const intervalid = setInterval(async() => {
            if(checkEpochShift('DASHBOARD')) // epoch has shifted. Update
            {
                fetchdata( true );
            }
            else
            {            
                await fetchDataIfRequired()
            }
        }, 120000);


        return () => clearInterval(intervalid);

    }, []);





    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar widgets={[<SettingsWidget refresh={()=>{fetchdata(true)}} />]}/>                
            </Box>
                <Box sx={{p: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Availability <span className='small'>7 day view</span>
                                    </Typography>
                                    <Divider/>
                                        {
                                            avail7daysBusy &&    
                                            <Box sx={{pt: 3, pb: 3}}>                                   
                                                <Stack>
                                                    <Skeleton variant="rectangle" height={265} />
                                                </Stack>
                                            </Box>
                                        }

                                        {
                                            !!avail7days && !avail7daysBusy &&
                                            <Box 
                                            sx={{pt: 3, pb: 3, height: 265, width: "100%"}}
                                            >

                                            <Bar
                                                data={avail7days}
                                                width={100}
                                                height={265}
                                                options={{ maintainAspectRatio: false }} 
                                            /> 
                                            </Box>
                                        }
                                         
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Availability <span className='small'>12 month view</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                        avail12monthsBusy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton variant="rectangle" height={265} />
                                            </Stack> 
                                        </Box>
                                    }
                                    {
                                        !!avail12months && !avail12monthsBusy &&
                                        <Box 
                                            sx={{pt: 3, pb: 3, height: 265, width: "100%"}}
                                            className="canvas"
                                            >
                                        <Pie
                                        data={avail12months}
                                        options={
                                            {maintainAspectRatio: false}
                                        }
                                        />      
                                        </Box>
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Event Overview <span className='small'>summary of event availability</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                    !eventsSummary && eventsSummaryBusy &&
                                    <Box sx={{pt: 3, pb: 3}}>
                                        <Stack>
                                            <Skeleton animation="wave" height={15} />
                                            <Skeleton animation="wave" height={15} />
                                            <Skeleton animation="wave" height={15} />
                                            <Skeleton animation="wave" height={15} />
                                        </Stack>
                                    </Box>
                                    }
                                    {
                                        !eventsSummaryBusy &&
                                        <EventsTable events={eventsSummary} /> 

                                    }
                                 
                                </CardContent>
                            </Card>  

                        </Grid> 

                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Waiting Lists <span className='small'>customers who have registered interest in fully booked classes</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                        waitingfutureBusy &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                            </Stack>
                                        </Box>
                                   }
                                   {
                                        !waitingfutureBusy &&
                                        <WaitingTable list={waitingfuture}/>

                                   }
 
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Sales <span className='small'>7 day view</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                        sales7daysBusy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton variant="rectangle" height={265} />
                                            </Stack>
                                        </Box>
                                    }
                                    {
                                       !sales7daysBusy && 
                                        <Box>
                                            <Bar
                                                data={sales7days}
                                                width={100}
                                                height={200}
                                                options={{ maintainAspectRatio: false }} 
                                            />                                            
                                        </Box>

                                    }

                                </CardContent>
                            </Card>  

                        </Grid>                        
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Sales detail <span className='small'>List of recent sales</span>
                                    </Typography>
                                    <Divider/>

                                    {
                                        salesdetailsBusy &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                                <Skeleton animation="wave" height={15} />
                                            </Stack>
                                        </Box>
                                    }
                                    {
                                        !salesdetailsBusy &&
                                        <SalesTable list={salesdetails}/>

                                    }

                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Purchases <span className='small'>12 month view</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                        sales12MonthBusy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton variant="rectangle" height={265} />
                                            </Stack>
                                        </Box>
                                    }
                                    {
                                       !sales12MonthBusy && 
                                        <Box>
                                            <Bar
                                                data={sales12month.transactions}
                                                width={100}
                                                height={200}
                                                options={{ maintainAspectRatio: false }} 
                                            />                                            
                                        </Box>

                                    }

                                </CardContent>
                            </Card>  

                        </Grid>                         
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Sales <span className='small'>12 month view</span>
                                    </Typography>
                                    <Divider/>
                                    {
                                        sales12MonthBusy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton variant="rectangle" height={265} />
                                            </Stack>
                                        </Box>
                                    }
                                    {
                                       !sales12MonthBusy && 
                                        <Box>
                                            <Bar
                                                data={sales12month.income}
                                                width={100}
                                                height={200}
                                                options={{ maintainAspectRatio: false }} 
                                            />                                            
                                        </Box>

                                    }

                                </CardContent>
                            </Card>  

                        </Grid>                           
                    </Grid>
                </Box>
                {
                    !!message && <PromptDialog title={message?.title} description={message.description} handleClose={() => setMessage(false)} handleOK={onAcknowledgeMessage}/>
                }
                {
                    snack &&
                    <Snackbar
                            open
                            message="You have a new customer note"
                            onClose={() =>{}}             
                            action={
                                <React.Fragment>
                                <Button color="secondary" size="small" onClick={() => {}}>
                                    READ
                                </Button>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={() => {}}
                                >
                                    <CloseIcon />
                                </IconButton>
                                </React.Fragment>
                            }
                            sx={{ bottom: { xs: 90, sm: 10 } }}
                        >
                        </Snackbar>
                }

                {
                    /* Setup 2FA reminder if we are not showin other messages */
                    !message && showMFA && <TwoFactorDialog open={true} onNavigate={(e) => {window.location.href = '/settings/mfa'}} onClose={(e) => setShowMFA(false) }/>
                    
                }

        </div>
    )
}