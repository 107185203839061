import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../elements/Copyright';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'


const theme = createTheme();

export default function ResetDone() {

  const [busy, setBusy] = React.useState(false);
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className="loginBox"
          sx={{
            marginTop: 1,
            paddingTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%' // Ensure the Box component spans the full width
          }}
        >
          <Avatar sx={{ m: 1 }} src="/logo192.png" style={{ width: '100px', height: '100px' }}>
          </Avatar>
          <Typography component="h1" variant="h5">
          Password Reset Complete
          </Typography>
          <Box sx={{ mt: 1 , width: '100%'}}>


              <Button
                component="a"
                href={`/client/login?email=${encodeURIComponent(email)}`}
                fullWidth
                variant="outlined"
                color="inherit"
                sx={{ mt: 3, mb: 2 }}
              >
                Click to Sign In
              </Button>       
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}