import { Backdrop, Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { checkEpochShift, getCache, setCache } from '../../libraries/cache';
import { get, post } from '../../libraries/network';
import SettingsIcon from '@mui/icons-material/Settings';
import { CustomersPanel } from '../elements/CustomersPanel';
import { BottomBar } from '../elements/BottomBar';




function SettingsWidget( props ) {
    const {refresh} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onRefresh = () => {
        handleClose();
        refresh();
    }
  
    return (
      <div>
        <IconButton
          id="positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="primary"
          size="large"
        >
            <SettingsIcon sx={{fontSize:'40px'}}/>
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={onRefresh}>Refresh</MenuItem>
        </Menu>
      </div>
    );
  }


export default function Customers()
{

    const [customers, setCustomers] = React.useState( getCache('customers') || [] );
    const [customersBusy, setCustomersBusy] = React.useState( true );
    const [busy, setBusy] = React.useState( false );
    const [progress, setProgress] = React.useState( true );
   

    React.useEffect(() => {
        if(!customersBusy)
        {
            const to = setTimeout( async() => {
                setProgress(false);
            }, 100);
            return () => clearTimeout(to);
        }

    }, [customersBusy]);

    const createStack = ( url, callback, key, busy, template, cache ) => {
        return {url, callback, key, busy, template, cache}
    }



    const callstack = [
        createStack('/customersrestapi/account/all', setCustomers, 'customers', setCustomersBusy, [], true),
        
    ]

    const fetchdata = async( server_requested ) => {
        if(busy)
        {
            return;
        }
        setBusy(true);
        for(let i = 0; i < callstack.length; i++)
        {
            try{
                callstack[i].busy( true );
                console.log("server requested", server_requested, callstack[i].key);
                if(!server_requested)
                {
                    const has_data = !!getCache(callstack[i].key);
                    if(has_data)
                    {
                        console.log("Skipping key", callstack[i].key);
                        callstack[i].busy( false );
                        continue;
                    }
                }
                // we need to throttle the requests to the server so we do not hammer it...
                const data = await new Promise(( resolve, reject) => {
                    setTimeout(async ()=> {
                        try{
                            let d = await get( callstack[i].url );
                            console.log("Data is ", d);
                            if(!d)
                            {
                                d =  callstack[i].template;
                            }
                            resolve(d);
                        }
                        catch(e)
                        {
                            if(window?.static_override)
                            {
                                let d = window?.static_customers[callstack[i].key];
                                console.log("static customers", d);
                                if(!d)
                                {
                                    d =  [];
                                }
                                resolve(d);
                            }
                            else
                            {
                                reject(e);
                            }
                            
                        }                                        
                    }, 500);
 
                });
                callstack[i].callback( data );
                callstack[i].busy( false );
                if(callstack[i].cache)
                    setCache(callstack[i].key, data);

            }
            catch(err)
            {
               
                console.error("Failed to get", err);
                callstack[i].busy( false );

            }
        }
        setBusy(false);
    }

    const fetchDataIfRequired = async() => {
        try{
            let d = await get( '/customersrestapi/is-customer-list');
            fetchdata( d?.refresh || customers.length < 1 );
        } 
        catch(e)
        {
            if(window?.static_override)
            {
                fetchdata( false );
            }

        }       
    }


   React.useEffect(() => {

            if(checkEpochShift('CUSTOMERS')) // epoch has shifted. Update
            {
                fetchdata( true );
            }
            else
            {
                fetchdata( true );
                //fetchDataIfRequired();
            }

            const intervalid = setInterval(async() => {
                if(checkEpochShift('CUSTOMERS')) // epoch has shifted. Update
                {
                    fetchdata( true );
                }
                else
                {            
                    await fetchDataIfRequired()
                }
            }, 120000);

            return () => clearInterval(intervalid);

    }, []);


    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar widgets={[<SettingsWidget refresh={()=>{fetchdata(true)}} />]}/>                
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Customers <span className='small'>active and archived customers</span>
                                </Typography>
                                <Divider/>
                                {
                                (!customers?.length && customersBusy) &&
                                <Box sx={{pt: 3, pb: 3}}>
                                    <Stack>
                                        <Skeleton animation="wave" height={15} />
                                        <Skeleton animation="wave" height={15} />
                                        <Skeleton animation="wave" height={15} />
                                        <Skeleton animation="wave" height={15} />
                                    </Stack>
                                </Box>
                                }
                                    <Backdrop open={progress} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                {

                                    !customersBusy &&
                                    <CustomersPanel customers={customers} reload={fetchDataIfRequired} />

                                }
                                
                            </CardContent>
                        </Card>  

                    </Grid> 
                    
                </Grid>
            </Box>
            <BottomBar />
        </div>
    )
}