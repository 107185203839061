import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import { ArchiveOutlined, MergeTypeOutlined } from '@mui/icons-material';
import { del, get, post, put } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import AlertDialog from './alertDialog';

const CustomerManage = ( props ) => {
   
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);   
  const [loading, setLoading] = React.useState(0);   

  const [selectedMergeAccount, setSelectedMergeAccount] = useState('');
  const [mergeAccounts, setMergeAccounts] = useState([]);

  const fetchAccounts = async() => {
    try{
      setLoading(1);
      var c = await get('/customersrestapi/account/all');
      setMergeAccounts(c);
      setLoading(100);
      setTimeout(()=> {
        setLoading(0);
       }, 100);           
   }
   catch(e)
   {
     if(window?.static_override)
     {
       setLoading(100);
       setTimeout(()=> {
         setLoading(0);
         setMergeAccounts([])
       }, 5000);
     }
     else
     {
       setLoading(0);
       setError("Failed to fetch customer list");
       setMergeAccounts([])

     }          
   }
  }

  React.useEffect(() => {

    if(openMergeDialog)
    {
      fetchAccounts();
    }
    else
    {
      setMergeAccounts([])
    }
  }, [openMergeDialog])

  const handleArchiveClick = () => {
    setOpenArchiveDialog(true);
  };

  const handleMergeClick = () => {
    setOpenMergeDialog(true);
  };

  const handleDialogClose = () => {
    setOpenArchiveDialog(false);
    setOpenMergeDialog(false);
  };

  const handleArchiveProceed = async () => {
    // Perform archive action
    console.log('Archive customer account');

    try{
      await del(`/customersrestapi/account/${window?.configuration?.access}/archive`);
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
        window.location.href = '/customers/';
      }, 500);
    }
    catch(e)
    {
      if(window?.static_override)
      {
        setProgress(100);
        setTimeout(()=> {
          setProgress(0);
          window.location.href = '/customers/';

        }, 1000);
      }
      else
      {
        setProgress(0);
        setError("Failed to archive customer");

      }
    }

    setOpenArchiveDialog(false);
  };

  const handleMergeProceed = async () => {
    // Perform merge action
    console.log('Merge customer account with:', selectedMergeAccount);
    try{
      var chain = 'to=' + encodeURIComponent(selectedMergeAccount);

      await put(`/customersrestapi/account/${window?.configuration?.access}/merge`, chain);

      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
        window.location.href = '/customers/';
      }, 500);
    }
    catch(e)
    {
      if(window?.static_override)
      {
        setProgress(100);
        setTimeout(()=> {
          setProgress(0);
          window.location.href = '/customers/';

        }, 5000);
      }
      else
      {
        setProgress(0);
        setError("Failed to merge customer");

      }
    }
    setOpenMergeDialog(false);
  };

  const handleMergeAccountSelect = (event) => {
    setSelectedMergeAccount(event.target.value);
  };

  return (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleArchiveClick}>
            <ListItemIcon>
              <IconButton>
                <ArchiveOutlined sx={{ color: 'red' }} />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Archive this customer" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleMergeClick}>
            <ListItemIcon>
              <IconButton>
                <MergeTypeOutlined sx={{ color: 'black' }} />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Merge customer with another account" />
          </ListItemButton>
        </ListItem>
      </List>
      <Dialog open={openArchiveDialog} onClose={handleDialogClose}>
        <DialogTitle>Archive Customer</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to archive this customer account?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleArchiveProceed}>Proceed</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMergeDialog} onClose={handleDialogClose}>
        <DialogTitle>Merge Customer</DialogTitle>
        <DialogContent>
          <p>Please select the account to merge with:</p>
          <FormControl fullWidth>
            <Select value={selectedMergeAccount} onChange={handleMergeAccountSelect}>
              {mergeAccounts.map((account) => (
                <MenuItem key={account} value={account.id}>
                  {`${account.firstname} ${account.lastname} (${account.email})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleMergeProceed}>Proceed</Button>
        </DialogActions>
      </Dialog>
      {
            !!error &&
            <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
        } 
        {
            progress > 0 &&
            <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
        } 
        {
            loading > 0 &&
            <ProgressDialog title="Fetching customer list.." text={''} progress={progress} />
        }  
    </div>
  );
};

export default CustomerManage;
