import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert, AlertTitle, Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import {  post } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import AlertDialog from './alertDialog';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getDateFormatString, getNavigatorLanguage } from '../../libraries/format';




export function BuyPassDialog( props ) {

  const {handleClose, setProgress, setError, access, passes, initiate, reload} = props;
  const [credits, setCredits] = React.useState(0);
  const [pass, setPass] = React.useState(false);
  const [expireson, setExpiresOn] = React.useState(null);
  const [mode, setMode] = React.useState(0);
  const [price, setPrice] = React.useState(0.00);
  const [ready, setReady] = React.useState(false);
  const [nullexpires, setNullExpires] = React.useState(false);
  const [nullstarts, setNullStarts] = React.useState(false);
  const [starts, setStarts] = React.useState(new Date());


  React.useEffect(() => {

    if( !pass?.id)
    {
      setReady(false);
      setCredits(0);
      setPrice(0);
   
      setExpiresOn(null);  
    }
    else
    {
      setReady(true);
      setCredits(parseInt(pass?.credits));
      setPrice(parseFloat(pass?.price));

      const period = parseInt(pass?.period);
      const expires = parseInt(pass?.expires);
      let date = new Date();
      if(period === 0) // days
      {
        date.setDate(date.getDate() + expires);
      }
      else
      {
        date.setMonth(date.getMonth() + expires);  
      }


      const m = parseInt(pass?.expiration_starts);
      setMode(m.toString());
      setExpiresOn(date);   
    
    }
  }, [ pass])




  const handleChange = (event) => {
    setPass(event.target.value);

  };




  const handleCreditChange = (e ) => {
    setCredits(parseInt(e.target.value))
  }

  const handlePriceChange = (e ) => {
    setPrice(parseFloat(e.target.value))
  }


  const onSave = async(e) => {
      e.preventDefault();
      const d = new Date(expireson);
      const str = d.toISOString().split('T');

      let chain = 'client_id=' + encodeURIComponent(access);
      chain += '&id=' + pass?.id;
      chain += '&credits=' + credits;
      chain += '&starts=' + (new Date(starts)).toISOString().replace('T', ' ');
      chain += '&expires=' + (new Date(expireson)).toISOString().replace('T', ' ');
      chain += '&expiration_starts=' + mode;


      if(!starts)
      {
        chain += '&nullstarts=' + true;
        setNullStarts(true);
      }
      else
      {
        chain += '&nullstarts=' + nullstarts;
      }

      if(!expireson)
      {
        chain += '&nullexpires=' + true;
        setNullExpires(true);
      }
      else
      {
        chain += '&nullexpires=' + nullexpires;
      }


      chain += '&price=' + price;
      try{
        setProgress(1);
        await post(`/customersrestapi/pass`, chain);
        setProgress(50);
        await reload();
        setProgress(100);
        handleClose()
        setTimeout(() => {
          setProgress(0);
        }, 2000)
      }
      catch(e)
      {
        await reload();
        setTimeout(() => {
          setProgress(0);
          setError("Sorry, we have been unable to complete this assignment. Please refresh this page an try again.");
          handleClose();
        }, 2000)
      }


  }

  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Assign a pass</DialogTitle>
        <DialogContent>
            <DialogContentText>
                To assign a pass to this customer, please complete the following form:
            </DialogContentText>
            <Box sx={{pt:2, pb:2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="pass-select-select-standard-label">Select Pass Type</InputLabel>
                                    <Select
                                        labelId="pass-select-select-standard-label"
                                        id="pass-select-select-standard"
                                        value={pass}
                                        onChange={handleChange}
                                        label="Select Pass Type"
                                    >
      
                                    {
                                      passes?.map( e => (
                                        <MenuItem key={`pass-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                                      ))
                                    }
                  
                                    </Select>
                                </FormControl> 
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl component="fieldset" sx={{pl: 0}} disabled={!pass?.id} required>
                                    <FormLabel id="pass-starts-label">Pass start mode</FormLabel>
                                    <RadioGroup aria-labelledby="pass-starts-label" name="passs-type" value={mode} onChange={(e) => setMode(e.target.value)}>
                                        <FormControlLabel key="standard" value='0' control={<Radio />} label="Standard - time of pass purchase" />
                                        <FormControlLabel key="advanced" value='1' control={<Radio />} label="Advanced - time of first event" />
                                    </RadioGroup>
                                </FormControl>                                     
                                <FormHelperText id="pass-type-helper-text">By default, a pass expiration is calculated from the date of purchase. Alternatively, a pass can start from the date of the first event purchased.</FormHelperText>
                                <Box sx={{pt:2}}>
                                    <Alert severity="warning">
                                        <AlertTitle>
                                            Note - <b>Advanced</b> passes will revert to <b>Standard</b> in the following situations:

                                        </AlertTitle>
                                        <ol>
                                            <li>The first booking using the pass is a media file.</li>
                                        </ol> 
                                    </Alert>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                                        renderInput={(props) => <TextField {...props}  fullWidth/>}
                                        label="Start"
                                        disabled={!!nullstarts || !pass?.id || parseInt(mode) === 0}
                                        value={starts}
                                        onChange={(newValue) => {
                                          setStarts(newValue)
                                        }}
                                        ampm={false} // This line sets the 24-hour format
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControlLabel control={<Checkbox checked={nullstarts} onChange={(e) => setNullStarts(e.target.checked)} />} label="Do not set Start" disabled={parseInt(mode) === 0 || !pass?.id}/>

                            </Grid>

                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                                        renderInput={(props) => <TextField {...props} fullWidth />}
                                        label="Expires"
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        value={expireson}
                                        onChange={(newValue) => {
                                        setExpiresOn(newValue);
                                        }}
                                        disabled={!!nullexpires || !pass?.id}
                                        ampm={false} // This line sets the 24-hour format
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControlLabel control={<Checkbox checked={nullexpires} onChange={(e) => setNullExpires( e.target.checked)} />} label="Do not set expiration" disabled={parseInt(mode) === 0 || !pass?.id}/>
                            </Grid>   


            
                            <Grid item xs={12}>
                                <TextField
                                    id="price"
                                    label="Price"
                                    type="number"
                                    fullWidth
                                    disabled={!pass?.id}
                                    error={price < 0}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="credits"
                                    label="Credits"
                                    type="number"
                                    fullWidth
                                    error={credits < 0}
                                    disabled={!pass?.id}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                                    value={credits}
                                    onChange={handleCreditChange}
                                />
                            </Grid>
                             
                        </Grid>

            </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined' disabled={!ready} color="primary">Assign Pass</Button>
        </DialogActions>
        </form>
      </Dialog>     
    </div>
  );
}

export function BuyPass( props ) {
  const {access, passes, reload, initiate} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState( false );
	const inputElement = React.useRef()

  const handleClick = (event) => {
    setState(true)
  };
  const handleClose = () => {
  };
  React.useEffect(() => {
    if(initiate === 1)
      inputElement?.current?.click();
  }, [initiate])

  return (
    <div>
      <Button
        id="book-button"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        ref={inputElement}

      >
        Assign Pass
      </Button>

      {
        !!state &&
        <BuyPassDialog handleClose={() => {setState(0)}} setError={setError} setProgress={setProgress} access={access} passes={passes} reload={reload}/>
      }



      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      }       
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }

    </div>
  );
}