import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';
import { EventSelector } from '../elements/EventSelector';


export function ReportWaitingJoinedList()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [events, setEvents] = React.useState([])
    const [filtr, setFilter] = React.useState([]);



    const onDownloadClick  = () =>
    {
        var rows = [
            ["ID", "Name", "Email", 'Mobile','Event ID', 'Event Name', 'Date of Event', 'Subscription Date'],
        ].concat(data.map( r => [`${r?.id}, ${r?.firstname} ${r?.lastname}, ${r?.email}, ${r?.mobile}, ${r?.eid}, ${r?.ename}, ${( new Date(r?.edate)).toLocaleString().replace(',', '')}, ${(new Date(r?.date)).toLocaleString().replace(',', ' ')}`]));
 
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Joined waiting list " + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }


    React.useEffect(() => {
        // load the events for this range
        async function load(){
            try{
                let z = {};

                const d = Object.keys(range).map(function(key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(range[key].getTime());
                }).join('&');
                setProgress(10);
  
                const data = await get( `/reportsrestapi/events-in-date-range?${d}`);
                setEvents( data );
                setTimeout(() => {
                    setProgress(0);
                }, 100)

            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    console.log( window?.static_reports );
                    setEvents( window?.static_reports.events );
                    setTimeout(() => {
                        setProgress(0);
                    }, 2000)
                }
                else
                {
                    setProgress(0);
                    setError("Failed to get the category list");

                }
    
            }
        }
        load();
    }, [range]);

    const processData = (d) =>{
        var rows = d;
        if(!!filtr?.length)
        {
            rows = d?.filter( r => !!filtr?.find( f => r.eid === f));
        }
        return rows;
    }



    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);

        async function load() {
            try{

                let z = {};

                for (var property in range) {
                    if (range.hasOwnProperty(property)) {
                        console.log(range);
                        z[property] = range[property].getTime()
                    }
                }
                setProgress(10);
                const d = new URLSearchParams(z).toString();
                const data = await get( `/reportsrestapi/wait-list-between-dates?${d}`);
                setData( processData(data) );
                setProgress(100);
                setTimeout(() => {
                    setBusy(false);
                    setProgress(0);
                }, 500);
            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    console.log( window?.static_reports );
                    setTimeout(() => {
                        setBusy(false);
                        setProgress(0);
                        setData(processData(window?.static_reports.report_joined_subscription));
                    }, 2000)
                }
                else
                {
                    setError('Failed to load report');
                    setProgress(0);

                }   
                
                // to do - present error 

            }
        }
        load();

    }


    return (
        <div className="rvNS">

            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more events</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EventSelector setEvents={setFilter} list={events} events={filtr}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    { 
                                        !busy && !!data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Email
                                                                </TableCell>
                                                                <TableCell>
                                                                    Mobile
                                                                </TableCell>
                                                                <TableCell>
                                                                    Event ID
                                                                </TableCell>
                                                                <TableCell>
                                                                    Event Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Event Date
                                                                </TableCell>
                                                                <TableCell>
                                                                    Subscribed
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                data?.map( d => (
                                                                    <TableRow key={`report-${d.id}`}>
                                                                        <TableCell>
                                                                            {`${d?.firstname} ${d?.lastname}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d?.email}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d?.mobile}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d?.eid}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d?.ename}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(new Date(d?.edate)).toLocaleString()}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(new Date(d?.date)).toLocaleString()}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))   
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Grid>
                                        </Box>
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                
            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}