import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Box, Typography } from '@mui/material';

function createData(name, email, event, when, eid, did) {
    const w = new Date(when);
    const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const z = w.toLocaleDateString();

    return { name, when: `${z} ${x}`, email, event, eid, did};
}



export default function WaitingTable( props ) {
  const {list} = props;
  const rows = list?.map( r => createData(r.name, r.email, r.event, r.date, r.eid, r.did));

  console.log("rows", rows);
  return (
    <TableContainer component={Paper} variant='outlined'>
      <Table aria-label="waiting list table">
        <TableHead>
          <TableRow>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Name</TableCell>
              <TableCell align="left">Contact</TableCell>
              <TableCell align="left">Event</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}}  align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows && rows.length > 0 && rows.map((row) => (
            <TableRow
              key={`waiting-${row.eid}-${row.did}-${row.email}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
               {row.name}
              </TableCell>
              <TableCell sx={{ display: { xs: 'table-cell', sm: 'none' }}} align="left">
                <Link href={`mailto:${row.email}`}>{row.name}</Link>
              </TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}} align="left">
                <Link href={`mailto:${row.email}`}>{row.email}</Link>
              </TableCell>
              <TableCell align="left">
                {row.event}
                <Box sx={{ display: { xs: 'table-cell', sm: 'none' }}} >{row.when}</Box>
              </TableCell>
              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}} >{row.when}</TableCell>
            </TableRow>
          ))}
          {
            (!!!rows ||rows?.length === 0) &&

              <TableRow>
                 <TableCell colSpan={5}  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                 <Box sx={{p: 10}}>
                     <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no one on a future waiting list.</Typography>
                 </Box>                                        
                 </TableCell>
                 <TableCell colSpan={2}  sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                 <Box sx={{p: 10}}>
                     <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no one on a future waiting list.</Typography>
                 </Box>                                        
                 </TableCell>                 
              </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}