import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import QuestionaireTextBox from './QuestionaireTextBox';
import QuestionaireRadio from './QuestionaireRadio';
import QuestionaireMultiTextBox from './QuestionaireMultiTextBox';
import QuestionaireMultiCheckbox from './QuestionaireMultiCheckbox';
import QuestionaireCheckbox from './QuestionaireCheckbox';
import QuestionaireTextBlock from './QuestionaireTextBlock';
import QuestionaireTextBlockConfirm from './QuestionaireTextBlockConfirm';
import { post } from '../../../../libraries/network';
import AlertDialog from '../../alertDialog';
import { ProgressDialog } from '../../progressDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionnaireAddDrawer( props ) {

  const {close, questionnaires, reload} = props;
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState( false );
  const [questionnaire, setQuestionnaire] = React.useState( '' );

  const [qa, setQA] = React.useState(false);
  const handleClose = () => {
    close()
  };
  const handleSave = async(e) => {
    e.preventDefault();
    try{
      setProgress(1);

      let chain = `&questionnaire_id=${qa?.id}`;
      chain += `&questionnaire=` + JSON.stringify(qa?.qo.map( q => {
        return {
          id: q.id,
          answer: q.answer
        }
      }));
      await post(`/customersrestapi/questionnaires/${window?.configuration?.access}`,chain);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
        close();
        close();
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to save questionnaire");
      }, 1000);
    }
  };

  const jsonUnescape = (str) =>{
    return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
  }
  console.log(questionnaires);

  const onAnswer = ( id, a  ) => {
      console.log(id, a);
      const q = Object.assign({}, qa);
      const thequestion = q?.qo?.find( question => question.id === id);
      console.log(thequestion);
      if(!!thequestion)
      {
          switch( thequestion?.type )
          {
            case 'input':
            case 'text':
            thequestion.answer = { 'id': id, 'answer': a};
            break;
            case 'radio':
              thequestion.answer  = { 'id': id, 'answer': a};
            break;
            case 'checkbox':
            case 'text-block-confirm':
              thequestion.answer  = { 'id': id, 'answer': a};
            break;

            case 'multi-checkbox':


                const answer = thequestion?.answer?.find( ans => ans.answer === a);
                if(!!answer)
                {
                  thequestion.answer = thequestion.answer.filter( ans => ans.answer !== a)
                }
                else
                {

                  if(!thequestion?.answer)
                  {
                    thequestion.answer = [];
                  }
                  const option = thequestion.options.find( option => option.id === a);
                  if(!!option)
                  {
                    thequestion.answer.push( { 'id': id, 'answer': a} );
                  }
                }
            break;
          }
      }
      setQA(q);
  }

  React.useEffect(() => {
    setQA( questionnaires?.find( q => q.id === questionnaire || false ))

  }, [questionnaire])

  return (
    <div>

      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Questionnaire Editor
            </Typography>
  
          </Toolbar>
        </AppBar>
        <Box sx={{p: 2}}>
          <form onSubmit={handleSave}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <h3>Step 1: Choose a questionnaire</h3>
                  <FormControl fullWidth={true}>
                      <InputLabel id="questionnaire-select-label">Select a questionnaire</InputLabel>
                      <Select
                      labelId="questionnaire-select-label"
                      id="questionnaire-select"
                      label='Select a questionnaire'
                      value={questionnaire}
                      onChange={(e) => setQuestionnaire( e.target.value)}
                      key='questionnaire-select-label'
                      >
                      <MenuItem value="">
                      <em>None</em>
                      </MenuItem>
                      {
                          questionnaires?.map( q =>(
                              <MenuItem value={q.id}>{q.name}</MenuItem>
                          ))
                      }
                      </Select>
                  </FormControl>                
              </Grid>

              {
                !!qa &&

                <React.Fragment>
                {
                   qa?.qo.map( (q, i) => {
                    switch(q?.type)
                    {
                      case 'input':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireTextBox key={q.id} index={i+1} id={q.id} answer={q?.answer || ''} text={jsonUnescape(q.text)} required={q.required} onAnswer={onAnswer} /></Grid>
                      case 'text':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireMultiTextBox key={q.id} index={i + 1} id={q.id} answer={q?.answer || ''} text={jsonUnescape(q.text)} required={q.required} onAnswer={onAnswer} /></Grid>
                      case 'radio':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireRadio key={q.id} index={i + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''}  options={q.options} required={q.required} onAnswer={onAnswer} /></Grid>
                      case 'multi-checkbox':
                      return  <Grid key={`element-${q.id}`} item xs={12}><QuestionaireMultiCheckbox key={q.id} index={i + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''}  options={q.options} required={q.required} onAnswer={onAnswer} /></Grid>
                      case 'checkbox':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireCheckbox key={q.id} index={i + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''} required={true} onAnswer={onAnswer} /></Grid>
                      case 'text-block':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireTextBlock key={q.id} index={i + 1} id={q.id} text={jsonUnescape(q.text)}/></Grid>
                      case 'text-block-confirm':
                      return <Grid key={`element-${q.id}`} item xs={12}><QuestionaireTextBlockConfirm key={q.id} index={i + 1} id={q.id} text={jsonUnescape(q.text)} confirm={q.options[0].text}  answer={q?.answer || ''} required={true} onAnswer={onAnswer} /></Grid>
  
                      default: 
                      return <div></div>;
  
                    }
                  })
                }
                <Grid item xs={12} md={12}>
                      <Button type="submit" variant="contained" className="submit" color="primary" fullWidth >Save</Button>
                </Grid> 
                </React.Fragment>
              }  
            </Grid>
          </form>



          {
            !!error &&
            <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
          }       
          {
            progress > 0 &&
            <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
          }        
        </Box>
      </Dialog>
    </div>
  );
}