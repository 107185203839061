import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomersTablePasses from './CustomersTablePasses';
import CustomersTableMedia from './CustomersTableMedia';
import EventsTableEvent from './EventsTableEvent';
import EventsCalendar from './EventsTableCalendar';
import { EventsPanelEvents } from './EventsPanelEvents';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`customers-tabpanel-${index}`}
      aria-labelledby={`customers-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `customers-tab-${index}`,
    'aria-controls': `customers-tabpanel-${index}`,
  };
}

export function EventsPanel( props ) {
  const { bookings, 
          events, 
          passes, 
          companypasses, 
          media, 
          companymedia,
          subscriptions,
          questionnaires,
          allquestionnaires,
          reloadPasses, 
          reloadBookings, 
          reloadVideos,
          reloadSubscriptions,
          reloadQuestionnaires,
          notes,
          reloadNotes,
          action
        } = props;
  const [value, setValue] = React.useState(0);
  const [initiate, setInitiate] = React.useState(-1);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("Rendering panel with", bookings)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="Customers"
          variant="scrollable"
          scrollButtons="auto"
        
        >
          <Tab label="Calendar" {...a11yProps(0)} />
          <Tab label="Events" {...a11yProps(1)} />
          <Tab label="Appointments" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <EventsCalendar />
      </TabPanel> 
      <TabPanel value={value} index={1}>
          <EventsPanelEvents events={events} reload={reloadBookings} action={action} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomersTablePasses passes={passes} allpasses={companypasses} initiate={initiate} reload={reloadPasses} events={events} />
      </TabPanel>
      
    </Box>
  );
}