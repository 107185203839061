import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Link, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Copyright from '../elements/Copyright';
import { post } from '../../libraries/network';


const theme = createTheme();

export default function SignInPassword() {
  const [showpassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passworderror, setPasswordError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const [email, setEmail] = React.useState('');

  // Check if the location state contains reload:true

  const navigate = ( r ) => {
    window.location.href = r;

  }



  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);
    try {
      let r = await post('/clientrestapi/validate', `password=${encodeURIComponent(password)}`);
      if (r.status === 'OK') {
        setBusy(false);
        if(r.route === '2fa')
        {
          navigate("/client/mfa");
        }
        else
        {
          navigate("/client/index");          
        }
      }
      else
      {
        setBusy(false);
        setPasswordError(true);
        setMessage(r?.message || 'Authentication error');
      }
    } catch (e) {
      setBusy(false);
      setMessage(e || 'Authentication error');
      if (window?.static_override) {
        // OK if in client mode - we can redirect
        setPasswordError(true);
        setMessage("Authentication failed. Check email address and password");
        setBusy(false);

        setTimeout(() => {
          navigate("/client/mfa");
        }, 5000);
      }
    }
  };

  const onSetPassword = (password) => {
    setPassword(password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showpassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showpassword);
  };

  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            className="loginBox"
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%' // Ensure the Box component spans the full width
            }}
          >
            <Avatar sx={{ m: 1 }} src="/logo192.png" style={{ width: '100px', height: '100px' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  type={showpassword ? 'text' : 'password'}
                  required
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => onSetPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showpassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  error={passworderror}
                  autoFocus
                />
                <FormHelperText error>
                  {message}
                </FormHelperText>
              </FormControl>


              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Continue
              </Button>


              <Button
                  component="a"
                  href={`/client/reset?email=${encodeURIComponent(email)}`}
                  fullWidth
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Forgotten password
                </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={busy}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

        </Container>
    </ThemeProvider>
  );
}
