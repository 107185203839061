import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import zxcvbn from 'zxcvbn'; // Password strength checker library
import { post } from '../../libraries/network';

const theme = createTheme();

export default function ResetPassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setBusy(true);
        try {
        // Check password strength and length
        const passwordStrength = zxcvbn(password);
        if (password.length < 8 || passwordStrength.score < 3) {
            setBusy(false);
            setPasswordError(true);
            setMessage('Password must be at least 8 characters long and strong');
            return;
        }
        if (password !== confirmPassword) {
            setBusy(false);
            setConfirmPasswordError(true);
            setMessage('Passwords do not match');
            return;
        }
        let r = await post('/clientrestapi/resetpassword', `password=${encodeURIComponent(password)}`);
        if (r.status === 'OK') {
                setBusy(false);
                window.location.href = "/client/resetcomplete";
            }
            else
            {
                setBusy(false);
                setPasswordError(true);
                setMessage(r?.message || 'Authentication error');    
            }
        }
        catch (e) 
        {
            setBusy(false);
            setMessage(e || 'Authentication error');
            if (window?.static_override) {
                // OK if in client mode - we can redirect
                setPasswordError(true);
                setMessage("Authentication failed. Check email address and password");
                setBusy(false);
                setTimeout(() => {
                    window.location.href = "/client/resetcomplete";
                }, 5000);
            }
        }
  };

  const onSetPassword = (password) => {
    setPassword(password);
    if (confirmPassword !== '' && password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const onSetConfirmPassword = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className="loginBox"
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%' // Ensure the Box component spans the full width
          }}
        >
          <Avatar sx={{ m: 1 }} src="/logo192.png" style={{ width: '100px', height: '100px' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required error={passwordError}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                autoComplete="new-password"
                value={password}
                onChange={(e) => {onSetPassword(e.target.value); setPasswordError(false);}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
                <FormHelperText error>
                    {passwordError && message}
                </FormHelperText>
                <FormHelperText error>
                    {!passwordError && password && zxcvbn(password)?.score < 3 && (
                        <>
                        Password is weak. We recommend 8 or more characters with a mix of uppercase and lowercase letters, numbers, and special characters.
                        </>
                    )}
                </FormHelperText>
            </FormControl>

            <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required error={confirmPasswordError}>
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                name="confirm-password"
                type={showPassword ? 'text' : 'password'}
                required
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => onSetConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              <FormHelperText error>
                {confirmPasswordError && message}
              </FormHelperText>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Continue
            </Button>

          </Box>
        </Box>
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </ThemeProvider>
  );
}
