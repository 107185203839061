import * as React from 'react';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';


export class ManageButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
          this.setState({ data: this.props.data });
        }
    }
    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Edit button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className="iconEdit"  disabled={this.props?.disabled || false}  aria-label="Edit" onClick={this._handleClick}>
                <EditIcon className="iconWidget"/>
            </IconButton>            
        )
    }
}
