import { Typography } from "@mui/material";
import Link from '@mui/material/Link';

export default function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Powered by reservie.net. Copyright © '}
        <Link color="inherit" href="https://reservie.net/">
          reservie ltd 
        </Link>{' '}
        {`2015 - ${new Date().getFullYear()}. All rights reserved. ${window?.configuration?.release && `Version: ${window?.configuration?.release}`}`} 
      </Typography>
    );
  }