import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, useMediaQuery, useTheme  } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import getSymbolFromCurrency from 'currency-symbol-map'
import { DeleteButton } from './DeleteButton';
import ConfirmDialog from './confirmDialog';
import { del } from '../../libraries/network';
import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import CustomerSubscriptionMobile from './CustomerSubscriptionMobile';





function createData( subscription_id, subscription_name, id, bought, next,  paid, currency, state, active, refundable) {


    const w = new Date(next);
    const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const z = w.toLocaleDateString();
    const xs = w.toLocaleDateString([], {year: '2-digit', month:'2-digit', day: 'numeric'});



    const p = new Date(bought);
    const c = p.toLocaleDateString();
    const r =  { subscription_id, subscription_name, id, purchased: c, next: `${z} ${x}`,  paid, currency, state, active, refundable};
    console.log("returning pass", r);
    return r;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };




  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomersTableSubscriptionInvoices( props ) {
  const {invoices, reload} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [cancel, setCancel] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleCancelComplete = async( ) => {
    try{
      setCancel(false);
      setProgress(1);
      await del(`/customersrestapi/invoice/${window?.configuration?.access}/${cancel?.id}`,'');
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to cancel invoice");
      }, 1000);
    }
 }

  React.useEffect(() =>{
    const frow = invoices?.filter( c => {

      if(!!!filtertext)
      {
        return true;
      }
      if( c.name.indexOf(filtertext) > -1 || c.purchased.indexOf(filtertext) > -1  || c.expires.indexOf(filtertext) > -1)
      {
        return true;
      }


      return false;
    });
    setRows(frow?.map( row => createData( row.subscription_id, row.subscription_name, row.id, row.created, row.next, row.paid, row.currency, row.status, row.active, row.refundable)));
  }, [filtertext, invoices])


  const convertCurrency = ( c ) => {
    const currency = getSymbolFromCurrency(c);
    console.log(currency);
    if(!currency)
    {
       return c;
    }
    return currency;
 }

  console.log("Rendering CustomersTableSubscriptionInvoices", invoices);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
          
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Subscription ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Subscription</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Created</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Due</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Price</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>State</TableCell>
                      {
                          !isMobile &&
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Cancel</TableCell>
                      }
                      {
                          isMobile &&
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                      }                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                
                     
                        <TableRow
                          key={`customers-${row.id}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "1px solid  rgba(224, 224, 224, 1);" } }}
                          >
                              <TableCell  align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {`${parseInt(row.id).toString(16)}`} 
                              </TableCell>

                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${parseInt(row.subscription_id).toString(16)}`} 
                              </TableCell>
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {row.subscription_name}
                              </TableCell>

                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {row.purchased} 
                              </TableCell>
        
                          

                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{row.next}</TableCell>
                              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${convertCurrency(row.currency)}${parseFloat(row.paid).toFixed(2)}`}
                              </TableCell>   
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{row.state}</TableCell>
                              {
                                !isMobile &&
                                <TableCell align="left"><DeleteButton data={row} disabled={row.state !== "Pending"} callback={()=> {setCancel(row)}}/></TableCell>

                              }
                              {
                                isMobile &&
                                <TableCell align="left" >
                                  <CustomerSubscriptionMobile context={row} cancelActive={row.state === "Pending"} onCancel={setCancel}/>
                                </TableCell>

                              }                            
                          </TableRow>
                
               
                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={8} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>This customer has no subscription invoices.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={2} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>This customer has no subscription invoices.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={8}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Invoices per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'Invoices per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Invoices per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'Invoices per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid>   
      {
        cancel && <ConfirmDialog title="Cancel Invoice" description="Are you sure that you want to cancel this invoice. This cannot be undone. The payment pending will not be taken." handleClose={()=>{setCancel(false)}} handleComplete={handleCancelComplete} />

      } 
            {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }
    </React.Fragment>


  );
}