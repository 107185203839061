import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export  function CustomerSelector( props ) {
  const theme = useTheme();
  const {list} = props;
  const { customers, setCustomers} = props;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCustomers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="event-selector-label">Customer filter</InputLabel>
        <Select
          labelId="customer-selector-label"
          id="customer-selector"
          multiple
          value={customers}
          onChange={handleChange}
          input={<OutlinedInput label="Customer filter" />}
          MenuProps={MenuProps}
          disabled={!(list?.length > 0)}
        >
          {list.map((l) => (
            <MenuItem
              key={l.id}
              value={l.id}
              style={getStyles(l, customers, theme)}
            >
              {`${l.firstname} ${l.lastname} (${l.email})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}