import React, { useState } from 'react';
import { Button, TextField, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { del, post, put } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import ConfirmDialog from './confirmDialog';
import AlertDialog from './alertDialog';

const CustomerNoteList = ( props ) => {
  const {reload, customernotes, initiate} = props;

  const [notes, setNotes] = useState(customernotes || []);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newNote, setNewNote] = useState({ title: '', topic: '' });
  const [editNote, setEditNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState( false );
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);   
  const inputElement = React.useRef();

  React.useEffect(() => {
    setNotes(customernotes);
 }, [customernotes])


 React.useEffect(() => {
    if(initiate === 5)
      inputElement?.current?.click();
  }, [initiate]);

  console.log("notes", customernotes);
  const handleEditNote = async( e ) => {
    e.preventDefault();
    // Logic to handle editing of a note
    // You can open a dialog or navigate to a separate page for editing
    const {title, topic, id} = editNote;
    var chain = '&title=' + encodeURI(title);
    chain += '&topic=' + encodeURI(topic);

    
    try{
        setProgress(50);
        await put(`/customersrestapi/notes/${window?.configuration?.access}/${id}`,chain);
        await reload();
        setProgress(100);
        setEditNote(false);
        setTimeout(()=> {
          setProgress(0);
        }, 500);
    }
    catch(e)
    {
        setTimeout(async()=> {
            if(window?.static_override)
            {
                await reload();
                setProgress(100);
                setEditNote(false);
                setTimeout(()=> {
                  setProgress(0);
                }, 500);
            }
            else
            {
                    setEditNote(false);
                    setProgress(0);
                    setError("Failed to edit this note");
            }

        }, 1000);
    }
    console.log(`Edit note with id ${id}`);

  };

  const handleDeleteNote = (id) => {
    setDeleteNote(id);
  };

  const handleCompleteDeleteNote = async () => {
    try{
        setProgress(50);
        await del(`/customersrestapi/notes/${window?.configuration?.access}/${deleteNote}`);
        await reload();
        setProgress(100);
        setDeleteNote(false);
        setTimeout(()=> {
          setProgress(0);
        }, 500);
    }
    catch(e)
    {
        setTimeout(()=> {
            setDeleteNote(false);
            setProgress(0);
            setError("Failed to delete this note");
        }, 1000);
    }
  };

  const handleAddNote = async(e) => {
    e.preventDefault();
    const {title, topic} = newNote;

    var chain = '&title=' + encodeURI(title);
    chain += '&topic=' + encodeURI(topic);
    try{
        setProgress(50);
        var res = await post(`/customersrestapi/notes/${window?.configuration?.access}`,chain);
        await reload();
        setProgress(100);
        setShowAddDialog(false);
        //setNotes([...notes, { id: res?.data?.id, ...newNote }]);
        setNewNote({ title: '', topic: '' });

        setTimeout(()=> {
          setProgress(0);
        }, 500);
    }
    catch(e)
    {
        setTimeout(()=> {
            setProgress(0);
            setError("Failed to add this note");
        }, 1000);
    }


  };

  return (
    <div>
        <Grid container justifyContent="flex-end">
            <Grid item>
                <Button variant="outlined" ref={inputElement} onClick={() => setShowAddDialog(true)}>Add Note</Button>
            </Grid>
        </Grid>

        <List>
            {notes.map((note) => (
            <ListItem key={note.id}>
                <ListItemText primary={note.title} secondary={note.topic} />

                <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => setEditNote({...note})}>
                    <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNote(note.id)}>
                    <DeleteIcon />
                </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            ))}
        </List>

        <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)}>
            <DialogTitle>Add New Note</DialogTitle>
            <form onSubmit={handleAddNote}>
            <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Title"
                        value={newNote.title}
                        variant='standard'
                        required
                        onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
                        fullWidth
                    />                    
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Note"
                        value={newNote.topic}
                        onChange={(e) => setNewNote({ ...newNote, topic: e.target.value })}
                        fullWidth
                        required
                        multiline
                        variant='standard'
                    />                   
                </Grid>

            </Grid>


            </DialogContent>
            <DialogActions>
            <Button onClick={() => setShowAddDialog(false)}>Cancel</Button>
            <Button type="submit" variant="outlined" color="primary">Add</Button>
            </DialogActions>
            </form>
        </Dialog>
        <Dialog open={!!editNote} onClose={() => setEditNote(false)}>
            <DialogTitle>Edit Note</DialogTitle>
            <form onSubmit={handleEditNote}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                value={editNote.title}
                                variant='standard'
                                onChange={(e) => setEditNote({ ...editNote, title: e.target.value })}
                                fullWidth
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Note"
                            value={editNote.topic}
                            variant='standard'
                            multiline
                            onChange={(e) => setEditNote({ ...editNote, topic: e.target.value })}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditNote(false)}>Cancel</Button>
                    <Button type="submit" variant="outlined" color="primary">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
        
        {
            !!deleteNote && <ConfirmDialog title="Delete note" description="Are you sure that you want to delete this note?" handleClose={()=>setDeleteNote( false )} handleComplete={handleCompleteDeleteNote}/>
        }
        {
            !!error &&
            <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
        } 
        {
            progress > 0 &&
            <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
        }      
    </div>
  );
};

export default CustomerNoteList;