import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Alert, AlertTitle, Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ProgressDialog } from './progressDialog';
import { post } from '../../libraries/network';
import AlertDialog from './alertDialog';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function PassEditor( props ) {

  const { object, vouchers, onClose, onRefresh } = props;
  const [progress, setProgress] = React.useState(0);
  const [error, setError]       = React.useState(false);

  const [record, setRecord] = React.useState( object );

  const onChange = ( key, value ) => {
    const o = Object.assign({}, record);
    o[key] = value;
    setRecord(o);
  }

  const onSubmit = ( e ) => {
    e.preventDefault();

    async function save(){
        try{
            setProgress(50);
          //  const d = JSON.stringify(JSON.parse(JSON.stringify(record)));

            const d = Object.keys(record).map(function(key) {
                if(Array.isArray(record[key]))
                {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(record[key]));
                }
                else
                {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(record[key]);
                }
            }).join('&');            
            const data = await post( `/passes/ajax-passes-edit`, d);
            if(data.status === 'OK')
            {
                setProgress(100);
                onRefresh();
                setTimeout( () => {
                    setProgress(0);
                    onClose()
                }, 500);
            }
            else
            {
                setProgress(0);
                setError(`Sorry, we have failed to save your pass. The following error(s) have been detected: ${data.reason}`);

            }
        }
        catch(e)
        {
            if(!!window?.static_override)
            {
                setProgress(100);
                setTimeout( () => {
                    setProgress(0);
                    onRefresh();
                    onClose()
                }, 2000);
            }
            else
            {
                setProgress(0);
                setError("Sorry, we have detected a network error. We are going to re-direct you to log back in.");
                console.error(e);
                setTimeout(()=>{
                    const {
                        origin
                      } = window.location;
                    window.location.replace(`${origin}/client/logout?redirect=${encodeURI(window.location.href)}`);
                }, 100);
            }

        }

    }
    save();


}


  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Pass Editor
            </Typography>
            <Button autoFocus color="inherit" onClick={onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <form onSubmit={onSubmit} className="fullscreen-panel">

            <Box sx={{p: 2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6" component="div" sx={{pt: 1, pb:1}}>
                            Step 1: Basic details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField name="name" variant='standard' value={record?.name} onChange={(e) => onChange('name', e.target.value)} id="pass-name" label="Name"  fullWidth={true} sx={{maxWidth: '1200px'}} autoFocus={true} helperText="Enter the name of the pass. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'"   InputProps={{inputProps:{required:true, pattern:'^[A-Za-z0-9-._(),$€£:;\',=+%\\- ]*$'}}} />
                    </Grid>  
                    <Grid item xs={12} md={12}>
                        <TextField name="description" variant='standard' multiline minRows={2} maxRows={4} value={record?.description} fullWidth={true} sx={{maxWidth: '1200px'}}  onChange={(e) => onChange('description', e.target.value)} label="Description"   helperText="Optional: Describe this pass. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" InputProps={{inputProps:{required:false, pattern:"'^[A-Za-z0-9-._(),$€£:;\',=+%\\- \\n]*$'"}}} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6" component="div" sx={{pt: 1, pb:1}}>
                            Step 2: Pass configuration
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField name="credits" variant='standard' error={record?.credits < 1} value={Number(record?.credits).toFixed(0)} step={1} fullWidth={true}  onChange={(e) => onChange('credits', e.target.value)} id="pass-credits" label="# of pass credits to be allocated"  helperText="Enter the number of pass credit that will be allocated to the client upon purchase. Allowed characters 0-9" type="number" InputProps={{inputProps:{required:true, pattern:"[1-9]{1}[0-9]+", min: 0}}} /> 
                    </Grid>  
                    <Grid item xs={12} md={12}>
                        <TextField name="expires" variant='standard'  value={Number(record?.expires).toFixed(0)} step={1} fullWidth={true}  onChange={(e) => onChange('expires', e.target.value)} id="pass-expires" label="# of days or months the pass will be valid for after purchase"  helperText="Enter the number of days or months which the pass will be valid for after purchase. Allowed characters 0-9. Remember to select days or months below." type="number"   InputProps={{ inputProps: { min: 1, required:true, pattern:"[1-9]{1}[0-9]+"} }}  />
                    </Grid> 

                    <Grid item xs={12}>
                        <FormControl component="fieldset" sx={{pl: 0}} required>
                            <FormLabel id="subcription-type-label">Pass expiration period</FormLabel>
                            <RadioGroup aria-labelledby="subcription-type-label" name="subcription-type" value={record?.period} onChange={(e) => onChange('period', e.target.value)}>
                                <FormControlLabel key="pass" value='0' control={<Radio />} label="Days" />
                                <FormControlLabel key="eventgroup" value='1' control={<Radio />} label="Months" />
                            </RadioGroup>
                        </FormControl>                                     
                        <FormHelperText id="subcription-type-helper-text">Select the pass expiration period type</FormHelperText>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl component="fieldset" sx={{pl: 0}} required>
                            <FormLabel id="subcription-starts-label">Pass starts</FormLabel>
                            <RadioGroup aria-labelledby="subcription-starts-label" name="subcription-type" value={record?.expiration_starts} onChange={(e) => onChange('expiration_starts', e.target.value)}>
                                <FormControlLabel key="onpurchase" value='0' control={<Radio />} label="Standard - time of pass purchase" />
                                <FormControlLabel key="onfirstdatepurchase" value='1' control={<Radio />} label="Advanced - time of first event" />
                            </RadioGroup>
                        </FormControl>                                     
                        <FormHelperText id="subcription-type-helper-text">By default, a pass expiration is calculated from the date of purchase. Alternatively, a pass can start from the date of the first event purchased.</FormHelperText>
                        <Box sx={{pt:2}}>
                            <Alert severity="warning">
                                <AlertTitle>
                                    Note - <b>Advanced</b> passes will revert to <b>Standard</b> in the following situations:

                                </AlertTitle>
                                <ol>
                                    <li>The pass is purchased via a subscription</li>
                                    <li>The pass is <b>not</b> purchased via version 4+ schedule.</li>
                                    <li>The first booking using the pass is a media file.</li>
                                </ol> 
                            </Alert>
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6" component="div" sx={{pt: 1, pb:1}}>
                            Step 3: Pass price and discounts 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            name="price" 
                            variant='standard'  
                            value={record?.price} 
                            step={0.01} 
                            fullWidth={true}  
                            onChange={(e) => onChange('price', e.target.value)} 
                            id="pass-price" 
                            label="Price of pass" 
                            helperText="Enter price of the pass. Allowed characters 0-9." 
                            type="number"   
                            inputProps = {{ min: 0, required:true, pattern:"[1-9]+[.]{0,1}[0-9]+",  step: "any"}}  />
                    </Grid> 


                    <Grid item xs={12} md={12}>

                    <Autocomplete
                        disablePortal
                        id="voucher-box"
                        options={vouchers}
                        getOptionLabel={option => option.name}
                        multiple
                        value={record?.vouchers}
                        onChange={(e, v) => onChange('vouchers', v )}
                        renderInput={params => <TextField {...params} variant='standard' label="Select discount vouchers"  />}
                        />


                    </Grid> 


                    <Grid item xs={12} >
                        <Typography variant="h6">Step 4: Limits</Typography>
                    </Grid>
                    

                    <Grid item xs={12} md={12}>
                        
                        <FormControlLabel
                            control={<Switch checked={record?.issubscription} onChange={(e)=> onChange( 'issubscription', e.target.checked)} name="subscriptionlimited" />}
                            label="Can only be purchased as part of subscription"
                        />                                    
                    </Grid>  

                    <Grid item xs={12} md={12}>
                        
                        <FormControlLabel
                            control={<Switch checked={record?.customerqtylimited} onChange={(e) => onChange('customerqtylimited', e.target.checked)} name="customer-limited" />}
                            label="Restrict the number of passes of this type a client can purchase"
                        />                                    
                    </Grid>  
                    <Grid item xs={12} md={12}>
                        {
                            !record?.customerqtylimited && (<TextField name="customer-qty-limit" variant='standard' disabled value={0} step={1} onChange={(e) => onChange('customerqtylimit', e.target.value)}  label="# passes of this type that can be purchased by a client" helperText="The number of passes of this type that a client is allowed to purchase. Allowed characters 1-9" type="number"  InputProps={{ inputProps: { min: 0, required:true, pattern:"[1-9]{1}[0-9]+"} }} />)
                        }
                        {
                            !!record?.customerqtylimited && (<TextField name="customer-qty-limit" variant='standard' value={record?.customerqtylimit} step={1} onChange={(e) => onChange('customerqtylimit', e.target.value)}  label="# passes of this type that can be purchased by a client"  helperText="The number of passes of this type that a client is allowed to purchase. Allowed characters 1-9" type="number"  InputProps={{ inputProps: { min: 0, required:true, pattern:"[1-9]{1}[0-9]+"} }} />)
                        }                                    

                    </Grid> 

                    <Grid item xs={12} md={12}>
                        
                        <FormControlLabel
                            control={<Switch checked={record?.accountqtylimited}  onChange={(e) => onChange('accountqtylimited', e.target.checked)} name="account-qty-limited" />}
                            label="Active Pass limit active"
                        />                                    
                        
                    </Grid>  
                    <Grid item xs={12} md={12}>
                        {
                            !record?.accountqtylimited  && (<TextField name="account-qty-limit" variant='standard' disabled value={Number(record?.accountqtylimit).toFixed(0)} step={1} onChange={(e) => onChange('accountqtylimit', e.target.value) } id="account-qty-limit" label="# passes of this type that can be active at any one time"  helperText="Enter the maximum number of passes of this tyoe that can be active. Allowed characters 0-9"  type="number" InputProps={{ inputProps: { min: 0, required:true, pattern:"[1-9]{1}[0-9]+"} }}/>)
                        }
                        {
                            !!record?.accountqtylimited  && (<TextField name="account-qty-limit" variant='standard' value={Number(record?.accountqtylimit).toFixed(0)} step={1} onChange={(e) => onChange('accountqtylimit', e.target.value) }  id="account-qty-limit" label="# passes of this type that can be active at any one time"  helperText="Enter the maximum number of passes of this tyoe that can be active. Allowed characters 0-9" type="number"  InputProps={{ inputProps: { required:true, pattern:"[1-9]{1}[0-9]+", min: 0} }} />)
                        }                                    

                    </Grid> 
                    
                    <Grid item xs={12} md={12}>
                        &nbsp;
                    </Grid>  
                    <Grid item xs={12} md={12}>
                        <Button type="submit" variant="contained" className="submit" color="primary" fullWidth >Save</Button>
                    </Grid> 
                    <Grid item xs={12} md={12}>
                        &nbsp;
                    </Grid>                                  
                </Grid>  
            </Box>


            </form>   


      </Dialog>
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your pass is being saved." text={''} progress={progress} />

      }

      {
        error.length > 0 &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError('')}} />             
      }

    </div>
  );
}