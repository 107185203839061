import * as React from 'react';
import { IconButton } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
export class PlayButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Edit button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        return (
            <IconButton name="delete" className="iconPlay" aria-label="Play" disabled={this.props?.disabled || false} onClick={this._handleClick}>
                <PlayCircleOutlineIcon className="iconWidget"/>
            </IconButton>            
        )
    }
}
