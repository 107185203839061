import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, CardContent, Grid } from '@mui/material';
import ResponsiveAppBar from '../elements/appBar';
import RichTextEditor from '../elements/RichTextEditor';
import { post } from '../../libraries/network';

const NotificationEdit = ( props ) => {

  const {enabledButtons} = props;
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');


  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };



  const handleSave = async() => {
    // Perform AJAX request to the server here
    // Replace the code below with your own implementation

    const data = {
      name: encodeURIComponent(name),
      subject: encodeURIComponent(subject),
      body: encodeURIComponent(body),
    };

    await post('/communicatorrestapi/ajax-notification', JSON.stringify(data) );

    // Simulating AJAX request with a console log
    console.log('Sending data to the server:', data);
  };

  return (
    <div className="rvNS">
        <Box>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} md={12}>
                    <ResponsiveAppBar 
                    widgets={[]}
                    />     
                </Grid>
            </Grid>           
        </Box>
        <Box sx={{p:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card variant="outlined">
                        <CardContent>
                        <Box sx={{ minidth: 400, margin: 'auto', padding: 2 }}>
                            <Typography variant="h5" gutterBottom>
                            Create a New Message
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    label="Name"
                                    value={name}
                                    onChange={handleNameChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    label="Subject"
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <RichTextEditor enabledButtons={enabledButtons}
                                html={''}
                                onHTMLChange={(e) => {console.log(e.currentTarget.textContent); setBody(e.currentTarget.textContent) }}
                                />
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    </div>
  );
};

export default NotificationEdit;
