import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardContent, Divider, Switch, Typography, Box, Grid, Link, TextField, Stack } from '@mui/material';
import QRCode from 'react-qr-code';
import { del, get, post } from '../../libraries/network';
import AlertDialog from '../elements/alertDialog';
import { ProgressDialog } from '../elements/progressDialog';
import ResponsiveAppBar from '../elements/appBar';

export function SettingsMFA( ) {
    const [secretKey, setSecretKey] = useState('');
    const [mfaEnabled, setMfaEnabled] = useState(false);
    const [mfaInitialEnabled, setMfaInitialEnabled] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState('');
    const [codes, setCodes] = useState(new Array(6).fill(''));
    const [locked, setLocked] = useState(true);
    const [cancelConfirm, setCancelConfirm] = useState(false);


    const [keyCode, setKeyCode] = React.useState(false);

    useEffect(() => {
        const fetchMFAConfiguration = async () => {
            try {
                const response = await get('/clientrestapi/authenticator-app-mfa');

                if (response.status === 'OK') {
                    if (response?.data?.auth_app_enabled) {
                        setMfaEnabled(true);
                        setMfaInitialEnabled(true);
                        setCodes(new Array(6).fill(''));
                        setLocked(false);
                    }
                    else
                    {
                        setSecretKey(response?.data?.key);
                        setLocked(false);
                    }
                } else {
                    setError('Failed to fetch MFA configuration');
                }
            } catch (e) {
                if (window?.static_override) {
                    setMfaEnabled(false);
                    setMfaInitialEnabled(false);
                    setSecretKey('DS456FPTJ3O3VPMGXYUOCFTFMKNLVXHL');
                    setCodes(new Array(6).fill(''));
                    setLocked(false);

                } else {
                    setError('Failed to fetch MFA configuration');
                }
            }
        };

        fetchMFAConfiguration();
    }, []);

    useEffect(() => {
        setShowQRCode(mfaEnabled && !mfaInitialEnabled && secretKey !== '');
    }, [mfaEnabled, secretKey]);



    const handleCodePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text').replace(/\D/g, '').split('');
        const newCodes = [...codes];
        pastedData.forEach((digit, index) => {
            if (index < 6) {
                newCodes[index] = digit;
                if (index < 5) {
                    inputRefs[index + 1].current.focus();
                }
            }
        });
        setCodes(newCodes);
    };



    const handleCodeChange = (index, event) => {
        const { value } = event.target;

        // Only allow numbers and backspace
        if (!isNaN(value) || keyCode === 8) {
            const newCodes = [...codes];
            newCodes[index] = value;
            setCodes(newCodes);

            // Handle backspace
            if (keyCode === 8 && index > 0) {
                // Move focus to the previous input field
                inputRefs[index - 1].current.focus();
            } else if (value !== '' && index < 5) {
                // Move focus to the next input field if a digit is entered and it's not the last one
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        setKeyCode(e.keyCode);
    };

    const onSave = async(e) => {
        e.preventDefault();
        try{
            setProgress(10);
            const v = `codes=${codes?.join('')}&key=${secretKey}`;
            const d = await post('/clientrestapi/authenticator-app-mfa', v); 
            setProgress(100);

            setTimeout(() => {
                setProgress(0);
                setMfaInitialEnabled(true);
                setShowQRCode(false);
            }, 3000);
        }
        catch(e)
        {
            if (window?.static_override) {
                setTimeout(() => {
                    setProgress(0);
                    setMfaInitialEnabled(true);
                    setShowQRCode(false);
                }, 3000);
            } else {
                setProgress(0);
                setError('Failed to activate MFA. Please check your code with your phone. It might have expired.');
            }            
        }
    }

    const onMfaChange = ( e ) => {
        if(mfaEnabled && mfaInitialEnabled)
        {
            // need to alert the customer
            setCancelConfirm(true);
        }
        else
        {
            setMfaEnabled(!mfaEnabled);
        }
    } 

    const onCancelConfirm = async( ) =>{

        try{
            setProgress(10);
            const d = await del('/clientrestapi/authenticator-app-mfa'); 
            setProgress(100);

            setTimeout(() => {
                setMfaEnabled(!mfaEnabled);
                setProgress(0);
                setCancelConfirm(false);
                
            }, 3000);
        }
        catch(e)
        {
            if (window?.static_override) {
                setTimeout(() => {
                    setMfaEnabled(!mfaEnabled);
                    setProgress(0);
                    setCancelConfirm(false);
                }, 3000);
            } else {
                setError('Failed to deactivate MFA. Please try again');
                setProgress(0);
            }            
        }
    }

    const inputRefs = Array.from({ length: 6 }, () => React.createRef());


    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar/>                
            </Box>
            <Box sx={{ p: 2 }}>
                <form onSubmit={onSave}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div" sx={{ pt: 1, pb: 1 }}>
                                        Two factor authentication (2FA) <span className='small'></span>
                                    </Typography>
                                    <Divider />
                                    <Typography gutterBottom variant="body2" component="div" sx={{ pt: 2, pb: 1 }}>
                                        The 2FA (Two-Factor Authentication) adds an extra layer of security to your account by requiring additional credentials before granting access. These additional credentials are provided as a time-sensitive, unique code known as a One-Time Password (OTP). This OTP is accessed via your mobile device through an authenticator app. Once 2FA has been activated, the service will require you to complete this additional layer of security before granting access.                                    </Typography>
                                    <Box mt={2} display="flex" alignItems="center" justifyContent="flex-start">
                                        <Typography variant="body2" gutterBottom>
                                            Two Factor Authentication (2FA)
                                        </Typography>
                                            <Switch
                                                disabled={locked}
                                                checked={mfaEnabled}
                                                onChange={() => onMfaChange(!mfaEnabled)}
                                                inputProps={{ 'aria-label': 'MFA Toggle Switch' }}
                                            />
                                            {
                                                mfaEnabled && mfaInitialEnabled && 
                                                <Typography variant="body2" gutterBottom>
                                                        <b>activated</b>
                                                </Typography>
                                            }
                                                                                        {
                                                !mfaInitialEnabled &&                                                 
                                                <Typography variant="body2" gutterBottom>
                                                        <b>deactivated</b>
                                                </Typography>
                                            }
                                    </Box>
                          
                                    <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                                        {showQRCode && (
                                            <div>
                                                <Typography variant="h6" component="div" gutterBottom>MFA setup</Typography>
                                                <Typography variant="body2" component="div">2FA is currently disabled for your account. To start, complete the following steps:</Typography>
                                                
                                                <Typography variant="h7" component="div" sx={{mt:2}}><b>Step 1: Install an authenticator app on your smart phone</b></Typography>
                                                <Typography mt={1} variant="body2" component="div">There are many authenticator apps available. I would recommend downloading the one that is provided by your mobile phone OS provider. </Typography>
                                                <ul>
                                                    <li><Link target="_blank" rel="noopener" href="https://play.google.com/store/search?q=google+authenticator&c=apps&hl=en_GB&gl=US">Google Authenticator for Android phones</Link></li>
                                                    <li><Link target="_blank" rel="noopener" href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google Authenticator for iPhone</Link></li>
                                                </ul>
                                                <Typography variant="h7" component="div" sx={{mt:2}}><b>Step 2: Open the authenticator app on your phone and add a new 'code'</b></Typography>
                                                <Typography mt={1} variant="body2" component="div">This functionality is application dependent. For the Google Authenticator Application, a '+' button is provided. When prompted, click 'Scan a QR Code' (or similar) and then scan the following QR Code below:</Typography>

                                                {
                                                    secretKey &&
                                                    <Box mt={2}>
                                                        <QRCode value={`otpauth://totp/go.reservie.net?secret=${secretKey}&issuer=reservie.net`} />
                                                    </Box>    
                                                }
                                                <Typography variant="h7" component="div" sx={{mt:2}}><b>Step 3: Enter the code generated by your phone</b></Typography>
                                                <Typography mt={1} variant="body2" component="div">Your phone's authenticator app will now generate a 6 digit authorisation code. This code is valid for up to 60 seconds. If you take longer, your phone will regenerate the authentication code. Enter the digits into the boxes below:</Typography>

                                                
                                                <Box mt={2} display="flex" justifyContent="flex-start">
                                                    {codes.map((code, index) => (
                                                        <TextField
                                                            key={index}
                                                            id={`code${index}`}
                                                            variant="outlined"
                                                            size="small"
                                                            type="text"
                                                            inputProps={{ maxLength: 1 }}
                                                            autoFocus={index === 0}
                                                            value={code}
                                                            onChange={(e) => handleCodeChange(index, e)}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                            onPaste={handleCodePaste}
                                                            inputRef={inputRefs[index]} // Set the ref for each input field
                                                            sx={{ mr: 1, width: 40 }} // Adjust width as needed
                                                        />
                                                    ))}
                                                </Box>
                                                <Typography variant="h7" component="div" sx={{mt:2}}><b>Step 4: Activate the authentication</b></Typography>
                                                <Typography mt={1} mb={2} variant="body2" component="div">Upon providing the digits, we will check this matches your phone. If successful, we will activate MFA for your account.</Typography>

                                                <Button variant="contained" disabled={!(codes?.length === 6 && codes?.join(''))} color="primary" type="submit">
                                                    Activate MFA
                                                </Button>
                                            </div>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {error && <AlertDialog title="Error" description={error} handleClose={() => setError(null)} />}
            {progress > 0 && <ProgressDialog progress={progress} title="Please wait" text={progressText} />}
            {cancelConfirm && <AlertDialog title="Confirm" description={"Are you sure that you want to disable MFA. Not recommended."} handleClose={() => onCancelConfirm(false)} />}
        </div>
    );
}
