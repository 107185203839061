import { Alert, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import NotInterestedIcon from '@mui/icons-material/NotInterested';import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinearProgress from '@mui/material/LinearProgress';
import AlertDialog from '../elements/alertDialog';
import { BottomBar } from '../elements/BottomBar';


function ProgressDialog( props ) {

    const {progress, text} = props;

    return (
        <div>

        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
            {"Processing"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Your report is being processed. Please standby...
            </DialogContentText>
                <Stack  spacing={2}>
                    <Box sx={{ width: '100%', pt: 4}}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ width: '100%', pt: 1, pb: 4, textAlign: 'center' }}>
                        {text}
                    </Box>

                </Stack>

            </DialogContent>
        </Dialog>
        </div>
    );
}

function CategorySelectDialog( props ) {

    const {data, onClose} = props;
    const [records, setRecords] = React.useState( data || [] )

    const onChange = ( d, e) => {
        let r = records.map( rec => {
            if(rec.id === d.id)
            {
                rec.selected = e.target.checked;
            }
            return rec;
         } );
        setRecords( r );
    }

    const handleClose = () => {
        onClose(  records );
    }

    return (
        <div>

        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
                <DialogTitle id="alert-dialog-title">
                {"Category selection"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                The following categories have been detected. Which categories ar you interested in?
                <Box sx={{ width: '100%', pt: 1}}>
                <Alert severity='warning'>Please be aware that large category sets can result in longer processing time.</Alert>
                </Box>
                </DialogContentText>
                    <Stack  spacing={2}>
                        <Box sx={{ width: '100%', pt: 1, pl:2}}>
                            
                            <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        {
                                            records && records.map( d => (
                                                <FormControlLabel key={`record-${d.id}`}
                                                    control={
                                                    <Checkbox checked={d.selected} onChange={(e) => onChange( d, e )} name={d.name}/>
                                                    }
                                                    label={d.name}
                                                />
                                            ))
                                        }

                                    </FormGroup>
                            </FormControl>                       
                        </Box>

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Continue
                    </Button>
                </DialogActions>

        </Dialog>
        </div>
    );
}

export function ReportSalesByCategory()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [progressText, setProgressText] = React.useState('');
    const [stage, setStage] = React.useState(0);
    const [error, setError] = React.useState('');
    const [categories, setCategories] = React.useState('');
    const [tmpcategories, setTmpCategories] = React.useState('');

 
    const onDownloadClick  = () =>
    {
        var rows = [
            ["Categories", "Booked", "Percentage"],
        ].concat(data.map( r => `"${r.categories.join(', ')}", ${r.data}, ${r.percentage}%`));

        let csvContent = "data:text/csv;charset=utf-8," + rows.join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event-report-" + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }


    React.useEffect(() => {

        async function load( ) 
        {
                let out = [];
                const binary_tree = categories;
                const max      = Math.pow(2, binary_tree.length);
                //console.log("Array is", binary_tree, max);

                for(var i = 1; i < max; i++)
                {
                    var chunka = [];
                    var chunkb = [];

                    for(let j = 0; j < binary_tree.length; j++)
                    {
                        console.log("checking", binary_tree[j].bin, i, binary_tree[j].bin & i )
                        if((binary_tree[j].bin & i) )
                        {
                            chunka.push(binary_tree[j]);
                        }
                        else
                        {
                            chunkb.push(binary_tree[j]);
                        }

                    }
                    setProgress((i/max)*100);
                    
                    let skip = chunka.find( c => c.selected === false);
                    if(!!skip)
                    {
                        continue;
                    }
                    chunkb = chunkb.filter( f => f.selected === true);
                    console.log('include is:', chunka, 'exclude is:', chunkb);

                    const include = JSON.stringify(chunka.map( c => c.id));
                    const include_labels = chunka.map( c => c.name);
                    const exclude = JSON.stringify(chunkb.map(c => c.id));
                    setProgressText(`Fetching data for categories: ${include_labels.join(', ')}`)
                    console.log(`Fetching data for categories: ${include_labels.join(', ')}`)
                    console.log('progress', i, max)
                    try{
        


                        const d = Object.keys(range).map(function(key) {
                                    return encodeURIComponent(key) + '=' + encodeURIComponent(range[key].getTime());
                                }).join('&');


                        const data = await get( `/backenddata/ajax-get-events-booked-by-category?${d}&include=${include}&exclude=${exclude}`);
                        out.push( { categories: include_labels, data: data, percentage: 0} );
                        //throttle fetches
                        await new Promise(resolve => setTimeout(resolve, 2000));                                
                        
                    }
                    catch(e)
                    {
                        if(window?.static_override)
                        {
                            console.log( window?.static_reports );
                            out.push( { categories: include_labels, data: Math.floor(Math.random() * 100), percentage: 0} );
                            await new Promise(resolve => setTimeout(resolve, 5000));                                
                        } 
                        else
                        {
                            setProgressText(``);
                            setStage(0);  
                            setBusy(false);
                            console.error("Failed to get categories", e);
                            setError(`Failed to get data for category set: ${include_labels.join(', ')}`);
                            return;
                        }                   
                    }                            



                }


                let total = out.reduce( (v, c) => {
                    return v + c.data;
                }, 0);
                
                setProgressText(``);
                setStage(0);  

                const final = out.map(  a => {
                    let x = Object.assign({}, a);
                    if(total > 0)
                    {
                        x.percentage = (Math.round((x.data/total*100)*100)/100).toFixed(2);
                    }
                    else
                    {
                        x.percentage = 0;
                    }
                    return x;
                });

                console.log("Setting final data set", final);
                setBusy(false);
                setData(final);  
        }

        if(categories.length > 0)
        {
            load( stage );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);



    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);
        setProgress(0);

        async function load(  ) 
        {                
            /* Step 1 - get the list of categories */
            try{
                setProgressText('Fetching category list')
                setStage( stage + 1 )
                const c = await get( `/backenddata/ajax-get-categories-list`);
                const binary_tree = c.map( (c, i) => ({id: c.id, name: c.name, bin:   Math.pow(2, i), selected: true }))
                
                if(binary_tree.length > 0)
                {
                    setTmpCategories(binary_tree);
                    setBusy(false);

                }
                else
                {
                    setError('No categories found');
                    setStage( 0 );
                    setProgress(0);
                    setProgressText('');
                    setBusy(false);
                }
            }
            catch(e)
            {
                if(window?.static_override)
                {
                    console.log( window?.static_reports );
                    setTimeout(async () => {
                        setBusy(false);
                        //setData( window?.static_reports.data );
                        const c = window?.static_reports.categories;
                        const binary_tree = c.map( (c, i) => ({id: c.id, name: c.name, bin:   Math.pow(2, i), selected: true }))
                        setTmpCategories(binary_tree);
                        setStage( stage + 1 )
                    }, 2000)
                }
                else
                {
                    setProgressText(``);
                    setStage(0);  
                    setBusy(false);
                    console.error("Failed to get categories", e);
                    setError("Failed to get list of categories.");
    
                }                    
            }
        }
        load( );
    }


    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar  title={"Sales by category"}/>                
            </Box>
            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more events</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    { 
                                        !busy && !!data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Categories
                                                                </TableCell>
                                                                <TableCell>
                                                                    Bookings
                                                                </TableCell>
                                                                <TableCell>
                                                                    Percentage
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                data?.map( d => (
                                                                    <TableRow key={`report-${d.categories.join('-')}`}>
                                                                        <TableCell>
                                                                            {d.categories.join(', ')}
                                                                        </TableCell>
                                                
                                                                        <TableCell>
                                                                            {d.data}
                                                                        </TableCell>
                                                
                                                                        <TableCell>
                                                                            {d.percentage}%
                                                                        </TableCell>


                                                                    </TableRow>
                                                                ))   
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Grid>
                                        </Box>
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form> 
            {
                stage > 0 &&
                <ProgressDialog progress={progress} text={progressText}/>               
            }
            {
                error.length > 0 &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError('')}} />             
            }
            {
                tmpcategories.length > 0 && 
                <CategorySelectDialog data={tmpcategories} onClose={( e ) => { setTmpCategories([]); setCategories(e)}}/>

            }
            <BottomBar />
        </div>
    )
}