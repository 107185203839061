import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'; // Import the Material-UI components
import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import { post } from '../../libraries/network';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { MuiTelInput } from 'mui-tel-input'

export  const AddCustomer = ( props ) => {
  const { reload, fullWidth} = props;
  const [open, setOpen] = useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);      

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
  });



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Perform validation here
    if (validateFormData(formData)) {
      // Send data via AJAX to the server
      // Replace the URL with your server endpoint
          let x = {};
          for (const key in formData) {
            x[key] = encodeURIComponent(formData[key]);
          }

          try{
              setProgress(1);
              
              await post( `/customersrestapi/account`, `data=${JSON.stringify(x)}`);
              setProgress(50);
              await reload();
              setProgress(100);
              setTimeout(()=> {
                setProgress(0);
                handleClose();
              }, 500);
            }
            catch(e)
            {
                setTimeout(()=> {
                  setProgress(0);
                  setError("Failed to create this account. Please check this account does not already exist. Error code: CM-GENERIC");
                  console.error(e);
                }, 1000);
            }      
      
        }
  };

    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
          return navigator.languages[0];
        } else {
          return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
    }


  const getCountry = () => {
    const country = getNavigatorLanguage().split('-');
    if(country.length > 1)
    {
        return country[1];
    }
    return 'GB';
}

  const isStrongPassword = (  ) => {
    const password = formData.password;
     // Check if the password is at least 8 characters long
     if (!!!password?.length) {
      return true;
    }

     if (password.length < 8) {
      return false;
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one digit
    if (!/\d/.test(password)) {
      return false;
    }

    // Check if the password contains at least one special character
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      return false;
    }

    // If all checks pass, the password is strong
    return true;

  }
  const isMobileValid = () => {
    
    if(formData.mobileNumber.toString().trim() !== '')
    {
        const parsedPhoneNumber = parsePhoneNumberFromString(formData.mobileNumber.toString().trim());

        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          return true;
        } else {
          return false;
        }      
    }
    return true;
  }

  const isValid = () => {
    // Perform validation on each field
    // For simplicity, we'll check if each field is not empty
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.mobileNumber.toString().trim() !== '' &&
      isMobileValid() && isStrongPassword()
    );

    
  };

  const validateFormData = (data) => {
    // Perform validation on each field
    // For simplicity, we'll check if each field is not empty
    return (
      data.firstName.trim() !== '' &&
      data.lastName.trim() !== '' &&
      data.email.trim() !== '' &&
      data.mobileNumber.toString().trim() !== '' &&
      isMobileValid() && isStrongPassword()

    );
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen} fullWidth={fullWidth?fullWidth:false}>
        Add Customer
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form  onSubmit={handleSubmit}>
        <DialogTitle>Customer Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                name="firstName"
                label="First Name"
                variant="standard"
                required
                autoComplete="off"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="lastName"
                label="Last Name"
                variant="standard"
                required
                autoComplete="off"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email Address"
                variant="standard"
                type="email"
                required
                autoComplete="off"
                value={formData.email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MuiTelInput 
                  value={formData.mobileNumber?.replace(/^0/,'')} 
                  required
                  autoComplete="off"
                  variant='standard'
                  label="Mobile Number"
                  helperText={"Enter mobile number"}
                  error={!isMobileValid()}
                  onChange={(e) =>{
                      let regex =/^\+[0-9]{0,3}\s[1-9]$|^\+[0-9]{0,3}\s[1-9][0-9\s]{1,11}$|^\+[0-9]{0,3}\s$|^\+[0-9]{0,3}$/;
                      let v = e.match(regex);
                      if(v)
                      {
                        // synthesis an event
                        const x = {target:{
                          name: 'mobileNumber',
                          value: v?.toString() || ''
                        }}
                        handleChange(x)
                      }
                  } }
                  defaultCountry={getCountry()}
                  fullWidth />



            </Grid>    
            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password (optional)"
                variant="standard"
                autoComplete="off"
                type="password"
                placeholder='Optional password'
                helperText={isStrongPassword()?'Strong password': 'Password should consist of at least one upper case, one lower case, one numeric digit, one special character and be at least 8 characters long.'}
                value={formData.password}
                error={!isStrongPassword()}
                onChange={handleChange}
                fullWidth
              />
            </Grid>                                 
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={!isValid()}>Save</Button>
        </DialogActions>
        </form>

      </Dialog>
      {
            !!error &&
            <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
            progress > 0 &&
            <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }          
    </div>
  );
};

