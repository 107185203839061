import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomersTableBookings from './CustomersTableBookings';
import CustomersTablePasses from './CustomersTablePasses';
import CustomersTableMedia from './CustomersTableMedia';
import { CustomersSubscriptionsPanel } from './CustomersSubscriptionPanel';
import CustomersTableQustionnaires from './CustomersTableQuestionnaires';
import CustomerNoteList from './CustomerNoteList';
import CustomerManage from './CustomerManage';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`customers-tabpanel-${index}`}
      aria-labelledby={`customers-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `customers-tab-${index}`,
    'aria-controls': `customers-tabpanel-${index}`,
  };
}

export function CustomerPanel( props ) {
  const { 
          permissions,
          bookings, 
          events, 
          passes, 
          companypasses, 
          media, 
          companymedia,
          subscriptions,
          plans,
          questionnaires,
          allquestionnaires,
          reloadPasses, 
          reloadBookings, 
          reloadVideos,
          reloadSubscriptions,
          reloadQuestionnaires,
          notes,
          reloadNotes,
          action
        } = props;
  const [value, setValue] = React.useState(0);
  const [initiate, setInitiate] = React.useState(-1);

  React.useEffect(() => {
    if(action==='event-add')
    {
      setValue(0);
      setInitiate(0);
    }
    else if(action==='pass-add')
    {
      setValue(1);
      setInitiate(1);
    }
    else if(action==='video-add')
    {
      setValue(2);
      setInitiate(2);
    }
    else if(action==='questionnaire-add')
    {
      setValue(4);
      setInitiate(4);
    }
    else if(action==='note-add')
    {
      setValue(5);
      setInitiate(5);
    }
  }, [action])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("Rendering panel with", bookings)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="Customers"
          variant="scrollable"
          scrollButtons="auto"
        
        >
          <Tab label="Bookings" {...a11yProps(0)} />
          <Tab label="Passes" {...a11yProps(1)} />
          <Tab label="Videos" {...a11yProps(2)} />
          <Tab label="Subscriptions" {...a11yProps(3)} />
          <Tab label="Questionnaires" {...a11yProps(4)} />
          <Tab label="Notes" {...a11yProps(5)} />
          <Tab label="Account" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          <CustomersTableBookings bookings={bookings} events={events} initiate={initiate} passes={passes} reload={reloadBookings}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomersTablePasses passes={passes} allpasses={companypasses} initiate={initiate} reload={reloadPasses} events={events} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomersTableMedia media={media} allmedia={companymedia} initiate={initiate} passes={passes} reload={reloadVideos} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CustomersSubscriptionsPanel plans={plans} subscriptions={subscriptions} initiate={initiate} reload={reloadSubscriptions}/>
      </TabPanel>    
      <TabPanel value={value} index={4}>
          <CustomersTableQustionnaires questionnaires={questionnaires}  initiate={initiate} allquestionnaires={allquestionnaires} reload={reloadQuestionnaires} events={events} />
      </TabPanel>     
      <TabPanel value={value} index={5}>
          <CustomerNoteList reload={reloadNotes}  initiate={initiate} customernotes={notes} />        
      </TabPanel>  

      <TabPanel value={value} index={6}>
          <CustomerManage  permissions={permissions}/>        
      </TabPanel>  

       
    </Box>
  );
}