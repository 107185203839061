import * as React from 'react';
import Button from '@mui/material/Button';
import { ProgressDialog } from './progressDialog';
import AlertDialog from './alertDialog';

import QuestionnaireAddDrawer from './subelements/CustomersQuestionnaires/Questionnaire-add-drawer';




export function AddQuestionnaireButton( props ) {
  const {access, questionnaires, reload, initiate} = props;
  const [state, setState] = React.useState(false);
  const inputElement = React.useRef();

  const handleClick = (event) => {
    setState(true)
  };

  React.useEffect(() => {
    if(initiate === 4)
      inputElement?.current?.click();
  }, [initiate]);


  return (
    <div>
      <Button
        id="book-button"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        ref={inputElement}

      >
        Complete Questionnaire
      </Button>


      {
        !!state &&
        <QuestionnaireAddDrawer questionnaires={questionnaires} close={() => {setState(0)}} access={access} reload={reload}/>
      }


    </div>
  );
}