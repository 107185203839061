import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomersTableActive from './CustomersTableActive';
import CustomersTableArchive from './CustomersTableArchive';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`customers-tabpanel-${index}`}
      aria-labelledby={`customers-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `customers-tab-${index}`,
    'aria-controls': `customers-tabpanel-${index}`,
  };
}

export function CustomersPanel( props ) {
  const {customers, reload} = props;
  const [value, setValue] = React.useState(0);
  const active = customers?.filter( c => !c?.archive );
  const archived =  customers?.filter( c => !!c?.archive );


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Customers">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Archived" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          <CustomersTableActive customers={active} reload={reload} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomersTableArchive customers={archived} reload={reload} />
      </TabPanel>

    </Box>
  );
}