import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card, CardContent, CardHeader, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { get } from '../../libraries/network';
import AlertDialog from './alertDialog';
import getSymbolFromCurrency from 'currency-symbol-map'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LibProperties(props){

    const {title, subtitle, value} = props;
    return (
      <Card variant="outlined">
        <CardHeader title={title} subheader={subtitle} className="header" />
          <CardContent>
              <span className="statistics">{value}</span>
          </CardContent>
      </Card>
    )
  }

export function VideoRegister( props ) {

  const { onClose, data } = props;
  const [subscribers, setSubscribers] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [active, setActive] = React.useState(0);
  const [view, setView] = React.useState(0);
  const [total, setTotal] = React.useState(0);


  const getRegister = async() =>{
      try{
        setBusy( true );
        const s = await get(`/video/get-register/${data?.id}`);
        setSubscribers(s?.customers || []);
        setActive(s?.active);
        setTotal(s?.total);
        setView(s?.view);
        setBusy( false );

      }
      catch(e)
      {
        if(!!window?.static_override)
        {
            setTimeout(() => {
                setBusy( false );
                setActive(window?.static_video_register?.active);
                setTotal(window?.static_video_register?.total);
                setView(window?.static_video_register?.view);
                setSubscribers(window?.static_video_register?.customers || [])
            //    setError('Failed to fetch the video subscribers. Please close this panel and try again. If the problem persists, please reload this page.');

            }, 1000)
        }
        else
        {
          console.error(e);
          setSubscribers([]);
          setActive(0);
          setTotal(0);
          setView(0);
          setBusy( false );

          setError('Failed to fetch the video subscribers. Please close this panel and try again. If the problem persists, please reload this page.');
  
        }
      }
  };

  const convertCurrncy = ( c ) => {
     const currency = getSymbolFromCurrency(c);
     console.log(currency);
     if(!currency)
     {
        return c;
     }
     return currency;
  }

  React.useEffect(() => {
    getRegister()
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Video Subscribers
            </Typography>
            <Button autoFocus color="inherit" onClick={onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <Box sx={{p: 2}} className='rvNSFSWindow'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                        Overview
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                 <LibProperties title={"Active subscribers"} subtitle="Number of people actively subscribed to video" value={active}/>
                </Grid>
                <Grid item xs={12} md={4}>
                 <LibProperties title={"Total subscribers"} subtitle="Number of people actively subscribed to video" value={total}/>
                </Grid>            
                <Grid item xs={12} md={4}>
                 <LibProperties title={"Viewed"} subtitle="Number of times watched" value={'N/A'}/>
                </Grid>                 

                <Grid item xs={12} sx={{pt: 2, pb:2}}>
                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                        Subscribers <span className='small'>Displays the people that have subscribed to this video.</span>
                    </Typography>
                </Grid> 
                <Grid item xs={12}> 
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>ID</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell align="center">Purchased</TableCell>
                                    <TableCell align="center">Payment type</TableCell>
                                    <TableCell align="center">Paid</TableCell>
                                    <TableCell align="center">Expires</TableCell>
                                    <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}># times viewed</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                      !busy && !subscribers?.length &&
                                      <TableRow>
                                        <TableCell colSpan={7} sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                        <Box sx={{p: 10}}>
                                            <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no subscribers.</Typography>
                                        </Box>                                                    
                                        </TableCell>
                                        <TableCell colSpan={5} sx={{ display: { xs: 'table-cell', md: 'none', lg: 'none' } }}>
                                        <Box sx={{p: 10}}>
                                            <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no subscribers.</Typography>
                                        </Box>                                                    
                                        </TableCell>                                        
                                      </TableRow>
                                }
                                {
                                    !busy && !!subscribers?.length && subscribers?.map( s => (                                               
                                      <TableRow key={`${s.id}`}>
                                          <TableCell  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>{`${s.id}`}</TableCell>
                                          <TableCell>{`${s.firstname} ${s.lastname}`}</TableCell>
                                          <TableCell align="center">{(new Date(s.purchased)).toLocaleDateString()}</TableCell>
                                          <TableCell align="center">{`${s.payment_type}`}</TableCell>
                                          <TableCell align="center">{`${convertCurrncy(s.currency)} ${s.paid.toFixed(2)}`}</TableCell>
                                          <TableCell align="center">{!s.expires? 'None': (new Date(s.expires)).toLocaleDateString()}</TableCell>
                                          <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>Not available</TableCell>
                                      </TableRow>   
                                    ))
                                  
                                }
                                {
                                  !!busy &&
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                        <Box sx={{p: 2}}>
                                          <Stack>
                                              <Skeleton height={40}/>
                                              <Skeleton animation="wave" height={40} />
                                              <Skeleton animation="wave" height={40} />
                                              <Skeleton animation="wave" height={40} />
                                              <Skeleton animation={false} height={40} />
                                          </Stack>
                                        </Box>                                                    
                                        </TableCell>
                                    </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                                
            </Grid>  
        </Box>

        {
          !!error && 
          <AlertDialog title="Error" description={error} handleClose={(e) => setError(false)} />
        }

      </Dialog>


    </div>
  );
}