import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';
import { CustomerSelector } from '../elements/CustomerSelector';
import getSymbolFromCurrency from 'currency-symbol-map';


export function ReportBookingDetail()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [customers, setCustomers] = React.useState([])
    const [customerlist, setCustomerList] = React.useState([])
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [customer, setCustomer] = React.useState(-1);
    const [filtr, setFilter] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const zeroPad = (num, places) => String(parseInt(num).toString(10)).padStart(places, '0')

    const convertCurrency = ( c ) => {
        const currency = getSymbolFromCurrency(c);
        console.log(currency);
        if(!currency)
        {
           return c;
        }
        return currency;
     }

    const onDownloadClick  = () =>
    {

        var rows = [];

        for(var i = 0; i < data?.length; i++)
        {

            rows.push(["ID", `U${zeroPad(data[i].id, 6)}`]);
            rows.push(['Name', `${data[i].firstname} ${data[i].lastname}`]);
            rows.push(['Email', `${data[i].email}`]);
            rows.push(['Booked before date range', `${data[i].before}`]);
            rows.push(['Booked after date range', `${data[i].after}`]);
            rows.push(['Report data']);
            rows.push(['Transaction ID', 'Transaction Date', 'Event', 'Event ID', 'Date(s) booked', 'Currency', 'Gross', 'Deductions', 'Net']);
            
            for(var j = 0; j < data[i]?.data.length; j++)
            {
                const d = data[i]?.data[j];
                if(!!d)
                    rows.push([`${d?.id}, ${(new Date(d?.date)).toLocaleDateString()}, ${d?.name}, ${d?.eid}, ${d?.ndates}, ${convertCurrency(d?.currency)}, ${d?.gross}, ${d?.discounts}, ${d?.net}`]);
            }
            
      
            rows.push([' ']);
            rows.push([' ']);

        }



        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event report detail " + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    const processData = (d) => {
        var rows = d;
        
        if(!!filtr?.length)
        {
            rows = d?.filter( r => !!filtr.find( f => f === r?.id));
        }
        return rows;
    }

    React.useEffect(() => {
        // load the events for this range
        async function load(){
            try{
  
                setProgress(10);
  
                const r = await get( `/reportsrestapi/customers`);
                setCustomers( r );
                setProgress(100);
                setTimeout(() => {
                    setProgress(0);
                }, 500)

            }
            catch(e)
            {
                if(window?.static_override)
                {
                    setProgress(100);
                    setCustomers( window?.static_customers.customers );
                    setTimeout(() => {
                        setProgress(0);
                    }, 2000)
                }
                else
                {
                    setProgress(0);
                    setError("Failed to get the customer list");

                }
    
            }
        }
        load();
    }, [range]);


    React.useEffect(() =>{
        if((customer >= 0) && (customer < customerlist.length))
        {
            loadCustomer( customer )
        }
        else
        {
            if(customer >= customerlist.length)
            {
                setProgress(100);
                setTimeout(()=>{
                    setProgress(0);
                    setBusy(false);
                }, 500);
            }
        }

    }, [customer]);

    const loadCustomer = async( i ) => {
        try{

            let z = {};

            for (var property in range) {
                if (range.hasOwnProperty(property)) {
                    console.log(range);
                    z[property] = range[property].getTime()
                }
            }
            const percentile = (i/customerlist.length) * 100;
            const d = new URLSearchParams(z).toString();
            const c = customerlist[i];
            const r = await get( `/reportsrestapi/booking-details/${c.id}?${d}`);
            setProgress(percentile);
            const s = JSON.parse(JSON.stringify(data));
            s.push(r)
            setData( processData(s) );
            setTimeout(() => {
                setCustomer(i+1);
            },500);
        }
        catch(e)
        {
            if(window?.static_override)
            {
                console.log( window?.static_reports );
                setTimeout(() => {
                    setBusy(false);
                    const s = JSON.parse(JSON.stringify(data));
                    const r = window?.static_reports.report_booking_detail.customers[customer];
                    s.push(r)
                    setData( processData(s) );
                    setCustomer(i+1)
                }, 2000)
            }
            else
            {
                setError('Failed to load report');
                setProgress(0);

            }   
            
            // to do - present error 

        }
    }

    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);
        setData([]);
        var customerset = customers
        if(filtr.length > 0)
        {
            customerset = customers.filter( c => !!filtr.find( f => f === c.id))
        }
        if(customerset.length > 0)
        {
            setCustomerList( customerset );
            setCustomer(0);
        }
   
    }


    return (
        <div className="rvNS">
            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more customers</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomerSelector setCustomers={setFilter} list={customers} customers={filtr}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    {
                                        !busy && data.length > 0 && data.map(c => (
                                            <Card variant="outlined" sx={{mb: 2}}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>ID:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">U{zeroPad(c.id, 6)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Name:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${c.firstname} ${c.lastname}`}</Typography>
                                                        </Grid>   
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>E-mail:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${c.email}`}</Typography>
                                                        </Grid> 
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Events booked:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${c.email}`}</Typography>
                                                        </Grid> 
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Booked before date range:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${c.before}`}</Typography>
                                                        </Grid>    
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Booked after date range:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${c.after}`}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                Transaction ID
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Transaction date
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Event
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Event ID
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Date(s) Booked
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Gross
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Deductions
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Net
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            c?.data.length === 0 && 
                                                                            <TableRow>
                                                                                <TableCell colSpan={8}>
                                                                                    <Box sx={{p: 10}}>
                                                                                        <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You has no bookings within the specified date range.</Typography>
                                                                                    </Box>  
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        }
                                                                        {
                                                                            c?.data.map( d  => (
                                                                                <TableRow>
                                                                                    <TableCell>{d?.id}</TableCell>
                                                                                    <TableCell>{(new Date(d?.date)).toLocaleDateString()}</TableCell>
                                                                                    <TableCell>{d?.name}</TableCell>
                                                                                    <TableCell>{d?.eid}</TableCell>
                                                                                    <TableCell>{d?.ndates}</TableCell>
                                                                                    <TableCell>{`${convertCurrency(d?.currency)}${parseFloat(d?.gross).toFixed(2)}`}</TableCell>
                                                                                    <TableCell>{`${convertCurrency(d?.currency)}${parseFloat(d?.discounts).toFixed(2)}`}</TableCell>
                                                                                    <TableCell>{`${convertCurrency(d?.currency)}${parseFloat(d?.net).toFixed(2)}`}</TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        }

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>

                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        ))

        
                                    }

    
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                
            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}