import {  Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { checkEpochShift, getCache, setCache } from '../../libraries/cache';
import { get } from '../../libraries/network';
import SettingsIcon from '@mui/icons-material/Settings';
import { CustomerPanel } from '../elements/CustomerPanel';
import QRCode from "react-qr-code";
import { Bar } from 'react-chartjs-2';
import CustomerEditForm from '../elements/CustomerEditForm';
import { BottomBar } from '../elements/BottomBar';
import QRCodeDialog from '../elements/qrcodeDialog';




function SettingsWidget( props ) {
    const {refresh} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onRefresh = () => {
        handleClose();
        refresh();
    }
  


    return (
      <div>
        <IconButton
          id="positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="primary"
          size="large"
        >
            <SettingsIcon sx={{fontSize:'40px'}}/>
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={onRefresh}>Refresh</MenuItem>
        </Menu>
      </div>
    );
  }

const template_customer_basic = {
    "id": -1, 
    "firstname": '', 
    "lastname": '', 
    "email": '', 
    "mobile": '', 
}

const template_12_sales = {
    "transactions": {
      "labels": [
      ],
      "datasets": [
        {
          "label": "# bookings",
          "data": [
          ],
          "backgroundColor": [
            "rgba(89, 150, 210, 1)"
          ]
        },
        {
          "label": "# passes",
          "data": [
          ],
          "backgroundColor": [
            "rgba(136, 206, 162, 1)"

          ]
        },
        {
          "label": "# videos",
          "data": [

          ],
          "backgroundColor": [
            '#2196f3'
          ]
        }
      ]
    }
}

export default function CustomerManager( props )
{
    
    const [events, setEvents] = React.useState(getCache(`events-list`, false) || []);
    const [customerbasic, setCustomerBasic] = React.useState(getCache(`customer-details-${window?.configuration?.access}`, false) || template_customer_basic);
    const [customerbookings, setCustomerBookings] = React.useState(getCache(`customer-bookings-${window?.configuration?.access}`, false) || []);
    const [customerpasses, setCustomerPasses] = React.useState(getCache(`customer-passes-${window?.configuration?.access}`, false) || []);
    const [customermedia, setCustomerMedia] = React.useState(getCache(`customer-media-${window?.configuration?.access}`, false) || []);
    const [customerquestionnaires, setCustomerQuestionnaires] = React.useState(getCache(`customer-questionnaires-${window?.configuration?.access}`, false));
    const [customersubscriptions, setCustomerSubscriptions] = React.useState(getCache(`customer-subscriptions-${window?.configuration?.access}`, false) || []);
    const [customerbookingsbusy, setCustomerBookingsBusy] = React.useState( true );
    const [customerpassesbusy, setCustomerPassesBusy] = React.useState( true );
    const [customermediabusy, setCustomerMediaBusy] = React.useState( true );
    const [salesdata, setSalesData] = React.useState(template_12_sales);
    const [companypasses, setCompanyPasses] = React.useState( getCache('company-passes', false) || [] );
    const [companyquestionnaires, setCompanyQuestionnaires] = React.useState( getCache('company-questionnaires', false) || [] );
    const [companymedia, setCompanyMedia] = React.useState( getCache('company-media', false) || [] );
    const [customernotes, setCustomerNotes] = React.useState( getCache(`customer-notes-${window?.configuration?.access}`, false) || [] );
    const [plans, setPlans] = React.useState( getCache('plans', false) || [] );
    const tz =  (Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe-London').replace(/\//g, '-');
    const [action, setAction] = React.useState('');
    const [busy, setBusy] = React.useState( false );
   
    const zeroPad = (num, places) => String(parseInt(num).toString(10)).padStart(places, '0')


    const createStack = ( url, callback, key, busy, template, cache, filter ) => {
        return {url, callback, key, busy, template, cache, filter}
    }




    const onKeyPress = (e) =>{

        var keycode = e.keyCode;
        
        if(!((keycode > 47 && keycode < 58)   || // number keys
            (keycode > 64 && keycode < 91)   || // letter keys
            (keycode > 95 && keycode < 112)  || // numpad keys
            (keycode > 185 && keycode < 193)))   // [\]' (in order)
        {
        return;
        }    

    }

    React.useEffect(()=>{
        window.addEventListener("keyup", onKeyPress, false);

        const urlParams = new URLSearchParams(window.location.search);

        setAction(urlParams.get('action')||'');
    
        return() =>{
          window.removeEventListener("keyup", onKeyPress, false)
        }
        
      }, [])
    

    const callstack = [
        createStack(`/customersrestapi/account/${window?.configuration?.access}`, setCustomerBasic, `customer-details-${window?.configuration?.access}`, false, template_customer_basic, true, 'account'),
        createStack(`/customersrestapi/events-list/${encodeURIComponent(tz)}`, setEvents, `events-list`, false, [], true, 'events'),
        createStack(`/customersrestapi/bookings/${window?.configuration?.access}`, setCustomerBookings, `customer-bookings-${window?.configuration?.access}`, setCustomerBookingsBusy, [], true, 'events'),
        createStack(`/customersrestapi/pass/${window?.configuration?.access}`, setCustomerPasses, `customer-passes-${window?.configuration?.access}`, setCustomerPassesBusy, [], true, 'passes'),
        createStack(`/customersrestapi/media/${window?.configuration?.access}`, setCustomerMedia, `customer-media-${window?.configuration?.access}`, setCustomerMediaBusy, [], true, 'media'),
        createStack(`/customersrestapi/subscriptions/${window?.configuration?.access}`, setCustomerSubscriptions, `customer-subscriptions-${window?.configuration?.access}`, false, [], true, 'subscriptions'),        
        createStack(`/customersrestapi/questionnaires/${window?.configuration?.access}`, setCustomerQuestionnaires, `customer-questionnaires-${window?.configuration?.access}`, false, [], true, 'questionnaires'),
        createStack(`/customersrestapi/company-passes`, setCompanyPasses, `company-passes`, false, [], true, 'passes'),       
        createStack(`/customersrestapi/company-media`, setCompanyMedia, `company-media`, false, [], true, 'media'),        
        createStack(`/customersrestapi/company-questionnaires`, setCompanyQuestionnaires, `company-questionnaires`, false, [], true, 'questionnaires'),        
        createStack(`/customersrestapi/plans`, setPlans, `plans`, false, [], true, 'plans'),        
        createStack(`/customersrestapi/notes/${window?.configuration?.access}/all`, setCustomerNotes, `customer-notes-${window?.configuration?.access}`, false, [], true, 'notes'),
    ]

    const isNullOrEmptyArray = (arr) => {
        return arr === null || (Array.isArray(arr) && arr.length === 0);
    }

    const fetchdata = async( server_requested ) => {
        if(busy)
        {
            return;
        }
        setBusy(true);
        var cs = callstack.filter( c => { 
            return ((typeof server_requested === 'string' && c.filter === server_requested) || typeof server_requested !== 'string')
        });

        for(let i = 0; i < cs.length; i++)
        {
            try{
                if(cs[i]?.busy)
                    cs[i].busy( true );
                console.log("server requested", server_requested, cs[i].key);
                if(!server_requested)
                {


                    const has_data = getCache(cs[i].key, false);
                    if(!isNullOrEmptyArray(has_data))
                    {
                        console.log("Skipping key", cs[i].key, has_data);
                        if(cs[i]?.busy)
                            cs[i].busy( false );
                        continue;
                    }
                }
                // we need to throttle the requests to the server so we do not hammer it...
                const data = await new Promise(( resolve, reject) => {
                    setTimeout(async ()=> {
                        try{
                            let d = await get( cs[i].url );
                            console.log("Data data is ", d);
                            if(!d)
                            {
                                d =  cs[i].template;
                            }
                            resolve(d);
                        }
                        catch(e)
                        {
                            if(window?.static_override)
                            {
                                let d = window?.static_customers[cs[i].key];
                                console.log("static customers", d);
                                if(!d)
                                {
                                    d = cs[i].template;
                                }
                                resolve(d);
                            }
                            else
                            {
                                reject(e);
                            }
                            
                        }                                        
                    }, 500);
 
                });
                cs[i].callback( data );
                if(cs[i]?.busy)
                    cs[i].busy( false );
                if(cs[i].cache)
                    setCache(cs[i].key, data, false);

            }
            catch(err)
            {
               
                console.error("Failed to get", err);
                if(cs[i]?.busy)
                    cs[i].busy( false );

            }
        }
        setBusy(false);
    }

    const fetchDataIfRequired = async() => {
        try{
            let d = await get( `/customersrestapi/is-customer-available/${window?.configuration?.access}`);
            fetchdata( d?.refresh );
        } 
        catch(e)
        {
            if(window?.static_override)
            {
                fetchdata( false );
            }

        }       
    }


    React.useEffect(() => {

        if(checkEpochShift(`CUSTOMER_${window?.configuration?.access}`)) // epoch has shifted. Update
        {
            fetchdata( true );
        }
        else
        {
            fetchdata( true );

            //fetchDataIfRequired();
        }
        const intervalid = setInterval(async() => {
            if(checkEpochShift(`CUSTOMER_${window?.configuration?.access}`)) // epoch has shifted. Update
            {       
                fetchdata( true );
            }
            else
            {            
                await fetchDataIfRequired()
            }
        }, 120000);

        return () => clearInterval(intervalid);

    }, []);

    React.useEffect(() => {
        const o = Object.assign({}, template_12_sales);
        for(let i = 12; i >= 0; i-- )
        {
            let d = new Date();
            d.setMonth(d.getMonth() - i);
            o.transactions.labels.push(  d.toLocaleDateString([], {year: 'numeric', month:'2-digit'}));

            let cb = customerbookings.reduce((p, c)=> {
                const df = new Date( c.purchased );
                if(d.getMonth() === df.getMonth() && d.getFullYear() === df.getFullYear())
                {
                    return p + c.dates.length;
                }
                return p;
            },0);

            let cp = customerpasses.reduce((p, c)=> {
                const df = new Date( c.purchased );
                if(d.getMonth() === df.getMonth() && d.getFullYear() === df.getFullYear())
                {
                    return p + 1;
                }
                return p;
            },0);    

            console.log("media", customermedia);
            let cv= customermedia.reduce((p, c)=> {
                const df = new Date( c.purchased );
                if(d.getMonth() === df.getMonth() && d.getFullYear() === df.getFullYear())
                {
                    return p + 1;
                }
                return p;
            },0);  

            o.transactions.datasets[0].data.push(cb)   
            o.transactions.datasets[1].data.push(cp)   
            o.transactions.datasets[2].data.push(cv)   

        }

        setSalesData(o);

        
    },[customerbookings, customerpasses, customermedia])


    const closeQR = () => {
        // Get the current URL
        const currentUrl = new URL(window.location.href);
        
        // Remove the 'action' parameter from the URL
        currentUrl.searchParams.delete('action');
        
        // Replace the current URL in the browser history
        window.history.replaceState({}, '', currentUrl.toString());
        setAction('');
    }


    const act = (action) => {
        // Get the current URL
        const currentUrl = new URL(window.location.href);
        
        // Remove the 'action' parameter from the URL
        currentUrl.searchParams.delete('action');
        // Replace the current URL in the browser history
        window.history.replaceState({}, '', currentUrl.toString());
        
        setAction(action);
    }

    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar widgets={[<SettingsWidget key="setting-1" refresh={()=>{fetchdata(true)}} />]}/>                
            </Box>
            <Box sx={{p: 2}}>
                <Grid container alignItems="stretch" spacing={2} >
                    <Grid item xs={12} md={6} display="flex">
                        <Card variant="outlined">
                            <CardContent>  
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Customer Details <span className='small'>account information</span>
                                </Typography>
                                <Divider />
                                <Grid container  spacing={3} sx={{pt:4}}>
                                    <Grid item  md={6} sx={{ display: { xs: 'none', md: 'block' }}} >
                                        <QRCode
                                            size={180}
                                            style={{ height: "auto", "marginLeft": "40px" }}
                                            value={`https://go.reservie.net/qrcode/${window?.configuration?.access}`}
                                            viewBox={`0 0 180 180`}
                                        />

                                    </Grid>
                                    <Grid item xs={12} display="flex" md={6}>
                                        <Grid container spacing={3} sx={{pt:2}}>
                                            <Grid item xs={2}><b>UID </b></Grid>
                                            <Grid item xs={10}>{`C${zeroPad(window?.configuration?.cid, 6)}-U${zeroPad(window?.configuration?.access, 6)}`}</Grid>
                                            <Grid item xs={2}><b>Name </b></Grid>
                                            <Grid item xs={10}>{`${customerbasic?.firstname} ${customerbasic?.lastname}`}</Grid>
                                            <Grid item xs={2}><b>Email </b></Grid>
                                            <Grid item xs={10}><a href={`mailto:${customerbasic?.email}`} className='link'>{`${customerbasic?.email}`}</a></Grid>
                                            <Grid item xs={2}><b>Mobile</b></Grid>
                                            <Grid item xs={10}><a href={`tel:${customerbasic?.mobile}`} className='link ml'>{`${customerbasic?.mobile}`}</a></Grid>
                                            <Grid item xs={12}>
                                                <CustomerEditForm 
                                                        id={window?.configuration?.access} 
                                                        firstname={customerbasic?.firstname} 
                                                        lastname={customerbasic?.lastname}
                                                        email={customerbasic?.email}
                                                        mobile={customerbasic?.mobile} 
                                                        reload={async()=>{await fetchdata('account')}}
                                                    />                                              
                                            </Grid>
                                        </Grid>
                           
                                    </Grid>                                         
                                </Grid>

                            </CardContent>
                        </Card>  

                    </Grid> 
                    <Grid item xs={12} md={6} display="flex">
                        <Card variant="outlined" sx={{display: 'block', width: '100%'}}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Sales activity <span className='small'> 12 month view</span>
                                </Typography>
                                <Divider/>
                                <Grid container spacing={3} sx={{pt:4, height: '300px'}}>
                                    <Grid item xs={12}>
                                    {
                                        (customerbookingsbusy || customerpassesbusy || customermediabusy)  && 
                                        <Box sx={{pt: 2, pb: 4}}>
                                            <Stack>
                                                <Skeleton variant="rectangle" height={150} />
                                            </Stack>
                                        </Box>
                                    }
                                    {
                                        !(customerbookingsbusy || customerpassesbusy || customermediabusy)  && 
                                        <Box>
                                            <Bar
                                                data={salesdata?.transactions}
                                                width={100}
                                                height={200}
                                                options={{ maintainAspectRatio: false }} 
                                            />                                            
                                        </Box>

                                    }
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>  
                    </Grid> 
                </Grid>
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>  
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Customer Account Manager
                                </Typography>
                                <Divider />
                                <CustomerPanel 
                                    permissions={customerbasic?.permissions}
                                    bookings={customerbookings} 
                                    events={events} 
                                    passes={customerpasses} 
                                    companypasses={companypasses} 
                                    media={customermedia} 
                                    companymedia={companymedia}
                                    plans={plans}
                                    subscriptions={customersubscriptions}
                                    questionnaires={customerquestionnaires}
                                    allquestionnaires={companyquestionnaires}
                                    reloadPasses={async()=>{await fetchdata('passes')}} 
                                    reloadBookings={async()=>{await fetchdata('events'); await fetchdata('passes')}}  
                                    reloadVideos={async()=>{await fetchdata('media');  await fetchdata('passes')}}                               
                                    reloadSubscriptions={async()=>{await fetchdata('subscriptions')}}
                                    reloadQuestionnaires={async()=>{await fetchdata('questionnaires')}}
                                    notes={customernotes}
                                    reloadNotes={async()=>{await fetchdata('notes')}} 
                                    action={action}
                                    />         
                            </CardContent>
                        </Card>  

                    </Grid> 
                </Grid>
            </Box> 
            {
                action === 'qrcode' && <QRCodeDialog handleClose={closeQR} handleComplete={act} /> 
            }           
            <BottomBar />
        </div>
    )
}