import * as React from 'react';
import { IconButton } from "@mui/material";

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

export class RestoreButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('transfer button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton  name="warning" className="iconWarning"  disabled={this.props?.disabled || false}  aria-label="restore" onClick={this._handleClick}>
                <SettingsBackupRestoreIcon className="iconWidget"/>
            </IconButton>            
        )
    }
}
