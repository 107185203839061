import * as React from 'react';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress, Stack } from "@mui/material";



export function ProgressDialog( props ) {

    const {progress, text, title} = props;



    return (
        <div>

        <Dialog
            open={progress > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
            {"Processing"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign: 'left'}}>
                {title}
            </DialogContentText>
                <Stack  spacing={2}>
                    <Box sx={{ width: '100%', pt: 4}}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ width: '100%', pt: 1, pb: 4, textAlign: 'center' }}>
                        {text}
                    </Box>

                </Stack>

            </DialogContent>
        </Dialog>
        </div>
    );
}