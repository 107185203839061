import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {  CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, IconButton, LinearProgress, Radio, RadioGroup, Skeleton, SpeedDial, SpeedDialAction, SpeedDialIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { Card, CardHeader, CardActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FileDrop } from 'react-file-drop';
import * as tus from 'tus-js-client'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { get, post, put } from '../../libraries/network';
import AlertDialog from '../elements/alertDialog';
import ResponsiveAppBar from '../elements/appBar';
import { ManageButton } from '../elements/ManageButton';
import {DeleteButton} from '../elements/DeleteButton';
import { ViewButton } from '../elements/ViewButton';
import { PlayButton } from '../elements/PlayButton';
import { VideoRegister } from '../elements/videoRegister';
import { HLSVideoPlayer } from '../elements/HLSVideoPlayer';

const UPLOAD_REQUEST_SPACE_STATE = 1;
const UPLOAD_REQUEST_UPLOAD_VIDEO_STATE = 2;
const UPLOAD_REGISTER_VIDEO_STATE = 3;
const UPLOAD_VIDEO_DONE_STATE = 4;

const TUS_ENDPOINT = 'https://video.bunnycdn.com/tusupload';
const RETRY_DELAYS = [0, 3000, 5000, 10000, 20000, 60000, 60000];


const headers = {
  AuthorizationSignature: window?.video_settings?.signature || 'no-signature' , // Replace with your actual SHA256 signature
  AuthorizationExpire:   window?.video_settings?.expiration || 1234, // Replace with the expiration time from the signature
  VideoId:   window?.video_settings?.videoUID || 'not-set', // Replace with the expiration time from the signature
  LibraryId: window?.video_settings?.access || 'NA', // Replace with the Library ID
};

const metadata = {
  filetype: '',
  title: '',
  collection: window?.video_settings?.library?.id || 'NA',
};

const steps = [
  'Configure',
  'Select Media',
  'Uploading',
  'Added to Process Queue',
];

function HorizontalLabelPositionBelowStepper( props ) {
  const {step} = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}


function PassSelector( props ) {
  const {state, pass, setPass} = props;

  const handleChange = (event) => {
    setPass(event.target.value);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ mt:2, width: "100%", pt:1}}>
        <InputLabel id="pass-select-label">Allowed passes</InputLabel>
        <Select
          labelId="pass-select-label"
          id="pass-select"
          value={state?[]:pass}
          multiple={true}
          onChange={handleChange}
          label="Allowed passes"
          disabled={state}
        >
          {
             window.video_settings.passes.map( p =>  (<MenuItem value={p.id} key={'pass-' + p.id}>{p.name}</MenuItem>))
          }
        </Select>
      </FormControl>
    </div>
  );
}

function MediaDialogEdit( props )
{
    const {open, media, onRefresh, onClose} = props;
    const [expswitch, setExpireSwitch] = React.useState(media?.expire_state)
    const [expires, setExpires] = React.useState( media?.expires )
    const [name, setName] = React.useState(media?.name||'');
    const [price, setPrice] = React.useState( media?.price)
    const [oncard, setCard] = React.useState( media?.card)
    const [credits, setCredits] = React.useState( media?.credits || 0)
    const [onpass, setPass] = React.useState(media?.pass);
    const [description, setDescription] = React.useState(media.description || '');
    const [selectedpass, onSelectedPass] = React.useState(media.passes || [])
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onSubmitRecords = (e) =>{
        e.preventDefault();
        setLoading(true);
        let chain  = '';
        chain += '&objectid='  + encodeURIComponent(media.id);
        chain += '&library='  + encodeURIComponent(media.lid);
        chain += '&name='  + encodeURIComponent(name);
        chain += '&description='  + encodeURIComponent(description);
        chain += '&allow_card='  + parseInt(oncard === true ? 1: 0);
        chain += '&allow_pass='  + parseInt(onpass === true ? 1: 0);
        chain += '&price='  + encodeURIComponent(price);
        chain += '&credits='  + encodeURIComponent(credits);
        chain += '&selected_passes='  + encodeURIComponent(JSON.stringify(selectedpass));
        chain += '&expires='  + expswitch;
        chain += '&period='  + parseInt(expires);

        fetch(`/video/media/${media.id}`, {
          method: "POST", // ideally this would be patch - but standard framework validation server side does not currently allow this
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body:  window.video_settings.security_name + '=' +  window.video_settings.security_value  + chain,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest'
          }
        })
        .then(res => {
          return res.json();
        })
        .then(async(response) => {
          //console.log(data);
          if(response.status === 'OK')
          {
            await onRefresh();
            onClose(false)
            setLoading(false)
            
          }
          else
          {
              setLoading(false)
              setError('Failed to delete folder. Reason: ' + response.reason);
          }
        }).catch((e)=>{
          setLoading(false)
          setError('Network error. Please reload and try again. ')
        });  
        // push the message to the server
    }
    console.log(media.title)
    return (
      <Dialog open={open} fullWidth={true} maxWidth="lg">
        <form onSubmit={onSubmitRecords}>
            <DialogTitle>Video</DialogTitle>
            <DialogContent>
              {
                !loading && !error &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                    <Grid item xs={5} className="video-box">
                        <h3>Preview</h3>
                        <picture style={{display: 'block', textAlign: 'center'}}>
                          <source type="image/webp" srcset={`${media?.animated_preview} 640w`}  style={{width: '100%', display: 'block'}}/>
                          <img src={`${ media?.basic_preview}`} style={{ width: "100%" }} alt={media.title} />
                        </picture>
                    </Grid>
                    <Grid item xs={7}>
                      <h3>Video detail</h3>

                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={name}
                        required
                        inputProps={{pattern:"[a-zA-Z0-9-_ ]{1,250}"}}
                        onChange={(e) => {setName(e.target.value)}}
                      />
                      <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={description}
                        onChange={(e) => {setDescription(e.target.value)}}
                      /> 
                      <h3>How can this video be purchased?</h3>
                      <div>
                          <FormControlLabel control={<Checkbox />} label="Using a Credit/Debit Card" checked={oncard !== false}  onChange={(event)=>setCard(event.target.checked)} /> 
                          <FormControlLabel control={<Checkbox />} label="Using Pass Credit" checked={onpass !== false} onChange={(event)=>setPass(event.target.checked)}/>

                      </div>
                      <TextField
                        margin="dense"
                        id="price"
                        label="Price"
                        type="number"
                        variant="standard"
                        inputProps={{
                          step:0.01,
                          pattern:"[0-9]+[.]{0,1}[0-9]{0,2}"
                        }}
                        value={price}
                        disabled={oncard===false}
                        sx={{paddingRight: '10px'}}
                        onChange={(e) => {
                          if(!isNaN(e.target.value))
                          {
                            const v = parseFloat(e.target.value);
                            if(v < 0)
                            {
                              setPrice(0.00);
                            }
                            else
                            {
                              setPrice(e.target.value);

                            }
                          }
                        }}
                      />   
                      <TextField
                        margin="dense"
                        id="credits"
                        label="Credits"
                        type="number"
                        variant="standard"
                        sx={{paddingLeft: '10px'}}
                        inputProps={{
                          step: 1
                        }}
                        value={credits}
                        disabled={onpass===false}
                        onChange={(e) => {
                          const credits = parseInt(e.target.value, 10);
                          if(credits < 0)
                          {
                            setCredits(0);
                          }
                          else
                          {
                            setCredits(credits)
                          }
                        }}
                      /> 
                      <PassSelector state={!!!onpass} pass={selectedpass} setPass={onSelectedPass}/>
                      <FormControl sx={{marginTop: "25px"}}>
                        <FormLabel id="expiry">Does video viewing expire after purchase?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={expswitch === true ? "1" : "0" }
                          onChange={(event) => setExpireSwitch(event.target.value === "1"?true: false)}
                        >
                          <FormControlLabel value="1"  control={<Radio />} label="Yes" />
                          <FormControlLabel value="0" control={<Radio />} label="No" />                
                        </RadioGroup>
                        <TextField
                              margin="dense"
                              id="credits"
                              label="Expiry in days after purchase"
                              type="number"
                              variant="standard"
                              disabled={!!!expswitch}
                              value={expires}
                              onChange={(e) => {setExpires(e.target.value)}}
                        />               
                      </FormControl>
                  </Grid>
                </Grid>
              }
              {
                loading && !error && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress sx={{ margin: 'auto' }} size={100} />
                    </Box>
                )
              }
              {
                !!error &&(
                  <Box sx={{ display: 'flex' }}>
                    <div className="alert"><span><WarningIcon /></span>{error}</div>
                  </Box>                  
                  ) 

              }


            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose(false)} disabled={loading}>Cancel</Button>
              <Button type="submit" disabled={loading}>OK</Button>
            </DialogActions>
        </form>

    </Dialog>
    )  
}


function MediaDialogCreate( props )
{
    const {open, lid, onRefresh, onClose} = props;
    const [expswitch, setExpireSwitch] = React.useState(false)
    const [expires, setExpires] = React.useState(0)
    const [name, setName] = React.useState('');
    const [price, setPrice] = React.useState(0)
    const [oncard, setCard] = React.useState(true)
    const [credits, setCredits] = React.useState(0)
    const [onpass, setPass] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [selectedpass, onSelectedPass] = React.useState([]);
    const [videoID, setVideoID] = React.useState('');
    const [videoUploadExpires, setVideoUploadExpires] = React.useState('');
    const [videoUploadSignature, setVideoUploadSignature] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [step, setCurrentStep] = React.useState(0);
    const [file, setFile] = React.useState(null);
    const [progress, setProgress] = React.useState(UPLOAD_REQUEST_SPACE_STATE);
    const [progressText, setProgressText] = React.useState('');
    const [uploaderror, setUploadError] = React.useState(false);
    const fileInputRef = React.useRef(null);
    const [space, setSpace] = React.useState('');
    const [percentage, setPercentage] = React.useState(0);


  


    React.useEffect(()=>{
      
      const load = async() => {
          if(step === 2)
          {
              switch(progress)
              {
                case UPLOAD_REQUEST_SPACE_STATE:
                {
                  setPercentage(0);
                  setProgressText("Requesting storage space");
                  /* Using bunny - so nothnig to do - skip ahead */
                  var chain = `title=${encodeURIComponent(file.name)}`;
                  chain += `&where=${window?.video_settings?.library.id}`;
                  try{

                    let result = await post('/video/space/', chain);
                    if(result.status === 'OK')
                    {
                      setVideoID(result.id);
                      setVideoUploadExpires(result.expires);
                      setVideoUploadSignature(result.signature);
                      setProgress(UPLOAD_REQUEST_UPLOAD_VIDEO_STATE);
                    }
                    else
                    {
                      setError('Failed to allocate space:'+ result.reason)
                    }


                  }
                  catch(e)
                  {
                    setError('Failed to allocate space:'+ e)
                  }

                }
                break;

                case UPLOAD_REQUEST_UPLOAD_VIDEO_STATE:
                {
                  setProgressText("Uploading file");
                  headers.VideoId = videoID;
                  headers.AuthorizationExpire = videoUploadExpires;
                  headers.AuthorizationSignature = videoUploadSignature;
                  metadata.filetype = file.type;
                  metadata.title = file.name;
                  var upload = new tus.Upload(file, {
                    endpoint: TUS_ENDPOINT,
                    headers,
                    metadata,
                    retryDelays: RETRY_DELAYS,
                    onError: (error) => {
                      console.error('Upload Error:', error);
                      setError('Network error. Please reload and try again. '+ error)

                    },
                    onSuccess: (uploadResult) => {
                      console.log('Upload Success:', uploadResult);
                      setProgress(UPLOAD_REGISTER_VIDEO_STATE);

                    },
                    onProgress: (progress) => {
                      console.log('Upload Progress:', progress);
                      const percentage = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100);
                      setPercentage(percentage);

                    },
                  });
                  // Check if there are any previous uploads to continue.
                  upload.findPreviousUploads().then(function (previousUploads) {
                    // Found previous uploads so we select the first one. 
                    if (previousUploads.length) {
                        upload.resumeFromPreviousUpload(previousUploads[0])
                    }

                    // Start the upload
                    upload.start()
                  })

                }
                break;

                case UPLOAD_REGISTER_VIDEO_STATE:
                {
                  setProgressText("Registering video to library");
                  let chain  = '';
                  chain += '&objectid='  + encodeURIComponent(videoID);
                  chain += '&name='  + encodeURIComponent(name);
                  chain += '&description='  + encodeURIComponent(description);
                  chain += '&allow_card='  + encodeURIComponent(oncard === true? 1: 0);
                  chain += '&allow_pass='  + encodeURIComponent(onpass === true ? 1: 0);
                  chain += '&price='  + parseFloat(price);
                  chain += '&credits='  + parseInt(credits);
                  chain += '&selected_passes='  + encodeURIComponent(JSON.stringify(selectedpass));
                  chain += '&expires='  + expswitch;
                  chain += '&period='   + parseInt(expires);
                  chain += '&library='  +  encodeURIComponent(lid);
                  chain += '&filename='  + encodeURIComponent(file.name);
                  chain += '&type='     +  encodeURIComponent(file.type);
          

                  try{
                    let result = await post('/video/register/', chain);
                    if(result.status === 'OK')
                    {
                      setProgressText("Upload complete. We will start processing this soon.");         
                      setProgress(UPLOAD_VIDEO_DONE_STATE);                      
                    }
                    else
                    {
                      setError('Failed to register video to library. Reason: ' + result.reason);
                      
                    }

                  }
                  catch(e)
                  {
                    setError('Failed to register video to library. Reason: ' + e);
                  }

                  // push the message to the server
                }
                break;

                case UPLOAD_VIDEO_DONE_STATE:
                {
                  await onRefresh();
                  onClose();
                }
              }
          }        
      }

      load();

    }, [step, progress]);




    const onFileInputChange = (event) => {
      const { files } = event.target;
      // do something with your files...
      console.log("Files", files);
      onFileDrop(event, files);

    }

    const onTargetClick = () => {
      fileInputRef.current.click()
    }

    const onIncrementStep = (e) => {
      e.preventDefault();
      setCurrentStep(step+1);
    }

    const onFileDrop = (e, f) => {
      
      e.preventDefault();
      e.stopPropagation();
      console.log("FileList event", f);
      if(f[0])
      {
        
        if(f[0].type === 'video/quicktime' || f[0].type === 'video/mp4')
        {
          setCurrentStep(step+1);          
          setFile(f[0]);
        }
        else
        {
          setUploadError('Invalid file type');
        }
      }
      else
      {
        setUploadError('File selection error');
      }
    }

    return (
      <Dialog open={open} fullWidth={true} maxWidth="lg">
        <form onSubmit={onIncrementStep}>
            <DialogTitle>Video</DialogTitle>
            <DialogContent>
              {
                !loading && !error && step === 0 &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                    <Grid item xs={12}>
                        <HorizontalLabelPositionBelowStepper step={step} />                     
                    </Grid>
                    <Grid item xs={12}>
                      <h3>Video detail</h3>

                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={name}
                        required
                        inputProps={{pattern:"[a-zA-Z0-9_- ]{1,250}"}}
                        onChange={(e) => {setName(e.target.value)}}
                      />
                      <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={description}
                        onChange={(e) => {setDescription(e.target.value)}}
                      /> 
                      <h3>How can this video be purchased?</h3>
                      <div>
                          <FormControlLabel control={<Checkbox />} label="Using a Credit/Debit Card" checked={oncard !== false || onpass === false} disabled={onpass === false}  onChange={(event)=>setCard(event.target.checked)} /> 
                          <FormControlLabel control={<Checkbox />} label="Using Pass Credit" checked={onpass !== false} onChange={(event)=> {
                            setPass(event.target.checked);
                            if(!event.target.checked)
                            {
                              setCard(true);
                            }
                          }
                          }/>

                      </div>
                      <TextField
                        margin="dense"
                        id="price"
                        label="Price"
                        type="number"
                        variant="standard"
                        inputProps={{
                          step: 1
                        }}
                        value={price}
                        disabled={oncard===false}
                        required={oncard}
                        sx={{paddingRight: '10px'}}
                        onChange={(e) => {
                          if(!isNaN(e.target.value))
                          {
                            const v = parseFloat(e.target.value);
                            if(v < 0)
                            {
                              setPrice(0.00);
                            }
                            else
                            {
                              setPrice(e.target.value);

                            }
                          }
                            
                        }}
                      />   
                      <TextField
                        margin="dense"
                        id="credits"
                        label="Credits"
                        type="number"
                        variant="standard"
                        sx={{paddingLeft: '10px'}}
                        inputProps={{
                          step: 1
                        }}
                        value={parseInt(credits).toFixed(0)}
                        disabled={onpass===false}
                        required={onpass}
                        onChange={(e) => {
                          const credits = parseInt(e.target.value, 10);
                          if(credits < 0)
                          {
                            setCredits(0);
                          }
                          else
                          {
                            setCredits(credits)
                          }
                        }}
                      /> 
                      <PassSelector state={!!!onpass} pass={selectedpass} setPass={onSelectedPass}/>
                      <FormControl sx={{marginTop: "25px"}}>
                        <FormLabel id="expiry">Does video viewing expire after purchase?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="expiry"
                          name="expiry-radio-buttons-group"
                          value={expswitch === true ? "1" : "0"}
                          onChange={(event) => setExpireSwitch(event.target.value === "1"? true : false)}
                        >
                          <FormControlLabel value="1"  control={<Radio />} label="Yes" />
                          <FormControlLabel value="0" control={<Radio />} label="No" />                
                        </RadioGroup>
                        <TextField
                              margin="dense"
                              id="credits"
                              label="Expiry in days after purchase"
                              type="number"
                              variant="standard"
                              disabled={!expswitch}
                              required={!!expswitch}
                              value={expires}
                              onChange={(e) => {setExpires(e.target.value)}}
                        />               
                      </FormControl>
                  </Grid>
                </Grid>
              }
              {
                  !loading && !error && step === 1 &&
                  (
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} >
                          <Grid item xs={12}>
                              <HorizontalLabelPositionBelowStepper step={step} />                     
                          </Grid>
                          <Grid item xs={12}>
                            <div className="drop-area" onClick={onTargetClick}>
                                <input onChange={onFileInputChange} ref={fileInputRef} type="file" className="hidden" />
                                <FileDrop 
                                      onTargetClick={onTargetClick}
                                      onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                      onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                      onFrameDrop={(event) => console.log("onFrameDrop", event)}
                                      onDragOver={(event) => console.log('onDragOver', event)}
                                      onDragLeave={(event) => console.log('onDragLeave', event)}
                                      onDrop={(files, event) =>  onFileDrop(event, files)}                      
                                  />
                                  <div className="label">
                                      Drop Video Here
                                      <div className="small">Drop or click here to start the video upload. We accept both .mov and .mp4 files.</div>
                                      {
                                        !!uploaderror &&
                                        <div className="uploaderror"><span><WarningIcon /></span>Error: {uploaderror}</div>
                                      }
                                  </div>
                            </div>

                      </Grid>
                    </Grid>
                  )

              }
              {
                  !loading && !error && step === 2 &&(
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} >
                      <Grid item xs={12}>
                          <HorizontalLabelPositionBelowStepper step={step} />                     
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress sx={{ margin: 'auto' }} size={100} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                            <div className="upload-progress">
                                  {progressText}
                            </div>
                            <div className="upload-progress">
                                <LinearProgress variant="determinate" value={percentage}/>
                            </div>
                      </Grid>
                    </Grid>

                  )
              
              }
              {
                loading && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress sx={{ margin: 'auto' }} size={100} />
                    </Box>
                )
              }
              {
                !!error &&(
                  <Box sx={{ display: 'flex' }}>
                    <div className="alert"><span><WarningIcon /></span>{error}</div>
                  </Box>                  
                  )
              } 

            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose(false)} disabled={(loading || step === 2)&&(!error)}>Cancel</Button>
              {
                step === 0 &&
                <Button type="submit" disabled={loading}>Next</Button>
              }
            </DialogActions>
        </form>

    </Dialog>
    )  
}


function DeleteConfirmLibrary( props )
{
    const {open, onClose, onRefresh, media} = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onDelete = () => {

      setLoading(true); 
      fetch(`/video/library/${media.id}`, {
        method: "DELETE",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body:  window.video_settings.security_name + '=' +  window.video_settings.security_value ,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
      })
      .then(res => {
        return res.json();
      })
      .then(async(response) => {
        //console.log(data);
        if(response.status === 'OK')
        {
          //await onRefresh();
          onClose(false)
          setLoading(false);
          window.location.href = '/video';
        }
        else
        {
            setLoading(false)
            setError('Failed to delete folder. Reason: ' + response.reason);
        }
      }).catch((e)=>{
        setLoading(false)
        setError('Network error. Please reload and try again. ')
      });  


      // push message to the server 
      
    }
    return (
      <Dialog open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                <span>Delete library</span>            
            </DialogTitle>
            <DialogContent>

              {
                !!!loading && !!!error &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                    <Grid item xs={12} className="confirm">
                        <DialogContentText>
                            Are you sure that you want to delete this library and all associated videos? Warning: This action <b>cannot</b> be undone. 
                        </DialogContentText>
                    </Grid>
                </Grid>
              }
              {
                !!loading &&(
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress sx={{ margin: 'auto' }} size={200} />
                </Box>                  
                )

              }
              {
                !!error &&(
                <Box sx={{ display: 'flex' }}>
                  <div className="alert"><span><WarningIcon /></span>{error}</div>
                </Box>                  
                )                
              }

            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose(false)} disabled={loading}>Cancel</Button>
              <Button onClick={() => onDelete()} disabled={loading || !!error}>Yes, delete</Button>
            </DialogActions>
        </Dialog>
    )  
}


function DeleteConfirmMedia( props )
{
    const {open, onClose, onRefresh, media} = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const onDelete = () => {

      setLoading(true); 
      fetch(`/video/media/${media.lid}/${media.id}`, {
        method: "DELETE",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body:  window.video_settings.security_name + '=' +  window.video_settings.security_value ,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
      })
      .then(res => {
        return res.json();
      })
      .then(async(response) => {
        //console.log(data);
        if(response.status === 'OK')
        {
          await onRefresh();
          onClose(false)
          setLoading(false)

        }
        else
        {
            setLoading(false)
            setError('Failed to delete folder. Reason: ' + response.reason);
        }
      }).catch((e)=>{
        setLoading(false)
        setError('Network error. Please reload and try again. ')
      });  


      // push message to the server 
      
    }
    return (
      <Dialog open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                <span>Delete Video</span>            
            </DialogTitle>
            <DialogContent>

              {
                !!!loading && !!!error &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                    <Grid item xs={12} className="confirm">
                        <DialogContentText>
                            <p>Are you sure that you want to delete this video? Warning: This action <b>cannot</b> be undone.</p>
                        </DialogContentText>
                    </Grid>
                </Grid>
              }
              {
                !!loading &&(
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress sx={{ margin: 'auto' }} size={200} />
                </Box>                  
                )

              }
              {
                !!error &&(
                <Box sx={{ display: 'flex' }}>
                  <div className="alert"><span><WarningIcon /></span>{error}</div>
                </Box>                  
                )                
              }

            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose(false)} disabled={loading}>Cancel</Button>
              <Button onClick={() => onDelete()} disabled={loading || error}>Yes, delete</Button>
            </DialogActions>
        </Dialog>
    )  
}




function PlayDialog( props )
{
    const {open, media, onClose} = props;
    return (
      <Dialog open={open} maxWidth="lg">
      <DialogTitle>Video</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{marginBottom:'10px;'}}>
            This video has been converted from the original format, to 240p, 360p and 720p HLS formats for bandwidth adaptive streaming.
        </DialogContentText>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2} className="mt-2">
          <Grid item xs={12} className="video-box">
            <div className="video-container">
                <iframe frameBorder="0" className="frame" src={`${media?.playfile}&autoplay=true`} loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen={true} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Close</Button>
      </DialogActions>
    </Dialog>
    )  
}


function MediaElement(props){

  const {title, id, file, status, lid, omManage, onPlay, onDelete} = props;

  return (
    <Box className="video-element">
        <Card variant="outlined">
            <CardHeader title={title} className="header" />
            <CardContent className="lib-content">
              <div className='preview'>
                
                {
                     (!(status === 3 || status === 4 )) &&
                     <img src={`${ window.video_settings.video_cdn_preview}/${lid}/${id}/${file}/preview.jpg`}
                        style={{ maxHeight: 180, height: "180px", width:"auto" }}
                        alt={title}
                    />
                }

                {
                     (!(status === 3 || status === 4 )) &&
                    <Box sx={{mb: '10px'}}>
                      <Skeleton variant="rectangular" height={180} />                      
                    </Box>
                }
                 {
                     (status === 3 || status === 4 ) &&
                    <div className="video-overlay">
                      <IconButton color="default" aria-label="play" component="span" className="playnow" onClick={()  => onPlay( props )} >
                        <PlayCircleOutlineIcon sx={{ fontSize: "60px", color: "#fff" }}/>
                      </IconButton>                
                    </div>
                }
              </div>
                {
                    (status === 3 || status === 4 )&&
                    <p className="info"><span>Status:</span> <b>READY</b></p>
                }
                {
                    (status === 5 || status === 8 )&&
                    <p className="info"><span>Status:</span> <b>ERROR</b></p>
                }
                {
                    (status === 0 || status === -1  ) &&
                    <p className="info"><span>Status:</span> <b>WAITING</b></p>
                }    
                {
                    (status === 1 || status === 2 || status === 6 || status === 7 || status === 9 || status === 10 ) &&
                    <p className="info"><span>Status:</span> <b>CONVERTING</b></p>
                }                             
            </CardContent>
            <CardActions>
              <Grid  container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={2}>
                  <Button variant="outlined" startIcon={<EditIcon />} disabled={ status !== "COMPLETE"} onClick={() =>{omManage(props)}} sx={{fontSize:"0.8em", textTransform:"lowercase", marginLeft: "10px"}}>
                    edit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <IconButton aria-label="delete" size="small" color="error" disabled={ status !== "COMPLETE"} onClick={()=>{onDelete(props)}}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>                  
                </Grid>                
              </Grid>
            </CardActions>
          </Card>
    </Box>
  )
}




export default function VideoLibrary() {

  const [media, setMedia] = React.useState(null);
  const [play, setPlay] = React.useState(null);
  const [del, setDel]   = React.useState(null);
  const [mediaadd, onMediaAdd ] = React.useState(false);
  const [deletelibrary, setDeleteLibrary] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [busy, setBusy]   = React.useState(false);
  const [error, setError]   = React.useState(false);
  const [view, setView]   = React.useState(false);


  const library = window?.video_settings?.library || {id: -1};
  
  
  const onLibraryRefresh = async () => {
 
        try{

          setBusy(true)
          
          const r = await get( `/video/get-media-library/${library.id}`);
          if(r.status === 'OK')
          {
              setData(r.data);
          }
          else
          {
            setData(null)
            setError("Failed to get pass list");                
          }
          setBusy(false)
      }
      catch(e)
      {
          if(window?.static_override)
          {
              setTimeout(async () => {
                setData(window.static_library_files);
              }, 2000);

          }
          else
          {
              setError("Sorry, we have detected a network error. We have not been able to fetch your library");
              setData(null)

          }

      }


  }

  const onNavigateAway = () => {
    window.location.reload();
  }

  const checkDisabled = () =>{
     const lst = data?.files?.filter( f => {
        return (f.status === "QUEUED" ||  f.status === "PROCESSING")
     }) || [];
     return lst.length > 0;
  }

  const checkReachedMax = () =>{
    if((!data?.allowance) || (!data?.used))
    {
      return true;
    }
    if(data?.allowance <= data?.used)
    {
      return true;
    }
    return false;
 }

  React.useEffect(()=>{

    onLibraryRefresh()

  }, [])
  return (
    <div className="rvNS">
            <Box>
                <Grid container spacing={2} sx={{p: 2}}>
                  <Grid item xs={12}>
                    <ResponsiveAppBar 
                        widgets={[
                          <Box sx={{pl:1}} key={'widget-1'}>
                            <Button variant="outlined" color="primary" disabled={checkReachedMax()} onClick={() => onMediaAdd(true)} startIcon={<CloudUploadIcon />}>
                            Upload
                          </Button>                      
                        </Box>,              
                        <Box sx={{pl:1, pr: 1}}  key={'widget-2'}>
                          <Button variant="outlined" color="error" disabled={checkDisabled()} onClick={() => setDeleteLibrary(library)} startIcon={<DeleteForeverIcon />}>
                            Delete
                          </Button>
                        </Box>
                      ]}
                    />  
                  </Grid>

                  <Grid item xs={12}>

                      <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{pt: 2, pb:2}}>
                                      <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                          Videos <span className='small'>The following video resources have been uploaded to and are available for streaming.</span>
                                      </Typography>
                                      <Divider /> 
                                </Grid>  
                                <Grid item xs={12}>
                                  <TableContainer>
                                      <Table>
                                          <TableHead>
                                              <TableRow>
                                                    <TableCell  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell>
                                                        Sold
                                                    </TableCell>
                                                    <TableCell  align='center'>
                                                        Play Video
                                                    </TableCell>  
                                                    <TableCell  align='center'>
                                                        Subscribers
                                                    </TableCell>                                                                                                            
                
                                                    <TableCell  align='center'  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                        Edit
                                                    </TableCell>  
                                                    <TableCell  align='right'  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                        Delete
                                                    </TableCell>                                                                                                                                                                                    
                                                </TableRow>
                                          </TableHead>
                                          <TableBody>
                                        {
                                            ( !data || data?.files?.length === 0 ) &&
                                             <TableRow>
                                                <TableCell colSpan={8}  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                <Box sx={{p: 10}}>
                                                    <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no videos.</Typography>
                                                </Box>                                                    
                                                </TableCell>
                                                <TableCell colSpan={5}  sx={{ display: { xs: 'table-cell', md: 'none', lg: 'none' } }}>
                                                <Box sx={{p: 10}}>
                                                    <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no videos.</Typography>
                                                </Box>                                                    
                                                </TableCell>                                                
                                             </TableRow>
                                        }
                                        {
                                             data && data?.files?.map( (l) =>(
                                                <TableRow key={`video-${l.id}`}>
                                                    <TableCell  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>{l.id.slice(-4)}</TableCell>
                                                    <TableCell>{l.name}</TableCell>
                                                    <TableCell>
                                                    {
                                                        (l.status === 3 || l.status === 4) &&
                                                        <p className="info"><b>READY</b></p>
                                                    }
                                                    {
                                                        (l.status === 0 || l.status === -1) &&
                                                        <p className="info"><b>WAITING</b></p>
                                                    } 
                                                    {
                                                        (l.status === 5 || l.status === 8) &&
                                                        <p className="info"><b>ERROR</b></p>
                                                    }                                                        
                                                    {
                                                         (l.status === 1 || l.status === 2 || l.status === 6 || l.status === 7  || l.status === 9 || l.status === 10) &&
                                                        <p className="info"><b>CONVERTING</b></p>
                                                    } 
                                                    </TableCell>
                                                    <TableCell>{l?.customers||0}</TableCell>
                                                    <TableCell  align="center">
                                                        <PlayButton callback={(e)=>{ setPlay( e )}} disabled={ (!(l.status === 3 || l.status ===  4)) } data={l}/>                                                        
                                                    </TableCell>
                                                    <TableCell  align="center">
                                                        <ViewButton callback={(e)=>{ setView(e) }}  disabled={ (!(l.status === 3 || l.status ===  4)) }  data={l}/>                                                        
                                                    </TableCell>
                                                    <TableCell  align="center"  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                        <ManageButton callback={(e)=>{ setMedia( e )}}  disabled={ (!(l.status === 3 || l.status ===  4)) }  data={l}/>                                                        
                                                    </TableCell>
                                                    <TableCell  align="right"  sx={{ display: { xs: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                                                        <DeleteButton callback={(e)=>{ setDel(e)}}  disabled={ (!(l.status === 3 || l.status ===  4)) }   data={l}/>                                                        
                                                    </TableCell>
                                                </TableRow>

                                             ))
                                        }
                                    </TableBody>
                                      </Table>
                                  </TableContainer>
                                </Grid>

                            </Grid>
                        </CardContent>
                      </Card>


                  </Grid>    
          
          </Grid>
      </Box>
      {
        media &&
        <MediaDialogEdit open={true}  media={media} onClose={setMedia} onRefresh={() => onLibraryRefresh()}/>
      }
      {
        play &&
        <PlayDialog open={true} media={play} onClose={setPlay}/>
      }
      {
        del && 
        <DeleteConfirmMedia open={true} media={del} onClose={setDel} onRefresh={() => onLibraryRefresh()}/>
      }
      {
        deletelibrary && 
        <DeleteConfirmLibrary open={true} media={deletelibrary} onClose={setDeleteLibrary} onRefresh={() => onNavigateAway()}/>

      }
      {
        mediaadd && 
        <MediaDialogCreate open={true} lid={library.id} onClose={() => onMediaAdd(false)} onRefresh={() => onLibraryRefresh()}/>
      }
      {
        error && 
        <AlertDialog title="Error" description={error} handleClose={(e) => setError(false)} />
      }
      {
        view &&
        <VideoRegister data={view} onClose={()=>setView(false)}/>
      }
    </div>
  );
}
