
import * as React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid'

import { Box } from '@mui/system'
import ResponsiveAppBar from '../elements/appBar'
import { Autocomplete, Card, CardContent, Grid } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { get } from '../../libraries/network';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getDateFormatString, getNavigatorLanguage } from '../../libraries/format';


const events = [
  { title: 'Meeting', start: new Date() }
]



function EventDialog( props ) {
    const {customers, event, setClose} = props;

    console.log("Dialog customers....", customers);
    const [sdate, setSDate] = React.useState( event?.start );
    const [edate, setEDate] = React.useState( event?.end );
  
    console.log("locale", getDateFormatString(getNavigatorLanguage()))
    const handleClose = () => {
      setClose();
    };

  
    return (
      <div>
            <Dialog open={true} onClose={handleClose}>
            <DialogTitle>Appointment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                To add an appointment, please select the customer, the resource and the associated service. 
                </DialogContentText>
                <Grid container spacing={2} sx={{pt:2}} alignItems="center">
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="customers"
                            options={customers}
                            renderInput={(params) => <TextField {...params} label="Select customer" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="resource"
                            options={customers}
                            renderInput={(params) => <TextField {...params} label="Resource" />}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <Autocomplete
                            disablePortal
                            id="service"
                            options={customers}
                            renderInput={(params) => <TextField {...params} label="Service" />}
                        />
                    </Grid>
                    <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                            renderInput={(props) => <TextField {...props} />}
                            label="Start"
                            value={sdate}
                            onChange={(newValue) => {
                            setSDate(newValue);
                            }}
                            ampm={false} // This line sets the 24-hour format

                        />
                    </LocalizationProvider>

                    </Grid>
                    <Grid item xs={2}> <div style={{textAlign: 'center'}}>- to -</div> </Grid>
                    <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                            renderInput={(props) => <TextField {...props} />}
                            label="End"
                            value={edate}
                            onChange={(newValue) => {
                            setEDate(newValue);
                            }}
                            ampm={false} // This line sets the 24-hour format

                        />
                    </LocalizationProvider>
                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose} variant="outlined">Book now</Button>
            </DialogActions>
            </Dialog>
    
      </div>
    );
  }




export function Appointments() {

  const [events, setEvents] = React.useState([]);
  const [event, setEvent] = React.useState( false );
  const [customers, setCustomers] = React.useState( [] );
  const [error, setError] = React.useState( false );
  const calendarRef = React.createRef()


  const addCustomers = ( cs ) => {
    const d = cs?.map( c => {
        return {
            id: c.id,
            label: `${c.firstname} ${c.lastname} (${c.email})`
        }
    })

    console.log("customer list", d );
    setCustomers( d );
  } 

  React.useEffect(() => {
    async function load(){
        try{
            const data = await get( '/backenddata/ajax-get-customers');
            addCustomers( data );
        }
        catch(e)
        {
            if(window?.static_override)
            {
                console.log( window?.static_customers )
                addCustomers( window?.static_customers );
            }

        }
    }
    load();

    calendarRef?.current?.calendar?.scrollToTime("21:00:00");
}, [])

  React.useEffect(() => {
   // if(calendarRef?.current)
   //
    console.log();
  }, [])

  const handleSelect = (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Enter, event name wilbble");
    if (eventNamePrompt) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: uuidv4(),
        },
      ]);
    }
  };




  return (
    <div className="rvNS">
        <Box>
            <ResponsiveAppBar title={"Appointments"}  />                
        </Box>
        <Box sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <FullCalendar
                                    locale={getNavigatorLanguage()}
                                    ref={calendarRef}
                                    headerToolbar={{
                                        start: "today prev next",
                                        center: 'title',
                                        end: "dayGridMonth dayGridWeek dayGridDay",
                                    }}
                                    editable
                                    selectable
                                    dateClick={(info) => {
                                        // alert('clicked ' + info.dateStr);
                                       // dialog.dialog("open");
                                       setEvent(info);
                                       info.jsEvent.preventDefault();
                                    }}
                                    select={function (info) {
                                        info.jsEvent.preventDefault();
                                        setEvent(info);
                                        alert('selected ' + info.startStr + ' to ' + info.endStr);
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView='timeGridWeek'
                                    weekends={true}
                                    allDaySlot={false}
                                    nowIndicator={true}
                                    scrollTime="20:00:00"
                                    events={events}
                                    eventContent={renderEventContent}
                                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        {
            event && <EventDialog  customers={customers} event={event} setClose={() => setEvent(false)} />
        }
    </div>

  )
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}