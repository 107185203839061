import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Alert, AlertTitle, Box, Checkbox, FormHelperText, Grid, Typography } from '@mui/material';
import { post, put } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import { getDateFormatString, getNavigatorLanguage } from '../../libraries/format';

export default function PassManagerDialog(props) {
  const { handleClose, reload, setError, pass } = props;
  const [record, setRecord] = React.useState(pass);
  const [progress, setProgress] = React.useState(0);
  const [nullexpires, setNullExpires] = React.useState(false);
  const [nullstarts, setNullStarts] = React.useState(false);
  const [advancedOnly, setAdvancedOnly] = React.useState(false);


  const onChange = (key, value) => {
    console.log("onChange", key, value);
    const r = { ...record };
    if (key === 'expiration_starts') {
      setNullStarts(false);
      setNullExpires(false);
    }
    if (key === 'expires_date') {
      if (!value) {
        if (parseInt(record?.expiration_starts) === 1) {
          setAdvancedOnly(true);
        }
      } else {
        setAdvancedOnly(false);
      }
    }
    r[key] = value;
    setRecord(r);
  }

  const handleExpiresChange = (e) => {
    setNullExpires(e.target.checked);
  }

  const handleStartChange = (e) => {
    setNullStarts(e.target.checked);
  }

  const onSave = async (e) => {
    e.preventDefault();

    let chain = '';
    chain += 'id=' + record?.id;
    chain += '&credits=' + record?.allocated;
    chain += '&starts=' + (new Date(record?.start_date)).toISOString().replace('T', '');
    chain += '&expires=' + (new Date(record?.expires_date)).toISOString().replace('T', '');
    chain += '&expiration_starts=' + record?.expiration_starts;
    if (!record?.start_date) {
      chain += '&nullstarts=' + true;
      setNullStarts(true);
    } else {
      chain += '&nullstarts=' + nullstarts;
    }

    if (!record?.expires_date) {
      chain += '&nullexpires=' + true;
      setNullExpires(true);
    } else {
      chain += '&nullexpires=' + nullexpires;
    }

    try {
      setProgress(1);
      await put('/customersrestapi/pass', chain);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
        handleClose();
      }, 2000);
    } catch (e) {
      await reload();
      setTimeout(() => {
        console.error("Failed to update pass", e);
        setProgress(0);
        setError("Failed to update pass");
      }, 2000);
    }
  }

  console.log("record", record);
  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
          <DialogTitle>Edit Pass</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To update this pass, please complete the following form:
            </DialogContentText>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <FormControl component="fieldset" sx={{ pl: 0 }} required>
                    <FormLabel id="subcription-starts-label">Pass start mode</FormLabel>
                    <RadioGroup aria-labelledby="subcription-starts-label" name="subcription-type" value={record?.expiration_starts} onChange={(e) => onChange('expiration_starts', e.target.value)}>
                      <FormControlLabel key="onpurchase" value='0' control={<Radio />} label="Standard - time of pass purchase" disabled={advancedOnly} />
                      <FormControlLabel key="onfirstdatepurchase" value='1' control={<Radio />} label="Advanced - time of first event" />
                    </RadioGroup>
                  </FormControl>
                  <FormHelperText id="subcription-type-helper-text">By default, a pass expiration is calculated from the date of purchase. Alternatively, a pass can start from the date of the first event purchased.</FormHelperText>
                  <Box sx={{ pt: 2 }}>
                    <Alert severity="warning">
                      <AlertTitle>
                        Note - <b>Advanced</b> passes will revert to <b>Standard</b> in the following situations:
                      </AlertTitle>
                      <ol>
                        <li>The pass is purchased via a subscription</li>
                        <li>The pass is <b>not</b> purchased via version 4+ schedule.</li>
                        <li>The first booking using the pass is a media file.</li>
                      </ol>
                    </Alert>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat={getDateFormatString(getNavigatorLanguage())}
                      renderInput={(props) => <TextField {...props} fullWidth />}
                      label="Start"
                      disabled={parseInt(record?.expiration_starts) !== 1 || !!nullstarts}
                      value={record?.start_date}
                      onChange={(newValue) => {
                        onChange('start_date', newValue)
                      }}
                      ampm={false} // This line sets the 24-hour format

                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel control={<Checkbox checked={nullstarts} onChange={handleStartChange} />} label="Reset Start" disabled={parseInt(record?.expiration_starts) !== 1} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat={getDateFormatString(getNavigatorLanguage())}
                      renderInput={(props) => <TextField required={parseInt(record?.expiration_starts, 10) === 0} {...props} fullWidth />}
                      label="Expires"
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      value={record?.expires_date}
                      onChange={(newValue) => {
                        onChange('expires_date', newValue)
                      }}
                      disabled={nullexpires}
                      key={'expires'}
                      ampm={false} // This line sets the 24-hour format

                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel control={<Checkbox checked={nullexpires} onChange={handleExpiresChange} />} label="Reset Expires" disabled={parseInt(record?.expiration_starts) !== 1} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="credits"
                    label="Credits"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    value={record?.allocated}
                    onChange={(e) => {
                      onChange('allocated', e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant='outlined' color="primary">Save Pass</Button>
          </DialogActions>
        </form>
      </Dialog>
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }
    </div>
  );
}
