import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

const CancelSubscriptionDialog = ({ open, onClose, onCancel }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        Are you sure you want to cancel this subscription?
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Yes, Cancel</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
