import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);



export default function EventsCalendar( props ) {
  const { events, reload, initiate} = props;
  const [event, setEvent] = React.useState( false );


  const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

const handleSelectEvent = () => {
  
}


  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
            <Box sx={{pt: 1}}>
              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                onSelectEvent={handleSelectEvent}
                style={{ height: "100vh" }}
              />
            </Box>      
        </Grid>

      </Grid>

    </React.Fragment>


  );
}