import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, Card, CardContent, Divider } from '@mui/material';
import ResponsiveAppBar from '../elements/appBar';
import { post } from '../../libraries/network';
import AlertDialog from '../elements/alertDialog';
import { ProgressDialog } from '../elements/progressDialog';
import { BottomBar } from '../elements/BottomBar';
import { useNavigate } from 'react-router-dom';

const CategoryAdd = () => {
    const [categoryName, setCategoryName] = useState('');
    const [error, setError] = useState('');
    const [busy, setBusy] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressText, setProgressText] = React.useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (categoryName) {
            try {
                const p = `category=${encodeURIComponent( categoryName )}`;
                const response = await post('/categoryrestapi/categories', p);
                if (response.status === 'OK') {
                    navigate('/category');
                } else {
                    setError('Failed to add category');
                }
            } catch (err) {
                if(window?.static_override)
                {
                    navigate('/category');
                }
                else
                {
                    setError('Network Error. Please try again.');
                }
            }
        } else {
            setError('Category name is required');
        }
    };

    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar 
                    widgets={[<Box sx={{p: 2}}><Button variant="outlined" color="primary" onClick={() => navigate('/categories')}>Back to Categories</Button></Box>]}
                />
            </Box>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div" sx={{ pt: 1, pb: 1 }}>
                                    Add Category
                                </Typography>
                                <Divider />
                                <Box sx={{ pt: 3, pb: 3 }}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Category Name"
                                                    value={categoryName}
                                                    onChange={(e) => setCategoryName(e.target.value)}
                                                    required
                                                    fullWidth
                                                    margin="normal"
                                                    variant="standard"
                                                />
                                            </Grid>
                                            {error && (
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="error">{error}</Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            {error && <AlertDialog title="Error" description={error} handleClose={() => setError('')} />}
            {progress > 0 && <ProgressDialog progress={progress} title="Please wait" text={progressText} />}
            <BottomBar />
        </div>
    );
};

export default CategoryAdd;
