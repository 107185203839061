import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ManageButton } from './ManageButton';
import SearchIcon from '@mui/icons-material/Search';
import getSymbolFromCurrency from 'currency-symbol-map'
import { DeleteButton } from './DeleteButton';
import { RefundButton } from './RefundButton';
import AlertDialog from './alertDialog';
import { BuyPass } from './BuyPass';
import PassManagerDialog from './PassManagerDialog';
import ConfirmDialog from './confirmDialog';
import { del } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import CustomerPassesMobile from './CustomerPassesMobile';




function createData( id, bought, name, expiration_starts, starts, expires, credits, allocated, used, paid, currency, state, active, refundable) {


    let x = '-';
    let z = '-';
    let xs = '-';
  
    if(!!expires)
    {
      const w = new Date(expires);
      x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      z = w.toLocaleDateString();
      xs = w.toLocaleDateString([], {year: '2-digit', month:'2-digit', day: 'numeric'});
  
    }


    let sd = '-';
    let st = '-';

    if(!!starts)
    {
      const s = new Date(starts);
      st = s.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      sd = s.toLocaleDateString();
    }



    const p = new Date(bought);
    const c = p.toLocaleDateString();
    const r =  { id, purchased: c, name, expiration_starts, start_date: starts,  starts:`${sd} ${st}`, expires: `${z} ${x}`,  expires_short: `${xs}`, expires_date: expires, credits, allocated, used, paid, currency, state, active, refundable};
    console.log("returning pass", r);
    return r;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomersTablePasses( props ) {
  const {passes, allpasses, reload, initiate} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [manage, setManage] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [refund, setRefund] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const access = window?.configuration?.access;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() =>{
    const frow = passes.filter( c => {

      if(!!!filtertext)
      {
        return true;
      }
      if( c.name.indexOf(filtertext) > -1 || c.purchased.indexOf(filtertext) > -1  || c.expires.indexOf(filtertext) > -1)
      {
        return true;
      }


      return false;
    }).sort((a, b) => {
      const dateA = new Date(a.purchased);
      const dateB = new Date(b.purchased);
      return dateB - dateA; // Sort in descending order (newest first)
    });

    setRows(frow?.map( row => createData(row.id, row.purchased, row.name, row.expiration_starts, row.starts, row.expires, row.credits, row.allocated, row.used, row.paid, row.currency, row.state, row.active, row.refundable)));
  }, [filtertext, passes])


  const convertCurrency = ( c ) => {
    const currency = getSymbolFromCurrency(c);
    console.log(currency);
    if(!currency)
    {
       return c;
    }
    return currency;
 }


 const handleCancelComplete = async( ) => {
    try{
      setCancel(false);
      setProgress(1);
      await del(`/customersrestapi/pass/${encodeURIComponent(access)}/cancel/${cancel?.id}`);
      setProgress(50);
      await reload();
      setProgress(100);
      setTimeout(()=> {
        setProgress(0);
      }, 500);
    }
    catch(e)
    {

      setTimeout(()=> {
        setProgress(0);
        setError("Failed to cancel pass");
      }, 1000);
    }
 }


 const handleRefundComplete = async( ) => {
  try{
    setRefund(false);
    setProgress(1);
    await del(`/customersrestapi/pass/${encodeURIComponent(access)}/refund/${refund?.id}`);
    setProgress(50);
    await reload();
    setProgress(100);
    setTimeout(()=> {
      setProgress(0);
    }, 500);
  }
  catch(e)
  {

    setTimeout(()=> {
      setProgress(0);
      setError("Failed to refund pass");
    }, 1000);
  }
}


  console.log("Rendering CustomersTableBookings", passes);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">

                <Box sx={{pt: 1}}>
                  <BuyPass initiate={initiate} passes={allpasses} access={access} reload={reload} />
                </Box>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>TX ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Purchased on</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Pass</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Starts</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Expires</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Credits Allocated</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Credits Used</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Credits Remaining</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Price</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>State</TableCell>
                      {
                        !isMobile &&
                        <React.Fragment>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Cancel</TableCell>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Refund</TableCell>
                        </React.Fragment>
                      }
                      {
                        isMobile &&
                        <React.Fragment>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                        </React.Fragment>
                      }                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                
                     
                        <TableRow
                          key={`customers-${row.id}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "1px solid  rgba(224, 224, 224, 1);" } }}
                          >
                        
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${parseInt(row.id).toString(16)}`} 
                              </TableCell>
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {row.purchased} 
                              </TableCell>
        
                          
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {row.name}
                                <Box  sx={{ display: { xs: 'block', sm: 'none' }}}>
                                Exp: {row.expires_short}
                                </Box>
                              </TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{`${row.starts}`}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{`${row.expires}`}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.allocated}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.used}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{`${parseInt(row.allocated) - parseInt(row.used)}`}</TableCell>
                              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${convertCurrency(row.currency)}${parseFloat(row.paid).toFixed(2)}`}
                              </TableCell>   
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.state}</TableCell>
                              {
                                !isMobile &&
                                <React.Fragment>
                                  <TableCell align="left"><ManageButton callback={(e)=>{setManage(e)}} disabled={!row.active} data={row}/></TableCell>
                                  <TableCell align="left"><DeleteButton callback={(e)=>{setCancel(e)}}  disabled={!row.active} data={row}/></TableCell>
                                  <TableCell align="left"  ><RefundButton callback={(e)=>{setRefund(e)}}  disabled={!row.refundable} data={row}/></TableCell>
                                </React.Fragment>
                              }
                              {
                                isMobile &&
                                <React.Fragment>
                                <TableCell align="left">
                                  <CustomerPassesMobile 
                                    editActive={row.active}
                                    cancelActive={row.active}
                                    refundActive={row.refundable}
                                    onEdit={setManage}
                                    onCancel={setCancel}
                                    onRefund={setRefund}
                                    context={row}
                                  />
                                </TableCell>
                                </React.Fragment>
                              }                              
                            
                          </TableRow>
                
               
                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={12} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no passes associated to this this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={3} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no passes associated to this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={12}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
      {
        manage && <PassManagerDialog pass={manage} handleClose={()=> setManage(false)} reload={reload} setError={(e)=>setError(e)}/>
      }
      {
        cancel && <ConfirmDialog title="Cancel Pass" description="Are you sure that you want to cancel this pass. This cannot be undone." handleClose={()=>{setCancel(false)}} handleComplete={handleCancelComplete} />
      }
      {
        refund && <ConfirmDialog title="Refund Pass" description="Are you sure that you want to refund this pass. This cannot be undone." handleClose={()=>{setRefund(false)}} handleComplete={handleRefundComplete} />
      }      
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }

    </React.Fragment>


  );
}