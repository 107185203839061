
import * as React from 'react';
import preval      from 'preval.macro';
import { Box } from '@mui/material';
const buildTimestamp = preval`module.exports = new Date().getTime();`;


export function BottomBar( props ){  

    return (
        <Box className="footer" component="footer">
            <div>Version: reserVie Unified UI RUUI-{buildTimestamp.toString(16)}</div>
        </Box>
    );
}
