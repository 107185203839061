
export const get = async(url) => {

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "GET",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
          })
          .then(function(response){
              console.log(response)
              if(response.ok)
              {
                return response.json();
              }
              else
              {
                throw response?.statusText || response?.status || 'reservie API generic error';
              }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
            }).catch((err) => {
                reject(err);
          }); 
    })
}


export const del = async(url) => {

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "DELETE",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
          })
          .then(function(response){
              console.log(response)
              if(response.ok)
              {
                return response.json();
              }
              else
              {
                throw response?.statusText || response?.status || 'reservie API generic error';
              }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
            }).catch((err) => {
                reject(err);
          }); 
    })
}

export const post = async(url, data) => {

    // escape the object for potential issues

    let u = window.configuration.k + '=' + window.configuration.v;

    if(!!data)
    {
        u = window.configuration.k + '=' + window.configuration.v + '&' + data;
    }

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "POST",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
             body: u
          })
          .then(function(response){
            if(response.ok)
            {
              return response.json();
            }
            else
            {
              throw response?.statusText || response?.status || 'reservie API generic error';
            }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
    
            }).catch((err) => {
                console.log(err);
                reject(err);
          }); 
    });   
}

export const put = async(url, data) => {

    // escape the object for potential issues

    let u = window.configuration.k + '=' + window.configuration.v;

    if(!!data)
    {
        u = window.configuration.k + '=' + window.configuration.v + '&' + data;
    }

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "PUT",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
             body: u
          })
          .then(function(response){
              console.log(response)

              if(response.ok)
              {
                return response.json();
              }
              else
              {
                throw response?.statusText || response?.status || 'reservie API generic error';
              }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
    
            }).catch((err) => {
                console.log(err);
                reject(err);
          }); 
    });   
}

export const patch = async(url, data) => {

    // escape the object for potential issues

    let u = window.configuration.k + '=' + window.configuration.v;

    if(!!data)
    {
        u = window.configuration.k + '=' + window.configuration.v + '&' + data;
    }

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "PATCH",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
             body: u
          })
          .then(function(response){
              console.log(response)

              if(response.ok)
              {
                return response.json();
              }
              else
              {
                throw response?.statusText || response?.status || 'reservie API generic error';
              }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
    
            }).catch((err) => {
                console.log(err);
                reject(err);
          }); 
    });   
}


export const postform = async(url, formData) => {

    // escape the object for potential issues


    formData.append(window.configuration.k, window.configuration.v);


    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "POST",
            headers : { 
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
             body: formData
          })
          .then(function(response){
              console.log(response)
              if(response.ok)
              {
                return response.json();
              }
              else
              {
                throw response?.statusText || response?.status || 'reservie API generic error';
              }
          })
          .then(function(json) {
                console.log(json);
                if(json?.status)
                {
                    if(json?.status !== 'OK')
                    {
                        throw json.message || 'Reservie API response error'
                    }
                }
                resolve(json);
            }).catch((err) => {
                console.log(err);
                reject(err);
          }); 
    });   
}
