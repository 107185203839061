import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomersTableSubscriptions from './CustomersTableSubscriptions';
import CustomersTableSubscriptionInvoices from './CustomersTableSubscriptionInvoices';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`customers-tabpanel-${index}`}
      aria-labelledby={`customers-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `customers-tab-${index}`,
    'aria-controls': `customers-tabpanel-${index}`,
  };
}

export function CustomersSubscriptionsPanel( props ) {
  const {subscriptions, plans, reload} = props;
  const [invoices, setInvoices] = React.useState([])
  const [value, setValue] = React.useState(0);

  React.useEffect(()=> {

    if(subscriptions)
    {
      const invoice_sets = subscriptions?.map( s => {
        return s.invoices?.map( i => {
          const o = Object.assign({}, i);
          o.subscription_id = s.id;
          o.subscription_name = s.name;
          o.subscription_price = s.price;
          o.currency = s.currency;
          return o;
        });
      });

      setInvoices( invoice_sets?.flat() || []);
    }

  }, [subscriptions])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Customers">
          <Tab label="Mandates" {...a11yProps(0)} />
          <Tab label="Invoices" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CustomersTableSubscriptions plans={plans} reload={reload}    subscriptions={subscriptions} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomersTableSubscriptionInvoices invoices={invoices} reload={reload} />
      </TabPanel>

    </Box>
  );
}