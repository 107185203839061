import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';

const CustomerPassesMobile = ({ editActive, cancelActive, refundActive, onEdit, onCancel, onRefund, context }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option, callback) => {
    if (option) {
      callback(context);
    }
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-controls="options-menu" aria-haspopup="true">
        <MoreVertIcon />
      </IconButton>
      <Popover
        id="options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled={!editActive} onClick={() => handleOptionClick(editActive, onEdit)}>
          Edit
        </MenuItem>
        <MenuItem disabled={!cancelActive} onClick={() => handleOptionClick(cancelActive, onCancel)}>
          Cancel
        </MenuItem>
        <MenuItem disabled={!refundActive} onClick={() => handleOptionClick(refundActive, onRefund)}>
          Refund
        </MenuItem>
      </Popover>
    </>
  );
};

export default CustomerPassesMobile;
