import * as React from 'react';
import { toast } from "react-toastify";
import TimezoneSelect from 'react-timezone-select'
import ResponsiveAppBar from '../elements/appBar';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';




const time = [
    { id: "null", t: "Select" },
    { id: "0", t: "0:00" },
    { id: "1", t: "1:00" },
    { id: "2", t: "2:00" },
    { id: "3", t: "3:00" },
    { id: "4", t: "4:00" },
    { id: "5", t: "5:00" },
    { id: "6", t: "6:00" },
    { id: "7", t: "7:00" },
    { id: "8", t: "8:00" },
    { id: "9", t: "9:00" },
    { id: "10", t: "10:00" },
    { id: "11", t: "11:00" },
    { id: "12", t: "12:00" },
    { id: "13", t: "13:00" },
    { id: "14", t: "14:00" },
    { id: "15", t: "15:00" },
    { id: "16", t: "16:00" },
    { id: "17", t: "17:00" },
    { id: "18", t: "18:00" },
    { id: "19", t: "19:00" },
    { id: "20", t: "20:00" },
    { id: "21", t: "21:00" },
    { id: "22", t: "22:00" },
    { id: "23", t: "23:00" },
];

export default function Appointment() {
    const [selectedTimezone, setSelectedTimezone] = React.useState({})
    const [schedule, setSchedule] = React.useState([
        { day: "Sun", startTime: "", endTime: "" },
        { day: "Mon", startTime: "", endTime: "" },
        { day: "Tue", startTime: "", endTime: "" },
        { day: "Wed", startTime: "", endTime: "" },
        { day: "Thu", startTime: "", endTime: "" },
        { day: "Fri", startTime: "", endTime: "" },
        { day: "Sat", startTime: "", endTime: "" },
    ]);
    //👇🏻 This updates the schedule array with the start and end time.
    const handleTimeChange = (e, id) => {
        const { name, value } = e.target;
        if (value === "Select") return;
        const list = [...schedule];
        list[id][name] = value;
        setSchedule(list);
    };
    //👇🏻 Logs the user's schedule to the console after setting the availability
    const handleSaveSchedules = () => {
        if (JSON.stringify(selectedTimezone) !== "{}") {
            console.log(schedule);
        } else {
            toast.error("Select your timezone");
        }
    };
    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar/>                
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                Select facilitator availability
                                </Typography>
                                <Divider/>
                                <p>Pick timezone</p>
                                <TimezoneSelect
                                    value={selectedTimezone}
                                    onChange={setSelectedTimezone}
                                />
                                <Grid container spacing={2}>
                                    {
                                    schedule.map((sch, id) => (
                                    <Grid item xs={12} md={12}>
                                        <Typography gutterBottom variant="h6" component="div" sx={{pt: 2, pb:2}}>
                                        {sch.day}
                                        </Typography> 
                                    </Grid>))
                                    }

                                </Grid>
                                                    {schedule.map((sch, id) => (
                        <div className='form' key={id}>
                            <p>{sch.day}</p>
                            <div className='select__wrapper'>
                                <label htmlFor='startTime'>Start Time</label>
                                <select
                                    name='startTime'
                                    id='startTime'
                                    onChange={(e) => handleTimeChange(e, id)}
                                >
                                    {time.map((t) => (
                                        <option key={t.id} value={t.t} id={t.id}>
                                            {t.t}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='select__wrapper'>
                                <label htmlFor='endTime'>End Time</label>
                                <select
                                    name='endTime'
                                    id='endTime'
                                    onChange={(e) => handleTimeChange(e, id)}
                                >
                                    {time.map((t) => (
                                        <option key={t.id} value={t.t} id={t.id}>
                                            {t.t}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    ))}                                
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

             <main className='dashboard__main'>

                <h2 className='dashboard__heading'>Select facilitator availability</h2>
                <div className='timezone__wrapper'>
                    <p>Pick timezone</p>
                    <TimezoneSelect
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                    />

                    {schedule.map((sch, id) => (
                        <div className='form' key={id}>
                            <p>{sch.day}</p>
                            <div className='select__wrapper'>
                                <label htmlFor='startTime'>Start Time</label>
                                <select
                                    name='startTime'
                                    id='startTime'
                                    onChange={(e) => handleTimeChange(e, id)}
                                >
                                    {time.map((t) => (
                                        <option key={t.id} value={t.t} id={t.id}>
                                            {t.t}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='select__wrapper'>
                                <label htmlFor='endTime'>End Time</label>
                                <select
                                    name='endTime'
                                    id='endTime'
                                    onChange={(e) => handleTimeChange(e, id)}
                                >
                                    {time.map((t) => (
                                        <option key={t.id} value={t.t} id={t.id}>
                                            {t.t}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='saveBtn__container'>
                    <button onClick={handleSaveSchedules}>Save Appointment</button>
                </div>
            </main>
        </div>
    );
};