import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Box, IconButton, TableFooter, TablePagination, Typography } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

function createData(name, when, sold, of, eid, did) {
    const w = new Date(when);
    const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const z = w.toLocaleDateString();

    return { name, when: `${z} ${x}`, sold, of, eid, did};
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function EventsTable( props ) {
  const {events} = props;
  const rows = events?.map( r => createData(r.name, r.date, r.sold, r.stock, r.eid, r.did));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  console.log("rows", rows);
  return (
    <TableContainer component={Paper} variant='outlined'>
      <Table aria-label="events table">
        <TableHead>
          <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>When</TableCell>
              <TableCell align="left" >Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows && (rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows).map((row) => (
            <TableRow
              key={`events-${row.eid}-${row.did}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Link href={`/events/info-redirect/${row.eid}/${row.did}`}>{row.name}</Link>
                <Box  sx={{ display: { xs: 'table-cell', sm: 'none' }}}>{row.when}</Box>
              </TableCell>
              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.when}</TableCell>
              <TableCell align="left">{`${row.sold} of ${row.of}`}</TableCell>
            </TableRow>
          ))}
          {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
              <TableRow>
                  <TableCell colSpan={3} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                  <Box sx={{p: 10}}>
                      <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no events data.</Typography>
                  </Box>                                                    
                  </TableCell>
                  <TableCell colSpan={2} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                  <Box sx={{p: 10}}>
                      <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no events data.</Typography>
                  </Box>                                                    
                  </TableCell>
              </TableRow>            
          }
        </TableBody>
        <TableFooter>
          <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={!!rows ? rows.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={2}
              count={!!rows ? rows.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Rows"
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>          
        </TableFooter>        
      </Table>
    </TableContainer>
  );
}