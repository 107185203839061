import {  Button, Card, CardContent, Divider, FormHelperText, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import {  postform } from '../../libraries/network';
import { BottomBar } from '../elements/BottomBar';
import { MuiTelInput } from 'mui-tel-input'
import HttpsIcon from '@mui/icons-material/Https';
import { FileUploader } from '../elements/FileUploader';
import AlertDialog from '../elements/alertDialog';
import { ProgressDialog } from '../elements/progressDialog';

export function SettingsCompany()
{
    const [busy, setBusy] = React.useState(false);
    const [info, setInfo] = React.useState(window.company_settings || false);
    const [emailerror, setEmailError] = React.useState(false);
    const [websiteerror, setWebsiteError] = React.useState(false);
    const [termserror, setTermsError] = React.useState(false);
    const [uraerror, setURAError] = React.useState(false);
    const [file, setFile] = React.useState(false);
    const [preview, setPreview] = React.useState(false);
    const [size, setSize] = React.useState(0);
    const [error,setError] = React.useState(false);
    const [progress,setProgress] = React.useState(0);
    const [progressText,setProgressText] = React.useState('');
    const [disable,setDisable] = React.useState(false);

    React.useEffect(() => {

        if(emailerror || websiteerror || termserror || uraerror)
        {
            setDisable(true);
        }
        else
        {
            setDisable(false);
        }
    }, [emailerror, websiteerror, termserror, uraerror])

    const onFileChange = ( f ) => {

        const url = URL.createObjectURL(f);

        const img = new Image();
        img.src = url;
        img.onload = (e) => {
        // img.width
        // img.height
        console.log(img)
        setPreview({url: url, width: img.width, height: img.height, rwidth: img.width > 300?300:img.width , rheight: img.width > 300 ? 'auto':img.height, size: f.size});
        setFile( f );
        };

    }

    const onChange = ( key, value ) => {
        const i = Object.assign({}, info);
        i[key] = value;
        setInfo(i);
    }

    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
          return navigator.languages[0];
        } else {
          return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
    }

    const getCountry = () => {
        const country = getNavigatorLanguage().split('-');
        if(country.length > 1)
        {
            return country[1];
        }
        return 'GB';
    }

    const onValidateAddress = ( input ) => {
        /* Validate input is a valid web URL */
        let givenURL;
        try {
            givenURL = new URL(input);
            //console.log("checking hostname",givenURL?.hostname)
            const res = /[a-zA-Z]$/.test(givenURL.hostname);
            //console.log('test', res, givenURL.hostname);
            if(!res)
            {
                console.log("invalid end to hostname",givenURL?.origin)
                return false;
            }
            console.log(givenURL);
        } catch (error) {
            console.log("error is",error)
          return false;  
        }
        return givenURL.protocol === "http:" || givenURL.protocol === "https:";
      
    }

    const onUpdateCompanyWebsite = ( v ) => {
        if(!onValidateAddress(v))
        {
            setWebsiteError( true );
        }
        else
        {
            setWebsiteError( false );
        }
        onChange('website', v)

    }

    const onUpdateCompanyTerms = ( v ) => {
        if(!onValidateAddress(v))
        {
            setTermsError( true );
        }
        else
        {
            setTermsError( false );
        }
        onChange('tos', v)

    }

    const onURAChange = ( input ) => {
        var valid = /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$/i.test(input);
        
        setURAError( !valid );
        if (input === 'go' || input === 'beta' || input === 'dev2' || input === 'dev') 
        {
            setURAError( true );
        }
        onChange('ura', input)
    }
    

    const onUpdateCompanyEmail = ( v ) => {
        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        var emailRegexMatch = emailRegex.exec(v);
        if (!!emailRegexMatch) {
            setEmailError(false)
        }
        else
        {
            console.error('Failed to validate email', v);
            setEmailError(true)
        }
        onChange( 'email', v );        
    
    }

    const onSave = async (e) => {
        const i = Object.assign({}, info);
        delete i.logo;
        e.preventDefault();
        setProgressText('');
        setBusy(true);
        try
        {
          if(file)
          {
            setProgress(10);
            let reader = new FileReader() 
            reader.readAsDataURL(file)
            reader.onload = async () => {  

                try{
                    const formData = new FormData()
                    formData.append('userfile', file, file.name);
                    formData.append('filename', file.name);
                    formData.append('filesize', file.size);
                    formData.append('filetype', file.type);
                    for(var k in i)
                    {
                        formData.append(k, info[k]);
                    }


                    setProgress(20);
                   // const o = { record: i, logo: {name: file.name, size: file.size, type: file.type}};
                    const data = await postform('/settings/ajax-save-customer-information', formData);
                    if(!!data?.OK)
                    {
                        setProgress(100);
                        setProgressText('Company record saved');
                        setTimeout(()=>{
                            setProgress(0);
                            setBusy(false);
    
                        }, 3000);   
                    }
                    else
                    {
                        setError(`The company records have not been fully updated. Reason: ${data?.reason}`);
                        //setProgress(100);
                        //setProgressText('Company record saved');
                        setProgress(0); 
                        setBusy(false);
    
                    }
                 
                }
                catch(e)
                {
                    setError('Sorry, we have failed to update your company record. Please try again.');
                    //setProgress(100);
                    //setProgressText('Company record saved');
                    setProgress(0); 
                    setBusy(false);
                   
                }

            }

          }
          else
          {
            const formData = new FormData()
            for(var k in i)
            {
                formData.append(k, info[k]);
            }
            setProgress(20);
           // const o = { record: i, logo: {name: file.name, size: file.size, type: file.type}};
            const data = await postform('/settings/ajax-save-customer-information', formData);
            if(!!data?.OK)
            {
                setProgress(100);
                setProgressText('Company record saved');
                setTimeout(()=>{
                    setProgress(0);
                    setBusy(false);
                }, 3000);
            }
            else
            {
                setError(`The company records have not been fully updated. Reason: ${data?.reason}`);
                //setProgress(100);
                //setProgressText('Company record saved');
                setProgress(0); 
                setBusy(false);

            }

            
        }
        }
        catch(e)
        {
            setError('Sorry, we have failed to update your company record. Please try again.');
            //setProgress(100);
            //setProgressText('Company record saved');
            setProgress(0);
            setBusy(false);


        }

    }

    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar/>                
            </Box>
                <Box sx={{p: 2}}>
                    <form onSubmit={onSave}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div" sx={{pt: 1, pb:1}}>
                                            Basic Company Information <span className='small'></span>
                                        </Typography>
                                        <Divider/>
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField name="name" variant='standard'  disabled={busy} value={info?.name} onChange={(e) => onChange('name',e.target.value)} id="name" label="Company name"  sx={{maxWidth: '1200px'}} fullWidth={true} autoFocus={true} helperText="This is your company name. If you do not have a company, enter your name here. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'"   InputProps={{inputProps:{required:true, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField name="address1" variant='standard'  disabled={busy} value={info?.address1} onChange={(e) => onChange('address1', e.target.value)} id="address1" label="Address"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="This is your company address.  This field is optional. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'"   InputProps={{inputProps:{required:false, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid>      
                                                <Grid item xs={12}> 
                                                    <TextField name="address2" variant='standard' disabled={busy} value={info?.address2} onChange={(e) => onChange('address2', e.target.value)} id="address2"   sx={{maxWidth: '800px'}} fullWidth={true}   InputProps={{inputProps:{required:false, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <TextField name="town" variant='standard'  disabled={busy} value={info?.town} onChange={(e) => onChange('town', e.target.value)} id="town" label="Town/City"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'"   InputProps={{inputProps:{required:false, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid>  
                                                <Grid item xs={12}>
                                                    <TextField name="postcode" variant='standard'  disabled={busy} value={info?.postcode} onChange={(e) => onChange('postcode', e.target.value)} id="postcode" label="Post or Zip code"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="Allowed characters A-Z, a-z, 0-9, '-', ' '"   InputProps={{inputProps:{required:false, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <TextField name="country" variant='standard'  disabled={busy} value={info?.country} onChange={(e) => onChange('country', e.target.value)} id="country" label="Country"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="Allowed characters A-Z, a-z, 0-9, '-', ' '"   InputProps={{inputProps:{required:false, pattern:"[A-Za-z0-9. ._\\(\\)\\-]{1,250}"}}} />
                                                </Grid>    
                                                <Grid item xs={12}>
                                                    <TextField type="email" name="email" variant='standard'  disabled={busy} value={info?.email} error={emailerror} onChange={(e) => onUpdateCompanyEmail( e.target.value )} id="email" label="Your company email"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="We use this address for notifications. Allowed characters A-Z, a-z, 0-9, '-', '_', '@', '.'"   InputProps={{inputProps:{required:true}}} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField name="website" type="url"  disabled={busy} error={websiteerror} variant='standard' value={info?.website} onChange={(e) => onUpdateCompanyWebsite( e.target.value )} id="website" label="Your website address"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="We recommend setting this address to where your schedule is located. Allowed characters A-Z, a-z, 0-9, '-', ':', '/'"  placeholder="https://www...." InputProps={{inputProps:{required:true}}} />
                                                </Grid>                                            
                                                <Grid item xs={12}>
                                                    <TextField name="terms" type="url"  disabled={busy} error={termserror} variant='standard' value={info?.tos} onChange={(e) => onUpdateCompanyTerms( e.target.value )} id="terms" label="Terns and conditions"  sx={{maxWidth: '800px'}} fullWidth={true} helperText="This is where we send your customer when they ask to see your terms of sale. Allowed characters A-Z, a-z, 0-9, '-', ':', '/'"  placeholder="https://www...." InputProps={{inputProps:{required:true}}} />
                                                </Grid>                                                

                                                <Grid item xs={12}>
                                                <MuiTelInput 
                                                        value={info?.mobile.replace(/^0/,'')} 
                                                        disabled={busy}
                                                        required
                                                        variant='standard'
                                                        helperText={"Enter mobile number"}
                                                        onChange={(e) =>{
                                                            console.log(e);
                                                            let regex =/^\+[0-9]{0,3}\s[1-9]$|^\+[0-9]{0,3}\s[1-9][0-9\s]{1,11}$|^\+[0-9]{0,3}\s$|^\+[0-9]{0,3}$/;
                                                            let v = e.match(regex);
                                                            console.log("is valid", v);
                                                            if(v)
                                                            {
                                                            onChange('mobile', e);
                                                            }
                                                        } }
                                                        defaultCountry={getCountry()}
                                                        sx={{maxWidth: '400px'}}
                                                        fullWidth />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="input-unique-reservie-address"
                                                        label="Unique reserVie address"
                                                        disabled={busy}
                                                        fullWidth
                                                        sx={{maxWidth:'600px'}}
                                                        InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                            <HttpsIcon />https://
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                .reservie.net
                                                            </InputAdornment>
                                                        ),                                                        
                                                        }}
                                                        error={uraerror}
                                                        onChange={(e) => onURAChange(e.target.value)}
                                                        value={info?.ura}
                                                        variant="standard"
                                                        helperText="This is your unique reservie address that your customers use to access your services"
                                                        />                                                        
                                                </Grid>

                                                {
                                                    info?.logo &&
                                                    <React.Fragment>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
                                                            Current logo
                                                            </Typography> 
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {
                                                                !!info?.logo &&
                                                                <img src={`${info?.logo}`} alt="logo.png" />

                                                            }
                                                            {
                                                                !!!info?.logo &&
                                                                <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                                                                No logo                                                                </Typography> 
    
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
                                                                New Logo
                                                            </Typography> 
                                                        </Grid>
                                                        <Grid item md={12} xs={12} sx={{pt:2, pb: 2}}>
                                                            {
                                                                !file &&
                                                                <FileUploader  setFile={onFileChange} max={10 * 1024*1024} width={500} height={500}/>
                                                            }
                                                            {
                                                                !!preview &&
                                                                <React.Fragment>
                                                                    <Stack>
                                                                        <img src={preview.url} style={{width:preview.rwidth, height: preview.rheight}} />
                                                                    </Stack>

                                                                </React.Fragment>
                                                            }                                                    
                                                            <Box sx={{pt: 2}}>
                                                                <FormHelperText>The image must be less the 1MB in size and no more than 300 x 300 pixels.</FormHelperText>
                                                            </Box>  

                                                        </Grid> 
                                                    </React.Fragment>
                                                }
                                                {
                                                    !!!info?.logo &&
                                                    <React.Fragment>                                   
                                                        <Grid item xs={12}>
                                                            <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
                                                                Add Logo
                                                            </Typography> 
                                                        </Grid>
                                                        <Grid item md={12} xs={12} sx={{pt:2, pb: 2}}>
                                                            {
                                                                !file &&
                                                                <FileUploader  setFile={onFileChange} max={10 * 1024*1024} width={500} height={500}/>
                                                            }
                                                            {
                                                                !!preview &&
                                                                <React.Fragment>
                                                                    <Stack>
                                                                        <img src={preview.url} style={{width:preview.rwidth, height: preview.rheight}} />
                                                                    </Stack>

                                                                </React.Fragment>
                                                            }    
                                                            <Box sx={{pt: 2}}>
                                                                <FormHelperText>The image must be less the 1MB in size and no more than 300 x 300 pixels.</FormHelperText>
                                                            </Box>                                                
                                                        </Grid> 
                                                    </React.Fragment>

                                                }
                                                <Grid item xs={12}>
                                                    <Button type="submit" disabled={!!!info || disable || busy} fullWidth variant="contained">Save</Button>
                                                </Grid>                                                                                            
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>  

                            </Grid> 
                
    
                        </Grid>                         
                    </form>

                </Box>
                {
                    error &&
                    <AlertDialog title="Error" description={error} handleClose={()=>setError(false)} />
                }
                {
                    progress > 0 &&
                    <ProgressDialog progress={progress} title="Please wait" text={progressText} />
                }

                <BottomBar />

        </div>
    )
}