import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import * as React from 'react';


export default function TimezoneSelectorElement(props) {
  const {label, tz, onTZChange} = props;
  const zones = Intl.supportedValuesOf('timeZone');
  const [timezone, setTimeZone] = React.useState(tz);
  const [ptimezone, setPTimeZone] = React.useState(timezone);

  React.useEffect(() => {

    if(timezone !== ptimezone)
    {
      setPTimeZone(timezone);
      onTZChange(timezone);
    }


  }, [timezone])



  return (
    <TextField
    select
    label={label}
    fullWidth
    value={timezone}
    onChange={(e) => setTimeZone(e.target.value)}
    variant="outlined"
    margin="normal"
  >
    {zones.map((m) => (
      <MenuItem key={m} value={m}>
        {m}
      </MenuItem>
    ))}
</TextField>                                        
  );
}