import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { RestoreButton } from './RestoreButton';
import { DeleteButton } from './DeleteButton';
import ConfirmDialog from './confirmDialog';
import { del, patch } from '../../libraries/network';
import { ProgressDialog } from './progressDialog';
import AlertDialog from './alertDialog';

function createData(id, firstname, lastname, email, mobile) {
  console.log("data", { id, firstname, lastname, email, mobile });
  return { id, firstname, lastname, email, mobile };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomersTableArchive(props) {
  const { customers, reload } = props;
  const [rows, setRows] = React.useState(customers?.map(r => createData(r.id, r.firstname, r.lastname, r.email, r.mobile)));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [deluser, setDelUser] = React.useState(false);
  const [restore, setRestore] = React.useState(false);
  const [filtertext, setFilterText] = React.useState('');
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const r = customers.filter(c => {
      if (!!!filtertext) {
        return true;
      }
      if (c.firstname.indexOf(filtertext) > -1) {
        return true;
      }
      if (c.lastname.indexOf(filtertext) > -1) {
        return true;
      }
      const name = `${c.firstname} ${c.lastname}`;
      if (name.indexOf(filtertext) > -1) {
        return true;
      }

      if (c.mobile.indexOf(filtertext) > -1) {
        return true;
      }
      if (c.email.indexOf(filtertext) > -1) {
        return true;
      }

      return false;
    });
    setRows(r);
  }, [filtertext]);

  const handleDeleteComplete = async () => {
    console.log(deluser);

    setDelUser(false);
    try {
      await del(`/customersrestapi/account/${deluser?.id}/remove`);
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
        reload();
      }, 500);
    } catch (e) {
      if (window?.static_override) {
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          reload();
        }, 5000);
      } else {
        setProgress(0);
        setError("Failed to archive customer");
      }
    }
  };

  const handleRestoreComplete = async () => {
    try {
      setRestore(false);
      await patch(`/customersrestapi/account/${restore?.id}`, '');
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
        reload();
      }, 500);
    } catch (e) {
      if (window?.static_override) {
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          reload();
        }, 5000);
      } else {
        setProgress(0);
        setError("Failed to archive customer");
      }
    }
  };

  console.log("rows", rows);
  return (
    <>
      <TableContainer component={Paper} variant='outlined' sx={{ pt: 4 }}>
        <Table aria-label="Active Customers">
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align='left'>
                <Grid container justifyContent="flex-end">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '45ch', pr: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="search"
                      variant="standard"
                      placeholder="Search"
                      value={filtertext}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setFilterText(e.target.value

)}
                    />
                  </Box>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Email</TableCell>
              <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Phone</TableCell>
              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'none' } }}>Contact</TableCell>
              <TableCell align="left">Restore</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              (rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows).map((row) => (
                  <TableRow
                    key={`customers-${row.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {`${row.firstname} ${row.lastname}`}
                    </TableCell>
                    <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}><a href={`mailto:${row.email}`}>{row.email}</a></TableCell>
                    <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}><a href={`tel:${row.mobile}`}>{row.mobile}</a></TableCell>
                    <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'none' } }}>
                      <Box><a href={`mailto:${row.email}`}>{row.email}</a></Box>
                      <Box><a href={`tel:${row.mobile}`}>{row.mobile}</a></Box>
                    </TableCell>
                    <TableCell align="left"><RestoreButton callback={(e) => { setRestore(e) }} data={row} /></TableCell>
                    <TableCell align="left"><DeleteButton callback={(e) => { setDelUser(e) }} data={row} /></TableCell>
                  </TableRow>
                ))}
            {(rowsPerPage === 0 || !!!rows || rows?.length === 0) &&
              <TableRow>
                <TableCell colSpan={4} sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  <Box sx={{ p: 10 }}>
                    <Typography variant="h6" component="p" style={{ textAlign: 'center' }}>You currently have no customers yet.</Typography>
                  </Box>
                </TableCell>
                <TableCell colSpan={2} sx={{ display: { xs: 'table-cell', sm: 'none' } }}>
                  <Box sx={{ p: 10 }}>
                    <Typography variant="h6" component="p" style={{ textAlign: 'center' }}>You currently have no customers yet.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={4}
                count={!!rows ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
            <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={!!rows ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Rows"
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {deluser && <ConfirmDialog title="Delete Customer" description="Are you sure that you want to delete this customer account? WARNING: This cannot be undone." handleClose={() => { setDelUser(false) }} handleComplete={handleDeleteComplete} />}
      {restore && <ConfirmDialog title="Restore Customer" description="Are you sure that you want to restore this customer account?" handleClose={() => { setRestore(false) }} handleComplete={handleRestoreComplete} />}
      {!!error && <AlertDialog title="Problem detected" description={error} handleClose={() => { setError(false) }} />}
      {progress > 0 && <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />}
    </>
  );
}