import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';


export default function DurationPickerElement(props) {
  const {label, duration, onChangeDuration} = props;
  const [value, setValue] = React.useState(duration);
  const [pvalue, setPValue] = React.useState(value);

  React.useEffect(()=> {
    if(value !== pvalue)
    {
      setPValue(value);
      onChangeDuration(value);
    }

  }, [value]);


  return (
    <TextField
    id="time"
    label={label}
    type="time"
    fullWidth={true}
    value={value}
    onChange={(newValue) => {
      console.log("new val", newValue.target.value )
      setValue(newValue.target.value)
    }}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{
      step: 300, // 5 min
    }}
    sx={{ width: 150 }}
  />
  );
}