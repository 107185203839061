import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimePickerElement(props) {
  const {label, time, onTimeChange} = props;
  const [value, setValue] = React.useState( time || dayjs('2018-01-01T00:00:00.000Z'));
  const [pvalue, setPValue] = React.useState(value);
  React.useEffect(() => {
    if(value !== pvalue)
    {
        setPValue(value);
        onTimeChange( value );
    }
  }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label}
          value={value}
          onChange={setValue}
          ampm={false}
          renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
  );
}