import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';


export default function FacilitatorPickerElement(props) {
  const {label, facilitator, facilitators, onFacilitatorsChange, helper} = props;
  const [value, setValue] = React.useState( facilitator );
  const [pvalue, setPValue] = React.useState(value);
  React.useEffect(() => {
    if(value !== pvalue)
    {
        setPValue(value);
        onFacilitatorsChange( value );
    }
  }, [value]);
  return (
      <FormControl fullWidth>
        <InputLabel id="facilitator-select-label">{label}</InputLabel>
        <Select
          labelId="facilitator-select-label"
          id="facilitator-select"
          value={value}
          label={label}
          onChange={setValue}
        >
          <MenuItem value={-1} key={`facilitator-none`}>{`Not assigned`}</MenuItem>
          {
              facilitators?.map( f => {
              <MenuItem value={f.id} key={`facilitator-${f.id}`}>{`${f.firstname} ${f.lastname}`}</MenuItem>
            })
          }
        </Select>
        <FormHelperText>{helper}</FormHelperText> 
      </FormControl>
  );
}