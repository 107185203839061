import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Link, Box, Grid, Card, CardContent, Divider } from '@mui/material';
import ResponsiveAppBar from '../elements/appBar';
import { del, get } from '../../libraries/network';
import AlertDialog from '../elements/alertDialog';
import { ProgressDialog } from '../elements/progressDialog';
import { BottomBar } from '../elements/BottomBar';
import { useNavigate } from 'react-router-dom';
import CategoryAction from '../elements/CategoryAction';
import ConfirmDialog from '../elements/confirmDialog';

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState('');
    const [busy, setBusy] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressText, setProgressText] = React.useState('');
    const navigate = useNavigate();
    const [deleteobj, setDeleteObj] = useState(false);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await get('/api/categories');
                if (response.status === 'OK') {
                    setCategories(response?.data);
                }
            } catch (error) {
                if (window?.static_override) {
                    setCategories(window?.static_categories);
                } else {
                    setMessage("Network Error. We are currently unable to fetch your categories. Please try again.");
                }
            }
        };

        fetchCategories();
    }, []);

    const handleDelete = async (id) => {
        try{
            const result = await del(`/categoryrestapi/categories/${id}`);
            if(result?.status === 'OK') {
                setCategories(categories.filter(category => category.id !== id));
            } else {
                setMessage('Error deleting category');
            }
        }
        catch(e){
            setMessage('Error deleting category');
        }

    };

    const handleAddCategory = () => {
        navigate('/category/add');
    };

    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar 
                    widgets={[<Box sx={{p: 2}}><Button variant="outlined" color="primary" onClick={handleAddCategory}>Create Category</Button></Box>]}
                />
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div" sx={{pt: 1, pb:1}}>
                                    Categories <span className='small'>set up categories that are used in combination with events and schedules to display only specific events</span>
                                </Typography>
                                <Divider/>
                                <Box sx={{pt: 3, pb: 3}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell align="right">Manage</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {categories.map((category) => (
                                                        <TableRow key={category.id}>
                                                            <TableCell>{category.name}</TableCell>
                                                            <TableCell align="right">
                                                                <CategoryAction categoryId={category.id} onDelete={() => setDeleteObj(category)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>  
                    </Grid> 
                </Grid>
            </Box>
            {message && <AlertDialog title="Error" description={message} handleClose={() => setMessage('')} />}
            {progress > 0 && <ProgressDialog progress={progress} title="Please wait" text={progressText} />}
            {deleteobj && <ConfirmDialog title="Delete category" description="Are you sure that you want to delete this category? WARNING: This cannot be undone." handleClose={() => { setDeleteObj(false) }} handleComplete={(category) => handleDelete(deleteobj?.id)} />}

            <BottomBar />
        </div>
    );
};

export default CategoryList;
