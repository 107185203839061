import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, Stack, TableFooter, TablePagination, TextField, Typography, useMediaQuery, useTheme  } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TransferButton } from './TransferButton';
import SearchIcon from '@mui/icons-material/Search';
import { BookEvent } from './BookEvent';
import getSymbolFromCurrency from 'currency-symbol-map'
import { DeleteButton } from './DeleteButton';
import { RefundButton } from './RefundButton';
import TransferDialog from './TransferDialog';
import AlertDialog from './alertDialog';
import ConfirmDialog from './confirmDialog';
import { ProgressDialog } from './progressDialog';
import { del, post } from '../../libraries/network';
import CustomerBookingMobile from './CustomerBookingMobile';


function createData(txid, eid, bought, name, dates, paid, currency, type) {
  let date_list = dates.map((d) => {
    const w = new Date(d.date);
    const x = w.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const z = w.toLocaleDateString();

    let o = {
      eid: d.eid,
      did: d.did,
      txid: txid,
      stxid: d.stxid,
      date: `${z} ${x}`,
      what: d.name,
      paid: d.paid,
      state: d.state,
      active: d.active,
      refundable: d.refundable,
    };
    return o;
  });

  const options = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  };

  const p = new Date(bought);
  const c = p.toLocaleDateString();
  const s = p.toLocaleDateString(undefined, options); // Ensure options are passed here
  const r = { txid, purchased: c, purchased_short: s, name, dates: date_list, paid, currency, type };
  return r;
}


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const access = window?.configuration?.access;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomersTableBookings( props ) {
  const {bookings, events, passes, reload, initiate} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [transfer, setTransfer] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [refund, setRefund] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const access = window?.configuration?.access;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() =>{
    const frow = bookings.filter( c => {
      if(!!!filtertext)
      {
        return true;
      }
      if(!!c?.dates?.find(d => d?.name?.toLowerCase().indexOf(filtertext)  > -1))
      {
        return true;
      }


      return false;
    });
    setRows(frow?.map( r => createData(r.txid, r.eid, r.purchased, r.name, r.dates, r.paid, r.currency, r.type)));
  }, [filtertext, bookings])


  const convertCurrency = ( c ) => {
    const currency = getSymbolFromCurrency(c);
    console.log(currency);
    if(!currency)
    {
       return c;
    }
    return currency;
 }

 const handleCancelComplete = async( ) => {
  try{
    setCancel(false);
    setProgress(1);
  
    await del(`/customersrestapi/bookings/${window?.configuration?.access}/cancel/${cancel?.stxid}`);
    setProgress(50);
    await reload();
    setProgress(100);
    setTimeout(()=> {
      setProgress(0);
    }, 500);
  }
  catch(e)
  {

    setTimeout(()=> {
      setProgress(0);
      setError("Failed to cancel booking");
    }, 1000);
  }
}


const handleRefundComplete = async( ) => {
try{
  setRefund(false);
  setProgress(1);

  await del(`/customersrestapi/bookings/${window?.configuration?.access}/refund/${refund?.stxid}`);
  setProgress(50);
  await reload();
  setProgress(100);
  setTimeout(()=> {
    setProgress(0);
  }, 500);
}
catch(e)
{

  setTimeout(()=> {
    setProgress(0);
    setError("Failed to refund booking");
  }, 1000);
}
}

const filterExpiredRows = (data) => {
  const now = new Date();
  const filteredData = data.filter(obj => {
    if(!obj?.expires)
    {
      return true;
    }
    const expirationDate = new Date(obj.expires);
    return expirationDate >= now;
  });
  return filteredData;
}


  console.log("Rendering CustomersTableBookings", bookings);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">

                <Box sx={{pt: 1}}>
                  <BookEvent initiate={initiate} access={access} events={events} passes={filterExpiredRows(passes)} reload={reload} />
                </Box>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>TX ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'none' }}}>TX</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Booked on</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>What</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>When</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Price</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>State</TableCell>
                      {
                        !isMobile &&
                        <React.Fragment>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Transfer</TableCell>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Cancel</TableCell>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Refund</TableCell>
                        </React.Fragment>
                      }
                      {
                        isMobile &&
                        <React.Fragment>
                          <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                        </React.Fragment>
                      }                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                      <React.Fragment key={`customer-bookings-${row.id}`}>
                        {
                          row.dates.map( (r, i) => (
                            <TableRow
                            key={`customers-${r.txid}-${r.stxid}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "1px solid  rgba(224, 224, 224, 1);" } }}
                            >
                              {
                                i === 0 &&
                                <TableCell rowSpan={row.dates.length} align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                  {`${parseInt(row.txid).toString(16)}`} 
                                </TableCell>
                              }
                              {
                                i === 0 &&
                                <TableCell rowSpan={row.dates.length} align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                  {row.purchased} 
                                </TableCell>
          
                              }  
                              {
                                i === 0 &&
                                <TableCell rowSpan={row.dates.length} align="left" sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                                  <Stack sx={{textAlign:"left"}}>
                                    <Typography variant="body">ID:{`${parseInt(row.txid).toString(16)}`}</Typography>
                                    <Typography variant="body">{row.purchased_short}</Typography> 
                                  </Stack>
                                </TableCell>
          
                              } 
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {r.what}
                                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                                {r.date}
                                </Box>
                              </TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{r.date}</TableCell>
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {`${convertCurrency(row.currency)}${parseFloat(r.paid).toFixed(2)}`}
                              </TableCell>   
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{r.state}</TableCell>
                              {
                                  !isMobile &&
                                  <React.Fragment>
                                    <TableCell align="left"><TransferButton callback={(e)=>{setTransfer(e)}} disabled={!r.active} data={r}/></TableCell>
                                    <TableCell align="left"><DeleteButton callback={(e)=>{setCancel(e)}}  disabled={!r.active} data={r}/></TableCell>
                                    <TableCell align="left"  ><RefundButton callback={(e)=>{setRefund(e)}}  disabled={!r.refundable} data={r}/></TableCell>
                                  </React.Fragment>
                              }
                              {
                                isMobile &&
                                <React.Fragment>
                                  <TableCell align="left">
                                  <CustomerBookingMobile
                                        transferActive={r.active}
                                        cancelActive={r.active}
                                        refundActive={r.refundable}
                                        onTransfer={setTransfer}
                                        onCancel={setCancel}
                                        onRefund={setRefund}
                                        context={r}
                                      />

                                  </TableCell>
                                </React.Fragment>

                              }
                            </TableRow>
                          ))
                        }
                      </React.Fragment>

                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={9} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no bookings yet for this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={3} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no bookings yet for this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={9}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={4}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Tx per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
      {
        transfer && <TransferDialog events={events} from={transfer} reload={reload} handleClose={()=> setTransfer(false)} setError={(e)=>setError(e)}/>
      }
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        cancel && <ConfirmDialog title="Cancel Event" description={`Are you sure that you want to cancel this event. This cannot be undone. The event being cancelled is "${cancel?.what} ${(new Date(cancel?.date)).toLocaleString()}"`} handleClose={()=>{setCancel(false)}} handleComplete={handleCancelComplete} />
      }
      {
        refund && <ConfirmDialog title="Refund Event" description={`Are you sure that you want to refund this event. This cannot be undone. The booking being refunded is "${refund?.what} ${(new Date(refund?.date)).toLocaleString()}"`}  handleClose={()=>{setRefund(false)}} handleComplete={handleRefundComplete} />
      }      
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }

    </React.Fragment>


  );
}