import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import startOfDay from "date-fns/startOfDay";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

export default function MultiDatePicker( props ) {
  const {minDate, onDatesChange, dates} = props;
  const [values, setValues] = React.useState(dates);
  const [pvalues, setPValues] = React.useState(dates);
  const [loading, setLoading] = React.useState( false );

  React.useEffect(() => {

    let matching = isDateArrayMatching(values, pvalues);
    if(!matching)
    {
      values.sort((a,b) => {
        return a - b;
      });
      setPValues(values);
      onDatesChange(values);
    }

  }, [values])

  const isDateArrayMatching = ( array1, array2 ) => {
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Compare each date object in the arrays
    for (let i = 0; i < array1.length; i++) {
      if (!(array1[i] instanceof Date && array2[i] instanceof Date)) {
        // Make sure both elements are date objects
        return false;
      }
  
      if (array1[i].getTime() !== array2[i].getTime()) {
        // Compare the time in milliseconds
        return false;
      }
    }
  
    // Arrays are equal
    return true;

  }

  const findDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!values) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={!!selected}
        onClick={() => {
            // this is a bit hacky - but the default onChange does not fire on consecutive clicks of the same date
            const d = startOfDay(date);
            const index = findIndexDate(values, d);
  
            if (index >= 0) {
              let array = [...values];
              array.splice(index, 1);
              setValues(array);
  
            }            
    
        }}
      />
    );
  };

  return (
 

    <LocalizationProvider  dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={values}
        minDate={minDate}
        onChange={(newValue) => {
          //copying the values array
          setLoading(true);

          const array = [...values];
          const date = startOfDay(newValue);
          const index = findIndexDate(array, date);
          console.log()
          if (index >= 0) {
            array.splice(index, 1);
          } else {
            array.push(date);
          }
          setValues(array);
          setLoading(false);
        }}
        onAccept={( ndate ) => {

          setLoading(true);
          const date = startOfDay(ndate);
          const index = findIndexDate(values, date);

          console.log(ndate, date, index);
          if (index >= 0) {
            let array = [...values];
            array.splice(index, 1);
            setValues(array);
          }
          setLoading(false);


        }}
        loading={loading}
        renderDay={renderPickerDay}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="'Week of' MMM d"
      />
    </LocalizationProvider>

  );
}