import {   Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { BottomBar } from '../elements/BottomBar';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';



  

export function SupportThankyou()
{


    return (
        <div className="rvNS">
            <Box>
                <Grid container spacing={2} sx={{p: 2}}>
                    <Grid item xs={12} md={12}>
                        <ResponsiveAppBar 
                        widgets={[]}
                        />     
                    </Grid>
                </Grid>           
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent sx={{minHeight: '400px', textAlign:'center', p: 4}}>
                                <Typography gutterBottom variant="h3" component="div" sx={{pt: 6, pb:1}}>
                                    Ticket received
                                </Typography>

                                <Typography gutterBottom variant="p" component="div" sx={{pt: 1, pb:1}}>
                                    Thank you for reaching out to us. We will review your ticket shortly. All ticket updates will be provided via email.

                                </Typography>
                                <Box sx={{p:4}}>
                                    <ThumbUpOffAltIcon style={{ fontSize: "100px" }} />
                                </Box>
                                <Typography gutterBottom variant="p" component="small" sx={{pt: 1, pb:1}}>
                                Unless urgent, tickets are answered during standard office hours 9:00 - 16:00 GMT/BST Monday to Friday.
                                </Typography>
                            </CardContent>
                        </Card>  

                    </Grid> 
            

                </Grid> 
            </Box>

                <BottomBar />
        </div>
    )
}