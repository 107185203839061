import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import {
  FormatBold,
  FormatUnderlined,
  FormatItalic,
  Person,
  PersonOutline,
  AddShoppingCart,
  Link,
  MeetingRoom,
  Lock,
  Business,
  Event,
  Language,
  Description,
  AccountBox,
  EventSeat,
  EventNote,
  EventAvailable,
} from '@mui/icons-material';

const RichTextEditor = ( props ) => {
  const {enabledButtons, html, onHTMLChange} = props;
  const htmlRef = useRef(null);
  const [isBold, setIsBold] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [fontSize, setFontSize] = useState('14px'); // Default font size

  const handleFormatClick = (format) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const formattedElement = document.createElement('span');

    if (format === 'bold') {
      let node = range.commonAncestorContainer;
      let wrapElement = null;
      while (node) {
        if (node.nodeName === 'SPAN' && node.style.fontWeight === 'bold') {
          wrapElement = node;
          break;
        }
        node = node.parentNode;
      }
      if (wrapElement) {
        wrapElement.outerHTML = wrapElement.innerHTML;
        setIsBold(false);
        return;
      }
      formattedElement.style.fontWeight = 'bold';
      setIsBold(true);
      range.surroundContents(formattedElement);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (format === 'underline') {
      let node = range.commonAncestorContainer;
      let wrapElement = null;
      while (node) {
        if (node.nodeName === 'SPAN' && node.style.textDecoration === 'underline') {
          wrapElement = node;
          break;
        }
        node = node.parentNode;
      }
      if (wrapElement) {
        wrapElement.outerHTML = wrapElement.innerHTML;
        setIsUnderline(false);
        return;
      }
      formattedElement.style.textDecoration = 'underline';
      setIsUnderline(true);
      range.surroundContents(formattedElement);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (format === 'italic') {
      let node = range.commonAncestorContainer;
      let wrapElement = null;
      while (node) {
        if (node.nodeName === 'SPAN' && node.style.fontStyle === 'italic') {
          wrapElement = node;
          break;
        }
        node = node.parentNode;
      }
      if (wrapElement) {
        wrapElement.outerHTML = wrapElement.innerHTML;
        setIsItalic(false);
        return;
      }
      formattedElement.style.fontStyle = 'italic';
      setIsItalic(true);
      range.surroundContents(formattedElement);
      selection.removeAllRanges();
      selection.addRange(range);

    }
    else if (format === 'firstname') {
      const firstnameText = document.createTextNode('{firstname}');
      range.insertNode(firstnameText);
      range.setStartAfter(firstnameText);
      range.setEndAfter(firstnameText);
      selection.removeAllRanges();
      selection.addRange(range);

    }
    else if (format === 'lastname') {
      const lastnameText = document.createTextNode('{lastname}');
      range.insertNode(lastnameText);
      range.setStartAfter(lastnameText);
      range.setEndAfter(lastnameText);
      selection.removeAllRanges();
      selection.addRange(range);

    }
    else if (format === 'purchase') {
      const purchaseText = document.createTextNode('{purchase}');
      range.insertNode(purchaseText);
      range.setStartAfter(purchaseText);
      range.setEndAfter(purchaseText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'bookingpage') {
      const bpText = document.createTextNode('{purchase-lnk}');
      range.insertNode(bpText);
      range.setStartAfter(bpText);
      range.setEndAfter(bpText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'event') {
      const evText = document.createTextNode('{event}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'customeraccount') {
      const evText = document.createTextNode('{account-link}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'termsandconditions') {
      const evText = document.createTextNode('{toc}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'companywebsite') {
      const evText = document.createTextNode('{url}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'date') {
      const evText = document.createTextNode('{date}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'meetinglink') {
      const evText = document.createTextNode('{meetinginvite}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'meetingidentifier') {
      const evText = document.createTextNode('{meetingid}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'meetingpasscode') {
      const evText = document.createTextNode('{meetingpasscode}');
      range.insertNode(evText);
      range.setStartAfter(evText);
      range.setEndAfter(evText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else if (format === 'meeting-info') {
      insertHtmlFragment(range, '{event}<br/><b>{date}:</b> <br/>Meeting invite: {meetinginvite}; <br/>Meeting ID: {meetingid}; </br>Passcode: {meetingpasscode}<br/> {!event}');
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const findWrappingElement = (node, tagName) => {
    while (node) {
      if (node.nodeName === tagName) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  };
  const handleFontSizeChange = (event) => {
    const selectedFontSize = event.target.value;
    setFontSize(selectedFontSize);

    // Apply the font size to the selected text
    document.execCommand('fontSize', false, '6');
    const fontSizeElements = document.getElementsByTagName('font');
    for (let i = 0; i < fontSizeElements.length; i++) {
      if (fontSizeElements[i].size === '6') {
        fontSizeElements[i].removeAttribute('size');
        fontSizeElements[i].style.fontSize = selectedFontSize;
      }
    }
  };



  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text/plain');

    // Remove unwanted formatting from the pasted text
    const cleanText = pastedText
      .replace(/<[^>]+>/g, '') // Remove all HTML tags
      .replace(/\n/g, '<br>'); // Convert line breaks to <br> tags

    // Insert the cleaned text at the current cursor position
    document.execCommand('insertHTML', false, cleanText);
  };

  const insertHtmlFragment = (range, fragment) => {
    const newElement = document.createElement('div');
    newElement.innerHTML = fragment;
    range.deleteContents();
  
    const fragmentNodes = Array.from(newElement.childNodes);
    fragmentNodes.forEach((node) => {
      range.insertNode(node);
      range.setStartAfter(node);
    });
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      const wrapElement = findWrappingElement(selection.getRangeAt(0).startContainer.parentNode, 'SPAN');
      setIsBold(wrapElement && wrapElement.style.fontWeight === 'bold');
      setIsUnderline(wrapElement && wrapElement.style.textDecoration === 'underline');
      setIsItalic(wrapElement && wrapElement.style.fontStyle === 'italic');
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    htmlRef.current.innerHTML = html;
  }, [html]);


  return (
    <Box sx={{ minWidth: '100%', margin: '0 auto' }}>
      <div
        contentEditable={false}
        style={{
          border: '1px solid #ccc',
          minHeight: '100px',
          marginBottom: '16px',
          width: '100%',
        }}>
        <div style={{ display: 'inline-block', padding: '8px' }} contentEditable={false}>
          <div id="wrap" contentEditable={true} 
            style={{
            display: 'block',
            minHeight: '100px',
            marginBottom: '16px',
            minWidth: '300px',
            outline: '0px solid transparent'
          }}
          ref={htmlRef}          
          onPaste={handlePaste}
          onInput={onHTMLChange}
          ></div>
        </div>
      </div>
      <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>

           <Select
              value={fontSize}
              onChange={handleFontSizeChange}
              style={{ marginRight: '8px' }}
            >
              <MenuItem value="10px">10px</MenuItem>
              <MenuItem value="11px">11px</MenuItem>
              <MenuItem value="12px">12px</MenuItem>
              <MenuItem value="13px">13px</MenuItem>
              <MenuItem value="14px">14px</MenuItem>
              <MenuItem value="15px">15px</MenuItem>
              <MenuItem value="16px">16px</MenuItem>
              <MenuItem value="17px">17px</MenuItem>
              <MenuItem value="18px">18px</MenuItem>
              <MenuItem value="19px">19px</MenuItem>
              <MenuItem value="20px">20px</MenuItem>
            </Select>          
            <Tooltip title="Bold">
              <IconButton
                onClick={() => handleFormatClick('bold')}
                color={isBold ? 'primary' : 'default'}
                aria-label="Bold"
                disabled={!enabledButtons.bold}
              >
                <FormatBold />
              </IconButton>
            </Tooltip>
            <Tooltip title="Underline">
              <IconButton
                onClick={() => handleFormatClick('underline')}
                color={isUnderline ? 'primary' : 'default'}
                aria-label="Underline"
                disabled={!enabledButtons.underline}
              >
                <FormatUnderlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
              <IconButton
                onClick={() => handleFormatClick('italic')}
                color={isItalic ? 'primary' : 'default'}
                aria-label="Italic"
                disabled={!enabledButtons.italic}
              >
                <FormatItalic />
              </IconButton>
            </Tooltip>
            <Tooltip title="First Name">
              <IconButton
                onClick={() => handleFormatClick('firstname')}
                aria-label="First Name"
                disabled={!enabledButtons.firstname}
              >
                <Person />
              </IconButton>
            </Tooltip>
            <Tooltip title="Last Name">
              <IconButton
                onClick={() => handleFormatClick('lastname')}
                aria-label="Last Name"
                disabled={!enabledButtons.lastname}
              >
                <PersonOutline />
              </IconButton>
            </Tooltip>
            <Tooltip title="Purchase">
              <IconButton
                onClick={() => handleFormatClick('purchase')}
                aria-label="Purchase"
                disabled={!enabledButtons.purchase}
              >
                <AddShoppingCart />
              </IconButton>
            </Tooltip>
            <Tooltip title="Meeting Link">
              <IconButton
                onClick={() => handleFormatClick('meetinglink')}
                aria-label="Meeting Link"
                disabled={!enabledButtons.meetinglink}
              >
                <Link />
              </IconButton>
            </Tooltip>
            <Tooltip title="Meeting Identifier">
              <IconButton
                onClick={() => handleFormatClick('meetingidentifier')}
                aria-label="Meeting Identifier"
                disabled={!enabledButtons.meetingidentifier}
              >
                <MeetingRoom />
              </IconButton>
            </Tooltip>
            <Tooltip title="Meeting Passcode">
              <IconButton
                onClick={() => handleFormatClick('meetingpasscode')}
                aria-label="Meeting Passcode"
                disabled={!enabledButtons.meetingpasscode}
              >
                <Lock />
              </IconButton>
            </Tooltip>
            <Tooltip title="Company">
              <IconButton
                onClick={() => handleFormatClick('company')}
                aria-label="Company"
                disabled={!enabledButtons.company}
              >
                <Business />
              </IconButton>
            </Tooltip>
            <Tooltip title="Date">
              <IconButton
                onClick={() => handleFormatClick('date')}
                aria-label="Date"
                disabled={!enabledButtons.date}
              >
                <Event />
              </IconButton>
            </Tooltip>
            <Tooltip title="Company Website">
              <IconButton
                onClick={() => handleFormatClick('companywebsite')}
                aria-label="Company Website"
                disabled={!enabledButtons.companywebsite}
              >
                <Language />
              </IconButton>
            </Tooltip>
            <Tooltip title="Terms and Conditions">
              <IconButton
                onClick={() => handleFormatClick('termsandconditions')}
                aria-label="Terms and Conditions"
                disabled={!enabledButtons.termsandconditions}
              >
                <Description />
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer Account">
              <IconButton
                onClick={() => handleFormatClick('customeraccount')}
                aria-label="Customer Account"
                disabled={!enabledButtons.customeraccount}
              >
                <AccountBox />
              </IconButton>
            </Tooltip>
            <Tooltip title="Booking Page">
              <IconButton
                onClick={() => handleFormatClick('bookingpage')}
                aria-label="Booking Page"
                disabled={!enabledButtons.bookingpage}
              >
                <EventSeat />
              </IconButton>
            </Tooltip>
            <Tooltip title="Event">
              <IconButton
                onClick={() => handleFormatClick('event')}
                aria-label="Event"
                disabled={!enabledButtons.event}
              >
                <EventNote />
              </IconButton>
            </Tooltip>
            <Tooltip title="Meeting Information">
              <IconButton
                onClick={() => handleFormatClick('meeting-info')}
                aria-label="Meeting Information"
                disabled={!enabledButtons['meeting-info']}
              >
                <EventAvailable />
              </IconButton>
            </Tooltip>
          </div>
        </div>     
    </Box>
  );
};

export default RichTextEditor;
