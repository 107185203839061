import React from 'react';

import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio  } from '@mui/material';

const QuestionaireRadio = ({id, text, index, required, options, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div  className="customer_questionnaire_field">
                <FormControl component="fieldset">
                    <FormLabel component="legend">{index}. {text}</FormLabel>
                    <RadioGroup aria-label={`radio-${id}`} name={id} value={answer.answer} onChange={(e) => {onAnswer(id, e.target.value)}}>
                        {
                            options.map( (o, i) => (
                                <FormControlLabel  key={id + '-' + o.id} value={o.id} control={<Radio />} label={o.text} />
                            ))
                        }
                    </RadioGroup>
                </FormControl>                
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireRadio;