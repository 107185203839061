import React from 'react';
import { FormControlLabel,  Checkbox, FormGroup } from '@mui/material';

const QuestionaireCheckbox = ({id, text, index, required, answer, onAnswer }) => {


    return ( 
        <React.Fragment>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox value={id} checked={!!answer.answer} name={id} id={id} required={required}  autoFocus={index===1? true: false } onChange={(e) => {
                        console.log("Selected checkbox", id, answer?.answer)
                        onAnswer(id, !!!answer?.answer)
                    }} />}
                    label={text}
                />
            </FormGroup>
        </React.Fragment>
        

     );
}
 
export default QuestionaireCheckbox;