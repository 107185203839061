import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import NotInterestedIcon from '@mui/icons-material/NotInterested';import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { EventSelector } from '../elements/EventSelector';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';


export function ReportAttendanceAttended()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [events, setEvents] = React.useState([])
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [filtr, setFilter] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);


    const processData = (d) =>{
        var rows = d;
        if(!!filtr?.length)
        {
            rows = d?.filter( r => !!filtr?.find( f => r.eid === f));
        }
        return rows;
    }


    const onDownloadClick  = () =>
    {
        var rows = [];

        for(var i = 0; i < data?.length; i++)
        {
            rows.push(['Event ID', data[i]?.eid])    
            rows.push(['Event Name', data[i]?.ename])    
            rows.push(['Date ID', data[i]?.did])    
            rows.push(['Date', (new Date(data[i]?.edate)).toLocaleDateString()]);   
            rows.push(['Attendees']);   
            rows.push(['']);   
            rows.push(['ID, Name, Email, Mobile, Attended']);   
            for(var j = 0; j < data[i]?.attendees.length; j++)
            {
                const a = data[i]?.attendees[j];
                if(!!a)
                    rows.push([`${a?.id}, ${a?.firstname} ${a?.lastname}, ${a?.email}, ${a?.mobile}, ${a?.attended.toString()}`]);
            }
            rows.push(['']);   
            rows.push(['']);   
        }

        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "attendance report " + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    React.useEffect(() => {
        // load the events for this range
        async function load(){
            try{
                let z = {};

                const d = Object.keys(range).map(function(key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(range[key].getTime());
                }).join('&');
                setProgress(10);
  
                const data = await get( `/reportsrestapi/events-in-date-range?${d}`);
                setEvents( data );
                setTimeout(() => {
                    setProgress(0);
                }, 100)

            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    console.log( window?.static_reports );
                    setEvents( window?.static_reports.events );
                    setTimeout(() => {
                        setProgress(0);
                    }, 2000)
                }
                else
                {
                    setProgress(0);
                    setError("Failed to get the category list");

                }
    
            }
        }
        load();
    }, [range]);


    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);

        async function load() {
            try{

                let z = {};

                for (var property in range) {
                    if (range.hasOwnProperty(property)) {
                        console.log(range);
                        z[property] = range[property].getTime()
                    }
                }
                const d = new URLSearchParams(z).toString();
                const data = await get( `/reportsrestapi/attendance-between-dates?${d}`);
                setProgress(10);
                setData( processData(data) );
                setProgress(100);
                setTimeout(() => {
                    setBusy(false);
                    setProgress(0);
                }, 500);
            }
            catch(e)
            {
                if(window?.static_override)
                {
                    setProgress(100);
                    setTimeout(() => {
                        setBusy(false);
                        setProgress(0);
                        setData( processData(window?.static_reports.report_attendance_data));
                    }, 2000)
                }
                else
                {
                    setError('Failed to load report');
                    setProgress(0);

                }   
                
                // to do - present error 

            }
        }
        load();

    }


    return (
        <div className="rvNS">
            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more events</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EventSelector setEvents={setFilter} list={events} events={filtr}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    { 
                                        !busy && !!data?.length &&
                                        data?.map( d => (
                                            <Box sx={{pt: 3, pb: 3}}>
                                            <Card variant="outlined" sx={{mb: 2}} id={`${d?.eid}-${d?.did}`}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Event ID:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{d?.eid}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Event Name:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${d?.ename}`}</Typography>
                                                        </Grid>   
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Date ID:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${d?.did}`}</Typography>
                                                        </Grid> 
                                                        <Grid item xs={2}>
                                                            <Typography gutterBottom variant="h8" component="div"><b>Event date:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography gutterBottom variant="h8" component="div">{`${(new Date(d?.edate)).toLocaleDateString()}`}</Typography>
                                                        </Grid> 

                                                 
                                                        <Grid item xs={12}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            ID
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Name
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Email
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Mobile
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Attended
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        d?.attendees.length === 0 && 
                                                                        <TableRow>
                                                                            <TableCell colSpan={8}>
                                                                                <Box sx={{p: 10}}>
                                                                                    <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You has no bookings within the specified date range.</Typography>
                                                                                </Box>  
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    {
                                                                        d?.attendees?.map( a => (
                                                                            <TableRow>
                                                                                <TableCell>{a?.id}</TableCell>
                                                                                <TableCell>{a?.firstname} {a?.lastname}</TableCell>
                                                                                <TableCell>{a?.email}</TableCell>
                                                                                <TableCell>{a?.mobile}</TableCell>
                                                                                <TableCell>{a?.attended?.toString()}</TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    }

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        ))
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                
            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}