import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';
import { CustomerSelector } from '../elements/CustomerSelector';
import getSymbolFromCurrency from 'currency-symbol-map';


export function ReportBookingItemised()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [customers, setCustomers] = React.useState([])
    const [customerlist, setCustomerList] = React.useState([])
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [customer, setCustomer] = React.useState(-1);
    const [filtr, setFilter] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const zeroPad = (num, places) => String(parseInt(num).toString(10)).padStart(places, '0')

    const convertCurrency = ( c ) => {
        const currency = getSymbolFromCurrency(c);
        console.log(currency);
        if(!currency)
        {
           return c;
        }
        return currency;
     }

     const processData = (d) => {
        var rows = d ||[];
        
        if(!!filtr?.length)
        {
            rows = d?.filter( r => !!filtr.find( f => f === r?.id));
        }
        return rows;
    }

    const onDownloadClick  = () =>
    {

        var rows = [
            ["ID", "Name", "Transaction ID", 'Date of transaction', 'Event', 'Event ID', 'Event Date', 'Currency', 'Gross', 'Deductions', 'Net', 'Facilitator'],
        ].concat(data.map( r => [`${r?.id},${r.firstname} ${r.lastname},${r.txid.toString(16)}-${r.stxid.toString(16)},${(new Date(r.txdate)).toLocaleDateString()}, ${r.ename}, ${r.eid},${(new Date(r.edate)).toLocaleDateString()},${convertCurrency(r.currency)}, ${parseFloat(r.stx_gross).toFixed(2)},${parseFloat(r.stx_deductions).toFixed(2)},${parseFloat(r.stx_net).toFixed(2)},${r?.facilitators?.join('; ')}`] ));
 

        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event report itemised " + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    React.useEffect(() => {
        // load the events for this range
        async function load(){
            try{
  
                setProgress(10);
  
                const r = await get( `/reportsrestapi/customers`);
                setCustomers( r );
                setTimeout(() => {
                    setProgress(0);

                }, 100)

            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    setCustomers( window?.static_customers.customers );
                    setTimeout(() => {
                        setProgress(0);
                    }, 2000)
                }
                else
                {
                    setProgress(0);
                    setError("Failed to get the customer list");

                }
    
            }
        }
        load();
    }, [range]);


    React.useEffect(() =>{
        if((customer >= 0) && (customer < customerlist.length))
        {

            var percentile = ((customer+1)/customerlist.length)*100;
            console.log("percentile",percentile);
            setProgress(percentile);
            loadCustomer( customer )
        }
        else
        {
            if(customer >= customerlist.length)
            {
                setProgress(100);
                setTimeout(()=>{
                    setProgress(0);
                    setCustomer(-1); // reset
                    setBusy(false);
                }, 500);
            }

        }

    }, [customer]);

    const loadCustomer = async( i ) => {
        try{

            let z = {};

            for (var property in range) {
                if (range.hasOwnProperty(property)) {
                    console.log(range);
                    z[property] = range[property].getTime()
                }
            }
            const i = customer;
            const d = new URLSearchParams(z).toString();
            if(!!filtr && filtr?.length > 0)
            {
                const r = await get( `/reportsrestapi/booking-itemised/${customerlist[i].id}?${d}`);
                
                if(!!r)
                {
                    const s = JSON.parse(JSON.stringify(data));
                    setData( s.concat(r) );
                }
                setTimeout(() => {
                    setCustomer(i+1);
                }, 150)
            }
            else
            {
                const c = customerlist?.filter((f, i) => i >= customer && i < (customer+100))?.map( c => c.id);
                const r = await get( `/reportsrestapi/booking-itemised/batch?${d}&c=${c.join(',')}`);
                
                if(!!r)
                {
                    const s = JSON.parse(JSON.stringify(data));
                    setData( s.concat(r) );
                }
                setTimeout(() => {
                    setCustomer(i+100);
                }, 150)
            }


        }
        catch(e)
        {
            if(window?.static_override)
            {
                console.log( window?.static_reports );
                setTimeout(() => {
                    setBusy(false);
                    const s = JSON.parse(JSON.stringify(data));
                    const r = window?.static_reports.report_booking_itemised.customers[customer];
                    s.push(r)
                    setData( s );
                    setCustomer(i+1)
                }, 2000)
            }
            else
            {
                setError('Failed to load report');
                setProgress(0);

            }   
            
            // to do - present error 

        }
    }

    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);
        var customerset = customers
        if(filtr.length > 0)
        {
            customerset = customers.filter( c => !!filtr.find( f => f === c.id))
        }
        if(customerset.length > 0)
        {
            setCustomerList( customerset );
            setCustomer(0);
        }
        setData( [] );

   
    }

    return (
        <div className="rvNS">
            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more customers</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomerSelector setCustomers={setFilter} list={customers} customers={filtr}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }

                                    {
                                        !busy && data.length > 0 &&   
                                        <Card variant="outlined" sx={{mb: 2}}>
                                            <CardContent>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Transaction ID</TableCell>
                                                            <TableCell>Transaction date</TableCell>
                                                            <TableCell>Event</TableCell>
                                                            <TableCell>Event ID</TableCell>
                                                            <TableCell>Event date</TableCell>
                                                            <TableCell>Gross</TableCell>
                                                            <TableCell>Deductions</TableCell>
                                                            <TableCell>Net</TableCell>
                                                            <TableCell>Facilitator</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            data?.map(c => (
                                                                <TableRow key={c.id}>
                                                                    <TableCell>{c.id}</TableCell>
                                                                    <TableCell>{`${c.firstname} ${c.lastname}`}</TableCell>
                                                                    <TableCell>{`${c?.txid.toString(16)}-${c?.stxid.toString(16)}`}</TableCell>
                                                                    <TableCell>{`${(new Date(c?.txdate)).toLocaleDateString()}`}</TableCell>
                                                                    <TableCell>{`${c?.ename}`}</TableCell>
                                                                    <TableCell>{`${c?.eid}`}</TableCell>
                                                                    <TableCell>{`${(new Date(c?.edate)).toLocaleDateString()}`}</TableCell>
                                                                    <TableCell>{`${convertCurrency(c?.currency) + parseFloat(c?.stx_gross).toFixed(2)}`}</TableCell>
                                                                    <TableCell>{`${convertCurrency(c?.currency) + parseFloat(c?.stx_deductions).toFixed(2)}`}</TableCell>
                                                                    <TableCell>{`${convertCurrency(c?.currency) + parseFloat(c?.stx_net).toFixed(2)}`}</TableCell>
                                                                    <TableCell>{`${c?.facilitators?.join(', ')}`}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    }


    
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                
            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}