import React from 'react';

import { FormControl, Checkbox, FormControlLabel, Box, Typography  } from '@mui/material';



const QuestionaireTextBlockConfirm = ({id, text, confirm, index, required, answer, onAnswer }) => {
    const jsonUnescape = (str) =>{
        const x =   str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
        return x;
    }
    return ( 
        <React.Fragment>
            <div>
                <Box pt={2} pb={2} >
                    <Typography variant="body1"> <span dangerouslySetInnerHTML={{ __html: jsonUnescape(text) }} /></Typography>
                </Box>                

                <FormControl component="fieldset" key={id}>
                    <FormControlLabel
                        control={<Checkbox checked={!!answer.answer} name={id} id={id} required={required} value={id}  autoFocus={id===1? true: false } onClick={(e) => {onAnswer(id, !answer.answer)}}/>}
                        label={jsonUnescape(confirm)}
                    />
                </FormControl>                                               
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireTextBlockConfirm;