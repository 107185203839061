import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/routing/Dashboard';
import SignIn from './components/routing/Signin';
import Reset from './components/routing/Reset';
import './index.scss';
import Oops from './components/routing/404';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { blue } from '@mui/material/colors';
import Appointment from './components/routing/Appointment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Appointments } from './components/routing/Appointments';
import { Reports } from './components/routing/Reports';
import { ReportBookingOverview } from './components/routing/ReportBookingOverview';
import { ReportSalesByCategory } from './components/routing/ReportSalesByCategory';
import { Passes } from './components/routing/Passes';
import { SettingsCompany } from './components/routing/SettingsCompany';
import VideoManager from './components/routing/VideoManager';
import VideoLibrary from './components/routing/VideoLibrary';
import { SupportIndex } from './components/routing/SupportIndex';
import { SupportThankyou } from './components/routing/SupportThankyou';
import Customers from './components/routing/Customers';
import CustomerManager from './components/routing/CustomerManager';
import React from 'react';
import NotificationEdit from './components/routing/NotificationsEdit';
import { ReportBookingStopped } from './components/routing/ReportBookingStopped';
import { ReportBookingDetail } from './components/routing/ReportBookingDetail';
import { ReportBookingItemised } from './components/routing/ReportBookingItemised';
import { ReportPassPurchased } from './components/routing/ReportPassPurchased';
import { ReportAttendanceAttended } from './components/routing/ReportAttendanceAttended';
import { ReportAttendanceNoShow } from './components/routing/ReportAttendanceNoShow';
import { ReportFinancialOverview } from './components/routing/ReportFinancialOverview';
import { ReportWaitingJoinedList } from './components/routing/ReportWaitingJoinedList';
import { ReportFinancialDetail } from './components/routing/ReportFinancialDetail';
import { ReportFinancialPercentile } from './components/routing/ReportFinancialPercentile';
import { ReportFinancialChargeAggregation } from './components/routing/ReportFinancialChargeAggregation';
import { ReportBookingStoppedRange } from './components/routing/ReportBookingStoppedRange';
import { ReportAttendanceAttendedCount } from './components/routing/ReportAttendanceAttendedCount';
import SignInPassword from './components/routing/SigninPassword';
import SigninMFA from './components/routing/SigninMFA';
import { SettingsMFA } from './components/routing/SettingsMFA';
import ResetCode from './components/routing/ResetCode';
import ResetDone from './components/routing/ResetDone';
import ResetPassword from './components/routing/ResetPassword';
import { Events } from './components/routing/Events';
import EventEditor from './components/routing/EventEditor';
import CategoryList from './components/routing/CategoryList';
import CategoryAdd from './components/routing/CategoryAdd';
import CategoryEdit from './components/routing/CategoryEdit';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#fff',
      contrastText: "#fff", //button text white instead of black
    },
    tertiary:{
      main: '#fff',
      contrastText: "#fff", //button text white instead of black
    }

  }
});
function Loading() {
  return <h2>🌀 Loading...</h2>;
}

function App() {
  return (
    <div className="App">
          <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                          <React.Suspense fallback={<Loading />}>
                              <Dashboard />
                          </React.Suspense>
                      } />
                    <Route path="/appointment/setup" element={
                          <React.Suspense fallback={<Loading />}>
                              <Appointment />
                          </React.Suspense>
                    } />
                    <Route path="/appointments" element={
                          <React.Suspense fallback={<Loading />}>
                              <Appointments />
                          </React.Suspense>
                      } />
                    <Route path="/client/index" element={
                        <React.Suspense fallback={<Loading />}>
                          <Dashboard />
                        </React.Suspense>
                    } />
                    <Route path="/client/login" element={
                        <React.Suspense fallback={<Loading />}>
                          <SignIn />
                        </React.Suspense>
                    } />
                    <Route path="/client/authenticate/*" element={
                        <React.Suspense fallback={<Loading />}>
                          <SignInPassword />
                        </React.Suspense>
                    } />
                    <Route path="/client/mfa" element={
                        <React.Suspense fallback={<Loading />}>
                          <SigninMFA />
                        </React.Suspense>
                    } />
                    <Route path="/client/reset" element={
                        <React.Suspense fallback={<Loading />}>
                          <Reset />
                        </React.Suspense>
                    } />
                    <Route path="/client/resetcode" element={
                        <React.Suspense fallback={<Loading />}>
                          <ResetCode />
                        </React.Suspense>
                    } />
                    <Route path="/client/resetpassword" element={
                        <React.Suspense fallback={<Loading />}>
                          <ResetPassword />
                        </React.Suspense>
                    } />
                    <Route path="/client/resetcomplete" element={
                        <React.Suspense fallback={<Loading />}>
                          <ResetDone />
                        </React.Suspense>
                    } />                    
                    <Route path="/settings/mfa" element={
                        <React.Suspense fallback={<Loading />}>
                          <SettingsMFA />
                        </React.Suspense>
                    } />
                    <Route path="/customers" element={
                        <React.Suspense fallback={<Loading />}>
                          <Customers />
                        </React.Suspense>
                    } />
                    <Route path="/customers/manage/*" element={
                        <React.Suspense fallback={<Loading />}>
                          <CustomerManager />
                        </React.Suspense>
                    } />
                    <Route path="/passes" element={
                        <React.Suspense fallback={<Loading />}>
                          <Passes />
                        </React.Suspense>
                    } />
                    <Route path="/schedules" element={
                        <React.Suspense fallback={<Loading />}>
                          <Passes />
                        </React.Suspense>
                    } />
                    <Route path="/reports/" element={
                        <React.Suspense fallback={<Loading />}>
                          <Reports  fallback={<Loading />} />
                        </React.Suspense>
                    } />
                    <Route path="/reports/booking-overview" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportBookingOverview   />
                        </React.Suspense>
                    } />
                    <Route path="/reports/bookings-stopped" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportBookingStopped   />
                        </React.Suspense>
                    } />
                      <Route path="/reports/bookings-stopped-range" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportBookingStoppedRange   />
                        </React.Suspense>
                    } />
                    <Route path="/reports/bookings-detail" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportBookingDetail />
                        </React.Suspense>
                    } /> 
                    <Route path="/reports/bookings-itemised" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportBookingItemised />
                        </React.Suspense>
                    } /> 

                    <Route path="/reports/pass-purchase" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportPassPurchased />
                        </React.Suspense>
                    } /> 

                    <Route path="/reports/attendance-attended" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportAttendanceAttended />
                        </React.Suspense>
                    } /> 

                    <Route path="/reports/attendance-no-show" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportAttendanceNoShow />
                        </React.Suspense>
                    } /> 


                    <Route path="/reports/attendance-count" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportAttendanceAttendedCount />
                        </React.Suspense>
                    } /> 

                    <Route path="/reports/joined-waiting-list" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportWaitingJoinedList />
                        </React.Suspense>
                    } /> 

                    <Route path="/reports/financial-overview" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportFinancialOverview  />
                        </React.Suspense>
                    } />

                    <Route path="/reports/financial-detail" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportFinancialDetail  />
                        </React.Suspense>
                    } />
                    <Route path="/reports/financial-charge-aggregates" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportFinancialChargeAggregation  />
                        </React.Suspense>
                    } />
                    <Route path="/reports/financial-percentile" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportFinancialPercentile  />
                        </React.Suspense>
                    } />
                    <Route path="/reports/report-financial-sales-by-category" element={
                        <React.Suspense fallback={<Loading />}>
                          <ReportSalesByCategory  />
                        </React.Suspense>
                    } />
                    <Route path="/settings/company-information" element={
                        <React.Suspense fallback={<Loading />}>
                          <SettingsCompany  />
                        </React.Suspense>
                    } />
                    <Route path="/video" element={
                        <React.Suspense fallback={<Loading />}>
                          <VideoManager  />
                        </React.Suspense>
                    } />
                    <Route path="/video/library/*" element={
                        <React.Suspense fallback={<Loading />}>
                          <VideoLibrary  />
                        </React.Suspense>
                    } />
                    <Route path="/ticket" element={
                        <React.Suspense fallback={<Loading />}>
                          <SupportIndex  />
                        </React.Suspense>
                    } />
                    <Route path="/ticket/thankyou" element={
                        <React.Suspense fallback={<Loading />}>
                          <SupportThankyou />
                        </React.Suspense>
                    } />
                    <Route path="/communicator/add/register" element={
                        <React.Suspense fallback={<Loading />}>
                          <NotificationEdit enabledButtons={{
                                    bold: true,
                                    underline: true,
                                    italic: true,
                                    firstname: true,
                                    lastname: true,
                                    purchase: false,
                                    meetinglink: false,
                                    meetingidentifier: false,
                                    meetingpasscode: false,
                                    company: true,
                                    date: false,
                                    companywebsite: true,
                                    termsandconditions: true,
                                    customeraccount: true,
                                    bookingpage: false,
                                    event: false,
                                    'meeting-info': false
                        }}/>
                        </React.Suspense>
                    } />   
                   <Route path="/communicator/add/event-purchase" element={
                        <React.Suspense fallback={<Loading />}>
                          <NotificationEdit enabledButtons={{
                                    bold: true,
                                    underline: true,
                                    italic: true,
                                    firstname: true,
                                    lastname: true,
                                    purchase: true,
                                    meetinglink: false,
                                    meetingidentifier: false,
                                    meetingpasscode: false,
                                    company: true,
                                    date: false,
                                    companywebsite: true,
                                    termsandconditions: true,
                                    customeraccount: true,
                                    bookingpage: false,
                                    event: false,
                                    'meeting-info': true
                        }}/>
                        </React.Suspense>
                    } />   
                    <Route path="/events/" element={
                        <React.Suspense fallback={<Loading />}>
                          <Events  fallback={<Loading />} />
                        </React.Suspense>
                    } />                    
                    <Route path="/events/event-editor/*" element={
                        <React.Suspense fallback={<Loading />}>
                          <EventEditor  fallback={<Loading />} />
                        </React.Suspense>
                    } />                     
                    <Route path="/category/*" element={
                        <React.Suspense fallback={<Loading />}>
                          <CategoryList  fallback={<Loading />} />
                        </React.Suspense>
                    } />  
                    <Route path="/category/add" element={
                        <React.Suspense fallback={<Loading />}>
                          <CategoryAdd  fallback={<Loading />} />
                        </React.Suspense>
                    } />
                    <Route path="/category/edit/:id" element={
                        <React.Suspense fallback={<Loading />}>
                          <CategoryEdit  fallback={<Loading />} />
                        </React.Suspense>
                    } />
                    <Route path="*" element={
                        <React.Suspense fallback={<Loading />}>
                          <Oops />
                        </React.Suspense>
                    } />
    
                </Routes>
                <ToastContainer />
            </BrowserRouter>
          </ThemeProvider>
    </div>
  );
}

export default App;
