import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, setRef } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { TransferButton } from './TransferButton';
import SearchIcon from '@mui/icons-material/Search';
import getSymbolFromCurrency from 'currency-symbol-map'
import { DeleteButton } from './DeleteButton';
import { RefundButton } from './RefundButton';
import TransferDialog from './TransferDialog';
import AlertDialog from './alertDialog';
import ConfirmDialog from './confirmDialog';
import { ProgressDialog } from './progressDialog';
import { del } from '../../libraries/network';
import { formatDateToLocal, formatDateToLocalDetectTz } from '../../libraries/format';
import EventCourseManagerAction from './EventCourseManagerAction';


function createData( eid, name, type, dates, options) {

    const r =  { eid, name, type, dates, options };
    console.log("returning", r);
    return r;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const access = window?.configuration?.access;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



export default function EventsTableEvent( props ) {
  const {events, passes, reload, initiate} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [transfer, setTransfer] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [refund, setRefund] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const access = window?.configuration?.access;


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  

  React.useEffect(() =>{
    const frow = events?.filter( c => {
      if(!!!filtertext)
      {
        return true;
      }
      if( c.name.indexOf(filtertext) > -1)
      {
        return true;
      }


      return false;
    })||[];

    const r = frow?.map( r => createData(r.eid,r.name, r.type, r.dates, r.options))
    setRows(r);
  }, [filtertext, events])



 const handleCancelComplete = async( ) => {
  try{
    setCancel(false);
    setProgress(1);
  
    await del(`/customersrestapi/bookings/${window?.configuration?.access}/cancel/${cancel?.stxid}`);
    setProgress(50);
    await reload();
    setProgress(100);
    setTimeout(()=> {
      setProgress(0);
    }, 500);
  }
  catch(e)
  {

    setTimeout(()=> {
      setProgress(0);
      setError("Failed to cancel booking");
    }, 1000);
  }
}


const handleRefundComplete = async( ) => {
try{
  setRefund(false);
  setProgress(1);

  await del(`/customersrestapi/bookings/${window?.configuration?.access}/refund/${refund?.stxid}`);
  setProgress(50);
  await reload();
  setProgress(100);
  setTimeout(()=> {
    setProgress(0);
  }, 500);
}
catch(e)
{

  setTimeout(()=> {
    setProgress(0);
    setError("Failed to refund booking");
  }, 1000);
}
}


  console.log("Rendering EventsTableEvent", events);
  return (
    <React.Fragment>

      
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">

                <Box sx={{pt: 1}}>
                <Button
                    id="book-button"
                    variant="outlined"
                    href={'/events/event-editor'}
                  >
                  Add Event
                  </Button>
                </Box>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Name</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Date Range</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Type</TableCell>
                      <TableCell align="center" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                      <React.Fragment key={`event-${row.eid}`}>
                        {
                          <TableRow>    
                              <TableCell align="left">
                                {`${parseInt(row?.eid).toString(16)}`} 
                              </TableCell>
                              <TableCell align="left">
                                {`${row?.name}`} 
                              </TableCell>
                              <TableCell align="left">
                                {`${row?.dates.length && formatDateToLocal(row?.dates[0].datetime, row?.dates[0].timezone)} `}  
                                {`${row?.dates.length > 1  && '- ' + formatDateToLocal(row?.dates[row?.dates.length-1].datetime, row?.dates[row?.dates.length-1].timezone)}`} 
                              </TableCell>  
                              <TableCell align="left">
                                {`${row?.type}`} 
                              </TableCell>   
                              <TableCell align="center">
                                <EventCourseManagerAction />
                              </TableCell>                         
                          </TableRow>
          
                        }
                      </React.Fragment>

                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={9} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>No Events.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={2} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>No Events.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={9}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
      {
        transfer && <TransferDialog events={events} from={transfer} reload={reload} handleClose={()=> setTransfer(false)} setError={(e)=>setError(e)}/>
      }
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        cancel && <ConfirmDialog title="Cancel Event" description="Are you sure that you want to cancel this event. This cannot be undone." handleClose={()=>{setCancel(false)}} handleComplete={handleCancelComplete} />
      }
      {
        refund && <ConfirmDialog title="Refund Event" description="Are you sure that you want to refund this event. This cannot be undone." handleClose={()=>{setRefund(false)}} handleComplete={handleRefundComplete} />
      }      
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }

    </React.Fragment>


  );
} 