import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Box, IconButton, TableFooter, TablePagination, Typography } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';


function createData(id, created, name, type, when, firstname, lastname, custid, key_1, key_2) {
    const w = new Date(when);
    const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const z = w.toLocaleDateString();
    const p = new Date(created);
    const c = p.toLocaleDateString();

    return { id, created: `${c}`, name, type, when: `${z} ${x}`, firstname, lastname, custid, key_1, key_2};
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function SalesTable( props ) {
  const {list} = props;
  const rows = list?.map( r => createData(r.id, r.created, r.name, r.type, r.date, r.firstname, r.lastname, r.custid, r.key_1, r.key_2));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log("rows", rows);
  return (
    <TableContainer component={Paper} variant='outlined'>
      <Table  aria-label="sales table">
        <TableHead>
          <TableRow>
              <TableCell  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Purchased</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell  sx={{ display: { xs: 'none', sm: 'table-cell' }}} align="left">When</TableCell>
              <TableCell align="left">Customer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows && (rowsPerPage > 0  && rows?.length > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows).map((row) => (
            <TableRow
              key={`sales-${row.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.created}</TableCell>
              <TableCell>
                {
                  row?.type === 'EVENT' &&
                  <Link href={`/events/info-redirect/${row.key_1}/${row.key_2}`}>{row.name}</Link>
                }
                {
                  row?.type === 'PASS' &&
                  <Typography variant="p" component="div">{row.name}</Typography>
                }
                {
                  row?.type === 'VIDEO' &&
                  <Typography variant="p" component="div">{row.name}</Typography>
                }                
              </TableCell>
              <TableCell align="left">
                {row.type}
                <Box sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                {
                  row?.type === 'EVENT' &&
                  <Typography variant="p" component="div">{row.when}</Typography>
                }                   
                </Box>
              </TableCell>
              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
              {
                  row?.type === 'EVENT' &&
                  <Typography variant="p" component="div">{row.when}</Typography>
                }
                {
                  row?.type === 'PASS' &&
                  <Typography variant="p" component="div">-</Typography>
                }
                {
                  row?.type === 'VIDEO' &&
                  <Typography variant="p" component="div">-</Typography>
                }  
              </TableCell>
              <TableCell align="left">{<Link href={`/customers/manager/${row.custid}`}>{`${row.firstname} ${row.lastname}`}</Link>}</TableCell>
            </TableRow>
          ))}
          {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
              <TableRow>
                  <TableCell  sx={{ display: { xs: 'none', sm: 'table-cell' }}} colSpan={5}>
                  <Box sx={{p: 10}}>
                      <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no sales data.</Typography>
                  </Box>                                                    
                  </TableCell>
                  <TableCell  sx={{ display: { xs: 'table-cell', sm: 'none' }}} colSpan={3}>
                  <Box sx={{p: 10}}>
                      <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no sales data.</Typography>
                  </Box>                                                    
                  </TableCell>
              </TableRow>            
          }
        </TableBody>
        <TableFooter>
          <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={5}
              count={!!rows ? rows.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
            <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={5}
                count={!!rows ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Rows"
                SelectProps={{
                  inputProps: {
                    'aria-label': 'Rows',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
        </TableFooter>            
      </Table>
    </TableContainer>
  );
}