import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DateRangeSelector } from '../elements/DateRangeSelector';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';
import { addDays } from 'date-fns';



export function ReportBookingStoppedRange()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);


    const onDownloadClick  = () =>
    {
        var rows = [
            ["first name", "last name", "email address", 'mobile', 'Last event booked', 'Booked on'],
        ].concat(data.map( r => [`${r.firstname}, ${r.lastname}, ${r.email}, ${r.mobile}, ${r.event_name} ${(new Date(r?.event_date)).toLocaleDateString()}, ${(new Date(r?.booked_on)).toLocaleDateString()}`]));

        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event-bookings-stopped-" + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }


    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);

        async function load() {
            try{
                const d = Object.keys(range).map(function(key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(range[key].getTime());
                }).join('&');
                setProgress(10);
                const data = await get( `/reportsrestapi/not-booked-after-range?${d}`);
                setProgress(100);        
                setData( data );
                setTimeout(() => {
                    setProgress(0);
                    setBusy(false);
                }, 500)
                
            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {

                    console.log( window?.static_reports );
                    setProgress(100);
                    setTimeout(() => {
                        setBusy(false);
                        setProgress(0);
                        setData(window?.static_reports.report_booking_stopped);
                    }, 2000)
                }  
                else
                {
                    setBusy(false);
                    setProgress(0);
                    setError('Failed to load report')
                } 
                
                // to do - present error 

            }
        }
        load();

    }


    return (
        <div className="rvNS">

            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range.</Typography>
                                                <Typography variant='body2' gutterBottom component="div">The range signifies a date window to indicate the period of time that we are interested in. The report will include anyone that has booked between the start and end date but not since the end date.</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    { 
                                        !busy && !!data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    ID
                                                                </TableCell>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Email
                                                                </TableCell>
                                                                <TableCell>
                                                                    Contact
                                                                </TableCell>
                                                                <TableCell>
                                                                    Last Event booked
                                                                </TableCell>
                                                                <TableCell>
                                                                    Booked on
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                data?.map( d => (
                                                                    <TableRow key={`report-${d.id}`}>
                                                                        <TableCell>
                                                                            {d.id}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {`${d.firstname} ${d.lastname}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.email}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.mobile}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {`${d?.event_name} ${(new Date(d?.event_date)).toLocaleDateString()}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {`${(new Date(d?.booked_on)).toLocaleDateString()}`}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))   
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Grid>
                                        </Box>
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                

            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}