import moment from 'moment-timezone';

export const timezones = [
    { value: 'UTC', label: 'UTC' },
    { value: 'Pacific/Honolulu', label: '(UTC-10:00) Hawaii' },
    { value: 'America/Anchorage', label: '(UTC-09:00) Alaska' },
    { value: 'America/Los_Angeles', label: '(UTC-08:00) Pacific Time' },
    { value: 'America/Denver', label: '(UTC-07:00) Mountain Time' },
    { value: 'America/Chicago', label: '(UTC-06:00) Central Time' },
    { value: 'America/New_York', label: '(UTC-05:00) Eastern Time' },
    { value: 'America/Caracas', label: '(UTC-04:30) Venezuela Time' },
    { value: 'America/Halifax', label: '(UTC-04:00) Atlantic Time' },
    { value: 'America/Santiago', label: '(UTC-04:00) Chile Time' },
    { value: 'America/St_Johns', label: '(UTC-03:30) Newfoundland Time' },
    { value: 'America/Buenos_Aires', label: '(UTC-03:00) Argentina Time' },
    { value: 'America/Sao_Paulo', label: '(UTC-03:00) Brasilia Time' },
    { value: 'Atlantic/Cape_Verde', label: '(UTC-01:00) Cape Verde Time' },
    { value: 'Africa/Casablanca', label: '(UTC+00:00) Greenwich Mean Time' },
    { value: 'Europe/London', label: '(UTC+00:00) British Time' },
    { value: 'Europe/Paris', label: '(UTC+01:00) Central European Time' },
    { value: 'Europe/Athens', label: '(UTC+02:00) Eastern European Time' },
    { value: 'Africa/Cairo', label: '(UTC+02:00) Eastern Africa Time' },
    { value: 'Asia/Dubai', label: '(UTC+04:00) Gulf Standard Time' },
    { value: 'Asia/Tehran', label: '(UTC+03:30) Iran Time' },
    { value: 'Asia/Kolkata', label: '(UTC+05:30) India Standard Time' },
    { value: 'Asia/Colombo', label: '(UTC+05:30) Sri Lanka Time' },
    { value: 'Asia/Kathmandu', label: '(UTC+05:45) Nepal Time' },
    { value: 'Asia/Dhaka', label: '(UTC+06:00) Bangladesh Time' },
    { value: 'Asia/Bangkok', label: '(UTC+07:00) Indochina Time' },
    { value: 'Asia/Hong_Kong', label: '(UTC+08:00) Hong Kong Time' },
    { value: 'Asia/Tokyo', label: '(UTC+09:00) Japan Standard Time' },
    { value: 'Australia/Sydney', label: '(UTC+10:00) Australian Eastern Time' },
    { value: 'Pacific/Auckland', label: '(UTC+12:00) New Zealand Time' },
];

export const timezonesSameLabel = [
    { value: 'UTC', label: 'UTC' },
    { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
    { value: 'America/Anchorage', label: 'America/Anchorage' },
    { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
    { value: 'America/Denver', label: 'America/Denver' },
    { value: 'America/Chicago', label: 'America/Chicago' },
    { value: 'America/New_York', label: 'America/New_York' },
    { value: 'America/Caracas', label: 'America/Caracas' },
    { value: 'America/Halifax', label: 'America/Halifax' },
    { value: 'America/Santiago', label: 'America/Santiago' },
    { value: 'America/St_Johns', label: 'America/St_Johns' },
    { value: 'America/Buenos_Aires', label: 'America/Buenos_Aires' },
    { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo' },
    { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape_Verde' },
    { value: 'Africa/Casablanca', label: 'Africa/Casablanca' },
    { value: 'Europe/London', label: 'Europe/London' },
    { value: 'Europe/Paris', label: 'Europe/Paris' },
    { value: 'Europe/Athens', label: 'Europe/Athens' },
    { value: 'Africa/Cairo', label: 'Africa/Cairo' },
    { value: 'Asia/Dubai', label: 'Asia/Dubai' },
    { value: 'Asia/Tehran', label: 'Asia/Tehran' },
    { value: 'Asia/Kolkata', label: 'Asia/Kolkata' },
    { value: 'Asia/Colombo', label: 'Asia/Colombo' },
    { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu' },
    { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
    { value: 'Asia/Bangkok', label: 'Asia/Bangkok' },
    { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong' },
    { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
    { value: 'Australia/Sydney', label: 'Australia/Sydney' },
    { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
];

export const getDateFormatByLocation = () => {
    const userLocale = navigator.language || navigator.userLanguage;
    
    // Define date and time format strings for specific locales
    const dateTimeFormats = {
        'af-ZA': 'dd/MM/yyyy HH:mm',
        'am-ET': 'dd/MM/yyyy HH:mm',
        'ar-AE': 'dd/MM/yyyy HH:mm',
        'ar-BH': 'dd/MM/yyyy HH:mm',
        'ar-DZ': 'dd/MM/yyyy HH:mm',
        'ar-EG': 'dd/MM/yyyy HH:mm',
        'ar-IQ': 'dd/MM/yyyy HH:mm',
        'ar-JO': 'dd/MM/yyyy HH:mm',
        'ar-KW': 'dd/MM/yyyy HH:mm',
        'ar-LB': 'dd/MM/yyyy HH:mm',
        'ar-LY': 'dd/MM/yyyy HH:mm',
        'ar-MA': 'dd/MM/yyyy HH:mm',
        'ar-OM': 'dd/MM/yyyy HH:mm',
        'ar-QA': 'dd/MM/yyyy HH:mm',
        'ar-SA': 'dd/MM/yyyy HH:mm',
        'ar-SY': 'dd/MM/yyyy HH:mm',
        'ar-TN': 'dd/MM/yyyy HH:mm',
        'ar-YE': 'dd/MM/yyyy HH:mm',
        'az-AZ': 'dd.MM.yyyy HH:mm',
        'be-BY': 'dd.MM.yyyy HH:mm',
        'bg-BG': 'dd.MM.yyyy HH:mm',
        'bn-BD': 'dd-MM-yyyy HH:mm',
        'bs-BA': 'dd.MM.yyyy HH:mm',
        'ca-ES': 'dd/MM/yyyy HH:mm',
        'cs-CZ': 'dd.MM.yyyy HH:mm',
        'da-DK': 'dd-MM-yyyy HH:mm',
        'de-AT': 'dd.MM.yyyy HH:mm',
        'de-CH': 'dd.MM.yyyy HH:mm',
        'de-DE': 'dd.MM.yyyy HH:mm',
        'de-LI': 'dd.MM.yyyy HH:mm',
        'de-LU': 'dd.MM.yyyy HH:mm',
        'dv-MV': 'dd/MM/yyyy HH:mm',
        'el-GR': 'dd/MM/yyyy HH:mm',
        'en-AU': 'dd/MM/yyyy HH:mm',
        'en-BZ': 'dd/MM/yyyy HH:mm',
        'en-CA': 'MM/dd/yyyy HH:mm',
        'en-GB': 'dd/MM/yyyy HH:mm',
        'en-IE': 'dd/MM/yyyy HH:mm',
        'en-IN': 'dd/MM/yyyy HH:mm',
        'en-JM': 'dd/MM/yyyy HH:mm',
        'en-MY': 'dd/MM/yyyy HH:mm',
        'en-NZ': 'dd/MM/yyyy HH:mm',
        'en-PH': 'MM/dd/yyyy HH:mm',
        'en-SG': 'dd/MM/yyyy HH:mm',
        'en-TT': 'dd/MM/yyyy HH:mm',
        'en-US': 'MM/dd/yyyy HH:mm',
        'en-ZA': 'dd/MM/yyyy HH:mm',
        'en-ZW': 'dd/MM/yyyy HH:mm',
        'eo-001': 'dd/MM/yyyy HH:mm',
        'es-AR': 'dd/MM/yyyy HH:mm',
        'es-BO': 'dd/MM/yyyy HH:mm',
        'es-CL': 'dd-MM-yyyy HH:mm',
        'es-CO': 'dd/MM/yyyy HH:mm',
        'es-CR': 'dd/MM/yyyy HH:mm',
        'es-DO': 'dd/MM/yyyy HH:mm',
        'es-EC': 'dd/MM/yyyy HH:mm',
        'es-ES': 'dd/MM/yyyy HH:mm',
        'es-GT': 'dd/MM/yyyy HH:mm',
        'es-HN': 'dd/MM/yyyy HH:mm',
        'es-MX': 'dd/MM/yyyy HH:mm',
        'es-NI': 'dd/MM/yyyy HH:mm',
        'es-PA': 'dd/MM/yyyy HH:mm',
        'es-PE': 'dd/MM/yyyy HH:mm',
        'es-PR': 'dd/MM/yyyy HH:mm',
        'es-PY': 'dd/MM/yyyy HH:mm',
        'es-SV': 'dd/MM/yyyy HH:mm',
        'es-UY': 'dd/MM/yyyy HH:mm',
        'es-VE': 'dd/MM/yyyy HH:mm',
        'et-EE': 'dd.MM.yyyy HH:mm',
        'eu-ES': 'dd/MM/yyyy HH:mm',
        'fa-IR': 'dd/MM/yyyy HH:mm',
        'fi-FI': 'dd.MM.yyyy HH:mm',
        'fil-PH': 'MM/dd/yyyy HH:mm',
        'fo-FO': 'dd-MM-yyyy HH:mm',
        'fr-BE': 'dd/MM/yyyy HH:mm',
        'fr-CA': 'yyyy-MM-dd HH:mm',
        'fr-CH': 'dd.MM.yyyy HH:mm',
        'fr-FR': 'dd/MM/yyyy HH:mm',
        'fr-LU': 'dd/MM/yyyy HH:mm',
        'fr-MC': 'dd/MM/yyyy HH:mm',
        'fy-NL': 'dd-MM-yyyy HH:mm',
        'ga-IE': 'dd/MM/yyyy HH:mm',
        'gd-GB': 'dd/MM/yyyy HH:mm',
        'gl-ES': 'dd/MM/yyyy HH:mm',
        'gu-IN': 'dd/MM/yyyy HH:mm',
        'ha-Latn-NG': 'dd/MM/yyyy HH:mm',
        'he-IL': 'dd/MM/yyyy HH:mm',
        'hi-IN': 'dd/MM/yyyy HH:mm',
        'hr-BA': 'dd.MM.yyyy HH:mm',
        'hr-HR': 'dd.MM.yyyy HH:mm',
        'hu-HU': 'yyyy.MM.dd. HH:mm',
        'hy-AM': 'dd.MM.yyyy HH:mm',
        'id-ID': 'dd/MM/yyyy HH:mm',
        'ig-NG': 'dd/MM/yyyy HH:mm',
        'ii-CN': 'dd/MM/yyyy HH:mm',
        'is-IS': 'dd.MM.yyyy HH:mm',
        'it-CH': 'dd.MM.yyyy HH:mm',
        'it-IT': 'dd/MM/yyyy HH:mm',
        'ja-JP': 'yyyy/MM/dd HH:mm',
        'ka-GE': 'dd.MM.yyyy HH:mm',
        'kk-KZ': 'dd.MM.yyyy HH:mm',
        'kl-GL': 'dd-MM-yyyy HH:mm',
        'km-KH': 'dd/MM/yyyy HH:mm',
        'kn-IN': 'dd/MM/yyyy HH:mm',
        'ko-KR': 'yyyy/MM/dd HH:mm',
        'kok-IN': 'dd-MM-yyyy HH:mm',
        'ku-Arab-IQ': 'dd/MM/yyyy HH:mm',
        'ky-KG': 'dd.MM.yyyy HH:mm',
        'lb-LU': 'dd/MM/yyyy HH:mm',
        'lo-LA': 'dd/MM/yyyy HH:mm',
        'lt-LT': 'yyyy.MM.dd HH:mm',
        'lv-LV': 'dd.MM.yyyy HH:mm',
        'mi-NZ': 'dd/MM/yyyy HH:mm',
        'mk-MK': 'dd.MM.yyyy HH:mm',
        'ml-IN': 'dd/MM/yyyy HH:mm',
        'mn-MN': 'yyyy.MM.dd HH:mm',
        'mn-Mong-CN': 'yyyy/m/d HH:mm',
        'mr-IN': 'dd/MM/yyyy HH:mm',
        'ms-BN': 'dd/MM/yyyy HH:mm',
        'ms-MY': 'dd/MM/yyyy HH:mm',
        'mt-MT': 'dd/MM/yyyy HH:mm',
        'my-MM': 'dd-MM-yyyy HH:mm',
        'nb-NO': 'dd.MM.yyyy HH:mm',
        'ne-NP': 'dd/MM/yyyy HH:mm',
        'nl-BE': 'dd/MM/yyyy HH:mm',
        'nl-NL': 'dd-MM-yyyy HH:mm',
        'nn-NO': 'dd.MM.yyyy HH:mm',
        'nso-ZA': 'dd/MM/yyyy HH:mm',
        'oc-FR': 'dd/MM/yyyy HH:mm',
        'or-IN': 'dd-MM-yyyy HH:mm',
        'pa-IN': 'dd/MM/yyyy HH:mm',
        'pl-PL': 'dd.MM.yyyy HH:mm',
        'ps-AF': 'dd/MM/yyyy HH:mm',
        'pt-BR': 'dd/MM/yyyy HH:mm',
        'pt-PT': 'dd/MM/yyyy HH:mm',
        'ro-MD': 'dd.MM.yyyy HH:mm',
        'ro-RO': 'dd.MM.yyyy HH:mm',
        'ru-RU': 'dd.MM.yyyy HH:mm',
        'rw-RW': 'MM/dd/yyyy HH:mm',
        'sd-Arab-PK': 'dd/MM/yyyy HH:mm',
        'si-LK': 'yyyy-MM-dd HH:mm',
        'sk-SK': 'dd.MM.yyyy HH:mm',
        'sl-SI': 'dd.MM.yyyy HH:mm',
        'smn-FI': 'dd.MM.yyyy HH:mm',
        'sq-AL': 'dd/MM/yyyy HH:mm',
        'sr-Cyrl-BA': 'dd.MM.yyyy HH:mm',
        'sr-Cyrl-CS': 'dd.MM.yyyy HH:mm',
        'sr-Cyrl-ME': 'dd.MM.yyyy HH:mm',
        'sr-Cyrl-RS': 'dd.MM.yyyy HH:mm',
        'sr-Latn-BA': 'dd.MM.yyyy HH:mm',
        'sr-Latn-CS': 'dd.MM.yyyy HH:mm',
        'sr-Latn-ME': 'dd.MM.yyyy HH:mm',
        'sr-Latn-RS': 'dd.MM.yyyy HH:mm',
        'sv-FI': 'dd.MM.yyyy HH:mm',
        'sv-SE': 'yyyy-MM-dd HH:mm',
        'sw-KE': 'dd/MM/yyyy HH:mm',
        'syr-SY': 'dd/MM/yyyy HH:mm',
        'ta-IN': 'dd-MM-yyyy HH:mm',
        'te-IN': 'dd-MM-yyyy HH:mm',
        'tg-Cyrl-TJ': 'dd.MM.yyyy HH:mm',
        'th-TH': 'dd/MM/yyyy HH:mm',
        'tk-TM': 'dd.MM.yyyy HH:mm',
        'tn-ZA': 'dd/MM/yyyy HH:mm',
        'tr-TR': 'dd.MM.yyyy HH:mm',
        'tt-RU': 'dd.MM.yyyy HH:mm',
        'tzm-Latn-DZ': 'dd/MM/yyyy HH:mm',
        'ug-CN': 'yyyy-m-d HH:mm',
        'uk-UA': 'dd.MM.yyyy HH:mm',
        'ur-PK': 'dd/MM/yyyy HH:mm',
        'uz-Cyrl-UZ': 'dd.MM.yyyy HH:mm',
        'uz-Latn-UZ': 'dd/MM/yyyy HH:mm',
        'vi-VN': 'dd/MM/yyyy HH:mm',
        'wo-SN': 'dd/MM/yyyy HH:mm',
        'xh-ZA': 'dd/MM/yyyy HH:mm',
        'yo-NG': 'dd/MM/yyyy HH:mm',
        'zh-CN': 'yyyy-MM-dd HH:mm',
        'zh-HK': 'dd/MM/yyyy HH:mm',
        'zh-MO': 'dd/MM/yyyy HH:mm',
        'zh-SG': 'dd/MM/yyyy HH:mm',
        'zh-TW': 'yyyy/MM/dd HH:mm',
        'zu-ZA': 'dd/MM/yyyy HH:mm',
        // Add more locale-date-time format pairs as needed
    };
    
    const defaultFormat = 'dd/MM/yyyy HH:mm'; // Default format if locale is not found
    
    // Check if the user's locale is in the predefined formats
    if (userLocale in dateTimeFormats) {
        return dateTimeFormats[userLocale];
    } else {
        return defaultFormat;
    }
};

const mapTimeZoneAbbreviationsToISO3166Codes = ( tz ) => {
    const timezoneToCountryCode = {
        "Africa/Abidjan": "CI",
        "Africa/Accra": "GH",
        "Africa/Addis_Ababa": "ET",
        "Africa/Algiers": "DZ",
        "Africa/Asmara": "ER",
        "Africa/Bamako": "ML",
        "Africa/Bangui": "CF",
        "Africa/Banjul": "GM",
        "Africa/Bissau": "GW",
        "Africa/Blantyre": "MW",
        "Africa/Brazzaville": "CG",
        "Africa/Bujumbura": "BI",
        "Africa/Cairo": "EG",
        "Africa/Casablanca": "MA",
        "Africa/Ceuta": "ES",
        "Africa/Conakry": "GN",
        "Africa/Dakar": "SN",
        "Africa/Dar_es_Salaam": "TZ",
        "Africa/Djibouti": "DJ",
        "Africa/Douala": "CM",
        "Africa/El_Aaiun": "EH",
        "Africa/Freetown": "SL",
        "Africa/Gaborone": "BW",
        "Africa/Harare": "ZW",
        "Africa/Johannesburg": "ZA",
        "Africa/Juba": "SS",
        "Africa/Kampala": "UG",
        "Africa/Khartoum": "SD",
        "Africa/Kigali": "RW",
        "Africa/Kinshasa": "CD",
        "Africa/Lagos": "NG",
        "Africa/Libreville": "GA",
        "Africa/Lome": "TG",
        "Africa/Luanda": "AO",
        "Africa/Lubumbashi": "CD",
        "Africa/Lusaka": "ZM",
        "Africa/Malabo": "GQ",
        "Africa/Maputo": "MZ",
        "Africa/Maseru": "LS",
        "Africa/Mbabane": "SZ",
        "Africa/Mogadishu": "SO",
        "Africa/Monrovia": "LR",
        "Africa/Nairobi": "KE",
        "Africa/Ndjamena": "TD",
        "Africa/Niamey": "NE",
        "Africa/Nouakchott": "MR",
        "Africa/Ouagadougou": "BF",
        "Africa/Porto-Novo": "BJ",
        "Africa/Sao_Tome": "ST",
        "Africa/Tripoli": "LY",
        "Africa/Tunis": "TN",
        "Africa/Windhoek": "NA",
        "America/Adak": "US",
        "America/Anchorage": "US",
        "America/Anguilla": "AI",
        "America/Antigua": "AG",
        "America/Araguaina": "BR",
        "America/Argentina/Buenos_Aires": "AR",
        "America/Argentina/Catamarca": "AR",
        "America/Argentina/Cordoba": "AR",
        "America/Argentina/Jujuy": "AR",
        "America/Argentina/La_Rioja": "AR",
        "America/Argentina/Mendoza": "AR",
        "America/Argentina/Rio_Gallegos": "AR",
        "America/Argentina/Salta": "AR",
        "America/Argentina/San_Juan": "AR",
        "America/Argentina/San_Luis": "AR",
        "America/Argentina/Tucuman": "AR",
        "America/Argentina/Ushuaia": "AR",
        "America/Aruba": "AW",
        "America/Asuncion": "PY",
        "America/Atikokan": "CA",
        "America/Bahia": "BR",
        "America/Bahia_Banderas": "MX",
        "America/Barbados": "BB",
        "America/Belem": "BR",
        "America/Belize": "BZ",
        "America/Blanc-Sablon": "CA",
        "America/Boa_Vista": "BR",
        "America/Bogota": "CO",
        "America/Boise": "US",
        "America/Cambridge_Bay": "CA",
        "America/Campo_Grande": "BR",
        "America/Cancun": "MX",
        "America/Caracas": "VE",
        "America/Cayenne": "GF",
        "America/Cayman": "KY",
        "America/Chicago": "US",
        "America/Chihuahua": "MX",
        "America/Costa_Rica": "CR",
        "America/Creston": "CA",
        "America/Cuiaba": "BR",
        "America/Curacao": "CW",
        "America/Danmarkshavn": "GL",
        "America/Dawson": "CA",
        "America/Dawson_Creek": "CA",
        "America/Denver": "US",
        "America/Detroit": "US",
        "America/Dominica": "DM",
        "America/Edmonton": "CA",
        "America/Eirunepe": "BR",
        "America/El_Salvador": "SV",
        "America/Fort_Nelson": "CA",
        "America/Fortaleza": "BR",
        "America/Glace_Bay": "CA",
        "America/Goose_Bay": "CA",
        "America/Grand_Turk": "TC",
        "America/Grenada": "GD",
        "America/Guadeloupe": "GP",
        "America/Guatemala": "GT",
        "America/Guayaquil": "EC",
        "America/Guyana": "GY",
        "America/Halifax": "CA",
        "America/Havana": "CU",
        "America/Hermosillo": "MX",
        "America/Indiana/Indianapolis": "US",
        "America/Indiana/Knox": "US",
        "America/Indiana/Marengo": "US",
        "America/Indiana/Petersburg": "US",
        "America/Indiana/Tell_City": "US",
        "America/Indiana/Vevay": "US",
        "America/Indiana/Vincennes": "US",
        "America/Indiana/Winamac": "US",
        "America/Inuvik": "CA",
        "America/Iqaluit": "CA",
        "America/Jamaica": "JM",
        "America/Juneau": "US",
        "America/Kentucky/Louisville": "US",
        "America/Kentucky/Monticello": "US",
        "America/Kralendijk": "BQ",
        "America/La_Paz": "BO",
        "America/Lima": "PE",
        "America/Los_Angeles": "US",
        "America/Lower_Princes": "SX",
        "America/Maceio": "BR",
        "America/Managua": "NI",
        "America/Manaus": "BR",
        "America/Marigot": "MF",
        "America/Martinique": "MQ",
        "America/Matamoros": "MX",
        "America/Mazatlan": "MX",
        "America/Menominee": "US",
        "America/Merida": "MX",
        "America/Metlakatla": "US",
        "America/Mexico_City": "MX",
        "America/Miquelon": "PM",
        "America/Moncton": "CA",
        "America/Monterrey": "MX",
        "America/Montevideo": "UY",
        "America/Montserrat": "MS",
        "America/Nassau": "BS",
        "America/New_York": "US",
        "America/Nipigon": "CA",
        "America/Nome": "US",
        "America/Noronha": "BR",
        "America/North_Dakota/Beulah": "US",
        "America/North_Dakota/Center": "US",
        "America/North_Dakota/New_Salem": "US",
        "America/Ojinaga": "MX",
        "America/Panama": "PA",
        "America/Pangnirtung": "CA",
        "America/Paramaribo": "SR",
        "America/Phoenix": "US",
        "America/Port-au-Prince": "HT",
        "America/Port_of_Spain": "TT",
        "America/Porto_Velho": "BR",
        "America/Puerto_Rico": "PR",
        "America/Punta_Arenas": "CL",
        "America/Rainy_River": "CA",
        "America/Rankin_Inlet": "CA",
        "America/Recife": "BR",
        "America/Regina": "CA",
        "America/Resolute": "CA",
        "America/Rio_Branco": "BR",
        "America/Santarem": "BR",
        "America/Santiago": "CL",
        "America/Santo_Domingo": "DO",
        "America/Sao_Paulo": "BR",
        "America/Scoresbysund": "GL",
        "America/Sitka": "US",
        "America/St_Barthelemy": "BL",
        "America/St_Johns": "CA",
        "America/St_Kitts": "KN",
        "America/St_Lucia": "LC",
        "America/St_Thomas": "VI",
        "America/St_Vincent": "VC",
        "America/Swift_Current": "CA",
        "America/Tegucigalpa": "HN",
        "America/Thule": "GL",
        "America/Thunder_Bay": "CA",
        "America/Tijuana": "MX",
        "America/Toronto": "CA",
        "America/Tortola": "VG",
        "America/Vancouver": "CA",
        "America/Whitehorse": "CA",
        "America/Winnipeg": "CA",
        "America/Yakutat": "US",
        "America/Yellowknife": "CA",
        "Antarctica/Casey": "AQ",
        "Antarctica/Davis": "AQ",
        "Antarctica/DumontDUrville": "AQ",
        "Antarctica/Macquarie": "AU",
        "Antarctica/Mawson": "AQ",
        "Antarctica/McMurdo": "AQ",
        "Antarctica/Palmer": "AQ",
        "Antarctica/Rothera": "AQ",
        "Antarctica/Syowa": "AQ",
        "Antarctica/Troll": "AQ",
        "Antarctica/Vostok": "AQ",
        "Arctic/Longyearbyen": "SJ",
        "Asia/Aden": "YE",
        "Asia/Almaty": "KZ",
        "Asia/Amman": "JO",
        "Asia/Anadyr": "RU",
        "Asia/Aqtau": "KZ",
        "Asia/Aqtobe": "KZ",
        "Asia/Ashgabat": "TM",
        "Asia/Atyrau": "KZ",
        "Asia/Baghdad": "IQ",
        "Asia/Bahrain": "BH",
        "Asia/Baku": "AZ",
        "Asia/Bangkok": "TH",
        "Asia/Barnaul": "RU",
        "Asia/Beirut": "LB",
        "Asia/Bishkek": "KG",
        "Asia/Brunei": "BN",
        "Asia/Chita": "RU",
        "Asia/Choibalsan": "MN",
        "Asia/Colombo": "LK",
        "Asia/Damascus": "SY",
        "Asia/Dhaka": "BD",
        "Asia/Dili": "TL",
        "Asia/Dubai": "AE",
        "Asia/Dushanbe": "TJ",
        "Asia/Famagusta": "CY",
        "Asia/Gaza": "PS",
        "Asia/Hebron": "PS",
        "Asia/Ho_Chi_Minh": "VN",
        "Asia/Hong_Kong": "HK",
        "Asia/Hovd": "MN",
        "Asia/Irkutsk": "RU",
        "Asia/Jakarta": "ID",
        "Asia/Jayapura": "ID",
        "Asia/Jerusalem": "IL",
        "Asia/Kabul": "AF",
        "Asia/Kamchatka": "RU",
        "Asia/Karachi": "PK",
        "Asia/Kathmandu": "NP",
        "Asia/Khandyga": "RU",
        "Asia/Kolkata": "IN",
        "Asia/Krasnoyarsk": "RU",
        "Asia/Kuala_Lumpur": "MY",
        "Asia/Kuching": "MY",
        "Asia/Kuwait": "KW",
        "Asia/Macau": "MO",
        "Asia/Magadan": "RU",
        "Asia/Makassar": "ID",
        "Asia/Manila": "PH",
        "Asia/Muscat": "OM",
        "Asia/Nicosia": "CY",
        "Asia/Novokuznetsk": "RU",
        "Asia/Novosibirsk": "RU",
        "Asia/Omsk": "RU",
        "Asia/Oral": "KZ",
        "Asia/Phnom_Penh": "KH",
        "Asia/Pontianak": "ID",
        "Asia/Pyongyang": "KP",
        "Asia/Qatar": "QA",
        "Asia/Qostanay": "KZ",
        "Asia/Qyzylorda": "KZ",
        "Asia/Riyadh": "SA",
        "Asia/Sakhalin": "RU",
        "Asia/Samarkand": "UZ",
        "Asia/Seoul": "KR",
        "Asia/Shanghai": "CN",
        "Asia/Singapore": "SG",
        "Asia/Srednekolymsk": "RU",
        "Asia/Taipei": "TW",
        "Asia/Tashkent": "UZ",
        "Asia/Tbilisi": "GE",
        "Asia/Tehran": "IR",
        "Asia/Thimphu": "BT",
        "Asia/Tokyo": "JP",
        "Asia/Tomsk": "RU",
        "Asia/Ulaanbaatar": "MN",
        "Asia/Urumqi": "CN",
        "Asia/Ust-Nera": "RU",
        "Asia/Vientiane": "LA",
        "Asia/Vladivostok": "RU",
        "Asia/Yakutsk": "RU",
        "Asia/Yangon": "MM",
        "Asia/Yekaterinburg": "RU",
        "Asia/Yerevan": "AM",
        "Atlantic/Azores": "PT",
        "Atlantic/Bermuda": "BM",
        "Atlantic/Canary": "ES",
        "Atlantic/Cape_Verde": "CV",
        "Atlantic/Faroe": "FO",
        "Atlantic/Madeira": "PT",
        "Atlantic/Reykjavik": "IS",
        "Atlantic/South_Georgia": "GS",
        "Atlantic/St_Helena": "SH",
        "Atlantic/Stanley": "FK",
        "Australia/Adelaide": "AU",
        "Australia/Brisbane": "AU",
        "Australia/Broken_Hill": "AU",
        "Australia/Currie": "AU",
        "Australia/Darwin": "AU",
        "Australia/Eucla": "AU",
        "Australia/Hobart": "AU",
        "Australia/Lindeman": "AU",
        "Australia/Lord_Howe": "AU",
        "Australia/Melbourne": "AU",
        "Australia/Perth": "AU",
        "Australia/Sydney": "AU",
        "Europe/Amsterdam": "NL",
        "Europe/Andorra": "AD",
        "Europe/Astrakhan": "RU",
        "Europe/Athens": "GR",
        "Europe/Belgrade": "RS",
        "Europe/Berlin": "DE",
        "Europe/Bratislava": "SK",
        "Europe/Brussels": "BE",
        "Europe/Bucharest": "RO",
        "Europe/Budapest": "HU",
        "Europe/Busingen": "DE",
        "Europe/Chisinau": "MD",
        "Europe/Copenhagen": "DK",
        "Europe/Dublin": "IE",
        "Europe/Gibraltar": "GI",
        "Europe/Guernsey": "GG",
        "Europe/Helsinki": "FI",
        "Europe/Isle_of_Man": "IM",
        "Europe/Istanbul": "TR",
        "Europe/Jersey": "JE",
        "Europe/Kaliningrad": "RU",
        "Europe/Kiev": "UA",
        "Europe/Kirov": "RU",
        "Europe/Lisbon": "PT",
        "Europe/Ljubljana": "SI",
        "Europe/London": "GB",
        "Europe/Luxembourg": "LU",
        "Europe/Madrid": "ES",
        "Europe/Malta": "MT",
        "Europe/Mariehamn": "AX",
        "Europe/Minsk": "BY",
        "Europe/Monaco": "MC",
        "Europe/Moscow": "RU",
        "Europe/Oslo": "NO",
        "Europe/Paris": "FR",
        "Europe/Podgorica": "ME",
        "Europe/Prague": "CZ",
        "Europe/Riga": "LV",
        "Europe/Rome": "IT",
        "Europe/Samara": "RU",
        "Europe/San_Marino": "SM",
        "Europe/Sarajevo": "BA",
        "Europe/Saratov": "RU",
        "Europe/Simferopol": "UA",
        "Europe/Skopje": "MK",
        "Europe/Sofia": "BG",
        "Europe/Stockholm": "SE",
        "Europe/Tallinn": "EE",
        "Europe/Tirane": "AL",
        "Europe/Ulyanovsk": "RU",
        "Europe/Uzhgorod": "UA",
        "Europe/Vaduz": "LI",
        "Europe/Vatican": "VA",
        "Europe/Vienna": "AT",
        "Europe/Vilnius": "LT",
        "Europe/Volgograd": "RU",
        "Europe/Warsaw": "PL",
        "Europe/Zagreb": "HR",
        "Europe/Zaporozhye": "UA",
        "Europe/Zurich": "CH",
        "Indian/Antananarivo": "MG",
        "Indian/Chagos": "IO",
        "Indian/Christmas": "CX",
        "Indian/Cocos": "CC",
        "Indian/Comoro": "KM",
        "Indian/Kerguelen": "TF",
        "Indian/Mahe": "SC",
        "Indian/Maldives": "MV",
        "Indian/Mauritius": "MU",
        "Indian/Mayotte": "YT",
        "Indian/Reunion": "RE",
        "Pacific/Apia": "WS",
        "Pacific/Auckland": "NZ",
        "Pacific/Bougainville": "PG",
        "Pacific/Chatham": "NZ",
        "Pacific/Chuuk": "FM",
        "Pacific/Easter": "CL",
        "Pacific/Efate": "VU",
        "Pacific/Enderbury": "KI",
        "Pacific/Fakaofo": "TK",
        "Pacific/Fiji": "FJ",
        "Pacific/Funafuti": "TV",
        "Pacific/Galapagos": "EC",
        "Pacific/Gambier": "PF",
        "Pacific/Guadalcanal": "SB",
        "Pacific/Guam": "GU",
        "Pacific/Honolulu": "US",
        "Pacific/Kiritimati": "KI",
        "Pacific/Kosrae": "FM",
        "Pacific/Kwajalein": "MH",
        "Pacific/Majuro": "MH",
        "Pacific/Marquesas": "PF",
        "Pacific/Midway": "UM",
        "Pacific/Nauru": "NR",
        "Pacific/Niue": "NU",
        "Pacific/Norfolk": "NF",
        "Pacific/Noumea": "NC",
        "Pacific/Pago_Pago": "AS",
        "Pacific/Palau": "PW",
        "Pacific/Pitcairn": "PN",
        "Pacific/Pohnpei": "FM",
        "Pacific/Port_Moresby": "PG",
        "Pacific/Rarotonga": "CK",
        "Pacific/Saipan": "MP",
        "Pacific/Tahiti": "PF",
        "Pacific/Tarawa": "KI",
        "Pacific/Tongatapu": "TO",
        "Pacific/Wake": "UM",
        "Pacific/Wallis": "WF",
    };
    
    return timezoneToCountryCode[tz] || null;
}


export const getCountryCode = () => {
    try {
        const countryCode = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];
        return countryCode.toUpperCase();
    } catch (error) {
        console.error("Error getting country code:", error);
        return "Unknown";
    }
};




export const getCountryTimeAbbreviation = ( utc, offset ) => {

    const currentMonth  = utc.getMonth();
    const countryCode   = getCountryCode();

    // Determine if the current month is within daylight saving time (DST)
    const isDST = (currentMonth > 2 && currentMonth < 9); // DST usually starts in March (3rd month) and ends in October (9th month)

  
    // Calculate the offset in hours and minutes
    let timeZoneAbbr = 'unknown';


    switch (countryCode) {
        case "AD": // Andorra
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "AE": // United Arab Emirates
            timeZoneAbbr = "GST"; // Gulf Standard Time
            break;
        case "AF": // Afghanistan
            timeZoneAbbr = isDST ? "AFT" : "AFT"; // Afghanistan Time
            break;
        case "AG": // Antigua and Barbuda
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "AI": // Anguilla
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "AL": // Albania
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "AM": // Armenia
            timeZoneAbbr = "AMT"; // Armenia Time
            break;
        case "AO": // Angola
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "AQ": // Antarctica
            timeZoneAbbr = "AQTT"; // Antarctica Time
            break;
        case "AR": // Argentina
            timeZoneAbbr = isDST ? "ART" : "ART"; // Argentina Time
            break;
        case "AS": // American Samoa
            timeZoneAbbr = "SST"; // Samoa Standard Time
            break;
        case "AT": // Austria
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "AU": // Australia
            if (isDST) {
                timeZoneAbbr = "AEDT"; // Australian Eastern Daylight Time
            } else {
                timeZoneAbbr = "AEST"; // Australian Eastern Standard Time
            }
            break;
        case "AW": // Aruba
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "AX": // Åland Islands
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "AZ": // Azerbaijan
            timeZoneAbbr = isDST ? "AZST" : "AZT"; // Azerbaijan Summer Time or Azerbaijan Time
            break;
        case "BA": // Bosnia and Herzegovina
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "BB": // Barbados
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "BD": // Bangladesh
            timeZoneAbbr = "BDT"; // Bangladesh Time
            break;
        case "BE": // Belgium
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "BF": // Burkina Faso
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "BG": // Bulgaria
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "BH": // Bahrain
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "BI": // Burundi
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "BJ": // Benin
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "BL": // Saint Barthélemy
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "BM": // Bermuda
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "BN": // Brunei Darussalam
            timeZoneAbbr = "BNT"; // Brunei Darussalam Time
            break;
        case "BO": // Bolivia
            timeZoneAbbr = "BOT"; // Bolivia Time
            break;
        case "BQ": // Bonaire, Sint Eustatius and Saba
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "BR": // Brazil
            if (isDST) {
                timeZoneAbbr = "BRST"; // Brasília Summer Time
            } else {
                timeZoneAbbr = "BRT"; // Brasília Time
            }
            break;
        case "BS": // Bahamas
            timeZoneAbbr = "EST"; // Eastern Standard Time
            break;
        case "BT": // Bhutan
            timeZoneAbbr = "BTT"; // Bhutan Time
            break;
        case "BV": // Bouvet Island
            timeZoneAbbr = "CET"; // Central European Time
            break;
        case "BW": // Botswana
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "BY": // Belarus
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "BZ": // Belize
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "CA": // Canada
            if (isDST) {
                switch (offset) {
                    case 240: // Eastern Daylight Time (EDT)
                        timeZoneAbbr = "EDT";
                        break;
                    case 300: // Central Daylight Time (CDT)
                        timeZoneAbbr = "CDT";
                        break;
                    case 360: // Mountain Daylight Time (MDT)
                        timeZoneAbbr = "MDT";
                        break;
                    case 420: // Pacific Daylight Time (PDT)
                        timeZoneAbbr = "PDT";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            } else {
                switch (offset) {
                    case 300: // Eastern Standard Time (EST)
                        timeZoneAbbr = "EST";
                        break;
                    case 360: // Central Standard Time (CST)
                        timeZoneAbbr = "CST";
                        break;
                    case 420: // Mountain Standard Time (MST)
                        timeZoneAbbr = "MST";
                        break;
                    case 480: // Pacific Standard Time (PST)
                        timeZoneAbbr = "PST";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            }
            break;
        case "CC": // Cocos (Keeling) Islands
            timeZoneAbbr = "CCT"; // Cocos Islands Time
            break;
        case "CD": // Congo, the Democratic Republic of the
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "CF": // Central African Republic
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "CG": // Congo
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "CH": // Switzerland
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "CI": // Côte d'Ivoire
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "CK": // Cook Islands
            timeZoneAbbr = "CKT"; // Cook Islands Time
            break;
        case "CL": // Chile
            timeZoneAbbr = isDST ? "CLST" : "CLT"; // Chile Summer Time or Chile Standard Time
            break;
        case "CM": // Cameroon
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "CN": // China
            timeZoneAbbr = "CST"; // China Standard Time
            break;
        case "CO": // Colombia
            timeZoneAbbr = "COT"; // Colombia Time
            break;
        case "CR": // Costa Rica
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "CU": // Cuba
            timeZoneAbbr = isDST ? "CDT" : "CST"; // Cuba Daylight Time or Cuba Standard Time
            break;
        case "CV": // Cape Verde
            timeZoneAbbr = "CVT"; // Cape Verde Time
            break;
        case "CW": // Curaçao
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "CX": // Christmas Island
            timeZoneAbbr = "CXT"; // Christmas Island Time
            break;
        case "CY": // Cyprus
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "CZ": // Czech Republic
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "DE": // Germany
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "DJ": // Djibouti
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "DK": // Denmark
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "DM": // Dominica
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "DO": // Dominican Republic
            timeZoneAbbr = isDST ? "EDT" : "EST"; // Eastern Daylight Time or Eastern Standard Time
            break;
        case "DZ": // Algeria
            timeZoneAbbr = "CET"; // Central European Time
            break;
        case "EC": // Ecuador
            timeZoneAbbr = "ECT"; // Ecuador Time
            break;
        case "EE": // Estonia
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "EG": // Egypt
            timeZoneAbbr = "EET"; // Eastern European Time
            break;
        case "EH": // Western Sahara
            timeZoneAbbr = "WET"; // Western European Time
            break;
        case "ER": // Eritrea
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "ES": // Spain
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "ET": // Ethiopia
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "FI": // Finland
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "FJ": // Fiji
            timeZoneAbbr = isDST ? "FJDT" : "FJT"; // Fiji Daylight Time or Fiji Time
            break;
        case "FK": // Falkland Islands (Malvinas)
            timeZoneAbbr = "FKST"; // Falkland Islands Standard Time
            break;
        case "FM": // Micronesia, Federated States of
            timeZoneAbbr = "CHST"; // Chamorro Standard Time
            break;
        case "FO": // Faroe Islands
            timeZoneAbbr = isDST ? "WEST" : "WET"; // Western European Summer Time or Western European Time
            break;
        case "FR": // France
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "GA": // Gabon
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "GB": // United Kingdom
            timeZoneAbbr = isDST ? "BST" : "GMT"; // British Summer Time or Greenwich Mean Time
            break;
        case "GD": // Grenada
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "GE": // Georgia
            timeZoneAbbr = isDST ? "GET" : "GET"; // Georgia Standard Time
            break;
        case "GF": // French Guiana
            timeZoneAbbr = "GFT"; // French Guiana Time
            break;
        case "GG": // Guernsey
            timeZoneAbbr = isDST ? "BST" : "GMT"; // British Summer Time or Greenwich Mean Time
            break;
        case "GH": // Ghana
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "GI": // Gibraltar
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "GL": // Greenland
            timeZoneAbbr = isDST ? "WGST" : "WGT"; // Western Greenland Summer Time or Western Greenland Time
            break;
        case "GM": // Gambia
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "GN": // Guinea
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "GP": // Guadeloupe
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "GQ": // Equatorial Guinea
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "GR": // Greece
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "GS": // South Georgia and the South Sandwich Islands
            timeZoneAbbr = "GST"; // South Georgia Time
            break;
        case "GT": // Guatemala
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "GU": // Guam
            timeZoneAbbr = "ChST"; // Chamorro Standard Time
            break;
        case "GW": // Guinea-Bissau
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "GY": // Guyana
            timeZoneAbbr = "GYT"; // Guyana Time
            break;
        case "HK": // Hong Kong
            timeZoneAbbr = "HKT"; // Hong Kong Time
            break;
        case "HM": // Heard Island and McDonald Islands
            timeZoneAbbr = "HST"; // Heard and McDonald Islands Time
            break;
        case "HN": // Honduras
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "HR": // Croatia
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "HT": // Haiti
            timeZoneAbbr = isDST ? "EDT" : "EST"; // Eastern Daylight Time or Eastern Standard Time
            break;
        case "HU": // Hungary
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "ID": // Indonesia
            timeZoneAbbr = "WIB"; // Western Indonesian Time
            break;
        case "IE": // Ireland
            timeZoneAbbr = isDST ? "IST" : "GMT"; // Irish Standard Time or Greenwich Mean Time
            break;
        case "IL": // Israel
            timeZoneAbbr = isDST ? "IDT" : "IST"; // Israel Daylight Time or Israel Standard Time
            break;
        case "IM": // Isle of Man
            timeZoneAbbr = isDST ? "BST" : "GMT"; // British Summer Time or Greenwich Mean Time
            break;
        case "IN": // India
            timeZoneAbbr = isDST ? "IST" : "IST"; // Indian Daylight Time or Indian Standard Time
            break;
        case "IO": // British Indian Ocean Territory
            timeZoneAbbr = "IOT"; // Indian Ocean Time
            break;
        case "IQ": // Iraq
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "IR": // Iran, Islamic Republic of
            timeZoneAbbr = "IRST"; // Iran Standard Time
            break;
        case "IS": // Iceland
            timeZoneAbbr = isDST ? "GMT" : "GMT"; // Greenwich Mean Time
            break;
        case "IT": // Italy
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "JE": // Jersey
            timeZoneAbbr = isDST ? "BST" : "GMT"; // British Summer Time or Greenwich Mean Time
            break;
        case "JM": // Jamaica
            timeZoneAbbr = "EST"; // Eastern Standard Time
            break;
        case "JO": // Jordan
            timeZoneAbbr = "EET"; // Eastern European Time
            break;
        case "JP": // Japan
            timeZoneAbbr = "JST"; // Japan Standard Time
            break;
        case "KE": // Kenya
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "KG": // Kyrgyzstan
            timeZoneAbbr = "KGT"; // Kyrgyzstan Time
            break;
        case "KH": // Cambodia
            timeZoneAbbr = "ICT"; // Indochina Time
            break;
        case "KI": // Kiribati
            timeZoneAbbr = "GILT"; // Gilbert Island Time
            break;
        case "KM": // Comoros
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "KN": // Saint Kitts and Nevis
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "KP": // Korea, Democratic People's Republic of
            timeZoneAbbr = "KST"; // Korean Standard Time
            break;
        case "KR": // Korea, Republic of
            timeZoneAbbr = "KST"; // Korean Standard Time
            break;
        case "KW": // Kuwait
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "KY": // Cayman Islands
            timeZoneAbbr = "EST"; // Eastern Standard Time
            break;
        case "KZ": // Kazakhstan
            timeZoneAbbr = isDST ? "KZT" : "KZT"; // Kazakhstan Time
            break;
        case "LA": // Lao People's Democratic Republic
            timeZoneAbbr = "ICT"; // Indochina Time
            break;
        case "LB": // Lebanon
            timeZoneAbbr = "EET"; // Eastern European Time
            break;
        case "LC": // Saint Lucia
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "LI": // Liechtenstein
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "LK": // Sri Lanka
            timeZoneAbbr = "IST"; // Indian Standard Time
            break;
        case "LR": // Liberia
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "LS": // Lesotho
            timeZoneAbbr = "SAST"; // South Africa Standard Time
            break;
        case "LT": // Lithuania
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "LU": // Luxembourg
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "LV": // Latvia
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "LY": // Libya
            timeZoneAbbr = "EET"; // Eastern European Time
            break;
        case "MA": // Morocco
            timeZoneAbbr = "WET"; // Western European Time
            break;
        case "MC": // Monaco
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "MD": // Moldova, Republic of
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "ME": // Montenegro
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "MF": // Saint Martin (French part)
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "MG": // Madagascar
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "MH": // Marshall Islands
            timeZoneAbbr = "MHT"; // Marshall Islands Time
            break;
        case "MK": // North Macedonia
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "ML": // Mali
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "MM": // Myanmar
            timeZoneAbbr = "MMT"; // Myanmar Time
            break;
        case "MN": // Mongolia
            timeZoneAbbr = isDST ? "ULAT" : "ULAT"; // Ulaanbaatar Time
            break;
        case "MO": // Macao
            timeZoneAbbr = "CST"; // China Standard Time
            break;
        case "MP": // Northern Mariana Islands
            timeZoneAbbr = "ChST"; // Chamorro Standard Time
            break;
        case "MQ": // Martinique
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "MR": // Mauritania
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "MS": // Montserrat
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "MT": // Malta
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "MU": // Mauritius
            timeZoneAbbr = "MUT"; // Mauritius Time
            break;
        case "MV": // Maldives
            timeZoneAbbr = "MVT"; // Maldives Time
            break;
        case "MW": // Malawi
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "MX": // Mexico
            if (isDST) {
                switch (offset) {
                    case 300: // Central Daylight Time (CDT)
                        timeZoneAbbr = "CDT";
                        break;
                    case 360: // Mountain Daylight Time (MDT)
                        timeZoneAbbr = "MDT";
                        break;
                    case 420: // Pacific Daylight Time (PDT)
                        timeZoneAbbr = "PDT";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            } else {
                switch (offset) {
                    case 360: // Central Standard Time (CST)
                        timeZoneAbbr = "CST";
                        break;
                    case 420: // Mountain Standard Time (MST)
                        timeZoneAbbr = "MST";
                        break;
                    case 480: // Pacific Standard Time (PST)
                        timeZoneAbbr = "PST";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            }
            break;
        case "MY": // Malaysia
            timeZoneAbbr = "MYT"; // Malaysia Time
            break;
        case "MZ": // Mozambique
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "NA": // Namibia
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "NC": // New Caledonia
            timeZoneAbbr = "NCT"; // New Caledonia Time
            break;
        case "NE": // Niger
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "NF": // Norfolk Island
            timeZoneAbbr = "NFT"; // Norfolk Island Time
            break;
        case "NG": // Nigeria
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "NI": // Nicaragua
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "NL": // Netherlands
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "NO": // Norway
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "NP": // Nepal
            timeZoneAbbr = "NPT"; // Nepal Time
            break;
        case "NR": // Nauru
            timeZoneAbbr = "NRT"; // Nauru Time
            break;
        case "NU": // Niue
            timeZoneAbbr = "NUT"; // Niue Time
            break;
        case "NZ": // New Zealand
            timeZoneAbbr = isDST ? "NZDT" : "NZST"; // New Zealand Daylight Time or New Zealand Standard Time
            break;
        case "OM": // Oman
            timeZoneAbbr = "GST"; // Gulf Standard Time
            break;
        case "PA": // Panama
            timeZoneAbbr = "EST"; // Eastern Standard Time
            break;
        case "PE": // Peru
            timeZoneAbbr = "PET"; // Peru Time
            break;
        case "PF": // French Polynesia
            timeZoneAbbr = "TAHT"; // Tahiti Time
            break;
        case "PG": // Papua New Guinea
            timeZoneAbbr = "PGT"; // Papua New Guinea Time
            break;
        case "PH": // Philippines
            timeZoneAbbr = "PHT"; // Philippine Time
            break;
        case "PK": // Pakistan
            timeZoneAbbr = isDST ? "PKT" : "PKT"; // Pakistan Daylight Time or Pakistan Standard Time
            break;
        case "PL": // Poland
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "PM": // Saint Pierre and Miquelon
            timeZoneAbbr = "PMST"; // Saint Pierre and Miquelon Standard Time
            break;
        case "PN": // Pitcairn
            timeZoneAbbr = "PST"; // Pitcairn Standard Time
            break;
        case "PR": // Puerto Rico
            timeZoneAbbr = isDST ? "AST" : "AST"; // Atlantic Standard Time
            break;
        case "PS": // Palestine, State of
            timeZoneAbbr = isDST ? "IDT" : "IST"; // Israel Daylight Time or Israel Standard Time
            break;
        case "PT": // Portugal
            timeZoneAbbr = isDST ? "WEST" : "WET"; // Western European Summer Time or Western European Time
            break;
        case "PW": // Palau
            timeZoneAbbr = "ChST"; // Chamorro Standard Time
            break;
        case "PY": // Paraguay
            timeZoneAbbr = "PYT"; // Paraguay Time
            break;
        case "QA": // Qatar
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "RE": // Réunion
            timeZoneAbbr = "RET"; // Réunion Time
            break;
        case "RO": // Romania
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "RS": // Serbia
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "RU": // Russian Federation
            timeZoneAbbr = "MSK"; // Moscow Standard Time
            break;
        case "RW": // Rwanda
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "SA": // Saudi Arabia
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "SB": // Solomon Islands
            timeZoneAbbr = "SBT"; // Solomon Islands Time
            break;
        case "SC": // Seychelles
            timeZoneAbbr = "SCT"; // Seychelles Time
            break;
        case "SD": // Sudan
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "SE": // Sweden
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "SG": // Singapore
            timeZoneAbbr = "SGT"; // Singapore Time
            break;
        case "SH": // Saint Helena, Ascension and Tristan da Cunha
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "SI": // Slovenia
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "SJ": // Svalbard and Jan Mayen
            timeZoneAbbr = "CET"; // Central European Time
            break;
        case "SK": // Slovakia
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "SL": // Sierra Leone
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "SM": // San Marino
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "SN": // Senegal
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "SO": // Somalia
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "SR": // Suriname
            timeZoneAbbr = "SRT"; // Suriname Time
            break;
        case "SS": // South Sudan
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "ST": // Sao Tome and Principe
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "SV": // El Salvador
            timeZoneAbbr = "CST"; // Central Standard Time
            break;
        case "SX": // Sint Maarten (Dutch part)
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "SY": // Syrian Arab Republic
            timeZoneAbbr = "EET"; // Eastern European Time
            break;
        case "SZ": // Eswatini
            timeZoneAbbr = "SAST"; // South Africa Standard Time
            break;
        case "TC": // Turks and Caicos Islands
            timeZoneAbbr = "EST"; // Eastern Standard Time
            break;
        case "TD": // Chad
            timeZoneAbbr = "WAT"; // West Africa Time
            break;
        case "TF": // French Southern Territories
            timeZoneAbbr = "UTC+05"; // French Southern and Antarctic Time
            break;
        case "TG": // Togo
            timeZoneAbbr = "GMT"; // Greenwich Mean Time
            break;
        case "TH": // Thailand
            timeZoneAbbr = "ICT"; // Indochina Time
            break;
        case "TJ": // Tajikistan
            timeZoneAbbr = "TJT"; // Tajikistan Time
            break;
        case "TK": // Tokelau
            timeZoneAbbr = "TKT"; // Tokelau Time
            break;
        case "TL": // Timor-Leste
            timeZoneAbbr = "TLT"; // Timor Leste Time
            break;
        case "TM": // Turkmenistan
            timeZoneAbbr = "TMT"; // Turkmenistan Time
            break;
        case "TN": // Tunisia
            timeZoneAbbr = "CET"; // Central European Time
            break;
        case "TO": // Tonga
            timeZoneAbbr = "TOT"; // Tonga Time
            break;
        case "TR": // Turkey
            timeZoneAbbr = "TRT"; // Turkey Time
            break;
        case "TT": // Trinidad and Tobago
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "TV": // Tuvalu
            timeZoneAbbr = "TVT"; // Tuvalu Time
            break;
        case "TW": // Taiwan, Province of China
            timeZoneAbbr = "CST"; // China Standard Time
            break;
        case "TZ": // Tanzania, United Republic of
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "UA": // Ukraine
            timeZoneAbbr = isDST ? "EEST" : "EET"; // Eastern European Summer Time or Eastern European Time
            break;
        case "UG": // Uganda
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "UM": // United States Minor Outlying Islands
            timeZoneAbbr = "Unknown"; // No specific time zone
            break;
        case "US": // United States
            if (isDST) {
                switch (offset) {
                    case 240: // Eastern Daylight Time (EDT)
                        timeZoneAbbr = "EDT";
                        break;
                    case 300: // Central Daylight Time (CDT)
                        timeZoneAbbr = "CDT";
                        break;
                    case 360: // Mountain Daylight Time (MDT)
                        timeZoneAbbr = "MDT";
                        break;
                    case 420: // Pacific Daylight Time (PDT)
                        timeZoneAbbr = "PDT";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            } else {
                switch (offset) {
                    case 300: // Eastern Standard Time (EST)
                        timeZoneAbbr = "EST";
                        break;
                    case 360: // Central Standard Time (CST)
                        timeZoneAbbr = "CST";
                        break;
                    case 420: // Mountain Standard Time (MST)
                        timeZoneAbbr = "MST";
                        break;
                    case 480: // Pacific Standard Time (PST)
                        timeZoneAbbr = "PST";
                        break;
                    default:
                        timeZoneAbbr = "Unknown";
                        break;
                }
            }
            break;
        case "UY": // Uruguay
            timeZoneAbbr = isDST ? "UYT" : "UYT"; // Uruguay Summer Time or Uruguay Standard Time
            break;
        case "UZ": // Uzbekistan
            timeZoneAbbr = "UZT"; // Uzbekistan Time
            break;
        case "VA": // Holy See (Vatican City State)
            timeZoneAbbr = isDST ? "CEST" : "CET"; // Central European Summer Time or Central European Time
            break;
        case "VC": // Saint Vincent and the Grenadines
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "VE": // Venezuela, Bolivarian Republic of
            timeZoneAbbr = "VET"; // Venezuela Time
            break;
        case "VG": // Virgin Islands, British
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "VI": // Virgin Islands, U.S.
            timeZoneAbbr = "AST"; // Atlantic Standard Time
            break;
        case "VN": // Vietnam
            timeZoneAbbr = "ICT"; // Indochina Time
            break;
        case "VU": // Vanuatu
            timeZoneAbbr = "VUT"; // Vanuatu Time
            break;
        case "WF": // Wallis and Futuna
            timeZoneAbbr = "WFT"; // Wallis and Futuna Time
            break;
        case "WS": // Samoa
            timeZoneAbbr = "WST"; // West Samoa Time
            break;
        case "YE": // Yemen
            timeZoneAbbr = "AST"; // Arabian Standard Time
            break;
        case "YT": // Mayotte
            timeZoneAbbr = "EAT"; // East Africa Time
            break;
        case "ZA": // South Africa
            timeZoneAbbr = isDST ? "SAST" : "SAST"; // South Africa Standard Time
            break;
        case "ZM": // Zambia
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        case "ZW": // Zimbabwe
            timeZoneAbbr = "CAT"; // Central Africa Time
            break;
        default:
            timeZoneAbbr = "Unknown";
            break;
    }
    return timeZoneAbbr;
}


export const formatDateToLocalDetectTz = ( d ) => {
    
    var utc             = new Date( d );
    var offset          = utc.getTimezoneOffset();

    // Output the time zone abbreviation
    

    //console.log("tz", sign, offset, utcOffsetString )
    const timeZoneAbbr = getCountryTimeAbbreviation( utc, offset );

    console.log("Time zone abbreviation: " + timeZoneAbbr);

    //console.log("tz", offset);
    var local = new Date((new Date(d)).getTime() - offset * 60000);

    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${local.toLocaleDateString()} ${local.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: browserTimeZone })} ${timeZoneAbbr}`;

}


export const formatDateToLocal = (d, tz) => {
    const l             = new Date(d);
    var offset          = l.getTimezoneOffset();


    // Format date and time to local format
    const localDateString = l.toLocaleDateString();
    const localTimeString = l.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Get timezone abbreviation
    const timezoneAbbr = getTimezoneAbbr(l, tz, offset);

    // Combine local date, time, and timezone abbreviation
    return `${localDateString} ${localTimeString} ${timezoneAbbr}`;
}

// Function to get timezone abbreviation
const getTimezoneAbbr = (l, tz) => {
    const countryCode = mapTimeZoneAbbreviationsToISO3166Codes(tz);

    console.log("is... ", l, tz);

    let timeZoneAbbr = 'unknown';

    const timezones = {
        "AD": { std: "CET", dst: "CEST" },
        "AE": { std: "GST" },
        "AF": { std: "AFT" },
        "AG": { std: "AST" },
        "AI": { std: "AST" },
        "AL": { std: "CET", dst: "CEST" },
        "AM": { std: "AMT" },
        "AO": { std: "WAT" },
        "AQ": { std: "AQTT" },
        "AR": { std: "ART" },
        "AS": { std: "SST" },
        "AT": { std: "CET", dst: "CEST" },
        "AU": { std: "AEST", dst: "AEDT" },
        "AW": { std: "AST" },
        "AX": { std: "EET", dst: "EEST" },
        "AZ": { std: "AZT", dst: "AZST" },
        "BA": { std: "CET", dst: "CEST" },
        "BB": { std: "AST" },
        "BD": { std: "BDT" },
        "BE": { std: "CET", dst: "CEST" },
        "BF": { std: "GMT" },
        "BG": { std: "EET", dst: "EEST" },
        "BH": { std: "AST" },
        "BI": { std: "CAT" },
        "BJ": { std: "WAT" },
        "BL": { std: "AST" },
        "BM": { std: "AST" },
        "BN": { std: "BNT" },
        "BO": { std: "BOT" },
        "BQ": { std: "AST" },
        "BR": { std: "BRT", dst: "BRST" },
        "BS": { std: "EST", dst: "EDT" },
        "BT": { std: "BTT" },
        "BV": { std: "CET" },
        "BW": { std: "CAT" },
        "BY": { std: "EET", dst: "EEST" },
        "BZ": { std: "CST" },
        "CA": {
            std: { 300: "EST", 360: "CST", 420: "MST", 480: "PST" },
            dst: { 240: "EDT", 300: "CDT", 360: "MDT", 420: "PDT" }
        },
        "CC": { std: "CCT" },
        "CD": { std: "WAT" },
        "CF": { std: "WAT" },
        "CG": { std: "WAT" },
        "CH": { std: "CET", dst: "CEST" },
        "CI": { std: "GMT" },
        "CK": { std: "CKT" },
        "CL": { std: "CLT", dst: "CLST" },
        "CM": { std: "WAT" },
        "CN": { std: "CST" },
        "CO": { std: "COT" },
        "CR": { std: "CST" },
        "CU": { std: "CST", dst: "CDT" },
        "CV": { std: "CVT" },
        "CW": { std: "AST" },
        "CX": { std: "CXT" },
        "CY": { std: "EET", dst: "EEST" },
        "CZ": { std: "CET", dst: "CEST" },
        "DE": { std: "CET", dst: "CEST" },
        "DJ": { std: "EAT" },
        "DK": { std: "CET", dst: "CEST" },
        "DM": { std: "AST" },
        "DO": { std: "EST", dst: "EDT" },
        "DZ": { std: "CET" },
        "EC": { std: "ECT" },
        "EE": { std: "EET", dst: "EEST" },
        "EG": { std: "EET" },
        "EH": { std: "WET" },
        "ER": { std: "EAT" },
        "ES": { std: "CET", dst: "CEST" },
        "ET": { std: "EAT" },
        "FI": { std: "EET", dst: "EEST" },
        "FJ": { std: "FJT", dst: "FJDT" },
        "FK": { std: "FKST" },
        "FM": { std: "CHST" },
        "FO": { std: "WET", dst: "WEST" },
        "FR": { std: "CET", dst: "CEST" },
        "GA": { std: "WAT" },
        "GB": { std: "GMT", dst: "BST" },
        "GD": { std: "AST" },
        "GE": { std: "GET" },
        "GF": { std: "GFT" },
        "GG": { std: "GMT", dst: "BST" },
        "GH": { std: "GMT" },
        "GI": { std: "CET", dst: "CEST" },
        "GL": { std: "WGT", dst: "WGST" },
        "GM": { std: "GMT" },
        "GN": { std: "GMT" },
        "GP": { std: "AST" },
        "GQ": { std: "WAT" },
        "GR": { std: "EET", dst: "EEST" },
        "GS": { std: "GST" },
        "GT": { std: "CST" },
        "GU": { std: "ChST" },
        "GW": { std: "GMT" },
        "GY": { std: "GYT" },
        "HK": { std: "HKT" },
        "HM": { std: "HST" },
        "HN": { std: "CST" },
        "HR": { std: "CET", dst: "CEST" },
        "HT": { std: "EST", dst: "EDT" },
        "HU": { std: "CET", dst: "CEST" },
        "ID": { std: "WIB" },
        "IE": { std: "GMT", dst: "IST" },
        "IL": { std: "IST", dst: "IDT" },
        "IM": { std: "GMT", dst: "BST" },
        "IN": { std: "IST" },
        "IO": { std: "IOT" },
        "IQ": { std: "AST" },
        "IR": { std: "IRST" },
        "IS": { std: "GMT" },
        "IT": { std: "CET", dst: "CEST" },
        "JE": { std: "GMT", dst: "BST" },
        "JM": { std: "EST" },
        "JO": { std: "EET" },
        "JP": { std: "JST" },
        "KE": { std: "EAT" },
        "KG": { std: "KGT" },
        "KH": { std: "ICT" },
        "KI": { std: "GILT" },
        "KM": { std: "EAT" },
        "KN": { std: "AST" },
        "KP": { std: "KST" },
        "KR": { std: "KST" },
        "KW": { std: "AST" },
        "KY": { std: "EST" },
        "KZ": { std: "KZT" },
        "LA": { std: "ICT" },
        "LB": { std: "EET" },
        "LC": { std: "AST" },
        "LI": { std: "CET", dst: "CEST" },
        "LK": { std: "IST" },
        "LR": { std: "GMT" },
        "LS": { std: "SAST" },
        "LT": { std: "EET", dst: "EEST" },
        "LU": { std: "CET", dst: "CEST" },
        "LV": { std: "EET", dst: "EEST" },
        "LY": { std: "EET" },
        "MA": { std: "WET" },
        "MC": { std: "CET", dst: "CEST" },
        "MD": { std: "EET", dst: "EEST" },
        "ME": { std: "CET", dst: "CEST" },
        "MF": { std: "AST" },
        "MG": { std: "EAT" },
        "MH": { std: "MHT" },
        "MK": { std: "CET", dst: "CEST" },
        "ML": { std: "GMT" },
        "MM": { std: "MMT" },
        "MN": { std: "ULAT" },
        "MO": { std: "CST" },
        "MP": { std: "ChST" },
        "MQ": { std: "AST" },
        "MR": { std: "GMT" },
        "MS": { std: "AST" },
        "MT": { std: "CET", dst: "CEST" },
        "MU": { std: "MUT" },
        "MV": { std: "MVT" },
        "MW": { std: "CAT" },
        "MX": {
            std: { 360: "CST", 420: "MST", 480: "PST" },
            dst: { 300: "CDT", 360: "MDT", 420: "PDT" }
        },
        "MY": { std: "MYT" },
        "MZ": { std: "CAT" },
        "NA": { std: "CAT" },
        "NC": { std: "NCT" },
        "NE": { std: "WAT" },
        "NF": { std: "NFT" },
        "NG": { std: "WAT" },
        "NI": { std: "CST" },
        "NL": { std: "CET", dst: "CEST" },
        "NO": { std: "CET", dst: "CEST" },
        "NP": { std: "NPT" },
        "NR": { std: "NRT" },
        "NU": { std: "NUT" },
        "NZ": { std: "NZST", dst: "NZDT" },
        "OM": { std: "GST" },
        "PA": { std: "EST" },
        "PE": { std: "PET" },
        "PF": { std: "TAHT" },
        "PG": { std: "PGT" },
        "PH": { std: "PHT" },
        "PK": { std: "PKT" },
        "PL": { std: "CET", dst: "CEST" },
        "PM": { std: "PMST" },
        "PN": { std: "PST" },
        "PR": { std: "AST" },
        "PS": { std: "IST", dst: "IDT" },
        "PT": { std: "WET", dst: "WEST" },
        "PW": { std: "ChST" },
        "PY": { std: "PYT" },
        "QA": { std: "AST" },
        "RE": { std: "RET" },
        "RO": { std: "EET", dst: "EEST" },
        "RS": { std: "CET", dst: "CEST" },
        "RU": { std: "MSK" },
        "RW": { std: "CAT" },
        "SA": { std: "AST" },
        "SB": { std: "SBT" },
        "SC": { std: "SCT" },
        "SD": { std: "EAT" },
        "SE": { std: "CET", dst: "CEST" },
        "SG": { std: "SGT" },
        "SH": { std: "GMT" },
        "SI": { std: "CET", dst: "CEST" },
        "SJ": { std: "CET" },
        "SK": { std: "CET", dst: "CEST" },
        "SL": { std: "GMT" },
        "SM": { std: "CET", dst: "CEST" },
        "SN": { std: "GMT" },
        "SO": { std: "EAT" },
        "SR": { std: "SRT" },
        "SS": { std: "CAT" },
        "ST": { std: "GMT" },
        "SV": { std: "CST" },
        "SX": { std: "AST" },
        "SY": { std: "EET" },
        "SZ": { std: "SAST" },
        "TC": { std: "EST" },
        "TD": { std: "WAT" },
        "TF": { std: "UTC+05" },
        "TG": { std: "GMT" },
        "TH": { std: "ICT" },
        "TJ": { std: "TJT" },
        "TK": { std: "TKT" },
        "TL": { std: "TLT" },
        "TM": { std: "TMT" },
        "TN": { std: "CET" },
        "TO": { std: "TOT" },
        "TR": { std: "TRT" },
        "TT": { std: "AST" },
        "TV": { std: "TVT" },
        "TW": { std: "CST" },
        "TZ": { std: "EAT" },
        "UA": { std: "EET", dst: "EEST" },
        "UG": { std: "EAT" },
        "UM": { std: "Unknown" },
        "US": {
            std: { 300: "EST", 360: "CST", 420: "MST", 480: "PST" },
            dst: { 240: "EDT", 300: "CDT", 360: "MDT", 420: "PDT" }
        },
        "UY": { std: "UYT" },
        "UZ": { std: "UZT" },
        "VA": { std: "CET", dst: "CEST" },
        "VC": { std: "AST" },
        "VE": { std: "VET" },
        "VG": { std: "AST" },
        "VI": { std: "AST" },
        "VN": { std: "ICT" },
        "VU": { std: "VUT" },
        "WF": { std: "WFT" },
        "WS": { std: "WST" },
        "YE": { std: "AST" },
        "YT": { std: "EAT" },
        "ZA": { std: "SAST" },
        "ZM": { std: "CAT" },
        "ZW": { std: "CAT" }
    };

    if (countryCode in timezones) {
        const timezone = timezones[countryCode];
        if (typeof timezone === 'object') {
            if ('std' in timezone && 'dst' in timezone) {
                timeZoneAbbr = moment(l).tz(tz).isDST() ? timezone.dst : timezone.std;
            } else {
                timeZoneAbbr = timezone.std || timezone;
            }
        } else {
            timeZoneAbbr = timezone;
        }
    }

    return timeZoneAbbr;
}






export const getLocaleDateTime = ( date ) => {
    const today = new Date();
    const viewerTimezoneOffset = today.getTimezoneOffset();

    // Check if the passed-in date is before DST
    const isBeforeDST = (date.getMonth() < today.getMonth()) ||
                        (date.getMonth() === today.getMonth() && date.getDate() < today.getDate());

    // If the date is before DST, return it as it is
    if (isBeforeDST) {
        return date;
    } else {
        // Calculate the UTC time with the viewer's timezone offset
        return new Date(date.getTime() - viewerTimezoneOffset * 60000);
    }

}



export const getDateFormatString = (locale) => {

    // sometimes it seems that chrome sometimes returns the wrong format. 
    if (locale === 'en-AU' || locale === 'en-GB') {
        return 'dd/MM/yyyy HH:mm';
    }

    const options = {
      hour: "numeric",
      minute: "numeric",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());

    const dateobj = formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "dd";
          case "month":
            return "MM";
          case "year":
            return "yyyy";
          default:
            return '';
        }
      })
      .filter(Boolean)
      .join("/");


      const timeobj = formatObj.map((obj) => {
        switch (obj.type) {
          case "hour":
            return "HH";
          case "minute":
            return "mm";
          default:
            return '';
        }
      })
      .filter(Boolean)
      .join(":");

      return `${dateobj.trim()} ${timeobj.trim()}`;

  };

  export const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
  }