import React, { useState } from "react"
import { FileDrop } from 'react-file-drop'
import WarningIcon from '@mui/icons-material/Warning';


export function FileUploader( props ) {
  const {setFile, max, width, height} = props;
  const fileInputRef = React.useRef(null);
  const [uploaderror, setUploadError] = React.useState(false);

  const onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...
    console.log("Files", files);
    onFileDrop(event, files);

  }


  const onFileDrop = (e, f) => {
      
    e.preventDefault();
    e.stopPropagation();
    console.log("FileList event", f);
    if(f[0])
    {
      
      if(f[0].type === 'image/jpeg' || f[0].type === 'image/jpg' || f[0].type === 'image/png')
      {
        if(f[0].size <= max)
        {
          //setFile(f[0]);
          const url = URL.createObjectURL(f[0]);

          const img = new Image();
          img.src = url;
          img.onload = (e) => {
            // img.width
            // img.height
            if(img.width > width)
            {
              setUploadError(`Image is too wide. Please resize and try again. Max width ${width} pixels`);
            }
            else  if(img.height > height)
            {
              setUploadError(`Image is too tall. Please resize and try again. Max height ${height} pixels`);
            }
            else
            {
              setFile( f[0] );

            }
          };

        }
        else
        {
          setUploadError('Image is too big. Please resize and try again. Max size in Bytes: ' + max);
        }
      }
      else
      {
        setUploadError('Invalid file type');
      }
    }
    else
    {
        setUploadError('File selection error');
    }
  }
  

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  return (
    <>
      <div className="drop-area" onClick={onTargetClick}>
          <input onChange={onFileInputChange} ref={fileInputRef} type="file" className="hidden" accept="image/png, image/jpeg, image/jpg" />
          <FileDrop 
                onTargetClick={onTargetClick}
                onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                onFrameDrop={(event) => console.log("onFrameDrop", event)}
                onDragOver={(event) => console.log('onDragOver', event)}
                onDragLeave={(event) => console.log('onDragLeave', event)}
                onDrop={(files, event) =>  {console.log('onDrop', event); onFileDrop(event, files)}}   
            />
            <div className="label">
                Drop File Here
                <div className="small">Drop or click here to start the image upload. We accept JPEG or PNG files.</div>
                {
                  !!uploaderror &&
                  <div className="uploaderror"><span><WarningIcon /></span>Error: {uploaderror}</div>
                }
            </div>
      </div>
    </>
  )
}