import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
//import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card, CardContent, CardHeader, ListItemIcon } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BlockIcon from '@mui/icons-material/Block';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ListItemText = ( props ) => {

  return (
    <li>
      <div className='MuiTypography-root MuiTypography-body1 MuiListItemText-primary' dangerouslySetInnerHTML={{ __html: props?.primary }} />
      
      {
        props.secondary &&
        <div className='MuiTypography-root MuiTypography-body2 MuiListItemText-secondary' style={{paddingTop: "15px"}} dangerouslySetInnerHTML={{ __html: props.secondary }} />
      }
    </li>
  )
}

export default function QuestionnaireViewer( props ) {

    const {title, qandas, close} = props;


  const handleClose = () => {
    close();
  };

  const jsonUnescape = (str) =>{
    return  str?.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
}

  const formatOptions = ( answer, options) =>{
    console.log(answer, options);
      const a = options?.find( o => answer?.answer === o.id);
      return jsonUnescape(a?.text  || '');
  }

  const listItemDOM = ( qa ) => {
    switch(qa?.type)
    {
      case 'text':
      case 'input':
      {
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')} secondary={jsonUnescape(qa?.answer?.answer || '')} />
            </ListItem>
            <Divider />
          </React.Fragment>);
      }

      case 'radio':
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')} secondary={formatOptions(qa?.answer, qa?.options)} />
            </ListItem>
            <Divider />
          </React.Fragment>);     
      

      case 'multi-checkbox':
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')}/>
            </ListItem>
            <List component="div" disablePadding>
                    {
                      qa?.options?.map(o => {

                          const a = qa.answer?.find( ans => {
                            return ans.answer === o.id;
                          });
                          if(!!a)
                          {
                            return (
                                    <ListItem key={`ans-${o.id}`}>
                                        <ListItemIcon>
                                            <CheckBoxIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={jsonUnescape(o?.text)}/>
                                    </ListItem>);
                          }
                          else
                          {
                            return (
                              <ListItem>
                                  <ListItemIcon>
                                      <BlockIcon/>
                                  </ListItemIcon>
                                  <ListItemText primary={jsonUnescape(o?.text)}/>
                              </ListItem>);                            
                          }

                      })
                    }
                </List>
            <Divider />
          </React.Fragment>);  

      case 'checkbox':
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')}/>
            </ListItem>
            <List component="div" disablePadding>
                    {
                      !!qa?.answer && 
                      <ListItem> 
                          <ListItemIcon>
                              <CheckBoxIcon/>
                          </ListItemIcon>
                          <ListItemText primary="Yes"/>
                      </ListItem> 
                    }
                    {
                      !qa?.answer && 
                      <ListItem> 
                        <ListItemIcon>
                            <BlockIcon/>
                            <ListItemText primary="No"/>
                        </ListItemIcon>
                      </ListItem> 

                    }
                </List>
            <Divider />
          </React.Fragment>);   

      case 'text-block':
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')} />
            </ListItem>
          </React.Fragment>);     

      case 'text-block-confirm':
        return  (
          <React.Fragment key={qa?.id}>
            <ListItem>
                <ListItemText primary={jsonUnescape(qa?.text  || '')}/>
            </ListItem>
            <List component="div" disablePadding>
                    {
                      !!qa?.answer && 
                      <ListItem> 
                          <ListItemIcon>
                              <CheckBoxIcon/>
                          </ListItemIcon>
                          <ListItemText primary="Yes"/>
                      </ListItem> 
                    }
                    {
                      !qa?.answer && 
                      <ListItem> 
                        <ListItemIcon>
                            <BlockIcon/>
                            <ListItemText primary="No"/>
                        </ListItemIcon>
                      </ListItem> 

                    }
                </List>
            <Divider />
          </React.Fragment>);   

      default:
        return 'Unknowm data type';
    }

  }

  //console.log("Viewer", qandas);
  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="body"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Questionnaire
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Card>
          <CardHeader />
          <CardContent>
          <List sx={{width: '100%'}}>

            {
              qandas?.map(qa => (
                  listItemDOM( qa )                
              ))
            }
          </List>
  
          </CardContent>
        </Card>
       </Dialog>
    </div>
  );
}