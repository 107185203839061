import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

const PauseSubscriptionDialog = ({ open, onClose, onPause }) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        Are you sure you want to pause this subscription?
      </DialogContent>
      <DialogActions>
        <Button onClick={onPause}>Yes, Pause</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PauseSubscriptionDialog;