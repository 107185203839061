import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  Backdrop, CircularProgress } from '@mui/material';
import Copyright from '../elements/Copyright';
import { post } from '../../libraries/network';


const theme = createTheme();

export default function SignIn() {

  const [email, setEmail] = React.useState('');
  const [emailerror, setEmailError] = React.useState(false);
  const [message, setMessage] = React.useState(' ');
  const [busy, setBusy] = React.useState(false);


  React.useEffect(() => {
    // Check URL search parameters for email
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);


  const handleSubmit = async(event) => {
    event.preventDefault();
    setBusy(true);
    setEmailError(false);
    if(emailerror)
    {
      setBusy(false);
      setMessage("Please check your email address and try again");
      setEmailError(true);
      return;
    }
    else
    {
      setMessage(" ");
      setEmailError(false);

    }
    try{
      let r = await post('/clientrestapi/initialize', `email=${encodeURIComponent(email)}`);
      if(r.status === 'OK')
      {
        setBusy(false);
        window.location.href = `/client/authenticate?email=${email}`;
        

      }
      else
      {
        setBusy(false);
        setEmailError(true);
        setMessage(r?.message || 'This account cannot be found.');
      }
    }
    catch(e)
    {
      setBusy(false);
      setMessage(e || 'This account cannot be found.');
      if(window?.static_override)
      {
        // OK if in client mode - we can redirect
        setMessage('Account unknown');
        setEmailError(true);

        setTimeout(() => {
          window.location.href = `/client/authenticate?email=${email}`;
        }, 5000);

      }

    }
  };

  const onSetEmail = ( email ) => {

    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValidEmail = emailRegex.test(email);
    setEmailError( !isValidEmail );
    console.log("Email validation", isValidEmail );
    setEmail( email );
  }





  return (
    <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs" >
          <CssBaseline />
          <Box
            className="loginBox"
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%' // Ensure the Box component spans the full width
            }}
          >
            <Avatar sx={{ m: 1 }} src="/logo192.png" style={{ width: '100px', height: '100px' }}>
              <LockOutlinedIcon />
            </Avatar>

          

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 , width: '100%'}}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => onSetEmail(e.target.value)}
                  error={emailerror}
                  autoFocus
                  helperText={message}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Next
                </Button>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Box sx={{ borderBottom: '1px solid #ccc', width: '100%' }} />
                  <Box sx={{ px: 2, color: '#777', fontSize: '14px' }}>or</Box>
                  <Box sx={{ borderBottom: '1px solid #ccc', width: '100%' }} />
                </Box>

                <Button
                  component="a"
                  href="https://go.reservie.net/register/stage1"
                  fullWidth
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign up
                </Button>
              </Box>

          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={busy}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        
          </Container>

    </ThemeProvider>
  );
}