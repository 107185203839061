import {  Card, CardContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Menu, MenuItem, Radio, RadioGroup, Skeleton, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { BottomBar } from '../elements/BottomBar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DeleteButton } from '../elements/DeleteButton';
import MultiDatePicker from '../elements/MultiDatePicker';
import { StyledEngineProvider } from '@mui/material/styles';
import TimePickerElement from '../elements/TimePicker';
import DurationPickerElement from '../elements/DurationPicker';
import FacilitatorPickerElement from '../elements/FacilitatorPicker';
import TimezoneSelectorElement from '../elements/TimeZoneSelector';

function CardHeader(props)
{
    const {title, subtitle} = props;
    return (
        <Box sx={{pl:2, pt:3}}>
            <Typography variant="h5" component="div" gutterBottom>{title}</Typography>
            <Typography variant="subtitle2" gutterBottom>{subtitle}</Typography>
        </Box>
    )
}


function CardSubHeader(props)
{
    const {title, subtitle} = props;
    return (
        <Box sx={{pl:2, pt:3}}>
            <Typography variant="h6" component="div" gutterBottom>{title}</Typography>
            <Typography variant="subtitle2" gutterBottom>{subtitle}</Typography>
        </Box>
    )
}

export default function EventEditor( props )
{
    const {id, name, description, wl_active, mode, dates, smode, default_time, timezone, duration, facilitators, facilitator, payment} = props;

    const [formData, setFormData] = useState({
        id: id || '',
        name: name || '',
        description: description || '',
        wl_active: wl_active || false,
        mode: mode || 'physical',
        dates: dates || [],
        smode: smode || 'manual',
        default_time: default_time || new Date(),
        tz: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        duration: duration || '00:00',
        facilitator: facilitator || -1,
        payment: payment || 'card',
    });
    

    const getDateFormatString = (locale) => {
    
        const options = {
          hour: "numeric",
          minute: "numeric",
          day: "numeric",
          month: "numeric",
          year: "numeric",
        };
      
        const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(
          Date.now()
        );
      
        return formatObj
          .map((obj) => {
            switch (obj.type) {
              case "hour":
                return "HH";
              case "minute":
                return "mm";
              case "second":
                return "SS";
              case "day":
                return "dd";
              case "month":
                return "MM";
              case "year":
                return "yyyy";
              default:
                return obj.value;
            }
          })
          .join("");
      }
      
      
        const getNavigatorLanguage = () => {
          if (navigator.languages && navigator.languages.length) {
            return navigator.languages[0];
          } else {
            return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
          }
        }


    React.useEffect(() => {


    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

    }

    const handleDateChange = (i, v) => {
        const x = Object.assign({}, formData);
        const index = x?.dates?.length || 0;
        if (i < index) 
        {
            x?.dates?.splice(i, 1,v );
        }
        else
        {
            x?.dates.push(v);
        }
        setFormData((prevData) => ({
            ...prevData,
            ['dates']: x?.dates,
          }));
    }


    return (
        <div className="rvNS">
            <Box>
                <ResponsiveAppBar widgets={[]}/>                
            </Box>
   
            <form  onSubmit={handleSubmit}>
                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardHeader title="Basic information" subtitle="Use this section to define the basic configuration of this event" />
                        <CardContent>  
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                    name="name"
                                    label="Event name"
                                    variant="outlined"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                    helperText="This is the name of your event. It should summarise the event that you are selling. For example, 'Monday Yoga with Henry'"
                                   />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    name="description"
                                    label="Event Description"
                                    variant="outlined"
                                    multiline={true}
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows={4}
                                    fullWidth
                                    helperText="Add a desciption of your event. This is displayed to the customer to give them a better idea of what you are offering."                                    
                                    />
                                </Grid> 
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={formData?.wl_active}
                                            onChange={handleChange}
                                            name="waitinglist"
                                            color="primary"
                                        />
                                        }
                                        label="Activate waiting list"
                                    />
                                </Grid>                     
                            </Grid>
                        </CardContent>

                        <CardSubHeader title="Assign categories" subtitle="Categories provide a way to tag events. When a schedule is also tagged, it will only display events with matching categories."/>
                        <CardContent>
                        {
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                {
                                    !!!facilitators && (
                                        <FacilitatorPickerElement label={"Who is teaching this event?"} facilitators={facilitators || []} facilitator={formData?.facilitator} helper="If you do not use facilitators, you can leave this unassigned."/>
                                    )
                                }
                                </Grid>
                                                                  
                            </Grid>

                        }                           
                        </CardContent>
                        <CardSubHeader title="Should a special term of sale be applied?" subtitle="Terms are conditions of sale that must be agreed to to enable a sale to complete."/>
                        <CardContent>
                        {
                            <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    {
                                        !!!facilitators && (
                                            <FacilitatorPickerElement label={"Who is teaching this event?"} facilitators={facilitators || []} facilitator={formData?.facilitator} helper="Facilitators are teachers, instructors or other staff members that will be providing instruction"/>
                                        )
                                    }
                
                                    </Grid>                                    
                            </Grid>

                        }   
                        </CardContent>    
                        <CardSubHeader title="Should a location be assigned?" subtitle="A location is typically a phyisical address. By assigning a location, this is listed publicly and if required a map assigned."/>
                        <CardContent>
                        {
                            <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    {
                                        !!!facilitators && (
                                            <FacilitatorPickerElement label={"Who is teaching this event?"} facilitators={facilitators || []} facilitator={formData?.facilitator} helper="Facilitators are teachers, instructors or other staff members that will be providing instruction"/>
                                        )
                                    }
                
                                    </Grid>                                    
                            </Grid>

                        }   
                        </CardContent>                                             
                    </Card>  
                </Box> 

                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardHeader title="Pricing" subtitle="Configure how the events should be paid for."/>
                        <CardContent>  
                            <Grid container spacing={2}>                            
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="payment-radios-label">Which of the following payment methods would you like to accept?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="payment-radios-label"
                                            name="payment"
                                            value={formData?.payment}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel value="card" control={<Radio />} label="Credit card or debit card only" sx={{ml:-1}}/>
                                            <FormControlLabel value="pass" control={<Radio />} label="Pass credits only" />
                                            <FormControlLabel value="all" control={<Radio />} label="Credit card, debit card or pass credit" />
                                        </RadioGroup>                                        
                                        <FormHelperText size="small" sx={{ml:1}}>Defines how people should pay for an event. Credit card/debit card requires people to pay directly for the event at the price you specify. Pass credits, allow associated pass credits to be used.</FormHelperText>                            
                                    </FormControl>
                                </Grid>                             
                            </Grid>
                        </CardContent>
                        <CardSubHeader title="Pricing options" />
                        <CardContent>
                            <Grid container>
                            <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    #
                                                </TableCell>
                                                <TableCell>
                                                    Name
                                                </TableCell> 
                                                <TableCell>
                                                    Mode
                                                </TableCell>  
                                                <TableCell>
                                                    Price - card payment
                                                </TableCell>   
                                                <TableCell>
                                                    Price - pass credits
                                                </TableCell> 
                                                <TableCell>
                                                    Delete
                                                </TableCell>                                                                                                                                                                                             
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableCell>1</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableBody>
                                    </Table>
                                </Grid>                                 
                            </Grid>
                        </CardContent>
                    </Card>  
                </Box> 

                     
                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardContent>  
                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                Scheduling
                            </Typography>
                            <Grid container spacing={2}>   
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="smode-radios-label">How should dates be scheduled?</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="smode-radios-label"
                                            name="smode"
                                            value={formData?.smode}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                                            <FormHelperText>Select this option if you want to manage the dates for this event yourself.</FormHelperText>
                                            <FormControlLabel value="automated" control={<Radio />} label="Automated" />
                                            <FormHelperText>Select this option if you want reservie to manage the dates for this event.</FormHelperText>

                                        </RadioGroup> 
                                    </FormControl>
                                </Grid> 
                                {

                                    
                                } 
                                {
                                    formData?.smode === "automated" &&
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel id="s-auto-mode-radios-label">When should this event repeat?</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="s-auto-mode-radios-label"
                                                name="s-automode"
                                                value={formData?.automode}
                                                onChange={handleChange}
                                            >

                                                <FormControlLabel value="weekly" control={<Radio />} label="Repeating weekly" sx={{ml:-1}}/>
                                                <FormControlLabel value="montly" control={<Radio />} label="Repeating monthly." />
                                            </RadioGroup> 
                                            <FormHelperText size="small" sx={{ml:-1}}>Automated scheduling operates in either weekly or monthly mode. Weekly scheduling will repeat an event on a weekly basis. Monthly will repeat an event on a monthly basis.</FormHelperText>                            
                                        </FormControl>                                    
                                    </Grid>
                                }
                       
                            </Grid>
                        </CardContent>
                    </Card>  
                </Box> 

                {
                formData?.smode === "manual" &&
                    <Box sx={{p: 2}}>
                        <Card variant="outlined">
                            <CardHeader title="Manual Schedule Setup" subtitle="Select one or more dates from the calendar, and configure the default time of the class, the timezone and the duration using the form below."></CardHeader>
                            <CardContent>  
                                <Box sx={{pl: 4}}>
                                    <Grid container spacing={4}>
                                    
                                        <Grid className="rvDatePicker" item xs={12} lg={5}>
                                            <StyledEngineProvider injectFirst>
                                                <MultiDatePicker sx={{m:0}} minDate={new Date()} dates={formData?.dates?.map( d => d.date) || []} onDatesChange={(ds) => {
                                                    const ndates = ds?.map( d => 
                                                        {
                                                            const nd = new Date(d);
                                                            const dt = new Date(formData?.default_time);
                                                            console.log(dt);

                                                            nd.setHours(dt?.getHours(),dt?.getMinutes());
                                                            const x = { 
                                                                date: nd,
                                                                facilitator: facilitator
                                                            }
                                                            return x;
                                                        })
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['dates']: ndates,
                                                        }));
                                                }}/>
                                            </StyledEngineProvider>                                          
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2} sx={{pt:3}}>
                                                <TimePickerElement label="Time of day" time={formData?.default_time} onTimeChange={(t) => {
                                                    const dates = formData?.dates.map( d => {
                                                        const time = new Date( t );
                                                        const x = new Date();
                                                        x.setHours(time.getHours(), time.getMinutes(), 0,0);
                                                        return x;
                                                    } );
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['default_time']: t,
                                                        ['dates']: dates,
                                                        }));
                                                }}/>
                                                <TimezoneSelectorElement label="Timezone" tz={formData?.tz} onTZChange={(t) => {
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['tz']: t,
                                                        }));
                                                }} />
                                                <DurationPickerElement label="Duration" duration={formData?.duration} onChangeDuration={(d) => {
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['duration']: d,
                                                        }));                                                    
                                                }} />
                                                <FacilitatorPickerElement label="Facilitator" facilitator={formData?.facilitator} onChangeFacilitator={(d) => {
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['facilitator']: d,
                                                        }));                                                    
                                                }} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>  
                    </Box>  
                }               

                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardHeader title="Assigned schedule" subtitle="Your assigned schedule has been listed below. This has been generated based on your configuration. You can modify individual dates below"/>
                        <CardContent>  
                            <Box sx={{pl:4}}>
                   
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Spaces</TableCell>
                                                <TableCell>Facilitator</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                        {
                                            !formData?.dates?.length  &&
                                            <TableRow key={`date-none`}>
                                                <TableCell colSpan={5}>
                                                    <Typography variant='h6' component="div" textAlign="center"> No assigned dates</Typography>
                                                </TableCell>
                                            </TableRow>

                                        }
                                        {
                                            !!formData?.dates?.length && formData?.dates.map((v, i) => (
                                                <TableRow key={`date-${i}`}>
                                                    <TableCell>{parseInt(i)+1}</TableCell>
                                                    <TableCell>      
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                            <DateTimePicker
                                                                renderInput={(props) => <TextField {...props} variant="standard" />}
                                                                label="Date and time"
                                                                value={v.date}
                                                                minDate={new Date()}
                                                                inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                                                                onChange={(newValue) => {
                                                                handleDateChange(i,newValue);
                                                                }}
                                                                ampm={false} // This line sets the 24-hour format
                                                            />
                                                        </LocalizationProvider>
                                                    </TableCell>

                                                    <TableCell>
                                      
                                                    </TableCell>
                                                    <TableCell>
                                                        <FacilitatorPickerElement facilitator={v?.facilitator|| -1} />
                                                    </TableCell> 

                                                    <TableCell>
                                                        <DeleteButton callback={(e)=>{}}  disabled={false} data={i} />

                                                    </TableCell>
                                                </TableRow>  
                                            ))  
                                            }
                                        </TableBody>
                                    </Table>
                    
                            </Box>

   
                        </CardContent>
                    </Card>  
                </Box> 

                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardContent>  
                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                Hosting
                            </Typography>
                            <Grid container spacing={2}>                            
                                <Grid item xs={12}>

                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="mode-radios-label">Where will this event be hosted?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="mode-radios-label"
                                            name="mode"
                                            value={formData?.mode}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel value="physical" control={<Radio />} label="Physical venue" sx={{ml:-1}}/>
                                            <FormControlLabel value="virtual" control={<Radio />} label="Virtual - online" />
                                        </RadioGroup>                                        
                                        <FormHelperText size="small" sx={{ml:-1}}>Physically hosted events are typically in-person at a physical space. Virtually hosted events are typically online uses platforms such as: Zoom, google meet etc.</FormHelperText>                            

                                    </FormControl>


                                </Grid>    
                                <Grid item xs={12}>
                                </Grid>                          
                            </Grid>
                        </CardContent>
                    </Card>  
                </Box> 

                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardHeader title="Notifications" subtitle="Configure how you would like notifications to be managed"/>
                        <CardContent>  
                            <Grid container spacing={2}>                            
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="mode-radios-label">What notifications would you like to use?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="notifications-radios-label"
                                            name="mode"
                                            value={formData?.mode}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel value="default" control={<Radio />} label="Default" sx={{ml:-1}}/>
                                            <FormControlLabel value="configured" control={<Radio />} label="Configured" />
                                        </RadioGroup>                                        
                                        <FormHelperText size="small" sx={{ml:1}}>Default uses the standard notifications. Configured allows you to select which notification you would like to use.</FormHelperText>                            

                                    </FormControl>


                                </Grid>    
                                <Grid item xs={12}>
                                </Grid>                          
                            </Grid>
                        </CardContent>
                    </Card>  
                </Box> 

                <Box sx={{p: 2}}>
                    <Card variant="outlined">
                        <CardHeader title="Properties" subtitle="Configure optional properties for this event. If enabled you can define the facilitators, terms, categories and location"/>
                        <CardContent>  
                            <Grid container spacing={2}>                            
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="mode-radios-label">Would you like to add properties to this event?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="properties-radios-label"
                                            name="mode"
                                            value={formData?.mode}
                                            onChange={handleChange}
                                        >

                                            <FormControlLabel value="no" control={<Radio />} label="No" sx={{ml:1}}/>
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        </RadioGroup>                                        
                                        <FormHelperText size="small" sx={{ml:1}}></FormHelperText>                            

                                    </FormControl>


                                </Grid>    
                                <Grid item xs={12}>
                                </Grid>                          
                            </Grid>
                        </CardContent>
                    </Card>  
                </Box>
            </form>
         
            <BottomBar />
        </div>
    )
}