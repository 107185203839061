import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { AppBar, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
//import { Container } from '@mui/system';
import { Card, CardHeader, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import WarningIcon from '@mui/icons-material/Warning';
//import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ResponsiveAppBar from './appBar';
import { ManageButton } from './ManageButton';
//import { useNavigate } from "react-router-dom";

const actions = [
  { icon: <FolderOutlinedIcon />, name: 'Create library', type: 'create-library' }
];






function PackageProperties(props){

  const {title, subtitle, when, type} = props;

  return (
    <Card variant="outlined" className="video-statistics">
      <CardHeader title={title} subheader={subtitle} className="header" />
        <CardContent>
          <p className="allowance">
              {type} <span className="of"> paid {when}</span>
          </p>
        </CardContent>
    </Card>
  )
}



function LibProperties(props){

  const {title, subtitle, allowance, used, what} = props;

  return (
    <Card variant="outlined" className="video-statistics">
      <CardHeader title={title} subheader={subtitle} className="header" />
        <CardContent>
          <p className="allowance">
              {used}<span className="of"> / {allowance} {what}</span>
          </p>
        </CardContent>
    </Card>
  )
}

function CreateLibraryDialog( props )
{
    const {open, onClose, onRefresh} = props;
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState('');
    const [error, setError] = React.useState('');
    const onSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      var chain = '';
      chain += '&name=' + encodeURI(name);

      fetch(`/video/add-media-library/`, {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body: window.video_settings.security_name + '=' + window.video_settings.security_value + chain ,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
      })
      .then(res => {
        return res.json();
      })
      .then(async(response) => {
        //console.log(data);
        if(response.status === 'OK')
        {
          await onRefresh();
          onClose(false)
          setLoading(false)
        }
        else
        {
            setLoading(false)
            setError('Failed to add library. Reason: ' + response.reason);
        }
      }).catch((e)=>{
        setLoading(false)
        setError('Network error. Please reload and try again. ')
      });  
    }

    return (
      <Dialog open={open} fullWidth={true} maxWidth="sm">
            <form onSubmit={onSubmit}>
              <DialogTitle>Create library</DialogTitle>
              <DialogContent>

                {
                  !!!loading && !!!error &&
                  <Grid container  direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3} className="mt-2">
                      <Grid item xs={12} className="confirm">
                          <DialogContentText>
                              Please enter the name of the library
                          </DialogContentText>
                          <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          fullWidth
                          variant="standard"
                          value={name}
                          required
                          inputProps={{pattern:"[a-zA-Z0-9 ]{1,250}"}}
                          onChange={(e) => {setName(e.target.value)}}
                        />                        
                      </Grid>
                  </Grid>
                }
                {
                  !!loading &&(
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ margin: 'auto' }} size={100} />
                  </Box>                  
                  )
                }
                {
                  !!error &&(
                  <Box sx={{ display: 'flex' }}>
                    <div className="alert"><span><WarningIcon /></span>{error}</div>
                  </Box>                  
                  )                
                }                
              </DialogContent>
              <DialogActions>
                <Button onClick={() => onClose(false)} disabled={!!loading}>Cancel</Button>
                <Button onClick={() => {}} disabled={!!loading || !!error} type="submit">Create</Button>
              </DialogActions>
            </form>

        </Dialog>
    )  
}


export default function VideoLibraries() {
  const [addlib, onAddLib] = React.useState(false);
  const [libraries, onSetLibrary] = React.useState(window.video_settings?.package?.libraries || []);
 // const navigate = useNavigate();

  const goto = ( to ) => {
    //navigate( to );
    window.location.href = to;
  }
  
  const refreshLibraries = () => {

      try
      {
          new Promise((resolve, reject) => {

              fetch("/video/get-media-libraries", {
                  method: "GET",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  //body: window.video_settings.security_name + '=' + window.video_settings.security_value ,
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest'
                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(async(response) => {
                  //console.log(data);
                  if(response.status === 'OK')
                  {
                      resolve(response.libraries);
                  }
                  else
                  {
                      reject('Failed to delete folder. Reason: ' + response.reason);
                  }
              }
              ).catch((e)=>{
                reject('Network error. Please reload and try again. ', e)
              }); 

          }).then( libraries => {
              console.error('fetched library response', libraries);
              onSetLibrary(libraries || [])
          }).catch(error => {
            console.error('Failed to load library.', error)
            onSetLibrary([])    
          });
      }
      catch(e)
      {
        console.error('Failed to load library.', e)
        onSetLibrary([])
      }

  }



  return (
    <div>
      <Box sx={{p:2}}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>

              <Grid item xs={12}>
                <ResponsiveAppBar 
                    title={"Videos"} 
                    widgets={[<Box sx={{p: 2}}><Button  variant="outlined" color="primary" onClick={() => onAddLib(true)}>Create Library</Button></Box>]}
                />  
              </Grid>


              <Grid item xs={12}>
                <Card>
                    <CardHeader></CardHeader>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <PackageProperties {...window.video_settings.package.platform}/>
                            </Grid>
                            {
                                window.video_settings.package.properties.map( (p) => (
                                    <Grid item xs={12}  md={3} key={uuidv4()}>
                                        <LibProperties {...p} />
                                    </Grid>          
                                ))
                            }                            
                        </Grid>
                        
                        <Grid item xs={12} sx={{pt: 2, pb:2}}>
                              <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                  Libraries <span className='small'>Libraries provide you with a logical way to manage and file your videos. All videos that you upload must be associatd to a library.</span>
                              </Typography>
                        </Grid>  

                        <Grid item xs={12} className="section">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right"># Videos</TableCell>
                                            <TableCell align="right">Manage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                             !libraries?.length &&
                                             <TableRow>
                                                <TableCell colSpan={4}>
                                                <Box sx={{p: 10}}>
                                                    <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You currently have no videos.</Typography>
                                                </Box>                                                    
                                                </TableCell>
                                             </TableRow>
                                        }
                                        {
                                             libraries?.length && libraries?.map( (l) =>(
                                                <TableRow key={`video-${l.id}`}>
                                                    <TableCell>{l.id.slice(-4)}</TableCell>
                                                    <TableCell>{l.name}</TableCell>
                                                    <TableCell  align="right">{l?.files?.length || 0}</TableCell>
                                                    <TableCell  align="right">
                                                        <ManageButton callback={(e)=>{ goto(`/video/library/${l.id}`)}}  data={l}/>                                                        
                                                    </TableCell>
                                                </TableRow>

                                             ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                    </CardContent>
                    
                </Card>
              </Grid>
              

        </Grid>
      </Box>
      {
        addlib &&
        <CreateLibraryDialog open={true} onClose={onAddLib} onRefresh={refreshLibraries}/>
      }
    </div>
  );
}
