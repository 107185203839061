import CryptoJS from "crypto-js";
const ekey = window.encrypt_key;
const varkey = 'a';

export const getCache = (key, ignore_epoch = false) => {
    var now = new Date();
    var fullDaysSinceEpoch = Math.floor(now/8.64e7);

    let k = `${key}-${window.configuration.cid}-${fullDaysSinceEpoch}`;
    if(ignore_epoch)
    {
        k = `${key}-${varkey}-${window.configuration.cid}`;
    }

    const raw = sessionStorage.getItem(k);

    if(!!raw)
    {
        const bytes = CryptoJS.AES.decrypt(raw, ekey);
        try{
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));     
            return data || null;   
        }
        catch(e)
        {
            return null;
        }
    }


    return null;
}


export const setCache = (key, data, ignore_epoch = false) => {
    var now = new Date();
    var fullDaysSinceEpoch = Math.floor(now/8.64e7);

    let k = `${key}-${window.configuration.cid}-${fullDaysSinceEpoch}`;
    if(ignore_epoch)
    {
        k = `${key}-${varkey}-${window.configuration.cid}`;
    }

    const raw = CryptoJS.AES.encrypt(JSON.stringify(data), ekey).toString();
    if(raw)
        sessionStorage.setItem(k, raw);
}


export const checkEpochShift = ( prefix ) =>
{

    const key = `fullDaysSinceEpoch_${prefix}`
    const currentDays = `${Math.floor(Date.now() / 8.64e7)}`;
    let previousDays = sessionStorage.getItem(key);
  

    console.log("Epoch - now vs then", key, currentDays, previousDays);

    if (currentDays !== previousDays) {
      // Update the value in the session store
      if(previousDays)
      {
        sessionStorage.removeItem(key)
      }
      sessionStorage.setItem(key, currentDays);
  
      // Trigger the refresh function
      return true;
    }  
    return false;  
}