import * as React from 'react';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import {isMobile} from 'react-device-detect';
import { DateRange } from 'react-date-range';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  addWeeks
} from "date-fns";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



export function DateRangeSelector( props ){

    const {onChange, range, defaultrange, lastyear, lastweek, lastmonth, last3months} = props;

    const [staticRanges, setStaticRanges] = React.useState([])
    const [state, setState] = React.useState([
        {
          startDate: range.start,
          endDate: range.end,
          key: 'selection'
        }
    ]);

    React.useEffect(() => {
      let x = [];
      if(!!defaultrange)
      {
        x = [...defaultStaticRanges];
      }
      if(!!lastweek)
      {
        x.push(          {
          label: "Last week",
          range: () => ({
            startDate: startOfWeek(addWeeks(new Date(), -1)),
            endDate: endOfWeek(addWeeks(new Date(), -1))
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        });
      }
      if(!!lastmonth)
      {
        x.push(          {
          label: "Last month",
          range: () => ({
            startDate: startOfMonth(addMonths(new Date(), -1)),
            endDate: endOfMonth(addMonths(new Date(), -1))
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        });
      }
      if(!!last3months)
      {
        x.push(          {
          label: "Last 3 months",
          range: () => ({
            startDate: addMonths(new Date(), -3),
            endDate: new Date()
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        });
      }
      if(!!lastyear)
      {
        x.push(          {
          label: "Last 12 months",
          range: () => ({
            startDate: addYears(new Date(), -1),
            endDate: new Date()
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        });
      }

      setStaticRanges(x);

    },[])

    React.useEffect(() => {
        if(!!state)
        {
          const s = new Date(state[0]?.startDate);
          s.setHours(0,0,0,0);
          const e = new Date(state[0]?.endDate);
          e.setHours(23,59,59,999);
          //console.log("Date change", s, e)
          onChange( { start: s, end: e} );
        }
    }, [state])

    
    

   // console.log("is mobile", isMobile);

    return (
      <React.Fragment>
      {
        !isMobile &&
        <DateRangePicker
        onChange={item => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={isMobile?1:2}
        ranges={state}
        direction={isMobile?'vertical':'horizontal'}
        staticRanges={staticRanges}
        />
      }
      {
        isMobile &&
        <DateRange
        onChange={item => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={isMobile?1:2}
        ranges={state}
        direction={isMobile?'vertical':'horizontal'}
        />
      }

      </React.Fragment>

  
            
            
    );
}

