import React from 'react';
import { Box, Typography } from '@mui/material';

const QuestionaireTextBlock = ({id, text, index, required, answer, onAnswer }) => {
    const jsonUnescape = (str) =>{
        const x =   str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
        return x;
    }
    return ( 
        <React.Fragment>
            <div>
                <Box pt={2} pb={2} >
                <Typography variant="body1"> <span dangerouslySetInnerHTML={{ __html: jsonUnescape(text) }} /></Typography>
                </Box>                
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireTextBlock;