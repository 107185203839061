import {  Button, Card, CardContent, Divider, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { post } from '../../libraries/network';
import { DeleteButton } from '../elements/DeleteButton';
import { ManageButton } from '../elements/ManageButton';
import { CodeButton } from '../elements/CodeButton';
import { PassEditor } from '../elements/passEditor';
import { BottomBar } from '../elements/BottomBar';
import PromptDialog from '../elements/promptDialog';
import { ProgressDialog } from '../elements/progressDialog';
import CodeDialog from '../elements/codeDialog';
import { encode } from 'js-base64';

const template =  {
    "id": null,
    "name": '',
    "description": '',
    "credits": 1,
    "price": 0,
    "expires": 30,
    "expiration_starts": "0",
    "period": 0,
    "issubscription": false,
    "customerqtylimited": false,
    "customerqtylimit": 0,
    "accountqtylimited": false,
    "accountqtylimit": 0,
    "vouchers": []
  };


export function Passes()
{
    const [apasses, setAPasses] = React.useState([]);
    const [vouchers, setVouchers] = React.useState([]);
    const [error, setError] = React.useState('');
    const [busy, setBusy] = React.useState(false);
    const [edit, setEdit] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [archive, setArchive] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressText, setProgressText] = React.useState(false);
    const load = async() => {
        try{

            setBusy(true)
            
            const d = await post( `/passes/ajax-fetch-passes?refresh=1`, null);
            if(d.status === 'OK')
            {
                
                setAPasses( d?.data?.passes );
                setVouchers( d?.data?.vouchers  );
    
            }
            else
            {
                setError("Failed to get pass list");                
            }
            setBusy(false)
        }
        catch(e)
        {
            if(window?.static_override)
            {
                setTimeout(async () => {
                console.log( window?.static_passes );
                setAPasses( window?.static_passes );
                setVouchers( window?.static_vouchers )
                setBusy(false)
                }, 2000);

            }
            else
            {
                setError("Sorry, we have detected a network error. We are going to re-direct you to log back in.");
                console.error(e);
                setTimeout(()=>{
                    const {
                        origin
                      } = window.location;
                    window.location.replace(`${origin}/client/logout?redirect=${encodeURI(window.location.href)}`);
                }, 100);


            }

        }
    }

    const onDelete = async() => {
        if(!!archive)
        {
            const v = `id=${archive?.id}`;
            try{
                setArchive(false);
                setProgress(10);
                setProgressText('Deleting the pass')
                const d = await post('/passes/ajax-passes-delete', v); 
                setProgress(100);

                setTimeout(() => {
                    setProgress(0);
                    load();
                }, 3000);
            }
            catch(e)
            {

                setProgress(0);
                setError("Sorry, we have detected a network error. We are going to re-direct you to log back in.");
                console.error(e);
                setTimeout(()=>{
                    const {
                        origin
                      } = window.location;
                    window.location.replace(`${origin}/client/logout?redirect=${encodeURI(window.location.href)}`);
                }, 1000);              
            }
        }
    }

    React.useEffect(() => {
        load();
    }, [])

    const base64 = ( a ) => {
        return encode(a);
    }


    return (
        <div className="rvNS">
            <Box>
                <Grid container spacing={2} sx={{p: 2}}>
                    <Grid item xs={12} md={12}>
                        <ResponsiveAppBar 
                        widgets={[<Box sx={{p: 2}}><Button  variant="outlined" color="primary" onClick={()=>setEdit(template)}>Create Pass</Button></Box>]}
                        />     
                    </Grid>
                </Grid>           
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Active Passes <span className='small'></span>
                                </Typography>
                                <Divider/>
                                <Box sx={{pt: 3, pb: 3}}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">Manage</TableCell>
                                                    <TableCell align="right">Code</TableCell>
                                                    <TableCell align="right">Archive</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    !busy && apasses?.map( p => (
                                                        <TableRow key={`pass-row-${p.id}`}>
                                                            <TableCell>{p.id.toString(16)}</TableCell>
                                                            <TableCell>{p.name}</TableCell>
                                                            <TableCell align="right"><ManageButton callback={(e)=>{ setEdit(e)}}  data={p}/></TableCell>
                                                            <TableCell align="right"><CodeButton callback={(e)=>{setCode(e)}}  data={p}/></TableCell>
                                                            <TableCell align="right"><DeleteButton callback={(e)=>{setArchive(e)}}  data={p}/></TableCell>
                                                        </TableRow>
                                                    ))
                                                    
                                                }
                                                {
                                                    busy && 
                                                    <TableRow>
                                                        <TableCell colSpan={5}>
                                                            <Stack>
                                                                <Skeleton height={40} />
                                                                <Skeleton  height={40} />
                                                                <Skeleton  height={40} />
                                                                <Skeleton  height={40} />
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                        
                                </Box>
                            </CardContent>
                        </Card>  

                    </Grid> 
            

                </Grid> 
            </Box>
  
                {
                    edit && <PassEditor object={edit} vouchers={vouchers} onRefresh={async()=>{await load()}} onClose={()=>{setEdit(null)}}/>
                }
                {
                    archive && <PromptDialog title="Please confirm"  description="Are you sure that you want to delete this pass? Once deleted it can no longer be used." handleClose={()=>{setArchive(false)}}  handleOK={async()=>{await onDelete()}} /> 
                }
                {
                    progress > 0 &&
                    <ProgressDialog progress={progress} title="Please wait" text={progressText} />
                }
                {
                    code &&
                    <CodeDialog title="Pass Code Widget" description="Select one of the following widgets below" base={window?.company_settings?.base} uri={`checkout/package-buy/${base64('v1.0,' + code?.id?.toString())}`} handleClose={()=> setCode(false)}/>
                }
                <BottomBar />
        </div>
    )
}