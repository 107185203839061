import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Cookies from 'js-cookie';

const TwoFactorDialog = ({ open, onClose, onNavigate }) => {
  // State to track whether the dialog is open
  const [isOpen, setIsOpen] = useState(open);


  // Handler for closing the dialog
  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  // Handler for navigating to 2FA settings
  const handleNavigate = () => {
    handleClose(); // Close dialog before navigating
    onNavigate();
  };

  // Handler for "Not Now" button
  const handleNotNow = () => {
    // Set a cookie to prevent the dialog from re-opening for 2 months
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    Cookies.set('disableTwoFactorDialog', 'true', { expires: expirationDate });
    handleClose();
  };

  // Check if the dialog should be displayed based on the cookie
  useEffect(() => {
    const disableDialog = Cookies.get('disableTwoFactorDialog');
    if (disableDialog) {
      setIsOpen(false);
    }
  }, [open]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Enable Two-Factor Authentication</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          For enhanced security, we recommend enabling two-factor authentication (2FA) for your account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNavigate} color="primary">
          Go to 2FA Settings
        </Button>
        <Button onClick={handleNotNow}>
          Remind me in 30 days
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TwoFactorDialog;
