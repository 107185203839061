import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, TextField } from '@mui/material';

const EditSubscriptionGraceDialog = ({ open, onClose, onSave, initialGrace }) => {
  const [gracePeriod, setGracePeriod] = useState(initialGrace || 0);
  
  const handleInputChange = (event) => {
    const { value } = event.target;
    setGracePeriod(value);
  };
  
  const handleSave = () => {
    if (!isNaN(gracePeriod) && Number.isInteger(parseFloat(gracePeriod)) && parseInt(gracePeriod) >= 0) {
      onSave(parseInt(gracePeriod));
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent>
        <p>
          The Grace period allows you to automatically change the expiration of future generated passes beyond the standard period.
          By default, the grace period is zero. This means that the subscription and any associated pass expire at the same time.
          By extending the grace period, the pass will have an expiration that extends beyond the renewal.
          This means that as the subscriptions renew, passes may overlap.
        </p>
        <TextField
          label="Set Grace Period (in days)"
          value={gracePeriod}
          onChange={handleInputChange}
          type="number"
          inputProps={{ min: 0 }}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save Changes</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubscriptionGraceDialog;
