import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const CategoryAction = ({ categoryId, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (callback) => {
        callback();
        handleClose();
    };

    const handleEdit = () => {
        navigate(`/category/edit/${categoryId}`);
    };

    return (
        <>
            <IconButton onClick={handleClick} aria-controls="options-menu" aria-haspopup="true">
                <MoreVertIcon />
            </IconButton>
            <Popover
                id="options-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleOptionClick(handleEdit)}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => handleOptionClick(onDelete)}>
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
};

export default CategoryAction;
