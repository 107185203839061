import {   Card, CardContent, CardHeader, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import ResponsiveAppBar from '../elements/appBar';
import { BottomBar } from '../elements/BottomBar';
import { Link } from 'react-router-dom';




  

export function SupportIndex()
{
    const [loading, setLoading] = React.useState( true );

    React.useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
    
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore

                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                        // the form is ready to manipulate!
                        var ele =  window.document.getElementsByClassName('hs-form-iframe')[0].contentWindow.document.getElementById("email-84809226-6aea-45d1-beb3-3239cf769bfc");
                        ele.value = window.tickets.email;
                        ele.focus();
                        ele.readOnly = true;
                        setLoading(false);
                       // var frm =  window.document.getElementsByClassName('hs-form-iframe')[0].contentWindow.document.getElementById("hsForm_84809226-6aea-45d1-beb3-3239cf769bfc");
                       // if(frm)
                       // {
                        //    var label = frm.getElementsByClassName('hs-form__virality-link');
                        //    if(!!label)
                        //    {
                       //         label[0].style.visibility = 'hidden';  
                        //    }
                            //frm[0].style.display = 'none';
                        //}

                        var styleElement = window.document.getElementsByClassName('hs-form-iframe')[0].contentWindow.document.createElement('style');
                        styleElement.innerHTML = '.hs-form__virality-link { visibility: hidden; } .hs-button.primary.large{width: 100%;}';
                
                        window.document.getElementsByClassName('hs-form-iframe')[0].contentWindow.document.head.appendChild(styleElement);

                        //console.log(window.document.getElementById('hs-form-iframe-0').contentWindow.document.getElementById("email-84809226-6aea-45d1-beb3-3239cf769bfc").value);

                    }
                });  


                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "139619509",
                    formId: "84809226-6aea-45d1-beb3-3239cf769bfc",
                    target: '#hubspotForm'
                });


            }
        });


    }, []);

    return (
        <div className="rvNS">
            <Box>
                <Grid container spacing={2} sx={{p: 2}}>
                    <Grid item xs={12} md={12}>
                        <ResponsiveAppBar 
                        widgets={[]}
                        />     
                    </Grid>
                </Grid>           
            </Box>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                           
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                    Support <span className='small'>Complete the form to raise a ticket</span>
                                </Typography>
                                <Divider /> 
                                <Typography variant="body" component="div" sx={{ flexGrow: 1, pt: 1 }}>
                                    Welcome to our new support system. To raise a suppport ticket, please complete the form below. All responses to this ticket will be sent via email. If you are looking for our legacy ticket system, <a href="/ticket/legacy">please click here</a>. Please note, no new tickets can be raised via our legacy system.
                                </Typography>  
                                
                                <Box id="hubspotForm" sx={{pt: 4, pb:2}}></Box>
                                {
                                    loading && <Box sx={{p: 2}}>
                                    <Stack>
                                        <Skeleton height={40}/>
                                        <Skeleton animation="wave" height={40} />
                                        <Skeleton animation="wave" height={40} />
                                        <Skeleton animation="wave" height={40} />
                                        <Skeleton animation={false} height={40} />
                                    </Stack>
                                  </Box>                                                    

                                }
                            </CardContent>
                        </Card>  

                    </Grid> 
            

                </Grid> 
            </Box>

                <BottomBar />
        </div>
    )
}