import { Button, Card, CardContent, Divider, Grid, IconButton, Skeleton, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { DateRangeSelector } from '../elements/DateRangeSelector';
import { addDays } from 'date-fns';
import { EventSelector } from '../elements/EventSelector';
import { get } from '../../libraries/network';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ProgressDialog } from '../elements/progressDialog';
import AlertDialog from '../elements/alertDialog';


export function ReportBookingOverview()
{

    const [range, setRange] = React.useState({ start: new Date(), end: addDays(new Date(), 7) })
    const [events, setEvents] = React.useState([])
    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [filtr, setFilter] = React.useState([]);
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0);

    const processData = ( d ) => {

        const x  = d?.filter( f => filtr.length === 0 || filtr.includes(f.eid)).reduce(( object, i ) => {
            if(!object.hasOwnProperty( i.id ))
            {
                object[i.id] = { id: i.id, firstname: i.firstname, lastname: i.lastname, email: i.email, mobile: i.mobile,  dates: i.q, data: [{ eid: i.eid}]};
            }
            else
            {
                const s = object[i.id].data.find( f => f.eid === i.eid);

                if(!s)
                {
                    object[i.id].data.push({ eid: i.eid});
                }
                object[i.id].dates += i.q;
            }
            return object;

        }, {});
        console.log("object", x, filtr, d);
        setData(Object.values(x))
    }

    const onDownloadClick  = () =>
    {
        var rows = [
            ["first name", "last name", "email address", 'mobile', 'events booked', 'dates booked'],
        ].concat(data.map( r => [`${r.firstname}, ${r.lastname}, ${r.email}, ${r.mobile}, ${r.data.length}, ${r.dates}`]));
 
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => Object.values(e).join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event-report-" + (new Date()).toDateString()+ ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    React.useEffect(() => {
        // load the events for this range
        async function load(){
            try{
                let z = {};

                const d = Object.keys(range).map(function(key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(range[key].getTime());
                }).join('&');
                setProgress(10);
  
                const data = await get( `/reportsrestapi/events-in-date-range?${d}`);
                setEvents( data );
                setTimeout(() => {
                    setProgress(0);
                }, 100)

            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    console.log( window?.static_reports );
                    setEvents( window?.static_reports.events );
                    setTimeout(() => {
                        setProgress(0);
                    }, 500)
                }
                else
                {
                    setProgress(0);
                    setError("Failed to get the event list");

                }
    
            }
        }
        load();
    }, [range]);


    const onSubmit = ( e ) => {
        e.preventDefault();
        setBusy(true);

        async function load() {
            try{

                let z = {};

                for (var property in range) {
                    if (range.hasOwnProperty(property)) {
                        console.log(range);
                        z[property] = range[property].getTime()
                    }
                }
                const d = new URLSearchParams(z).toString();
                const data = await get( `/reportsrestapi/events-booked-between-dates?${d}`);
                setProgress(10);
                processData(data);
                setProgress(100);
                setTimeout(() => {
                    setBusy(false);
                    setProgress(0);
                    //setData( window?.static_reports.data );
                }, 2000)
            }
            catch(e)
            {
                console.error(e);
                if(window?.static_override)
                {
                    setProgress(100);
                    console.log( window?.static_reports );
                    setTimeout(() => {
                        setBusy(false);
                        setProgress(0);
                        //setData( window?.static_reports.data );
                        processData(window?.static_reports.report_booking_overview_data);
                    }, 2000)
                }
                else
                {
                    setError('Failed to load report');
                    setProgress(0);
                    setBusy(false);


                }   
                
                // to do - present error 

            }
        }
        load();

    }


    return (
        <div className="rvNS">
            <form onSubmit={onSubmit}>
                <Box sx={{p: 0}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                        Configure the report <span className='small'>complete the following form and click fetch report</span>
                                    </Typography>
                                    <Divider/>
                                    <Box sx={{pt: 3, pb: 3}}>

                                       <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 1: Select a date range</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRangeSelector  lastyear={true} lastweek={true} lastmonth={true} last3months={true} onChange={setRange} range={range}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h7' gutterBottom component="div">Step 2: Optional - select one or more events</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EventSelector setEvents={setFilter} list={events} events={filtr}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <Button type="submit" fullWidth variant="contained">Fetch report</Button>
                                            </Grid>                                              
                                       </Grid>
                                         
                                    </Box>
                                </CardContent>
                            </Card>  

                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container>
                                        <Grid item >
                                            <Typography gutterBottom variant="h5" component="div" sx={{pt: 1, pb:1}}>
                                                Report
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item >
                                            <IconButton aria-label="delete" size="large" onClick={onDownloadClick}>
                                                <FileDownloadIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>                                        
                                    </Grid>


                                    <Divider/>
                    
                                    { 
                                        busy && 
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Stack>
                                                <Skeleton height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                                <Skeleton  height={40} />
                                            </Stack>
                                        </Box>                                        

                                    }
                                    { 
                                        !busy && !data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item><NotInterestedIcon className="icon opaque"/></Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    { 
                                        !busy && !!data?.length &&
                                        <Box sx={{pt: 3, pb: 3}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    ID
                                                                </TableCell>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Email
                                                                </TableCell>
                                                                <TableCell>
                                                                    Contact
                                                                </TableCell>
                                                                <TableCell>
                                                                    Events
                                                                </TableCell>
                                                                <TableCell>
                                                                    Date
                                                                </TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                data?.map( d => (
                                                                    <TableRow key={`report-${d.id}`}>
                                                                        <TableCell>
                                                                            {d.id}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {`${d.firstname} ${d.lastname}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.email}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.mobile}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.data.length}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.dates}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))   
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Grid>
                                        </Box>
                                    }
                                          
                                </CardContent>
                            </Card>  

                        </Grid>


   
                    </Grid>
                </Box>
            </form>                
            {
                !!error &&
                <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
            }
            {
                progress > 0 &&
                <ProgressDialog title="Please standby. Fetching data..." text={''} progress={progress} />
            }
        </div>
    )
}