import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import { post } from '../../libraries/network';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';


import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import { getDateFormatString, getNavigatorLanguage } from '../../libraries/format';


export function BookMediaByCash( props ) {

  const {handleClose, setProgress, setError, allmedia, access, reload} = props;
  const [media, setMedia] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const [expires, setExpires] = React.useState(false);
  const [expireson, setExpiresOn] = React.useState(false);

  const toUTCDate = function( date ) {
      return date.getUTCFullYear() + '-' +
             ('0'+ (date.getUTCMonth()+1)).slice(-2) + '-' +
             ('0'+ date.getUTCDate()).slice(-2) + 'T' + date.getHours() + ":" 
             + date.getMinutes();

  }
  React.useEffect(() => {

    if(media?.id)
    {
      if(!Number.isNaN(price))
      {

          if(expires)
          {
            if(expireson)
            {
                console.log("expireson", expireson);
                setReady(true);
                return;
            }
            else
            {
              console.log("expireson", expireson);
            }
          }
          else
          {
            console.log("expires off", expires);
            setReady(true);
            return;
          }
      }
      else
      {
        console.log("Price not an integer", typeof price);
      }
    }
    else
    {
      console.log("Media id not specified", media);
    }
    setReady(false);
  },[media, price, expires, expireson]);

  

  React.useEffect(() => {

    if(media)
    {
      let date = new Date();
      date.setDate(date.getDate() + parseInt(media?.period));

      setReady(true);
      setPrice(parseFloat(media.price));
      setExpires(media.expires);
      setExpiresOn(date);
    }
    else
    {
      setReady(false);
      setPrice(0.00);
      setExpires(false);
    }
  }, [media])


  const handleChange = (event) => {
    setMedia(event.target.value);

  };


  const handlePriceChange = (e ) => {
    setPrice(parseFloat(e.target.value))
  }

  const getDateFormatString = (locale) => {
    
    const options = {
      hour: "numeric",
      minute: "numeric",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
  
    const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(
      Date.now()
    );
  
  
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "hour":
            return "HH";
          case "minute":
            return "mm";
          case "second":
            return "SS";
          case "day":
            return "dd";
          case "month":
            return "MM";
          case "year":
            return "yyyy";
          default:
            return obj.value;
        }
      })
      .join("");
  }
  
  
  
    const getNavigatorLanguage = () => {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
    }

  const onSave = async(e) => {
      e.preventDefault();

      const d = new Date(expireson);
      const str = d.toISOString().split('T');
  

      let chain = '&id='            + media?.id;
      chain    += '&paid='          + encodeURIComponent(price);
      chain    += '&expires='       + encodeURIComponent(expires);
      chain    += '&expireson='     + str[0] + ' ' + str[1].split('.')[0];
  

      try{
        setProgress(1);
        await post(`/customersrestapi/media/cash/${encodeURIComponent(access)}`, chain);
        setProgress(50);
        await reload();
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          handleClose();
        }, 2000)
      }
      catch(e)
      {
        setTimeout(() => {
          setProgress(0);
          setError("Sorry, we have been unable to complete this booking. Please refresh this page an try again.");
          handleClose();
        }, 2000)
      }


  }

  const handleSetExpiresOn = ( e ) => {

    console.log("New date is", e);
    setExpiresOn(e);
  }
  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Assign a Video - Cash Payment</DialogTitle>
        <DialogContent>
            <DialogContentText>
                To assign a video to this customer, please complete the following form:
            </DialogContentText>
            <Box sx={{pt:2, pb:2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="media-select-select-standard-label">Select a Video</InputLabel>
                                    <Select
                                        labelId="media-select-select-standard-label"
                                        id="media-select-select-standard"
                                        value={media}
                                        onChange={handleChange}
                                        label="Select a Video"
                                    >
      
                                    {
                                      allmedia?.map( e => (
                                        <MenuItem key={`media-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                                      ))
                                    }
                  
                                    </Select>
                                </FormControl> 
                            </Grid>
                 
                    
                            <Grid item xs={12}>
                                <TextField
                                    id="price"
                                    label="Price paid"
                                    type="number"
                                    fullWidth
                                    disabled={!media?.id}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={expires}
                                      onChange={(e) => setExpires( e.target.checked )}
                                      name="expires"
                                      disabled={!media?.id}
                                      color="primary"
                                    />
                                  }
                                  label="Enable expiration of video"
                                  />
                            </Grid> 
                            <Grid item xs={12}>
                              {
                                media?.id && expires &&
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
      
                                        inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                                        renderInput={(props) => <TextField {...props}  fullWidth/>}
                                        label="Expires on"
                                        disabled={!media?.id || !expires}
                                        value={!expires ? '' : expireson}
                                        onChange={(e) => {handleSetExpiresOn(e)}}
                                        minDate={new Date()}
                                        ampm={false} // This line sets the 24-hour format

                                    />
                                </LocalizationProvider>  
                              }
                                               
                            </Grid>                                                        
                        </Grid>
                
 
            </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined'  disabled={!ready} color="primary">Assign Video</Button>
        </DialogActions>
        </form>
      </Dialog>     
    </div>
  );
}


export function BookMediaByPass( props ) {

  const {handleClose, setProgress, setError, allmedia, access, passes, reload} = props;
  const [media, setMedia] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const [expires, setExpires] = React.useState(false);
  const [expireson, setExpiresOn] = React.useState(false);
  const [pass, setPass] = React.useState(false);
  const [passesf, setPassesf] = React.useState(passes?.filter(f => (f.credits > 0 && f.allocated <= f.credits)));

  React.useEffect(() => {

    if(media?.id)
    {
      if(Number.isInteger(price))
      {
        if(pass?.id)
        {

            if(expires)
            {
              if(expireson)
              {
                  console.log("expireson", expireson);
                  setReady(true);
                  return;
              }
              else
              {
                console.log("expireson", expireson);
              }
            }
            else
            {
              console.log("expires off", expires);
              setReady(true);
              return;
            }

        }
        else
        {
          console.log("Pass not specified", pass);  
        }
      }
      else
      {
        console.log("Price not an integer", typeof price);
      }
    }
    else
    {
      console.log("Media id not specified", media);
    }
    setReady(false);
  },[media, price, pass, expires, expireson]);

  

  React.useEffect(() => {

    if(media)
    {
      let date = new Date();
      date.setDate(date.getDate() + parseInt(media?.period));

      setReady(true);
      setPrice(parseInt(media.credits));
      setExpires(media.expires);
      setExpiresOn(date);
      setPassesf(passes?.filter(f => {

        if(date)
        {
          if(!!f.expires)
          {
            const expires = new Date(f.expires);
  
            if(expires < date)
            {
              return false;
            }            
          }          
        }
        if(f.state === 'VOID')
        {
          return false;
        }
        return (f.credits > 0 && price <= (f.credits) );      
  
      }));


    }
    else
    {
      setReady(false);
      setPrice(0.00);
      setExpires(false);
    }
  }, [media])


  const handleChange = (event) => {
    setMedia(event.target.value);

  };


  const handlePriceChange = (e ) => {
    setPrice(parseFloat(e.target.value))
  }

  const handlePassChange = (e ) => {
    setPass( e.target.value);
  }

  const formatDate = ( d ) => {
      if(d)
      {
        const w = new Date( d );
        const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        const z = w.toLocaleDateString();
        return `${z} ${x}`;        
      }
      return '- -';

  }
  const onSave = async(e) => {
      e.preventDefault();

      const d = new Date(expireson);
      const str = d.toISOString().split('T');
  

      let chain = '&id='            + media?.id;
      chain    += '&paid='          + encodeURIComponent(price);
      chain    += '&expires='       + encodeURIComponent(expires);
      chain    += '&expireson='     + str[0] + ' ' + str[1].split('.')[0];
      chain    += '&pass='           + pass?.id;

      try{
        setProgress(1);
        await post(`/customersrestapi/media/credit/${encodeURIComponent(access)}`, chain);
        setProgress(50);
        await reload();
        setProgress(100);
        setTimeout(() => {
          handleClose()
          setProgress(0);
        }, 2000)
      }
      catch(e)
      {
        setTimeout(() => {
          setProgress(0);
          setError("Sorry, we have been unable to complete this booking. Please refresh this page an try again.");
          handleClose();
        }, 2000)
      }


  }


  const handleSetExpiresOn = ( e ) => {

    console.log("New date is", e);
    setExpiresOn(e);
  }

  return (
    <div>

      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={onSave}>
        <DialogTitle>Assign a Video - Pass Payment</DialogTitle>
        <DialogContent>
            <DialogContentText>
                To assign a video to this customer, please complete the following form:
            </DialogContentText>
            <Box sx={{pt:2, pb:2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="media-select-select-standard-label">Select a Video</InputLabel>
                                    <Select
                                        labelId="media-select-select-standard-label"
                                        id="media-select-select-standard"
                                        value={media}
                                        onChange={handleChange}
                                        label="Select a Video"
                                    >
      
                                    {
                                      allmedia?.map( e => (
                                        <MenuItem key={`media-list-item-${e.id}`} value={e}>{e.name}</MenuItem>
                                      ))
                                    }
                  
                                    </Select>
                                </FormControl> 
                            </Grid>
                 
                    
                            <Grid item xs={12}>
                                <TextField
                                    id="price"
                                    label="Credits used"
                                    type="number"
                                    fullWidth
                                    disabled={!media?.id}
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={expires}
                                      onChange={(e) => setExpires( e.target.checked )}
                                      name="expires"
                                      disabled={!media?.id}
                                      color="primary"
                                    />
                                  }
                                  label="Enable expiration of video"
                                  />
                            </Grid> 
                            <Grid item xs={12}>
                                    
                            <Grid item xs={12}>
                              {
                                media?.id && expires &&
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        inputFormat={  getDateFormatString(getNavigatorLanguage()) }
                                        renderInput={(props) => <TextField {...props}  fullWidth/>}
                                        label="Expires on"
                                        disabled={!media?.id || !expires}
                                        value={!expires ? '' : expireson}
                                        onChange={(e) => {handleSetExpiresOn(e)}}
                                        minDate={new Date()}
                                        ampm={false} // This line sets the 24-hour format

                                    />
                                </LocalizationProvider>  
                              }
                                               
                            </Grid>                  
                            </Grid>                                                        
                        </Grid>
                        <Grid item xs={12} sx={{mt:2}}>
                        <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="pass-select-select-standard-label">Select Pass</InputLabel>
                                    <Select
                                        labelId="pass-select-select-standard-label"
                                        id="pass-select-select-standard"
                                        value={pass || false}
                                        disabled={!media?.id}
                                        onChange={handlePassChange}
                                        label="Select a pass"
                                    >
                                    {
                                      passesf?.filter( f => ((f.credits > 0) &&((f.credits - f.used) >= price))).map( e => (
                                        <MenuItem key={`pass-list-item-${e.id}`} value={e}>{`${e.name}. ID: ${parseInt(e.id).toString(16)}. Credits: ${e.credits}. Expires: ${formatDate(e.expires)}`}</MenuItem>
                                      ))
                                    }
                                    </Select>
                                </FormControl> 

                        </Grid>
                
 
            </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='outlined'  disabled={!ready} color="primary">Assign Video</Button>
        </DialogActions>
        </form>
      </Dialog>     
    </div>
  );
}

export function BookMedia( props ) {
  const {passes, access, allmedia, initiate, reload} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState( false );
  const inputElement = React.useRef();


  const open = Boolean(anchorEl);


  React.useEffect(() => {
    if(initiate === 2)
      inputElement?.current?.click();
  }, [initiate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onBookByCash = () => {
    setState(1);
    handleClose();
  }
  const onBookByPass = () => {
    setState(2);
    handleClose();
  }


  return (
    <div>
      <Button
        id="book-button"
        aria-controls={open ? 'book-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        ref={inputElement}

      >
        Assign Video
      </Button>
      <Menu
        id="book-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'book-button',
        }}
      >
        <MenuItem onClick={onBookByCash}>Payment by cash</MenuItem>
        <MenuItem onClick={onBookByPass}>Charge a pass</MenuItem>
      </Menu>
      {
        state === 1 &&
        <BookMediaByCash access={access} allmedia={allmedia} handleClose={() => {setState(0)}} setError={setError} setProgress={setProgress} reload={reload}/>
      }
      {
        state === 2 &&
        <BookMediaByPass access={access}  allmedia={allmedia} handleClose={() => {setState(0)}} setError={setError} setProgress={setProgress} passes={passes} reload={reload}/>
      }



      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      }       

      {
          progress > 0 &&
          <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      } 

    </div>
  );
}