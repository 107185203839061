import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, InputAdornment, TableFooter, TablePagination, TextField, Typography, useMediaQuery, useTheme  } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import { DeleteButton } from './DeleteButton';
import { del } from '../../libraries/network';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ViewButton } from './ViewButton';
import ConfirmDialog from './confirmDialog';
import AlertDialog from './alertDialog';
import { ProgressDialog } from './progressDialog';
import QuestionnaireViewer from './subelements/CustomersQuestionnaires/QuestionnaireViewer';
import { ManageButton } from './ManageButton';
import QuestionnaireEditDrawer from './subelements/CustomersQuestionnaires/Questionnaire-edit-drawer';
import { AddQuestionnaireButton } from './AddQuestionnaireButton';
import CustomerQuestionniareMobile from './CustomerQuestionnaireMobile';



function createData( id, created, name, expires, important, quid, qo) {


    const w = new Date(expires);
    const x = w.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const z = w.toLocaleDateString();
    const xs = w.toLocaleDateString([], {year: '2-digit', month:'2-digit', day: 'numeric'});


    const p = new Date(created);
    const c = p.toLocaleDateString();
    const r =  { id, created: c, name, expires: `${z} ${x}`,  expires_short: `${xs}`, important, quid, qo};
    console.log("returning questionniare", r);
    return r;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomersTableQustionnaires( props ) {
  const {questionnaires, allquestionnaires, reload, initiate} = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [filtertext, setFilterText] = React.useState('');
  const [manage, setManage] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [archive, setArchive] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const access = window?.configuration?.access;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() =>{
    const frow = questionnaires?.filter( c => {

      if(!!!filtertext)
      {
        return true;
      }
      if( c.name.indexOf(filtertext) > -1 || c.date.indexOf(filtertext) > -1  || c.expires.indexOf(filtertext) > -1)
      {
        return true;
      }


      return false;
    }) || [];
    setRows(frow?.map( row => createData(row.id, row.date, row.name, row.expires, row.important_active, row.quid, row.qo)));
  }, [filtertext, questionnaires])




 const handleArchiveComplete = async( ) => {
  try{
    setArchive(false);
    setProgress(1);
    let chain = `id=${archive?.id}`;
    await del(`/customersrestapi/questionnaires/${window?.configuration?.access}/${archive?.id}`);
    setProgress(50);
    await reload();
    setProgress(100);
    setTimeout(()=> {
      setProgress(0);
    }, 500);
  }
  catch(e)
  {

    setTimeout(()=> {
      setProgress(0);
      setError("Failed to archive this questionnaire");
    }, 1000);
  }
}


  console.log("Rendering CustomersTableQuestionnaires", questionnaires, view);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container justifyContent="flex-end">

                <Box sx={{pt: 1}}>
                  <AddQuestionnaireButton initiate={initiate} questionnaires={allquestionnaires} access={access} reload={reload} />
                </Box>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1,  width: '45ch', pl:2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="search" 
                        variant="standard" 
                        placeholder="Search"
                        value={filtertext}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterText(e.target.value)} 
                      />
                </Box>  
          </Grid>      
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table aria-label="Bookings">
                <TableHead>       
                  <TableRow>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>ID</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Created on</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Questionnaire</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>Expires</TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Important</TableCell>
                      {
                        !isMobile && 
                        <React.Fragment>
                            <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>View</TableCell>
                            <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Edit</TableCell>
                            <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Archive</TableCell>                          
                        </React.Fragment>
                      }
                      {
                        isMobile && 
                        <React.Fragment>
                            <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>Manage</TableCell>
                        </React.Fragment>
                      }                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows && (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows).map((row) => (
                
                     
                        <TableRow
                          key={`customers-${row.id}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "1px solid  rgba(224, 224, 224, 1);" } }}
                          >
                        
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {`${parseInt(row.id).toString(16)}`} 
                              </TableCell>
                              <TableCell  align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                                {row.created} 
                              </TableCell>
        
                          
                              <TableCell align="left" sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>
                                {row.name}
                              </TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}}>{row.expires}</TableCell>
                              <TableCell align="left"  sx={{ display: { xs: 'table-cell', sm: 'table-cell' }}}>{row.important && <CheckCircleOutlineIcon />}</TableCell>
                              {
                                !isMobile &&
                                <React.Fragment>
                                    <TableCell align="left"><ViewButton key={`view-button-${row.id}`} callback={(e)=>{setView(e)}} data={row}/></TableCell>
                                    <TableCell align="left"><ManageButton key={`manage-button-${row.id}`} callback={(e)=>{setManage(e)}} data={row}/></TableCell>
                                    <TableCell align="left"  ><DeleteButton key={`delete-button-${row.id}`} callback={(e)=>{setArchive(e)}}  data={row}/></TableCell>
                                </React.Fragment>
                              }
                              {
                                isMobile &&
                                <React.Fragment>
                                    <TableCell align="left">
                                      <CustomerQuestionniareMobile 
                                          key={`mobile-manage-button-${row.id}`}
                                          context={row}
                                          viewActive={true}
                                          editActive={true}
                                          cancelActive={true}
                                          onEdit={setManage}
                                          onView={setView}
                                          onCancel={setArchive}
                                      />
                                    </TableCell>
                                </React.Fragment>
                              }                            
                          </TableRow>
                
               
                  ))}
                  {(rowsPerPage === 0 || !!!rows ||rows?.length === 0) &&
                      <TableRow>
                          <TableCell colSpan={12} sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no questionnaires completed yet for this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                          <TableCell colSpan={5} sx={{ display: { xs: 'table-cell', sm: 'none' }}}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="p" style={{textAlign: 'center'}}>You currently have no questionnaires completed yet for this customer.</Typography>
                            </Box>                                                    
                          </TableCell>
                      </TableRow>            
                  }
                </TableBody>
                <TableFooter>
                  <TableRow   sx={{ display: { xs: 'none', sm: 'table-row' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={12}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Questionnaires per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'questionnaires per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                  <TableRow   sx={{ display: { xs: 'table-row', sm: 'none' }}}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={!!rows ? rows.length : 0}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Transactions per page"
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'transactions per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>          
                </TableFooter>        
              </Table>
            </TableContainer>
        </Grid>
      </Grid>

      {
        archive && <ConfirmDialog title="Archive Customer's Answers" description="Are you sure that you want to archive your customer's answers to the questionnaire. This cannot be undone." handleClose={()=>{setArchive(false)}} handleComplete={handleArchiveComplete} />

      }
      {
        !!view && <QuestionnaireViewer title={view?.name} qandas={view?.qo} close={() => setView(false)}/>
      }
      {
        !!error &&
        <AlertDialog title="Problem detected" description={error} handleClose={()=>{setError(false)}} />             
      } 
      {
        progress > 0 &&
        <ProgressDialog title="Please standby. Your change is being processed." text={''} progress={progress} />
      }
      {
        manage && <QuestionnaireEditDrawer close={() => setManage(false)} questionnaire={manage} reload={reload} />
      }

    </React.Fragment>


  );
}